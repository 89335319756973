import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { useBillSubjectAssociations, useDeleteBankLineSubjectAssociations } from '../../bankReconciliation/hooks'
import { DifferenceType } from '../../bankReconciliation/types'
import { PaymentRegistrationSuccessState } from '../elements/BillReconciliationModal/enums/paymentRegistrationSuccessState'
import { notifyBillReconciliationError } from '../elements/BillReconciliationModal/utils/notifyBillReconciliationError'
import { notifyBillReconciliationSuccess } from '../elements/BillReconciliationModal/utils/notifyBillReconciliationSuccess'
import { useCreateBillReconciliationSuggestion } from './useAddBillReconciliationSuggestion'

interface UseReconcileBillProps {
  billId?: string
  matchId?: string
  onSuccess?: () => void
  selectedDifferenceType?: DifferenceType
}

export const useReconcileBill = ({ billId, matchId, onSuccess, selectedDifferenceType }: UseReconcileBillProps) => {
  const { billSubjectAssociations } = useBillSubjectAssociations({ billId })
  const { deleteAssociations } = useDeleteBankLineSubjectAssociations()
  const { organization } = useUserOrganization()
  const { t } = useTranslation()
  const { create: createBillReconciliationSuggestion } = useCreateBillReconciliationSuggestion()
  const [isProcessing, setIsProcessing] = useState(false)

  const reconcileBill = async () => {
    if (!matchId || !billId) {
      return
    }

    setIsProcessing(true)

    const associationIds = billSubjectAssociations.map(({ id }) => id)

    await deleteAssociations(associationIds, {
      onSuccess: () => {
        createBillReconciliationSuggestion(
          {
            matchId,
            billId,
            differenceType: selectedDifferenceType,
            defaultBankFeeAccountId: organization?.defaultBankFeeAccountId,
          },
          {
            onSuccess: () => {
              notifyBillReconciliationSuccess(PaymentRegistrationSuccessState.RegisteredAndReconciled, t)
              queryClient.invalidateQueries([QueryKeys.Bill, billId])
              queryClient.invalidateQueries([QueryKeys.BillRecociliationMatches, billId])
              setIsProcessing(false)
              onSuccess?.()
            },
            onError: () => {
              notifyBillReconciliationError(t)
              setIsProcessing(false)
            },
          },
        )
      },
    })
  }

  return {
    reconcileBill,
    isProcessing,
  }
}
