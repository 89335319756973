import { notify, Timeout, useModal } from '@design-system'

import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'

import { ModalId } from '../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../hooks'
import { useUserOrganization } from '../../../../app/organization'
import { useCurrentUser } from '../../../../app/user'
import { useSendEInvoice } from '../../../hooks/useSendEInvoice'
import { getInvoiceSnapshot } from '../../../query-api'
import { isForeignGln } from '../elements/RegisterInternationallyFormModal/utils/isForeignGln'
import { useRegisterInPeppol } from '../hooks/useRegisterInPeppol'
import { getPeppolVerification, RegisterInPeppolPayload } from '../query-api'
import { EInvoiceForm, getEInvoiceValidationSchema, sendEInvoiceFormDefaultValues } from '../utils/eInvoiceFormData'
import { useSendInvoiceModal } from './sendInvoiceModalContext'

const RETRIES_SNAPSHOT = 7
const RETRIES_EINVOICE = 3
const RETRY_DELAY_SNAPSHOT = 2500
const RETRY_DELAY_EINVOICE = 5000

interface ContextState {
  Form: FormComponent
  isIdentifierChecked: boolean
  isIdentifierLoading: boolean
  isIdentifierVerified: boolean
  isLoading: boolean
  isPeppolRegistrationLoading: boolean
  isPeppolVerified?: boolean
  registerInPeppol: (props: RegisterInPeppolPayload) => void
  setIsIdentifierChecked: (isChecked: boolean) => void
  setIsIdentifierVerified: (verified: boolean) => void
  submitForm: () => void
}

const EInvoiceContext = createContext<ContextState | undefined>(undefined)

interface EInvoiceContextProps {
  children: ReactNode
}

export const EInvoiceContextProvider = ({ children }: EInvoiceContextProps) => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const { close } = useModal(ModalId.SendInvoiceModal)
  const { open: openRegisterInternationallyModal, close: closeRegisterInternationallyModal } = useModal(
    ModalId.RegisterInternationallyFormModal,
  )
  const { open: openRegisterInternationallyPendingModal } = useModal(ModalId.RegisterInternationallyPendingModal)
  const { open: openRegisterInternationallyFailedModal } = useModal(ModalId.RegisterInternationallyFailedModal)
  const { open: openRegisterInternationallySuccessModal } = useModal(ModalId.RegisterInternationallySuccessModal)
  const { invoiceId, onInvoiceSent } = useSendInvoiceModal()
  const [isIdentifierVerified, setIsIdentifierVerified] = useState(false)
  const [isIdentifierChecked, setIsIdentifierChecked] = useState(false)
  const sendEInvoiceRetryCount = useRef(0)
  const retryTimeoutRef = useRef<Timeout>()

  const [isLoadingSendEInvoice, setIsLoadingSendEInvoice] = useState(false)
  const queryClient = useQueryClient()

  const { data: peppolVerificationData } = useQuery(
    [QueryKeys.PeppolVerification, organization?.id],
    () => getPeppolVerification(organization?.id as string),
    {
      enabled: !!organization?.id,
    },
  )

  const { data: snapshotResponse, isLoading: isLoadingSnapshot } = useQuery(
    [QueryKeys.InvoiceSnapshot, invoiceId],
    () => getInvoiceSnapshot(invoiceId, false),
    {
      refetchOnMount: false,
      retry: RETRIES_SNAPSHOT,
      retryDelay: RETRY_DELAY_SNAPSHOT,
      onError: () => {
        setValue('cvr', '')
      },
    },
  )

  const { registerInPeppol, isLoading: isPeppolRegistrationLoading } = useRegisterInPeppol({
    onError: () => {
      closeRegisterInternationallyModal()
      openRegisterInternationallyFailedModal()
    },
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKeys.PeppolVerification, organization?.registrationNo])
      closeRegisterInternationallyModal()
      openRegisterInternationallySuccessModal()
    },
  })

  const { sendEInvoice, isLoading } = useSendEInvoice({
    onError: (error) => {
      if (error?.statusCode === 400 && sendEInvoiceRetryCount.current < RETRIES_EINVOICE) {
        setIsLoadingSendEInvoice(true)
        retryTimeoutRef.current = setTimeout(() => {
          submitForm()
          sendEInvoiceRetryCount.current += 1
        }, RETRY_DELAY_EINVOICE)
      } else {
        setIsLoadingSendEInvoice(false)
        notify({ id: NotificationKeys.EInvoiceSave, message: t('modal.send_invoice.sent_error'), variant: 'error' })
      }
    },
    onSuccess: () => {
      setIsLoadingSendEInvoice(false)
      notify({
        id: NotificationKeys.EInvoiceSave,
        message: t('modal.send_invoice.sent'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.Invoice)
      queryClient.invalidateQueries(QueryKeys.InvoiceLogs)
      onInvoiceSent({ detail: { invoiceWasSentVia: 'eInvoice' } })
      close()
    },
  })

  useEffect(() => {
    return () => {
      if (retryTimeoutRef.current) {
        clearTimeout(retryTimeoutRef.current)
      }
    }
  }, [])

  const { Form, handleSubmit, setValue } = useForm({
    defaultValues: sendEInvoiceFormDefaultValues,
    validationSchema: useMemo(() => getEInvoiceValidationSchema(t), [t]),
  })

  const handleEInvoiceSubmitForm = useCallback(
    (values: EInvoiceForm) => {
      const { identifierType, gln, orderReference } = values
      const organizationId = organization?.id
      const senderUserId = user?.id
      const isGlnForeign = gln && isForeignGln(gln)

      if (isGlnForeign && peppolVerificationData?.status === null) {
        openRegisterInternationallyModal()
        return
      }

      if (isGlnForeign && peppolVerificationData?.status === 'pending') {
        openRegisterInternationallyPendingModal()
        return
      }

      if (organizationId && senderUserId) {
        sendEInvoice({ organizationId, invoiceId, identifierType, gln, senderUserId, orderReference })
      }
    },
    [
      organization?.id,
      user?.id,
      peppolVerificationData?.status,
      openRegisterInternationallyModal,
      openRegisterInternationallyPendingModal,
      sendEInvoice,
      invoiceId,
    ],
  )

  useEffect(() => {
    setValue('cvr', snapshotResponse?.snapshot.invoice.contact.registrationNo)
  }, [snapshotResponse?.snapshot.invoice.contact.registrationNo])

  const submitForm = useCallback(() => {
    handleSubmit(handleEInvoiceSubmitForm)()
  }, [handleSubmit, handleEInvoiceSubmitForm])

  return (
    <EInvoiceContext.Provider
      value={{
        Form,
        isIdentifierChecked,
        isIdentifierLoading: isLoadingSnapshot,
        isIdentifierVerified,
        isLoading: isLoading || isLoadingSendEInvoice || isLoadingSnapshot,
        isPeppolRegistrationLoading,
        isPeppolVerified: peppolVerificationData?.isVerified,
        registerInPeppol,
        setIsIdentifierChecked,
        setIsIdentifierVerified,
        submitForm,
      }}
    >
      {children}
    </EInvoiceContext.Provider>
  )
}

export const useEInvoiceContext = () => {
  const context = useContext(EInvoiceContext)

  if (!context) {
    throw new Error('EInvoiceContextProvider is missing in the module!')
  }

  return context
}
