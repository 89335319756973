import { Avatar } from '@components'
import { Text } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { WrappedInsightsClient } from 'react-instantsearch-core'

import { HitItem } from '../../types/hitItem'
import * as Styled from './styles'

interface AlgoliaHitItemProps {
  hit: HitItem
  insights: WrappedInsightsClient
}

export const AlgoliaHitItem = ({ hit, insights }: AlgoliaHitItemProps): ReactElement => {
  const { title, excerpt, url, __queryID: queryId } = hit
  const linkUrl = `https://www.billy.dk${url}${queryId ? `&queryID=${queryId}` : ''}`
  const hasDescription = !!excerpt?.length

  const handleClick = useCallback(
    () => insights('clickedObjectIDsAfterSearch', { eventName: 'Hit clicked' }),
    [insights],
  )

  return (
    <Styled.HitItemLink href={linkUrl} onClick={handleClick} rel="noopener noreferrer" target="_blank">
      <Styled.HitItemLeft>
        <Text weight="medium">{title}</Text>
        {hasDescription && (
          <Styled.HitDescription>
            <Text variant="micro" colorVariant="secondary">
              {excerpt}
            </Text>
          </Styled.HitDescription>
        )}
      </Styled.HitItemLeft>
      <Styled.HitItemRight>
        <Avatar url={hit.thumbnail} />
      </Styled.HitItemRight>
    </Styled.HitItemLink>
  )
}
