import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { defaultQuery } from '../contactBalance'
import { DebtorsRoute } from './enums/debtorsRoute'
import { DebtorsList } from './routes/DebtorsList'

export const Debtors = (): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route component={DebtorsList} exact path={DebtorsRoute.List} />
    </Switch>
  )
}
