import { InternalAccessor, notify, Table, TableColumn, Text } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Row } from 'react-table'

import { queryClient } from '../../../../../../config/queryClient'
import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { TableIds } from '../../../../../../enums/tableIds'
import { APIError } from '../../../../../../utils'
import { Account } from '../../../../../app/accounts/types'
import { setAccountArchivedStatus, SetAccountArchivedStatusPayload } from '../../query-api'
import { ChartOfAccountsAccount } from '../../types'
import { AccountActionsCell } from '../AccountActionsCell'
import { AccountNameCell } from '../AccountNameCell'

interface AccountsTableProps {
  accountNo?: string
  data: ChartOfAccountsAccount[]
  id: string
  isLoading?: boolean
  name?: string
  onDeleteAccount: (accountId: string) => void
  onEditAccount: (account: Account) => void
  onEditPredefinedAccount: (account: Account) => void
}

export const AccountsTable = React.memo(
  ({
    accountNo = '',
    data,
    isLoading = true,
    name = '',
    onDeleteAccount,
    onEditAccount,
    onEditPredefinedAccount,
  }: AccountsTableProps): ReactElement => {
    const { t } = useTranslation()
    const { navigate } = useEmberRouter()

    const handleToggleAccountArchivedMutation = useMutation(
      (payload: SetAccountArchivedStatusPayload) => setAccountArchivedStatus(payload),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(QueryKeys.Accounts)
        },
        onError: (error: APIError) => {
          notify({
            id: NotificationKeys.AccountArchive,
            message: error?.body?.errorMessage,
            variant: 'error',
          })
        },
      },
    )

    const columns = useMemo(
      (): TableColumn<ChartOfAccountsAccount>[] => [
        {
          Header: accountNo || '',
          accessor: 'accountNo',
          size: 'xs',
          Cell: ({ value }) => (
            <Text colorVariant="secondary" inline title={value}>
              {value}
            </Text>
          ),
        },
        {
          Header: name || '',
          accessor: 'name',
          size: 'xxxl',
          Cell: AccountNameCell,
        },
        {
          Header: '',
          accessor: 'natureId',
          size: 's',
          Cell: ({ value }) => <Text colorVariant="secondary">{t(`account_nature.${value}`)}</Text>,
        },
        {
          Header: '',
          accessor: 'taxRateId',
          size: 's',
          Cell: ({ data, row }) => {
            const { taxRate } = data[row.id]
            return <Text colorVariant="secondary">{taxRate}</Text>
          },
        },
        {
          id: 'actions',
          Header: '',
          showOnRowHover: true,
          size: 's',
          skipOnRowClick: true,
          Cell: (props) => (
            <AccountActionsCell
              handleEditAccount={onEditAccount}
              handleEditPredefinedAccount={onEditPredefinedAccount}
              handleToggleAccountArchived={handleToggleAccountArchivedMutation.mutate}
              handleDeleteAccount={onDeleteAccount}
              {...props}
            />
          ),
        },
        {
          accessor: InternalAccessor.UrlInternal,
        },
      ],
      [
        accountNo,
        handleToggleAccountArchivedMutation.mutate,
        name,
        onDeleteAccount,
        onEditAccount,
        onEditPredefinedAccount,
        t,
      ],
    )

    const handleRowClick = useCallback(
      (row: Row<ChartOfAccountsAccount>) => {
        const { id: accountId } = row.original

        navigate(EmberRoute.Account, accountId)
      },
      [navigate],
    )

    return (
      <Table
        columns={columns}
        data={data}
        id={TableIds.AccountsTable}
        isLoading={isLoading}
        onRowClick={handleRowClick}
        withColumnsFiltering={false}
        withEmptyRowInfo={false}
        withStickyHeader
      />
    )
  },
)
