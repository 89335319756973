import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../hooks'
import { useAccounts } from '../../../../../../../modules/app/accounts'
import { useUserOrganization } from '../../../../../../../modules/app/organization'
import { useTaxRates } from '../../../../../../../modules/app/taxrates'
import {
  BookkeepingHeroSelect,
  BookkeepingTagNavItemValue,
  getAccountFromBookkeepingTagAnswer,
  getAnswerFromBookkeepingTagValue,
  getTaxRateFromBookkeepingTagAnswer,
} from '../../../../../../selects/BookkeepingHeroSelect'
import { ContactFormSchema } from '../../../../utils/formData'

interface ContactDefaultProductDescriptionProps {
  disabled?: boolean
}

export const ContactDefaultProductDescription = ({ disabled }: ContactDefaultProductDescriptionProps): ReactElement => {
  const { t } = useTranslation()
  const { control, FormItem, setValue, getValues } = useFormContext<ContactFormSchema>()
  const country = useWatch({ control, name: 'country' })
  const { organization } = useUserOrganization()
  const { accounts = [] } = useAccounts()
  const { taxRates = [] } = useTaxRates()

  const isVatExempted = organization?.isVatExempted

  const handleBookkeepingHeroTagSelect = useCallback(
    (bookkeepingTagValue?: BookkeepingTagNavItemValue) => {
      setValue('defaultBookkeepingTag', bookkeepingTagValue)

      if (!bookkeepingTagValue) {
        return
      }

      // Get current form values
      const autoCompletedFields = { ...getValues('autoCompletedFields') }
      const defaultExpenseAccount = getValues('defaultExpenseAccount')
      const paymentTermsMode = getValues('paymentTermsMode')

      // Get default data from bookeeping hero answer
      const answer = getAnswerFromBookkeepingTagValue(bookkeepingTagValue, country?.id)
      const account = answer ? getAccountFromBookkeepingTagAnswer(answer, accounts) : undefined
      const taxRate = answer ? getTaxRateFromBookkeepingTagAnswer(answer, isVatExempted, taxRates) : undefined

      // Set values if haven't been set
      if (account && !defaultExpenseAccount?.id) {
        setValue('defaultExpenseAccount', account)
        autoCompletedFields.defaultExpenseAccount = true
      }

      if (taxRate && !paymentTermsMode?.id) {
        setValue('paymentTermsMode', taxRate)
        autoCompletedFields.paymentTermsMode = true
      }

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', autoCompletedFields)
      })
    },
    [accounts, country?.id, getValues, isVatExempted, setValue, taxRates],
  )

  return (
    <FormItem
      label={t('create_contact_modal.form.contact_standard_settings.description')}
      name="defaultProductDescription"
      render={({ field: { value, onChange, ...props } }) => (
        <BookkeepingHeroSelect
          disabled={disabled}
          inputValue={value}
          onChange={onChange}
          onSelect={handleBookkeepingHeroTagSelect}
          onClear={() => onChange('')}
          {...props}
        />
      )}
    />
  )
}
