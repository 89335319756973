import {
  FinancialSummary as FinancialSummaryComponent,
  GridList as GridListComponent,
  GridListItem,
} from '@components-deprecated'
import { amountToDisplayValue } from '@design-system'

import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Themable } from '../../../../types/themable'
import { Theme } from '../../../../types/theme'
import { formatDate } from '../../../../utils'
import { accountsSelector } from '../../../app'
import { taxRateSelector } from '../../../app/taxrates/selectors/taxRateSelector'
import { Contact } from '../../../contacts/types/contact'
import { State } from '../../../types'
import { BankPaymentData, BillData, Voucher } from '../../types'

const GridList = styled(GridListComponent)<Themable>`
  & + & {
    margin-top: 20px;
    padding-top: 20px;
    border-top: ${({ theme }) => `2px solid ${theme.colors.shade20}`};
  }
`

const FinancialSummary = styled(FinancialSummaryComponent)<Themable>`
  margin-top: 20px;
  padding-top: 20px;
  border-top: ${({ theme }) => `2px solid ${theme.colors.shade20}`};
`

type RecordedAsBillViewProps = {
  voucher: Voucher
  billData: BillData
  bankPaymentData?: BankPaymentData
}

export const RecordedAsBillView = ({ bankPaymentData, billData, voucher }: RecordedAsBillViewProps): ReactElement => {
  const { voucherReference } = voucher
  const { bankPayment } = bankPaymentData || {}
  const { t } = useTranslation()
  const theme = useTheme<Theme>()

  // Selectors

  const contacts: Contact[] = useSelector((state: State) => state.app.contacts)
  const taxRates = useSelector((state: State) => taxRateSelector(state, { purchase: true }))
  const accounts = useSelector((state: State) => accountsSelector(state, {}))

  // Helpers

  const getContactById = (id: string) => contacts?.find((contact) => contact.id === id)

  // Computed values

  const vendor = getContactById(billData.bill.contactId || '') as Contact | undefined
  const paidFrom = bankPayment?.cashAccountId && accounts.find((account) => account.id === bankPayment?.cashAccountId)

  return (
    <>
      <GridList
        truncateValue={false}
        theme={theme}
        items={[
          {
            id: 'vendor',
            text: t('vendor'),
            value: vendor?.name || '-',
          },
          {
            id: 'bill_date',
            text: t('voucher.inbox.form.bill_date'),
            value: formatDate(billData.bill.entryDate, 'MMM. d yyyy') || '-',
          },
          ...(bankPayment?.entryDate
            ? [
                {
                  id: 'payment_date',
                  text: t('voucher.inbox.form.payment_date'),
                  value: formatDate(bankPayment?.entryDate, 'MMM. d yyyy') || '-',
                },
              ]
            : []),
          ...(paidFrom
            ? [
                {
                  id: 'paid_from',
                  text: t('voucher.inbox.form.paid_from'),
                  value: `${paidFrom.accountNo} - ${paidFrom.name}`,
                },
              ]
            : []),
        ]}
      />

      {billData.billLines
        .slice(0)
        .reverse()
        .map((line, index) => {
          const taxRate = taxRates.find((taxRate) => taxRate.id === line.taxRateId)
          const account = accounts.find((account) => account.id === line.accountId)

          const itemsLine: GridListItem[] = [
            {
              id: 'description',
              text: t('voucher.inbox.form.description'),
              value: line.description || '-',
            },
            {
              id: 'account',
              text: t('account'),
              value: account ? `${account.accountNo} - ${account.name}` : '-',
            },
            {
              id: 'vat_rate',
              text: t('voucher.inbox.form.vat_rate'),
              value: taxRate ? `${taxRate.name} (${taxRate.rate * 100}%)` : '-',
            },
            {
              id: 'amount',
              text: t('amount'),
              value: amountToDisplayValue(line.amount || 0) || '-',
            },
          ]

          return <GridList key={line.id || index} truncateValue={false} theme={theme} items={itemsLine} />
        })}

      <FinancialSummary
        placement="right"
        theme={theme}
        items={[
          {
            id: '0',
            text: t('vat_summary.excl_vat'),
            value: voucherReference?.amount ?? 0,
          },
          {
            id: '1',
            text: t('vat_summary.vat'),
            value: (voucherReference?.grossAmount ?? 0) - (voucherReference?.amount ?? 0),
          },
          {
            id: '2',
            text: t('vat_summary.incl_vat'),
            value: voucherReference?.grossAmount ?? 0,
          },
        ]}
      />
    </>
  )
}
