import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { deleteVoucher } from '../../inbox/services/query-api'
import { useOptimisticVoucherListUpdate } from './useOptimisticVoucherListUpdate'

interface DeleteVoucherMutationProps {
  organizationId: string
  voucherId: string
  onSuccess?: (data: void, variables: void, context: unknown) => void
  onError?: (error: unknown, variables: void, context: unknown) => void
}

export const useDeleteVoucherMutation = ({
  organizationId,
  voucherId,
  onSuccess,
  onError,
}: DeleteVoucherMutationProps) => {
  const { t } = useTranslation()
  const { updateVouchersList, invalidateVouchersList } = useOptimisticVoucherListUpdate(organizationId, voucherId)

  return useMutation(() => deleteVoucher({ organizationId, voucherId }), {
    onSuccess: (...props) => {
      onSuccess && onSuccess(...props)
      notify({
        id: NotificationKeys.VoucherInboxDelete,
        message: t('voucher.inbox.toast.voucher_deleted_success'),
        variant: 'success',
      })
      updateVouchersList()
    },
    onError: (...props) => {
      onError && onError(...props)
      notify({
        id: NotificationKeys.VoucherInboxDelete,
        message: t('voucher.inbox.toast.voucher_deleted_failed'),
        variant: 'error',
      })
    },
    onSettled: invalidateVouchersList,
  })
}
