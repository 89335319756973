import { Button, SkeletonBox, Space } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReconciliationTransaction } from '../../../../../../../bankReconciliation/types'
import { ReconciliationSuggestionLine } from '../../../../../ReconciliationSuggestionLine'
import { BillReconciliationSuggestion } from '../BillReconciliationSuggestion'
import * as Styled from '../styles'

interface ReconciliationSuggestionVariantAProps {
  onButtonClick: () => void
  matchSuggestion?: ReconciliationTransaction
}

export const ReconciliationSuggestionVariantA = ({
  onButtonClick,
  matchSuggestion,
}: ReconciliationSuggestionVariantAProps): ReactElement => {
  const { t } = useTranslation()

  const handleButtonClick = useCallback(() => {
    onButtonClick()
  }, [onButtonClick])

  return (
    <>
      <BillReconciliationSuggestion withIcon>
        <Styled.ReconciliationSuggestionContent linePosition="left">
          {matchSuggestion ? (
            <ReconciliationSuggestionLine
              title={matchSuggestion.description}
              amount={matchSuggestion.amount}
              date={matchSuggestion.bank_date}
              currency={matchSuggestion.currency}
            />
          ) : (
            <SkeletonBox fullWidth />
          )}
          <Button size="m" variant="secondary" icon="xSignCircle" onClick={handleButtonClick}>
            {t('bill.payment_reconcile.wrong_match')}
          </Button>
        </Styled.ReconciliationSuggestionContent>
      </BillReconciliationSuggestion>
      <Space size="l" />
    </>
  )
}
