import { NavItem } from '@design-system'

import invert from 'lodash/invert'
import pull from 'lodash/pull'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { umbrellaRouteToEmberRoute } from '../../../../umbrella'
import { UmbrellaRoute } from '../../../../umbrella/enums/UmbrellaRoute'

const cleanUpRoute = (routeSplitted: string[]) => {
  // clean up from not relevant menu routes
  return pull(routeSplitted, 'index', 'user', 'organization')
}

const isPathInRoute = (currentRoute: string, menuRoute: string, isUmbrella: boolean): boolean => {
  if (!currentRoute || !menuRoute) {
    return false
  }

  if (isUmbrella) {
    const menuEmberRoutesToUmbrellaPath: Partial<Record<EmberRoute, UmbrellaRoute>> = invert(umbrellaRouteToEmberRoute)
    return menuEmberRoutesToUmbrellaPath[menuRoute] === currentRoute
  }

  const currentRouteSplitted = cleanUpRoute(currentRoute.split('.'))
  const menuRouteSplitted = cleanUpRoute(menuRoute.split('.'))

  if (menuRouteSplitted.length > 1) {
    return menuRouteSplitted.every((menuRoutePart) => currentRouteSplitted.includes(menuRoutePart))
  }

  return currentRouteSplitted.some((routePart) => menuRouteSplitted.includes(routePart))
}

export const getSelectedMenuItemId = (
  items: NavItem[],
  currentRoute: string,
  isUmbrella: boolean,
): string | undefined => {
  const submenus = items.flatMap((item) => item.subItems)
  const selectedItem = [...submenus, ...items].find(
    (item) => item && !item.subItems?.length && isPathInRoute(currentRoute, item.value, isUmbrella),
  )

  return selectedItem?.id
}
