import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { useUserOrganization } from '../../../../app/organization'
import { InvoiceState } from '../../../enums/invoiceState'
import { fetchInvoices } from '../../../query-api'
import { InvoicesQueryProps } from '../../../types/invoicesQueryProps'
import { getInvoicesQueryKey } from '../../../utils/getInvoicesQueryKey'
import { useInvoicesListFilters } from './useInvoicesListFilters'

interface FailedEInvoicesCounterProps {
  isLoading: boolean
  count: number
}

export const useFailedEInvoicesCounter = (): FailedEInvoicesCounterProps => {
  const [{ period }] = useInvoicesListFilters()
  const { organization } = useUserOrganization()

  const queryProps: InvoicesQueryProps = useMemo(
    () => ({
      entryDatePeriod: period,
      organizationId: organization?.id as string,
      page: 1,
      pageSize: 1 as ItemsPerPage,
      sendingState: 'failed',
      state: [InvoiceState.Approved, InvoiceState.Draft],
    }),
    [organization?.id, period],
  )

  const queryKey = useMemo(() => getInvoicesQueryKey(queryProps), [queryProps])

  const { data: invoices, isLoading } = useQuery(queryKey, () => fetchInvoices(queryProps), {
    enabled: !!organization?.id,
    staleTime: defaultStaleTime,
  })

  return {
    isLoading,
    count: invoices?.meta.paging.total || 0,
  }
}
