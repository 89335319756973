import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { getSalaryStatistics } from '../query-api'

export const useSalaryStatistics = () => {
  const { organization } = useUserOrganization()

  const { data: statistics, ...rest } = useQuery(
    [QueryKeys.SalaryStatistics, organization?.id, organization?.registrationNo],
    () => getSalaryStatistics(organization?.id as string, organization?.registrationNo as string),
    {
      enabled: !!organization?.id && !!organization?.registrationNo,
    },
  )

  return {
    ...rest,
    statistics: statistics?.data,
  }
}
