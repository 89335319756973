import { Metable } from '../../types/metable'
import { getRequest } from '../../utils'
import { Account } from '../app/accounts/types'
import { Organization } from '../app/organization/types/organization'
import { Product } from '../app/products/types'
import { Invoice } from '../bankReconciliation/types'
import { Contact } from '../contacts/types/contact'
import { Bill } from '../inbox/types'

export interface SearchGloballyResponseData extends Metable {
  accounts?: Account[]
  bills?: Bill[]
  contacts?: Contact[]
  invoices?: Invoice[]
  organizations?: Organization[]
  products?: Product[]
}

export const searchGlobally = (organizationId: string, query: string): Promise<SearchGloballyResponseData> => {
  return getRequest(`/v2/search?organizationId=${organizationId}&q=${query}`)
}
