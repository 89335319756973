import { ErrorMessage } from '@components-deprecated'

import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { FieldError } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FileUploadRefProps } from '../../../../../../components-deprecated/FileUpload'
import { ImageFile, UploadItem } from '../../../../../../components-deprecated/FileUpload/types'
import { useUserOrganization } from '../../../../../app/organization'
import { Voucher, VoucherInboxState } from '../../../../../inbox/types'
import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { useVoucherFileReplaceMutation } from '../../../../hooks/useVoucherFileReplaceMutation'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

interface UnreadableImageProps {
  voucher: Voucher
}

export const UnreadableImage = ({ voucher }: UnreadableImageProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const fileUploadRef = useRef<FileUploadRefProps>(null)
  const [error, setError] = useState<FieldError | null>(null)

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)
  const filesReplaceMutation = useVoucherFileReplaceMutation({
    ...mutationBaseData,
    onSuccess: () => {
      updateMutation.mutate({ inboxState: VoucherInboxState.RECEIVED })
    },
  })

  const isLoading = filesReplaceMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading

  const handleFileUpload = useCallback(
    (_: UploadItem, itemFile?: ImageFile) => {
      if (!itemFile) {
        return
      }

      return filesReplaceMutation.mutateAsync([itemFile])
    },
    [filesReplaceMutation],
  )

  const handleSubmit = useCallback(() => {
    const isSomeFileDropped = fileUploadRef.current?.isSomeFileDropped()

    if (isSomeFileDropped) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fileUploadRef.current?.submitFileUpload()
    } else {
      setError({
        message: t('things_to_do.validation.upload_file'),
        type: 'required',
      })
    }
  }, [fileUploadRef, t])

  const handleFileDrop = (filesAccepted: File[]) => {
    if (filesAccepted.length > 0) {
      setError(null)
    }
  }

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  return (
    <MissingInformationContent
      ref={fileUploadRef}
      disabled={isLoading}
      direction="column"
      onFileUpload={handleFileUpload}
      onFileDrop={handleFileDrop}
    >
      {error && <ErrorMessage error={error} />}
      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}
