import { PurchaseCard } from '@components'
import { Text } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionPeriod } from '../../../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../../../app/organization/enums/subscriptionPlan'
import { useSubscriptionPlanPrices } from '../../../../hooks/useSubscriptionPlanPrices'
import { useSelectPlan } from '../../contexts/planSelectionContext'
import { useIsCurrentPlan } from '../../hooks/useIsCurrentPlan'
import { getPlanFeatures } from '../../utils/getPlanFeatures'

interface CompletePlanCardProps {
  plan: SubscriptionPlan
}

export const CompletePlanCard = ({ plan }: CompletePlanCardProps): ReactElement => {
  const { t } = useTranslation()
  const { selectPlan } = useSelectPlan()
  const { isCurrentPlan } = useIsCurrentPlan(plan)
  const { monthlyPrice, isLoading: isLoadingPrice } = useSubscriptionPlanPrices(plan)

  const handleUpgradePlanButtonClick = useCallback(() => {
    selectPlan(plan, SubscriptionPeriod.Monthly)
  }, [selectPlan, plan])

  return (
    <PurchaseCard highlight={{ text: t('organization_subscription.all_inclusive'), variant: 'success' }}>
      <PurchaseCard.Header
        description={t('subscription.plan.complete.description')}
        title={t('organization_subscription.complete')}
      />
      {isCurrentPlan ? (
        <PurchaseCard.Button disabled>{t('subscription.plan.current')}</PurchaseCard.Button>
      ) : (
        <PurchaseCard.Button onClick={handleUpgradePlanButtonClick}>
          {t('subscription.plan.upgrade_to_complete')}
        </PurchaseCard.Button>
      )}
      <PurchaseCard.PriceSection
        isLoading={isLoadingPrice}
        price={monthlyPrice}
        priceSuffix={t('organization_subscription.plans.price_suffix_star')}
        pricePeriodNode={
          <Text variant="small" colorVariant="secondary">
            {t('organization_subscription.monthly')}
          </Text>
        }
      />
      <PurchaseCard.Features
        footer={t('organization_subscription.plans.price_reference')}
        header={t('subscription.plan.no_limitations')}
        items={getPlanFeatures(plan)}
        itemsLabel={t('subscription.plan.complete.comparison_to_other_plans')}
      />
    </PurchaseCard>
  )
}
