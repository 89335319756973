import { Flex, FormItem, Spacing, Switch, Text } from '@design-system'

import { ReactElement } from 'react'

import { UmbrellaRole } from '../../../../../../../app/umbrellas/types/umbrellaRole'
import * as Styled from './styles'

interface RoleItemProps {
  role: UmbrellaRole
}

export const RoleItem = ({ role }: RoleItemProps): ReactElement => {
  return (
    <FormItem>
      <Flex gap={Spacing.S}>
        <Switch key={role.id} value={role.id} />
        <Styled.TextWrapper>
          <Text weight="bold" colorVariant="secondary">
            {role.name}
          </Text>
          <Text colorVariant="secondary">{role.description}</Text>
        </Styled.TextWrapper>
      </Flex>
    </FormItem>
  )
}
