import { notify, useModal } from '@design-system'

import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../enums/notificationKeys'
import { useForm } from '../../../../../../../../../hooks'
import { APIError } from '../../../../../../../../../utils'
import { useUserOrganization } from '../../../../../../../../app/organization'
import { useResetOrganization } from '../../../../../hooks/useResetOrganization'
import { DeleteTrialDataOptions } from '../../../types/deleteTrialDataOptions'
import { DeleteTrialDataRecord } from '../../../types/deleteTrialDataRecord'
import { defaultValues, DeleteTrialDataForm, getValidationSchema } from '../utils/formData'

interface ContextState {
  deleteTrialDataOptions: DeleteTrialDataOptions
  isProcessing: boolean
  submitForm: () => void
}

const defaultValue: ContextState = {
  deleteTrialDataOptions: {
    resetContacts: false,
    resetProducts: false,
  },
  isProcessing: false,
  submitForm: noop,
}

const DeleteTrialDataModalContext = createContext<ContextState>(defaultValue)

interface DeleteTrialDataModalContextProps {
  deleteTrialDataOptions: DeleteTrialDataOptions
  children: ReactNode
}

export const DeleteTrialDataModalContextProvider = ({
  deleteTrialDataOptions,
  children,
}: DeleteTrialDataModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { Form, handleSubmit } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })
  const { close: closeDeleteTrialDataModal } = useModal(ModalId.DeleteTrialDataModal)
  const { resetOrganization, isLoading: isProcessing } = useResetOrganization({
    onSuccess: () => {
      notify({
        id: NotificationKeys.OrganizationReset,
        message: t('delete_trial_data_modal.notification.success'),
        variant: 'success',
      })
      closeDeleteTrialDataModal()
      window.location.reload()
    },
    onError: (error: APIError) => {
      notify({
        id: NotificationKeys.OrganizationReset,
        message:
          error?.body?.validationErrors?.record?.attributes?.password || error?.body?.errorMessage || t('error_saving'),
        variant: 'error',
      })
    },
  })

  const handleFormSubmit = useCallback(
    (values: DeleteTrialDataForm) => {
      if (!organization?.id) {
        return
      }

      const resetTrialDataPayload: DeleteTrialDataRecord = {
        password: values.password,
        ...deleteTrialDataOptions,
      }

      resetOrganization({ payload: resetTrialDataPayload, organizationId: organization.id })
    },
    [deleteTrialDataOptions, organization?.id, resetOrganization],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleFormSubmit)()
  }, [handleFormSubmit, handleSubmit])

  return (
    <DeleteTrialDataModalContext.Provider value={{ deleteTrialDataOptions, isProcessing, submitForm }}>
      <Form>{children}</Form>
    </DeleteTrialDataModalContext.Provider>
  )
}

export const useDeleteTrialDataModal = () => useContext(DeleteTrialDataModalContext)
