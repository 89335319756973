import { useUserOrganization } from '../../../../app/organization'

export const VARIANT_A_BUCKETS = [0, 1, 2, 3, 4]
export const VARIANT_B_BUCKETS = [5, 6, 7, 8, 9]

export const useBillReconciliationABTest = () => {
  const { organization } = useUserOrganization()
  const isInVariantA = organization?.randomBucket !== undefined && VARIANT_A_BUCKETS.includes(organization.randomBucket)
  const isInVariantB = organization?.randomBucket !== undefined && VARIANT_B_BUCKETS.includes(organization.randomBucket)

  return {
    isInVariantA,
    isInVariantB,
  }
}
