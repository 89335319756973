import { AccountSelect, LanguageSelect, ProcessingOverlay, SettingsSection } from '@components'
import { Checkbox, CheckboxGroup, Color, FormItemsGroup, Space, Spacing } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../../../hooks'
import { useInvoiceAndProductSettings } from '../../../../contexts/invoiceAndProductSettingsContext'
import { InvoiceAndProductSettingsFormSchema } from '../../../../utils/formData'
import { AttachmentDeliveryModeSelect } from './elements/AttachmentDeliveryModeSelect'
import { InvoiceNumberingModeSelect } from './elements/InvoiceNumberingModeSelect'
import { NextInvoiceNumber } from './elements/NextInvoiceNumber'
import { PaymentTermsDays } from './elements/PaymentTermsDays'
import { PaymentTermsModeSelect } from './elements/PaymentTermsModeSelect'
import { TaxModeSelect } from './elements/TaxModeSelect'
import * as Styled from './styles'

export const InvoiceSettingsColumn = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<InvoiceAndProductSettingsFormSchema>()
  const { isLoading, isProcessing } = useInvoiceAndProductSettings()

  return (
    <SettingsSection.Content title={t('settings.organization.invoice.invoice_settings.title')}>
      <Styled.InvoiceSettingsColumnWrapper>
        {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
        <CheckboxGroup direction="column" spacing={Spacing.L}>
          <FormItem
            name="isDuplicateInvoiceCheckDisabled"
            render={({ field: { value, ...props } }) => (
              // Please note that checked is contrary to the form item name. The reason is inconsistency between checkbox's label (Duplicate checks on existing invoices) and API key (isDuplicateInvoiceCheckDisabled)
              <Checkbox {...props} checked={value}>
                {t('settings.organization.invoice.invoice_settings.duplicate_checks_on_invoices')}
              </Checkbox>
            )}
          />
          <FormItem
            name="isOrderNoEnabled"
            render={({ field: { value, ...props } }) => (
              <Checkbox {...props} checked={value}>
                {t('settings.organization.invoice.invoice_settings.is_order_no_enabled')}
              </Checkbox>
            )}
          />
          <FormItem
            name="isSummaryInclVat"
            render={({ field: { value, ...props } }) => (
              <Checkbox {...props} checked={value}>
                {t('settings.organization.invoice.invoice_settings.is_summary_incl_vat')}
              </Checkbox>
            )}
          />
        </CheckboxGroup>
        <Space size="xl" />
        <FormItemsGroup>
          <FormItem
            label={t('settings.organization.invoice.invoice_settings.default_invoice_bank_account.label')}
            name="defaultInvoiceBankAccountId"
            render={({ field: { value, onChange, ...props } }) => (
              <AccountSelect
                {...props}
                dropdownSize="fitTrigger"
                isBankAccount
                onSelect={(id?: string) => onChange(id)}
                selectedId={value}
              />
            )}
          />
          <FormItemsGroup itemsInRow={2} itemsTemplate={[4, 1]}>
            <FormItem
              label={t('settings.organization.invoice.invoice_settings.payment_terms_mode.label')}
              name="paymentTermsMode"
              render={({ field: { value, onChange, ...props } }) => (
                <PaymentTermsModeSelect
                  {...props}
                  dropdownSize="fitTrigger"
                  onSelect={(id, value) => onChange(value)}
                  selectedId={value}
                  withSearch={false}
                />
              )}
            />
            <PaymentTermsDays />
          </FormItemsGroup>
          <FormItem
            label={t('settings.organization.invoice.invoice_settings.default_tax_mode.label')}
            name="defaultTaxMode"
            render={({ field: { value, onChange, ...props } }) => (
              <TaxModeSelect
                {...props}
                dropdownSize="fitTrigger"
                onSelect={(id, value) => onChange(value)}
                selectedId={value}
                withSearch={false}
              />
            )}
          />
          <FormItem
            label={t('settings.organization.invoice.invoice_settings.invoice_no_mode.label')}
            name="invoiceNoMode"
            render={({ field: { value, onChange, ...props } }) => (
              <InvoiceNumberingModeSelect
                {...props}
                dropdownSize="fitTrigger"
                onSelect={(id, value) => onChange(value)}
                selectedId={value}
                withSearch={false}
              />
            )}
          />
          <NextInvoiceNumber />
          <FormItem
            label={t('settings.organization.invoice.invoice_settings.language.label')}
            name="localeId"
            render={({ field: { value, onChange, ...props } }) => (
              <LanguageSelect
                {...props}
                dropdownSize="fitTrigger"
                onSelect={(value) => onChange(value)}
                selectedId={value}
                withSearch={false}
              />
            )}
          />
          <FormItem
            label={t('settings.organization.invoice.invoice_settings.email_attachment_delivery_mode.label')}
            name="emailAttachmentDeliveryMode"
            render={({ field: { value, onChange, ...props } }) => (
              <AttachmentDeliveryModeSelect
                {...props}
                dropdownSize="fitTrigger"
                onSelect={(id, value) => onChange(value)}
                selectedId={value}
                withSearch={false}
              />
            )}
          />
        </FormItemsGroup>
      </Styled.InvoiceSettingsColumnWrapper>
    </SettingsSection.Content>
  )
}
