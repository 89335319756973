import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchContactById } from '../../app/contacts/query-api'

export const useFetchContact = (contactId?: string | null) => {
  const { data, ...rest } = useQuery([QueryKeys.Contact, contactId], () => fetchContactById(contactId as string), {
    enabled: !!contactId,
  })

  return {
    contact: data?.contact,
    ...rest,
  }
}
