import produce from 'immer'
import { AnyAction } from 'redux'

import { AppState } from '../types'
import { COUNTRIES_RECEIVED } from './actions'
import { CountriesState } from './types'

const countriesReducer = (state: AppState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case COUNTRIES_RECEIVED:
      return produce(state, (draftState: CountriesState) => {
        draftState.countries.list = payload.countries
      })
    default:
      return state
  }
}

export default countriesReducer
