import { IconMagnifier, StatusIcon, Text } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useCallback } from 'react'
import { Draggable, DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd'
import { useSelector } from 'react-redux'
import { Box, BoxProps } from 'rebass'

import { useHover } from '../../../hooks'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import { BillyTransaction, Posting } from '../types'
import { getPosting, prefixAmount } from '../utils'
import { LineWrapper } from './LineWrapper'
import { MatchListRowActionOverlay as ActionOverlay } from './MatchListRowActionOverlay'

export type TransactionProps = BoxProps & {
  billyTransaction: BillyTransaction & {
    paymentDate?: string
  }
  index: number
}

export const Transaction = ({
  billyTransaction: { amount = 0, description, entryDate, id, paymentDate, side, type, subtype },
  index,
  ...rest
}: TransactionProps) => {
  const organizationUrl = useSelector((state: SpecificState) => state.app.organization)?.url || ''
  const { hoverRef, isHovered } = useHover()

  const openOriginator = useCallback(async () => {
    const baseUrl = `${window.location.origin}/${organizationUrl}`
    let url
    let posting: Posting
    switch (subtype) {
      case 'invoice':
      case 'bill':
        url = `${baseUrl}/${subtype}s/${id}`
        break

      case 'posting':
        posting = await getPosting(id)
        url = `${baseUrl}/transactions/${posting.transactionId}`
        break

      case 'salesTaxReturn':
        url = `${baseUrl}/salesTaxReturns/${id}`
        break
    }

    if (url) {
      window.open(url, '_blank')
    }
  }, [id, organizationUrl, subtype])

  const renderDraggableChild = useCallback(
    (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
      <Box
        className={reactClass('billytransaction')}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={{
          ...provided.draggableProps.style,
          transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'unset',
        }}
        {...rest}
      >
        <div ref={hoverRef}>
          {snapshot.isDragging ? (
            <LineWrapper isDragging={snapshot.isDragging} width="290px" style={{ border: '3px solid tomato' }}>
              <Text variant="body1" type="currency" currencyColored flex="1">
                {prefixAmount({ amount, side })}
              </Text>
              <Text
                variant="body1"
                css={css`
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: keep-all;
                  white-space: nowrap;
                `}
              >
                {description || ' '}
              </Text>
              <Text variant="body1" type="date">
                {paymentDate || entryDate}
              </Text>
            </LineWrapper>
          ) : (
            <LineWrapper
              isDragging={snapshot.isDragging}
              width="100%"
              css={css`
                & + .billtransaction {
                  background: red;
                }
              `}
            >
              {!snapshot.draggingOver && !snapshot.isDragging && (
                <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                  <ActionOverlay
                    className="remove-transaction"
                    hoverable={isHovered}
                    placement={'right'}
                    css={css`
                      top: 0;
                      right: 0px;
                    `}
                  >
                    <Box
                      css={css`
                        margin-top: -2px;
                        margin-right: 10px;
                        i {
                          cursor: pointer;
                        }
                      `}
                    >
                      <IconMagnifier
                        width="12px"
                        height="12px"
                        color={'#3a4a54'}
                        strokeWidth={'2px'}
                        onClick={openOriginator}
                      />
                    </Box>
                  </ActionOverlay>
                </div>
              )}

              <StatusIcon status={type} />
              <Text variant="body1" type="date" width="100px">
                {entryDate}
              </Text>
              <Text
                variant="body1"
                css={css`
                  flex: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: keep-all;
                  white-space: nowrap;
                `}
              >
                {description || ' '}
              </Text>
              <Text
                variant="body1"
                type="currency"
                currencyColored
                ml="25px"
                css={css`
                  min-width: 60px;
                  text-align: right;
                `}
              >
                {prefixAmount({ amount, side })}
              </Text>
            </LineWrapper>
          )}
        </div>
      </Box>
    ),
    [rest, hoverRef, amount, side, description, paymentDate, entryDate, isHovered, openOriginator, type],
  )

  return (
    <Draggable draggableId={id} index={index}>
      {renderDraggableChild}
    </Draggable>
  )
}
