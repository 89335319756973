import { SimpleCheckList } from '@components'
import { Button, SkeletonBox, Space, Text, useTheme } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationSubscription } from '../../../../../../../../hooks'
import { SubscriptionPeriod } from '../../../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../../../app/organization/enums/subscriptionPlan'
import { useSubscriptionMetrics } from '../../../../../../../findAccountant/hooks/useSubscriptionMetrics'
import { useSubscriptionPlanPrices } from '../../../../hooks/useSubscriptionPlanPrices'
import { useSelectPlan } from '../../contexts/planSelectionContext'
import { useIsCurrentPlan } from '../../hooks/useIsCurrentPlan'
import { getPlanFeatures } from '../../utils/getPlanFeatures'
import * as Styled from './styles'

interface FreePlanCardProps {
  plan: SubscriptionPlan
}

export const FreePlanCard = ({ plan }: FreePlanCardProps): ReactElement => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { isTrial, isLocked } = useOrganizationSubscription()
  const { data: subscriptionMetrics, isLoading: loadingSubscriptionMetrics } = useSubscriptionMetrics()
  const { selectPlan } = useSelectPlan()
  const { isCurrentPlan } = useIsCurrentPlan(plan)
  const { yearlyPricePerMonth, isLoading: isLoadingPrice } = useSubscriptionPlanPrices(plan)

  const isLockedOrTrial = isLocked || isTrial
  const isCurrentPlanEligible = subscriptionMetrics?.plans.free.isEligible
  const hasExceededLimits = !loadingSubscriptionMetrics && !isCurrentPlanEligible
  const isCardDisabled = hasExceededLimits && !isCurrentPlan

  const handleUpgradePlanButtonClick = useCallback(() => {
    selectPlan(plan, SubscriptionPeriod.Yearly)
  }, [selectPlan, plan])

  return (
    <Styled.PlanCardWrapper>
      <Styled.InnerWrapper>
        <Styled.CardTop disabled={isCardDisabled}>
          <Styled.PlanHeaderWrapper>
            <Text variant="displayXS">{t('organization_subscription.free')}</Text>
            <Space size="m" horizontal />
            {/* !yearlyPricePerMonth would be incorrect here, because it can be 0 */}
            {isLoadingPrice || yearlyPricePerMonth === undefined ? (
              <SkeletonBox width={83} height={48} />
            ) : (
              <Styled.PriceContainer>
                <Text variant="displayS" color={theme.ds.colors.pricing.accent} weight="regular">
                  {yearlyPricePerMonth}
                </Text>
                <Text variant="large" color={theme.ds.colors.pricing.accent}>
                  {t('organization_subscription.plans.price_suffix')}
                </Text>
              </Styled.PriceContainer>
            )}
          </Styled.PlanHeaderWrapper>

          {isCurrentPlan ? (
            <Button disabled variant="secondary">
              {t('subscription.plan.current')}
            </Button>
          ) : (
            <Button onClick={handleUpgradePlanButtonClick} variant="secondary" disabled={isCardDisabled}>
              {t('subscription.plan.free.cta')}
            </Button>
          )}
        </Styled.CardTop>

        <Styled.CardCenter disabled={isCardDisabled}>
          <Text alignment="left">
            {t('subscription.plan.free.description')}{' '}
            {!isLockedOrTrial && t('subscription.plan.free.description.trial_included')}
          </Text>
          <Styled.FeaturesLabelText alignment="left" variant="large" weight="bold">
            {t('subscription.plan.free.limitations_description')}
          </Styled.FeaturesLabelText>
          <Space size="xl" />
          <SimpleCheckList items={getPlanFeatures(plan)} />
        </Styled.CardCenter>
      </Styled.InnerWrapper>
    </Styled.PlanCardWrapper>
  )
}
