import { notify } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useTransferUmbrellaOwnership } from '../../../../../modules/app/umbrellas/hooks/useTransferUmbrellaOwnership'
import { useUmbrellaOwners } from '../../../../../modules/app/umbrellas/hooks/useUmbrellaOwners'
import { useUmbrella } from '../../../../../modules/app/umbrellas/umbrellaContext'
import { UmbrellaMember } from '../../../../../query-api/umbrella-members-query'
import { APIError } from '../../../../../utils/api'

export interface UmbrellaOwnerRow extends UmbrellaMember {
  id: string
}

interface ContextState {
  isLoading: boolean
  owners: UmbrellaOwnerRow[]
  selectedOwner: UmbrellaOwnerRow | undefined
  selectOwnership: (owner: UmbrellaOwnerRow) => void
  transferOwnership: () => void
}

const TransferUmbrellaOwnershipContext = createContext<ContextState | undefined>(undefined)

interface TransferUmbrellaOwnershipContextProps {
  onTransferSuccess?: () => void
  children: ReactNode
}

export const TransferUmbrellaOwnershipContextProvider = ({
  onTransferSuccess,
  children,
}: TransferUmbrellaOwnershipContextProps): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const [selectedOwner, setSelectedOwner] = useState<UmbrellaOwnerRow>()
  const { umbrellaOwners, isLoading } = useUmbrellaOwners()

  const { transferUmbrellaOwnership } = useTransferUmbrellaOwnership({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.Umbrella, { refetchInactive: true })
      onTransferSuccess?.()
      notify({
        id: NotificationKeys.TransferOwnership,
        message: t('transfer_ownership_modal.success.umbrella'),
        variant: 'success',
      })
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.TransferOwnership,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const umbrellaOwnersRows: UmbrellaOwnerRow[] = useMemo(
    () => umbrellaOwners.map((owner) => ({ ...owner, id: owner.userId })),
    [umbrellaOwners],
  )

  const transferOwnership = useCallback(() => {
    if (!selectedOwner || !umbrella) {
      return
    }
    transferUmbrellaOwnership({ umbrellaId: umbrella.id, payload: { ownerUserId: selectedOwner.id } })
  }, [selectedOwner, transferUmbrellaOwnership, umbrella])

  const selectOwnership = useCallback(
    (owner: UmbrellaOwnerRow) => {
      setSelectedOwner(owner)
    },
    [setSelectedOwner],
  )

  return (
    <TransferUmbrellaOwnershipContext.Provider
      value={{
        isLoading,
        owners: umbrellaOwnersRows,
        selectedOwner,
        selectOwnership,
        transferOwnership,
      }}
    >
      {children}
    </TransferUmbrellaOwnershipContext.Provider>
  )
}

export const useTransferUmbrellaOwnershipModal = () => {
  const context = useContext(TransferUmbrellaOwnershipContext)

  if (!context) {
    throw new Error('TransferUmbrellaOwnershipContextProvider is missing in the module!')
  }

  return context
}
