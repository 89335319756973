import { EmberRoute } from '../../../enums/emberRoute'
import { SettingsRoute } from '../../settings/enums/settingsRoute'

export const settingsRouteToEmberSettingsRoute: Record<SettingsRoute, EmberRoute> = {
  [SettingsRoute.Organization]: EmberRoute.SettingsOrganization,
  [SettingsRoute.OrganizationAccessTokens]: EmberRoute.SettingsAccessTokens,
  [SettingsRoute.OrganizationAccounting]: EmberRoute.SettingsAccounting,
  [SettingsRoute.OrganizationAccountingVatReturn]: EmberRoute.SalesTaxReturnSettings,
  [SettingsRoute.OrganizationAccountingChartOfAccounts]: EmberRoute.ChartOfAccounts,
  [SettingsRoute.OrganizationBetas]: EmberRoute.SettingsOrganizationFeatures,
  [SettingsRoute.OrganizationInvoicing]: EmberRoute.SettingsApp,
  [SettingsRoute.OrganizationSubscription]: EmberRoute.SettingsSubscription,
  [SettingsRoute.OrganizationSubscriptionPlansSelection]: EmberRoute.SettingsSubscriptionsPlansSelection,
  [SettingsRoute.OrganizationUsers]: EmberRoute.SettingsOrganizationUsers,
  [SettingsRoute.OrganizationVatRates]: EmberRoute.SettingsVatRates,
  [SettingsRoute.Profile]: EmberRoute.SettingsUser,
  [SettingsRoute.ProfileNotifications]: EmberRoute.SettingsNotification,
  [SettingsRoute.ProfilePrivacy]: EmberRoute.SettingsPrivacy,
}
