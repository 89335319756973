import { ButtonProtected, FileButtonProtected, ProtectedComponent } from '@components'
import {
  Button,
  ButtonsGroup,
  IconButton,
  ModalConfirmation,
  TabButtonsGroup,
  useModal,
  useSidePanel,
} from '@design-system'

import Cookies from 'js-cookie'
import qs from 'qs'
import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { CookieKeys } from '../../../../enums/cookieKeys'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { TrackingContext } from '../../../../enums/trackingContext'
import { useSegment } from '../../../../hooks'
import {
  BulkNavigationQueryParams,
  defaultQuery as defaultBulkQuery,
} from '../../../../hooks/useBulkNavigationQueryParams'
import { OrganizationFeature, useOrganizationFeature } from '../../../settings/routes/OrganizationFeaturesSettings'
import { useGetFirstReceipt } from '../../hooks/useGetFirstReceipt'
import { useReceiptsDelete } from '../../hooks/useReceiptsDelete'
import { useReceiptsFilters } from '../../hooks/useReceiptsFilters'
import { useUploadReceipts } from '../../hooks/useUploadReceipts'
import { ReceiptFilterType } from '../Receipt'
import { ReceiptsCountBadge } from '../ReceiptsCountBadge'
import * as Styled from './styles'

const DELETE_CONFIRMATION_MODAL_ID = 'delete-receipts-confirmation'

interface ReceiptsListSideActionsProps {
  selectedReceiptsIds: string[]
  setSelectedReceiptsIds: (selectedReceiptsIds: string[]) => void
}

// @todo: Uncomment commented out places when filled tab will be needed

export const ReceiptsListSideActions = ({
  selectedReceiptsIds,
  setSelectedReceiptsIds,
}: ReceiptsListSideActionsProps): ReactElement => {
  const { hasFeature } = useOrganizationFeature()
  const { open: openSidePanel } = useSidePanel()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { navigate } = useEmberRouter()
  const { open: openDeleteConfirmationModal, close: closeDeleteConfirmationModal } =
    useModal(DELETE_CONFIRMATION_MODAL_ID)
  const [{ type, sortBy, sortDirection }, setFilters] = useReceiptsFilters()
  const { uploadFiles, isUploading } = useUploadReceipts({
    onSuccess: () => {
      track('File Uploaded (FE)', {
        context: TrackingContext.Uploads,
      })
    },
  })
  const { attachment: firstAttachment } = useGetFirstReceipt({ type })
  const { isDeleting, deleteReceipts } = useReceiptsDelete({
    onSuccess: () => {
      setSelectedReceiptsIds([])
      closeDeleteConfirmationModal()
    },
  })

  const isUploadsActive = type === ReceiptFilterType.Upload
  const isEInvoicesActive = type === ReceiptFilterType.EDocument
  const selectedReceiptsCount = selectedReceiptsIds.length

  const handleUploadsFilterClick = useCallback(() => {
    setFilters({ type: ReceiptFilterType.Upload })
  }, [setFilters])

  const handleEInvoicesFilterClick = useCallback(() => {
    setFilters({ type: ReceiptFilterType.EDocument })
  }, [setFilters])

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteConfirmationModal()
  }, [openDeleteConfirmationModal])

  const handleDeleteModalOkClick = useCallback(() => {
    deleteReceipts(selectedReceiptsIds)
  }, [deleteReceipts, selectedReceiptsIds])

  const handleFillInAll = useCallback(() => {
    if (firstAttachment) {
      const bulkQuery: BulkNavigationQueryParams = {
        ...defaultBulkQuery,
        bulkSortDirection: sortDirection,
        bulkSortProperty: sortBy,
        bulkUploadType: type,
      }
      Cookies.set(CookieKeys.BulkQuery, qs.stringify(bulkQuery))
      navigate(EmberRoute.BillsNew, { queryParams: { attachmentIdBulk: firstAttachment.id } })
    }
  }, [firstAttachment, navigate, sortBy, sortDirection, type])

  const handleScoutsButtonClick = useCallback(() => {
    track('Scout Side Panel Opened (FE)')
    openSidePanel()
  }, [openSidePanel, track])

  return (
    <>
      <Styled.ReceiptsListSideActionsWrapper>
        <ProtectedComponent scopes={Scope.EDocumentRead}>
          <TabButtonsGroup>
            <TabButtonsGroup.Button
              onClick={handleUploadsFilterClick}
              selected={isUploadsActive}
              suffix={<ReceiptsCountBadge documentFilterType={ReceiptFilterType.Upload} />}
            >
              {t('receipts.filters.type.uploads')}
            </TabButtonsGroup.Button>

            <TabButtonsGroup.Button
              onClick={handleEInvoicesFilterClick}
              selected={isEInvoicesActive}
              suffix={<ReceiptsCountBadge documentFilterType={ReceiptFilterType.EDocument} />}
            >
              {t('receipts.filters.type.e_invoices')}
            </TabButtonsGroup.Button>
          </TabButtonsGroup>
        </ProtectedComponent>

        <Styled.ButtonsGroupWrapper>
          <ButtonsGroup>
            {selectedReceiptsCount > 0 && (
              <Button danger icon="trash" onClick={handleDeleteButtonClick}>
                {t('delete_receipts')}
              </Button>
            )}
            {!isEInvoicesActive && (
              <FileButtonProtected
                scopes={Scope.UploadsWrite}
                icon="picture"
                loading={isUploading}
                multiple
                onFilesSelect={uploadFiles}
                trackingContext={TrackingContext.Uploads}
              >
                {t('upload_files')}
              </FileButtonProtected>
            )}
            <ButtonProtected
              icon="documentChecked"
              onClick={handleFillInAll}
              disabled={!firstAttachment}
              variant="secondary"
              scopes={[Scope.BillWrite, Scope.BulkWorkflows]}
              trackingContext={TrackingContext.Uploads}
            >
              {t('receipts.fill_in_all')}
            </ButtonProtected>
            {hasFeature(OrganizationFeature.EmailScanning) && (
              <IconButton
                aria-label={t('settings.organization.features.email_scanning.title')}
                icon="chainDisconnected"
                onClick={handleScoutsButtonClick}
                variant="secondary"
              />
            )}
          </ButtonsGroup>
        </Styled.ButtonsGroupWrapper>
      </Styled.ReceiptsListSideActionsWrapper>

      <ModalConfirmation
        cancelLabel={t('cancel')}
        danger
        id={DELETE_CONFIRMATION_MODAL_ID}
        message={t('receipts.delete_modal.message', { count: selectedReceiptsCount })}
        okLabel={t('receipts.delete_modal.ok_label')}
        okLoading={isDeleting}
        onOk={handleDeleteModalOkClick}
        title={t('receipts.delete_modal.title', { count: selectedReceiptsCount })}
      />
    </>
  )
}
