import { IconButtonWithLabel } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { isSettingsModulePath } from '../../../../../settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '../../../../../settings/utils/routeToInternalSettings'

export const UserSettingsButton = () => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()

  const handleUserSettingsClick = useCallback(() => {
    if (isSettingsModulePath()) {
      routeToInternalSettings(EmberRoute.SettingsUser)
    } else {
      navigate(EmberRoute.SettingsUser)
    }
  }, [navigate])

  return (
    <IconButtonWithLabel icon="user" onClick={handleUserSettingsClick}>
      {t('menu.footer.user_action')}
    </IconButtonWithLabel>
  )
}
