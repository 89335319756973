import { createSelector } from 'reselect'

import { State } from '../../types'
import { UmbrellaOrganization, UserOrganization } from './types'

export const selectOrganization = createSelector(
  (state: State) => state.app.organizations,
  (_: unknown, organizationId: string) => organizationId,
  (organizations, organizationId: string): UserOrganization | undefined => {
    return organizations.find((organization: UserOrganization) => organization.organizationId === organizationId)
  },
)

export const selectNotTerminatedUmbrellaOrganizations = createSelector(
  (state: State) => state.app.umbrellaOrganizations,
  (organizations: UmbrellaOrganization[]): UmbrellaOrganization[] => {
    return organizations.filter((organization) => !organization.isTerminated)
  },
)
