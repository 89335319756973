import { boolean, object, SchemaOf } from 'yup'

import { OrganizationSettings } from '../../../../../../app/organization/types/organizationSettings'

export interface UmbrellaVoucherInboxForm {
  isInboxEnabled: boolean
}

export type UmbrellaVoucherInboxFormSchema = SchemaOf<UmbrellaVoucherInboxForm>

export const getValidationSchema = (): UmbrellaVoucherInboxFormSchema =>
  object({
    isInboxEnabled: boolean().required(),
  })

export const getDefaultValues = (organizationSettings?: OrganizationSettings): UmbrellaVoucherInboxForm => {
  return {
    isInboxEnabled: organizationSettings?.isInboxEnabled === '1' || false,
  }
}
