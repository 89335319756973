import qs from 'qs'

import { getRequest, postRequest, putRequest } from '../../../../../utils'
import { SubscriptionPeriod } from '../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../app/organization/enums/subscriptionPlan'
import { Organization } from '../../../../app/organization/types/organization'
import { Plan } from '../../../../app/organization/types/plan'
import { CouponEligibilityPayload, SubscriptionUpdatePayloadData, UpodiSubscriptionUpdatePayloadData } from '../types'
import { ProductPlan } from '../types/productPlan'

export const updateSubscription = async (organizationId: string, payload: SubscriptionUpdatePayloadData) => {
  return putRequest(`/v2/organizations/${organizationId}/subscription`, payload)
}

export const updateUpodiSubscription = async (organizationId: string, payload: UpodiSubscriptionUpdatePayloadData) => {
  return postRequest(`/integrations/billing/${organizationId}/subscribe/start`, payload)
}

export const applyUpodiCoupon = async (organizationId: string, code: string) => {
  return putRequest(`/integrations/billing/${organizationId}/discount/${code}`, {})
}

export const redeemCoupon = async (organizationId: string, code: string) => {
  return putRequest(`/v2/organizations/${organizationId}/coupon/${code}`, {})
}

export const checkCouponEligibility = async (
  organizationId: string,
  code: string,
  payload: CouponEligibilityPayload,
) => {
  return getRequest(`/integrations/billing/${organizationId}/discount/${code}?${qs.stringify(payload)}`)
}

export interface SubscriptionResponseData {
  organization: Organization
  plans: Plan[]
}

export const fetchSubscription = async (organizationId: string): Promise<SubscriptionResponseData> => {
  return getRequest(`/v2/organizations/${organizationId}/subscription`)
}

export interface PlanMetrics {
  revenueLimit: number | null
  postingsNumberLimit: number | null
  invoicesLimit: number | null
  expensesLimit: number | null
  isEligible: boolean
}

export interface SubscriptionMetricsData {
  expenses?: number
  invoices?: number
  isRevenueEstimated: boolean
  isSufficientData: boolean
  postings: number
  revenue: number
  plans: Record<SubscriptionPlan, PlanMetrics>
}

export const fetchSubscriptionMetrics = async (organizationId: string): Promise<SubscriptionMetricsData> => {
  return getRequest(`/v2/organizations/${organizationId}/subscriptionMetrics`)
}

export const changeSubscriptionPeriod = async (organizationId: string, period: SubscriptionPeriod) => {
  return postRequest(`/integrations/billing/${organizationId}/subscribe/switch`, {
    period,
    organizationId,
  })
}

export interface FetchSubscriptionBillingOptions {
  organizationId: string
  period: string
  plan: SubscriptionPlan
}

export interface FetchSubscriptionBillingResponse {
  billingStartDate: string
  initialPrice: number
  isProration: boolean
  recurringPrice: number
}

export const fetchSubscriptionBilling = ({
  organizationId,
  period,
  plan,
}: FetchSubscriptionBillingOptions): Promise<FetchSubscriptionBillingResponse> => {
  return postRequest(`/integrations/billing/${organizationId}/subscribe/setup`, {
    organizationId,
    plan,
    period,
  })
}

export type FetchSubscriptionPlansResponse = ProductPlan[]

export const fetchSubscriptionPlans = (organizationId: string): Promise<FetchSubscriptionPlansResponse> => {
  return getRequest(`/integrations/billing/${organizationId}/productPlans`)
}
