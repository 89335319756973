import { Divider, Flex, Spacing } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import { ScoutCardFooter } from './elements/ScoutCardFooter'
import { ScoutCardHeader } from './elements/ScoutCardHeader'
import * as Styled from './styles'

interface ScoutCardProps {
  children: ReactNode
}

export const ScoutCard = ({ children }: ScoutCardProps): ReactElement => {
  return (
    <Styled.ScoutCardWrapper>
      <Flex flexDirection="column" gap={Spacing.L}>
        {children}
      </Flex>
    </Styled.ScoutCardWrapper>
  )
}

ScoutCard.Header = ScoutCardHeader
ScoutCard.Divider = Divider
ScoutCard.Footer = ScoutCardFooter
