import { encodePeriodValue } from '@components'
import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { useGlobalFiscalYear } from '../../../../../contexts/globalFiscalYearContext'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { getPeriodValueFromFiscalYear } from '../../../../../utils/getPeriodValueFromFiscalYear'
import { useUserOrganization } from '../../../../app/organization'
import { useFiscalYearQueryParams } from '../../../../dashboard/hooks/useFiscalYearQueryParams'
import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { InvoicesSortProperty } from '../../../query-api'
import { InvoiceSendingState } from '../../../types/invoiceSendingState'

export interface InvoicesListFilters {
  contactId?: string
  contactName?: string
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sendingState?: InvoiceSendingState
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  status?: InvoiceStatus
}

type UseInvoicesFiltersResponse = [InvoicesListFilters, (filters: Partial<InvoicesListFilters>) => void]

export const defaultQuery: InvoicesListFilters = {
  contactId: undefined,
  contactName: undefined,
  page: 1,
  pageSize: undefined,
  period: undefined,
  searchQuery: undefined,
  sendingState: undefined,
  sortDirection: SortDirection.Desc,
  sortProperty: InvoicesSortProperty.EntryDate,
  status: InvoiceStatus.All,
}

export const useInvoicesListFilters = (): UseInvoicesFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const { organization } = useUserOrganization()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const { queryParams: fiscalYearQueryParams } = useFiscalYearQueryParams(organization?.id || '')
  const [queryParams, setQueryParams] = useQueryParams<InvoicesListFilters>({
    defaultQuery: {
      ...defaultQuery,
      ...fiscalYearQueryParams,
      ...(globalFiscalYear && organization?.id
        ? { period: encodePeriodValue(getPeriodValueFromFiscalYear(globalFiscalYear), organization.id) }
        : {}),
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `page` comes as a string. It needs to be always `number` type.
        page: Number(queryParams.page),
        // Sometimes `pageSize` comes as string. It needs to be always `number` type.
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
