import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../config/queryClient'
import { useDirtyRoute } from '../../../../../../../contexts/dirtyRouteContext'
import { NotificationKeys } from '../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { UpdateOrganizationProps, useUpdateOrganization, useUserOrganization } from '../../../../../../app/organization'
import { OrganizationInfoForm } from '../utils/formData'

export const useUpdateOrganizationInfoSettings = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { setDirty } = useDirtyRoute()
  const { update, isProcessing, ...rest } = useUpdateOrganization({
    onSuccess: () => {
      notify({
        id: NotificationKeys.OrganizationSettingsUpdate,
        message: t('settings.organization.organization_info.notification.update_success'),
        variant: 'success',
      })
      setDirty(false)
      queryClient.invalidateQueries(QueryKeys.UserOrganization)
    },
    onError: () => {
      notify({ id: NotificationKeys.OrganizationSettingsUpdate, message: t('error_saving'), variant: 'error' })
    },
  })

  const updateOrganizationInfoSettings = useCallback(
    (values: OrganizationInfoForm) => {
      if (!organization?.id) {
        return
      }

      const updateOrganizationPayload: UpdateOrganizationProps = {
        organizationId: organization.id,
        payload: values,
      }

      update(updateOrganizationPayload)
    },
    [organization?.id, update],
  )

  return { update: updateOrganizationInfoSettings, isProcessing, ...rest }
}
