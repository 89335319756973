import { GridList, GridListItem } from '@components-deprecated'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { formatDate } from '../../../../utils'
import { accountsSelector } from '../../../app'
import { State } from '../../../types'
import { BankPaymentData, BillData, PostingData, Voucher, VoucherAllReasons, VoucherInboxState } from '../../types'
import { getNeedInfoReasons } from '../../utils/getNeedInfoReasons'
import { getVoucherStateReasons } from '../../utils/getVoucherStateReasons'
import { RecordedAsBillView } from './RecordedAsBillView'
import { RecordedAsTransactionView } from './RecordedAsTransactionView'

type VoucherViewReadOnlyProps = {
  bankPaymentData?: BankPaymentData
  billData?: BillData
  transactionData?: PostingData
  voucher?: Voucher
}

export const VoucherViewReadOnly = ({
  bankPaymentData,
  billData,
  transactionData,
  voucher,
}: VoucherViewReadOnlyProps): ReactElement | null => {
  const { t } = useTranslation()

  const bankAccount = useSelector((state: State) =>
    voucher ? accountsSelector(state, { accountId: voucher.bankAccountId }) : [],
  )[0]

  if (!voucher) {
    return null
  }

  const renderRecordedData = () => {
    if (!voucher || (!billData && !transactionData)) {
      return null
    }

    if (billData) {
      return <RecordedAsBillView voucher={voucher} billData={billData} bankPaymentData={bankPaymentData} />
    }

    if (transactionData) {
      return <RecordedAsTransactionView transactionData={transactionData} />
    }
  }

  const renderNotRecordedData = () => {
    const needInfoReasons = getNeedInfoReasons(t)
    const stateReasons = getVoucherStateReasons(t)
    const voucherAllReasons: Record<VoucherAllReasons, string> = { ...needInfoReasons, ...stateReasons }

    const items: GridListItem[] = [
      {
        id: 'description',
        text: t('voucher.inbox.view.description'),
        value: voucher?.description || '-',
      },
      {
        id: 'payment_method',
        text: t('voucher.inbox.view.payment_method'),
        value: bankAccount?.text || '-',
      },
      {
        id: 'upload_date',
        text: t('voucher.inbox.view.upload_date'),
        value: formatDate(voucher?.createdTimestamp, 'MMM. d yyyy') || '-',
      },
      {
        id: 'reason',
        text: t('voucher.inbox.view.reason'),
        value: voucher?.stateReason ? voucherAllReasons[voucher.stateReason] : '-',
      },
      {
        id: 'comment',
        text: t('voucher.inbox.view.comment'),
        value: voucher?.stateComment || '-',
      },
    ]

    return <GridList truncateValue={false} itemPerRow items={items} />
  }

  return <>{voucher.inboxState === VoucherInboxState.RECORDED ? renderRecordedData() : renderNotRecordedData()}</>
}
