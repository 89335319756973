import React, { ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { billRequested } from '../action-creators'

type IProps = {
  billId: string
  match: any
}

function BillView({ match }: IProps): ReactElement {
  const { bill } = useSelector((state: any) => ({
    bill: state.bills.bill,
  }))
  const { billId } = match.params

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(billRequested(billId))
  }, [dispatch, billId])

  return !bill ? (
    <div>Loading</div>
  ) : (
    <div>
      <div>BillView</div>
      <div>{bill.id}</div>
      <div>{bill.subject}</div>
    </div>
  )
}

export default BillView
