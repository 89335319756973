import { BookkeepingTagNavItemValue } from '@components'

import { Account } from '../../../../app/accounts/types'
import { TaxRate } from '../../../../app/taxrates/types'
import { Contact } from '../../../../contacts/types/contact'
import { BillFormPartial } from './formData'
import { getBillLinesMock } from './getBillLinesMock'
import { getDataFromBookkeepingTag } from './getDataFromBookkeepingTag'

interface GetBillFormFromBookkeepingTagProps {
  accounts?: Account[]
  bookkeepingTagValue: BookkeepingTagNavItemValue | undefined
  isVatExempted?: boolean
  lineIndex?: number
  linesTotal?: number
  taxRates?: TaxRate[]
  vendor?: Partial<Contact>
}

export const getBillFormFromBookkeepingTag = ({
  accounts,
  bookkeepingTagValue,
  isVatExempted,
  lineIndex = 0,
  linesTotal = 1,
  taxRates,
  vendor,
}: GetBillFormFromBookkeepingTagProps): BillFormPartial => {
  const { account, taxRate } = bookkeepingTagValue
    ? getDataFromBookkeepingTag({
        accounts,
        bookkeepingTagValue,
        isVatExempted,
        taxRates,
        vendor,
      })
    : { account: undefined, taxRate: undefined }
  const billLines = getBillLinesMock(linesTotal)

  if (account && billLines[lineIndex]) {
    billLines[lineIndex].expenseCategory = account
  }

  if (taxRate && billLines[lineIndex]) {
    billLines[lineIndex].taxRate = taxRate
  }

  return {
    billLines,
  }
}

interface GetBillFormFromBookkeepingTagFormProps {
  accounts?: Account[]
  billForm: BillFormPartial
  isVatExempted?: boolean
  taxRates?: TaxRate[]
  vendor?: Partial<Contact>
}

export const getBillFormFromBookkeepingTagForm = ({
  accounts,
  billForm,
  isVatExempted,
  taxRates,
}: GetBillFormFromBookkeepingTagFormProps) => {
  if (!billForm?.billLines?.length) {
    return {}
  }

  const billFormPartial: BillFormPartial = {
    billLines: [],
  }

  if (billFormPartial.billLines) {
    for (let i = 0; i < billForm.billLines.length; i++) {
      const bookkeepingTagValue = billForm.billLines[i].bookkeepingTag
      const { account, taxRate } = bookkeepingTagValue
        ? getDataFromBookkeepingTag({
            accounts,
            bookkeepingTagValue,
            isVatExempted,
            taxRates,
            vendor: billForm.vendor,
          })
        : {
            account: undefined,
            taxRate: undefined,
          }

      billFormPartial.billLines[i] = {
        ...(account ? { expenseCategory: account } : {}),
        ...(taxRate ? { taxRate } : {}),
      }
    }
  }

  return billFormPartial
}
