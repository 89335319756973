import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '../../../../app/organization'
import { fetchTransactions } from '../../../query-api'
import { TransactionsQueryProps } from '../../../types/transactionsQueryProps'
import { getTransactionsQueryKey } from '../../../utils/getTransactionsQueryKey'

export const useIsTransactionsListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps: TransactionsQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      organizationId: organization?.id as string,
    }),
    [organization?.id],
  )

  const queryKey = useMemo(() => getTransactionsQueryKey(queryProps), [queryProps])

  const { data: transactionsData, isLoading } = useQuery(queryKey, () => fetchTransactions(queryProps), {
    enabled: !!organization?.id,
  })

  return !isLoading && transactionsData?.transactions.length === 0
}
