import pick from 'lodash/pick'
import { useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { useCurrentUser } from '../../../../app/user'
import { CreateBillTimelinePayload, GetBillTimelineResponseData } from '../../../query-api'
import { BillTimeline } from '../../../types/billTimeline'
import { getBillTimelineQueryKey } from '../../../utils/getBillTimelineQueryKey'

export const useAddBillTimelineOptimisticUpdate = (billId: string, timeline?: BillTimeline[]) => {
  const queryClient = useQueryClient()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()

  const billTimelineQueryKey = useMemo(
    () => getBillTimelineQueryKey(billId, organization?.id),
    [billId, organization?.id],
  )

  const onMutate = async (variables: CreateBillTimelinePayload): Promise<GetBillTimelineResponseData> => {
    // Cancel other queries
    await queryClient.cancelQueries(billTimelineQueryKey)

    // Get previouse state of the bill timeline
    const previousBillTimeline: GetBillTimelineResponseData = queryClient.getQueryData([
      QueryKeys.BillTimeline,
      billId,
      organization?.id,
    ]) as GetBillTimelineResponseData

    // Prepare updated state of the bill timeline
    const updatedNumber = (timeline?.[0]?.number || -1) + 1
    const updatedBillTimeline: GetBillTimelineResponseData = {
      data: [
        ...(previousBillTimeline?.data || []),
        {
          properties: {
            comment: variables.comment,
            user: pick(user, 'id', 'name', 'profilePicUrl'),
            organization: pick(organization, 'id', 'url', 'name'),
          } as BillTimeline['properties'],
          timestamp: new Date().toISOString(),
          type: 'UserCommentedOnBill',
          number: updatedNumber,
        },
      ],
    }
    // Set updated state in query
    queryClient.setQueryData(billTimelineQueryKey, updatedBillTimeline)

    // Return previous state so it could be used to be resored in 'onError'
    return previousBillTimeline
  }

  return { onMutate }
}
