import { BillyWelcomeModal } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'

import { ThemeProvider } from '../../contexts/themeProvider'
import { SubscriptionPlan } from '../../modules/app/organization/enums/subscriptionPlan'

class BillyWelcomeModalWebComponent extends DOMModel {
  @byAttrVal plan: string
}

interface BillyWelcomeModalProps {
  plan: SubscriptionPlan.Basic | SubscriptionPlan.Pro | SubscriptionPlan.Premium
}

function BillyWelcomeModalModule({ plan }: BillyWelcomeModalProps): ReactElement {
  return (
    <ThemeProvider>
      <ModalContextProvider>
        <BillyWelcomeModal plan={plan} />
      </ModalContextProvider>
    </ThemeProvider>
  )
}

const BillyWelcomeModalCustomElement = createCustomElement(
  BillyWelcomeModalModule,
  BillyWelcomeModalWebComponent,
  'element',
)

customElements.get('billy-welcome-modal-module') ||
  customElements.define('billy-welcome-modal-module', BillyWelcomeModalCustomElement)

export default BillyWelcomeModalCustomElement
