import { NavItem, Select } from '@design-system'

import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useWatch } from '../../../../../../../../hooks'
import { OrganizationAccountingFormSchema } from '../../../../utils/organizationAccountingSettingsFormData'

type SalesTaxItemType = 'month' | 'quarter' | 'halfYear'

export const SalesTaxPeriodFormItem = () => {
  const { t } = useTranslation()
  const { control, FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const isVatExempted = useWatch({ control, name: 'isVatExempted' })
  const canUseVatReportSelect = isVatExempted

  const salesTaxItems: NavItem<SalesTaxItemType>[] = useMemo(
    () => [
      {
        id: 'month',
        children: t('settings.organization.accounting.sales_tax_period.month'),
        value: 'month',
      },
      {
        id: 'quarter',
        children: t('settings.organization.accounting.sales_tax_period.quarter'),
        value: 'quarter',
      },
      {
        id: 'halfYear',
        children: t('settings.organization.accounting.sales_tax_period.half_year'),
        value: 'halfYear',
      },
    ],
    [t],
  )

  return (
    <FormItem
      name="salesTaxPeriod"
      label={t('settings.organization.accounting.vat_report')}
      render={({ field: { onChange, value, ...rest } }) => (
        <Select
          selectedId={value}
          items={salesTaxItems}
          onSelect={onChange}
          dropdownSize="fitTrigger"
          disabled={canUseVatReportSelect}
          {...rest}
        />
      )}
    />
  )
}
