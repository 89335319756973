import React from 'react'
import { useTranslation } from 'react-i18next'

import { FinancialSummary, FinancialSummaryProps } from '../FinancialSummary'

type VATSummaryProps = Omit<FinancialSummaryProps, 'items'> & {
  amount: number
  rate: number
}

export const VATSummary = ({ amount, rate = 0, ...rest }: VATSummaryProps) => {
  const { t } = useTranslation()

  const summaryItems = [
    {
      id: '0',
      text: t('vat_summary.excl_vat'),
      value: amount - rate * amount,
    },
    {
      id: '1',
      text: t('vat_summary.vat'),
      value: rate * amount,
    },
    {
      id: '2',
      text: t('vat_summary.incl_vat'),
      value: amount,
    },
  ]

  return <FinancialSummary {...rest} items={summaryItems} />
}
