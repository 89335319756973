import { NotificationSettings } from '../../../../../../../../app/user'
import { NotificationSettingsKey } from '../../../../../types/notificationSettingsTypes'

export const muteSettings = (notificationSettings: NotificationSettings): NotificationSettings => {
  const notificationSettingsKeys = Object.keys(notificationSettings) as NotificationSettingsKey[]
  const payload: NotificationSettings = {}

  for (const property of notificationSettingsKeys) {
    if (property === 'organizationSpecific') {
      continue
    }
    if (property === 'muted') {
      payload[property] = 'yes'
      continue
    }
    payload[property] = 'no'
  }

  return payload
}

export const unmuteSettings = (notificationSettings: NotificationSettings): NotificationSettings => {
  const notificationSettingsKeys = Object.keys(notificationSettings) as NotificationSettingsKey[]
  const payload: NotificationSettings = {}

  for (const property of notificationSettingsKeys) {
    if (property === 'muted') {
      payload[property] = 'no'
      continue
    }
    payload[property] = 'yes'
  }

  return payload
}
