import { Attachment, ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { SortDirection } from '../../../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../../../hooks/usePaginationPrefetching'
import { Paging } from '../../../../../types/metable'
import { useUserOrganization } from '../../../../app/organization'
import { Contact } from '../../../../contacts/types/contact'
import { billStatusToQueryProps } from '../../../constants/billStatusToQueryProps'
import { BillStatus } from '../../../enums/billStatus'
import { BillsSortProperty, fetchBills, FetchBillsResponseData } from '../../../query-api'
import { Bill } from '../../../types/bill'
import { BillsQueryProps } from '../../../types/billsQueryProps'
import { getBillsQueryKey } from '../../../utils/getBillsQueryKey'

interface UseFetchBillsProps {
  contactId?: string
  currencyId?: string
  isDisabled?: boolean
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: BillsSortProperty
  status?: BillStatus
}

interface UseFetchBillsResponse {
  attachments: Attachment[]
  bills: Bill[]
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchBills = ({
  contactId,
  currencyId,
  isDisabled,
  page,
  pageSize,
  period,
  searchQuery,
  sortDirection,
  sortProperty,
  status,
}: UseFetchBillsProps): UseFetchBillsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps: BillsQueryProps = useMemo(
    () => ({
      ...(contactId ? { contactId } : {}),
      ...(currencyId ? { currencyId } : {}),
      ...(status ? billStatusToQueryProps[status] : {}),
      ...(period ? { entryDatePeriod: period } : {}),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      organizationId: organization?.id as string,
    }),
    [contactId, currencyId, organization?.id, page, pageSize, period, searchQuery, sortDirection, sortProperty, status],
  )
  const queryKey = useMemo(() => getBillsQueryKey(queryProps), [queryProps])

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchBills(queryProps), {
    enabled: !!organization?.id && !isDisabled,
    staleTime: defaultStaleTime,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<BillsQueryProps, FetchBillsResponseData>({
    getQueryFunction: useMemo(() => fetchBills, []),
    getQueryKey: useMemo(() => getBillsQueryKey, []),
    isEnabled: !!organization?.id && !isLoading && !isDisabled,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    attachments: data?.attachments || [],
    bills: data?.bills || [],
    contacts: data?.contacts || [],
    isLoading: isIdle || isLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
