import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { Flex } from 'rebass'

import { store } from '../../modules'
import { CurrencySelector } from '../../modules/app/currencies'

class CurrenciesWebComponent extends DOMModel {
  @byAttrVal name: string
}

type CurrenciesProps = {
  name: string
}

function CurrenciesModule({ name }: CurrenciesProps): ReactElement {
  return (
    <ReduxProvider store={store}>
      <Flex>
        <CurrencySelector name={name} />
      </Flex>
    </ReduxProvider>
  )
}

const CurrenciesCustomElement = createCustomElement(CurrenciesModule, CurrenciesWebComponent, 'element')

customElements.get('currencies-module') || customElements.define('currencies-module', CurrenciesCustomElement)

export default CurrenciesCustomElement
