import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../../../app/organization'
import { fetchStandardInvoiceTemplate } from '../query-api'

export const useStandardInvoiceTemplate = () => {
  const { organization } = useUserOrganization()
  const organizationId = organization?.id

  const { data, ...rest } = useQuery(
    [QueryKeys.StandardInvoiceTemplate, organizationId],
    () => fetchStandardInvoiceTemplate(organizationId as string),
    {
      enabled: !!organizationId,
    },
  )

  return { standardInvoiceTemplate: data?.standardTemplate, ...rest }
}
