import { convertFiscalYearToCustomDatePeriod, decodePeriodValue, encodePeriodValue } from '@components'
import { ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { SortDirection } from '../../../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../../../hooks/usePaginationPrefetching'
import { useUserOrganization } from '../../../../app/organization'
import { QuoteState } from '../../../enums/quoteState'
import { fetchQuotes, FetchQuotesResponseData, QuotesSortProperty } from '../../../query-api'
import { Quote } from '../../../types/quote'
import { QuotesQueryProps } from '../../../types/quotesQueryProps'
import { getQuotesQueryKey } from '../../../utils/getQuotesQueryKey'
import { QuotesPagination } from '../types/quotesPagination'

interface UseFetchQuotesProps {
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: QuotesSortProperty
  state?: QuoteState
}

interface UseFetchQuotesResponse {
  data: Quote[]
  isLoading: boolean
  pagination?: QuotesPagination
}

export const useFetchQuotes = ({
  page,
  pageSize,
  period,
  searchQuery,
  sortDirection,
  sortProperty,
  state = QuoteState.Open,
}: UseFetchQuotesProps): UseFetchQuotesResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<number | undefined>(0)

  const queryProps: QuotesQueryProps = useMemo(() => {
    const decodedPeriod = period && decodePeriodValue(period)
    // We need to convert fiscal year mode to custom dates because endpoint doesn't accept fiscal year
    const convertedPeriod =
      decodedPeriod && convertFiscalYearToCustomDatePeriod(decodedPeriod, organization?.fiscalYearEndMonth)
    const encodedPeriod = convertedPeriod && organization?.id && encodePeriodValue(convertedPeriod, organization.id)

    return {
      ...(encodedPeriod ? { entryDatePeriod: encodedPeriod } : {}),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      ...(state ? { state } : {}),
      organization: organization?.id as string,
    }
  }, [organization, page, pageSize, period, searchQuery, sortDirection, sortProperty, state])

  const queryKey = useMemo(() => getQuotesQueryKey(queryProps), [queryProps])

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchQuotes(queryProps), {
    enabled: !!organization,
  })

  const hasTotal = data?.meta.total !== undefined

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (hasTotal) {
      previousPagination.current = data.meta.total
    }
  }, [data?.meta.total, hasTotal])

  const totalPages = useMemo(
    () => Number(data?.meta.total && pageSize ? Math.ceil(data.meta.total / pageSize) : 1),
    [pageSize, data],
  )

  usePaginationPrefetching<QuotesQueryProps, FetchQuotesResponseData>({
    getQueryFunction: useMemo(() => fetchQuotes, []),
    getQueryKey: useMemo(() => getQuotesQueryKey, []),
    isEnabled: !!organization?.id && !isLoading,
    queryProps,
    totalPages,
  })

  return {
    data: data?.data || [],
    isLoading: isIdle || isLoading,
    pagination: {
      total: hasTotal ? data.meta.total : previousPagination.current,
    },
  }
}
