import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { fetchBankConnections } from '../../bankReconciliation/query-api'

export const useBankConnections = () => {
  const { organization } = useUserOrganization()

  const {
    data: bankConnections,
    isLoading,
    isError,
  } = useQuery([QueryKeys.BankConnections, organization?.id], () => fetchBankConnections(organization?.id as string), {
    enabled: !!organization?.id,
  })

  return {
    bankConnections,
    isLoading,
    isError,
  }
}
