import { TFunction } from 'i18next'
import * as Yup from 'yup'

export const CreateAssetsSchema = (t: TFunction) =>
  Yup.object().shape({
    description: Yup.string().required(t('fixed_assets.form.description_error')),
    assetType: Yup.string().required(t('fixed_assets.form.type_error')),
    lifetime: Yup.string().required(t('fixed_assets.form.lifetime_error')),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    scrapValue_input: Yup.number()
      .positive()
      .required(t('fixed_assets.form.scrap_value_error'))
      .lessThan(Yup.ref('amount_input'), t('fixed_assets.form.scrap_value_lower_error'))
      .typeError(t('fixed_assets.form.scrap_value_error')),
    vendor: Yup.string().required(t('fixed_assets.form.vendor_error')),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    billDate_date: Yup.string().required(t('fixed_assets.form.bill_date_error')),
    paymentDate: Yup.string().when('billDate_checked', {
      is: true,
      then: Yup.string().required(t('fixed_assets.form.payment_date_error')),
    }),
    paidFrom: Yup.string().when('billDate_checked', {
      is: true,
      then: Yup.string().required(t('fixed_assets.form.paid_from_error')),
    }),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    amount_input: Yup.number()
      .positive()
      .required(t('fixed_assets.form.amount_with_error'))
      .typeError(t('fixed_assets.form.amount_with_error')),
    vatRate: Yup.string().required(t('fixed_assets.form.vat_rate_error')),
  })
