import { Switch } from '@design-system'

import { ChangeEvent, ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import {
  NotificationSettings,
  NotificationSettingsLineValue,
  UpdateUserNotificationSettingsPayload,
} from '../../../../../../../app/user'
import { useOrganizationNotificationSettings } from '../../../../context/organizationNotificationSettings'
import * as Styled from './styles'
import { muteSettings, unmuteSettings } from './utils/muteSettings'

export interface NotificationSettingsTableRowProps {
  notificationSettingsKey: keyof NotificationSettings
  value: boolean
}

export const NotificationSettingsTableRow = ({
  notificationSettingsKey,
  value,
}: NotificationSettingsTableRowProps): ReactElement => {
  const { t } = useTranslation()
  const { organizationId, notificationSettings, updateSettingsChange, isMuted } = useOrganizationNotificationSettings()
  const isMutedRow = notificationSettingsKey === 'muted'

  const handleSwitchToggle = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const writtenValue: NotificationSettingsLineValue = event.target.checked ? 'yes' : 'no'
      const changedNotificationSettings: UpdateUserNotificationSettingsPayload = {
        organizationId,
        payload: {},
      }

      if (isMuted && !isMutedRow) {
        changedNotificationSettings.payload.muted = 'no'
      }

      if (isMutedRow) {
        changedNotificationSettings.payload = event.target.checked
          ? muteSettings(notificationSettings)
          : unmuteSettings(notificationSettings)
      } else {
        changedNotificationSettings.payload[notificationSettingsKey] = writtenValue
      }

      updateSettingsChange(changedNotificationSettings)
    },
    [organizationId, isMuted, isMutedRow, updateSettingsChange, notificationSettings, notificationSettingsKey],
  )

  return (
    <Styled.NotificationsTableRow>
      <Switch checked={value} onChange={handleSwitchToggle} danger={isMutedRow}>
        {t(`notification_settings.category.${notificationSettingsKey}`)}
      </Switch>
    </Styled.NotificationsTableRow>
  )
}
