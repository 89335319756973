import { ButtonProtected } from '@components'
import { Input } from '@components-deprecated'
import { localeToLanguage, notify, Text } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../../enums/queryKeys'
import { Scope } from '../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { isAuthorized } from '../../../../../../utils/isAuthorized'
import { useUserOrganization } from '../../../../../app/organization'
import { SubscriptionPlan } from '../../../../../app/organization/enums/subscriptionPlan'
import { useCurrentUser } from '../../../../../app/user'
import { useSalaryEmberActions } from '../../../../contexts/salaryEmberActionsContext'
import { SalarySignupConfirmationModal } from '../SalarySignupConfirmationModal'
import { PredefinedFields } from '../types/predefinedFields'
import { useCreateSalaryAccount } from './hooks/useCreateSalaryAccount'
import { useUpdateAccountSettings } from './hooks/useUpdateAccountSetttings'
import * as Styled from './styles'
import { defaultValues, getValidationSchema, SignupForm } from './utils/formData'

export const SalarySignupForm = (): ReactElement => {
  const [predefinedFields, setPredefinedFields] = useState<PredefinedFields>({} as PredefinedFields)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { openUpgradePlanOverlay } = useSalaryEmberActions()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const form = useForm<SignupForm>({
    defaultValues,
    resolver: useMemo(() => yupResolver(getValidationSchema(t)), [t]),
  })
  const formRef = useRef<HTMLFormElement>(null)
  const formSubmittedValuesRef = useRef<SignupForm>()

  // Mutations

  const { update: updateAccountSettings } = useUpdateAccountSettings()

  const { create: createSalaryAccount, isLoading: isCreatingAccount } = useCreateSalaryAccount({
    onSuccess: (data) => {
      // check salary connection status again to display payroll data
      queryClient.invalidateQueries(QueryKeys.SalaryConnectionStatus)

      // @todo check for browser support
      window.open(data.login, '_blank')?.focus()
    },
    onError: (error) => {
      const message = error?.message || t('salary.signup.error.failed')

      notify({
        id: 'salary-create',
        message,
        variant: 'error',
      })
    },
  })

  // Computed values

  const disabled = !organization || !user || isCreatingAccount
  const { handleSubmit, control } = form

  // Functions

  const focusOnForm = useCallback(() => {
    if (formRef.current) {
      const input = formRef.current.querySelector('input:not(:disabled)') as HTMLInputElement | undefined
      input?.focus()
    }
  }, [])

  const handleModalCancel = useCallback(() => {
    setIsModalOpen(false)
    formSubmittedValuesRef.current = undefined
  }, [])

  const handleModalConfirm = useCallback(() => {
    setIsModalOpen(false)

    const formValues = formSubmittedValuesRef.current

    if (formValues && user && organization) {
      createSalaryAccount({
        organizationAddress: formValues.organizaionStreet,
        organizationCity: formValues.organizationCity,
        organizationCvr: formValues.organizationCvr,
        organizationId: organization.id,
        organizationName: organization.name,
        organizationPostalCode: formValues.organizationZipCode,
        userEmail: user.email,
        userLanguage: localeToLanguage[user.locale],
        userName: formValues.userName,
        userPhone: formValues.userPhone,
      })

      // Use new data to update account settings if missed:
      updateAccountSettings(formValues, predefinedFields)
    }

    formSubmittedValuesRef.current = undefined
  }, [createSalaryAccount, organization, predefinedFields, updateAccountSettings, user])

  const handleFormSubmit = useCallback(
    (values: SignupForm) => {
      if (!isAuthorized(Scope.SalaryWrite)) {
        return
      }

      if (organization?.subscriptionPlan !== SubscriptionPlan.Premium && !organization?.isTrial) {
        openUpgradePlanOverlay()
        return
      }

      formSubmittedValuesRef.current = values
      setIsModalOpen(true)
    },
    [organization, openUpgradePlanOverlay],
  )

  // Lifecycle

  useEffect(() => {
    if (organization && user) {
      const predefinedFields = {} as PredefinedFields

      if (user.name) {
        form.setValue('userName', user.name)
        predefinedFields.userName = true
      }

      if (user.phone) {
        form.setValue('userPhone', user.phone)
        predefinedFields.userPhone = true
      }

      if (organization.city) {
        form.setValue('organizationCity', organization.city)
        predefinedFields.organizationCity = true
      }

      if (organization.street) {
        form.setValue('organizaionStreet', organization.street)
        predefinedFields.organizaionStreet = true
      }

      if (organization.zipcode) {
        form.setValue('organizationZipCode', organization.zipcode)
        predefinedFields.organizationZipCode = true
      }

      if (organization.registrationNo) {
        form.setValue('organizationCvr', organization.registrationNo)
        predefinedFields.organizationCvr = true
      }

      setPredefinedFields(predefinedFields)

      window.requestAnimationFrame(() => {
        focusOnForm()
      })
    }
  }, [user, organization])

  return (
    <Styled.SalarySignupFormWrapper>
      <Styled.TitleWrapper>
        <Text variant="h2">{t('salary.signup.form.title')}</Text>
      </Styled.TitleWrapper>

      <FormProvider {...form}>
        <Styled.Form ref={formRef} onSubmit={handleSubmit(handleFormSubmit)}>
          <Input
            name="userName"
            formControl={control}
            label={t('salary.signup.form.userName.label')}
            disabled={disabled || predefinedFields.userName}
            required
          />
          <Input
            name="organizaionStreet"
            formControl={control}
            label={t('salary.signup.form.street.label')}
            placeholder={t('salary.signup.form.street.placeholder')}
            disabled={disabled || predefinedFields.organizaionStreet}
            required
          />

          <Styled.AddressGroup>
            <Styled.ZipCodeField>
              <Input
                name="organizationZipCode"
                formControl={control}
                placeholder={t('salary.signup.form.zip_code.placeholder')}
                disabled={disabled || predefinedFields.organizationZipCode}
              />
            </Styled.ZipCodeField>
            <Styled.CityField>
              <Input
                name="organizationCity"
                formControl={control}
                placeholder={t('salary.signup.form.city.placeholder')}
                disabled={disabled || predefinedFields.organizationCity}
              />
            </Styled.CityField>
          </Styled.AddressGroup>

          <Input
            name="organizationCvr"
            formControl={control}
            label={t('salary.signup.form.tax_id.label')}
            disabled={disabled || predefinedFields.organizationCvr}
            required
          />
          <Input
            name="userPhone"
            formControl={control}
            label={t('salary.signup.form.phone.label')}
            disabled={disabled || predefinedFields.userPhone}
            required
          />

          <Styled.Footer>
            <ButtonProtected
              scopes={Scope.SalaryWrite}
              size="xxl"
              type="submit"
              loading={isCreatingAccount}
              fullWidth
              trackingContext={TrackingContext.SalarySignup}
            >
              {t('salary.signup.form.submit')}
            </ButtonProtected>

            <Styled.FooterInfo>
              <Text variant="small" colorVariant="secondary">
                {t('salary.signup.form.footer_info')}
              </Text>
            </Styled.FooterInfo>
          </Styled.Footer>
        </Styled.Form>
      </FormProvider>

      <SalarySignupConfirmationModal
        isOpen={isModalOpen}
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
        onClose={handleModalCancel}
      />
    </Styled.SalarySignupFormWrapper>
  )
}
