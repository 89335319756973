import findIndex from 'lodash/findIndex'
import findLastIndex from 'lodash/findLastIndex'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useKey } from 'react-use'

import { NON_SELECTABLE_ACCESSORS } from '../constants/nonSelectableAccessors'
import { SELECTABLE_ACCESSORS } from '../constants/selectableAccessors'
import { NavItemAccessor } from '../types/navItemAccessor'
import { NavItemNavigable } from '../types/navItemNavigable'
import { getRealAndSelectableItems } from '../utils/getRealAndSelectableItems'
import { getRealItems } from '../utils/getRealItems'

const getClosestNonAccessorIndex = <T extends NavItemNavigable>(
  items: T[],
  currentIndex: number,
  direction: 'up' | 'down',
) => {
  if (direction === 'down') {
    return findIndex(
      items,
      (item) => !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor),
      currentIndex,
    )
  }

  return findLastIndex(
    items,
    (item) => !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor),
    currentIndex,
  )
}

const getInitialIndex = <T extends NavItemNavigable>(items: T[]) => {
  return getClosestNonAccessorIndex(items, 0, 'down')
}

const getInitialItemId = (selectedId?: string) => {
  return selectedId || ''
}

interface UseNavItemsNavigationProps<T> {
  isActive?: boolean
  items: T[]
  onItemSelect?: (item: T) => void
  selectedId?: string
}

export const useNavItemsNavigation = <T extends NavItemNavigable>({
  isActive,
  items,
  onItemSelect,
  selectedId = '',
}: UseNavItemsNavigationProps<T>): string | undefined => {
  const initialCurrentId = getInitialItemId(selectedId)
  const [currentId, setCurrentId] = useState<string>(initialCurrentId)
  const target = isActive ? window : null
  const canNavigate = useMemo(
    () => items.filter((item) => !NON_SELECTABLE_ACCESSORS.includes(item.accessor as NavItemAccessor)).length > 0,
    [items],
  )

  useEffect(() => {
    const isCurrentItemExist = items.find((item) => item.id === currentId)

    if (!isCurrentItemExist) {
      setCurrentId('')
    }
  }, [items, currentId, selectedId])

  useEffect(() => {
    const currentId = getInitialItemId(selectedId)
    setCurrentId(currentId)
    // should only be invoked on isActive or selectedId change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isActive, selectedId])

  const handleArrowUpPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isActive || !canNavigate) {
        return
      }

      event.preventDefault()

      setCurrentId((previousId) => {
        const previousIndex = items.findIndex((item) => item.id === previousId)
        const nextIndex = getClosestNonAccessorIndex(items, previousIndex - 1, 'up')
        return (nextIndex === -1 ? items[items.length - 1]?.id : items[nextIndex]?.id) || ''
      })
    },
    [isActive, canNavigate, items],
  )

  const handleArrowDownPress = useCallback(
    (event: KeyboardEvent) => {
      if (!isActive || !canNavigate) {
        return
      }

      event.preventDefault()

      setCurrentId((previousId) => {
        const previousIndex = items.findIndex((item) => item.id === previousId)
        const nextIndex = getClosestNonAccessorIndex(items, previousIndex + 1, 'down')
        return (nextIndex === -1 ? items[getInitialIndex(items)]?.id : items[nextIndex]?.id) || ''
      })
    },
    [isActive, canNavigate, items],
  )

  const handleSubmit = useCallback(() => {
    const realItems = getRealItems(items)
    const realAndSelectableItems = getRealAndSelectableItems(items)
    let submittedItem: T | undefined

    // if no element is selected or only one element has left on the list, take it after submitting
    if (
      (realAndSelectableItems.length === 1 &&
        realAndSelectableItems[0]?.accessor &&
        !SELECTABLE_ACCESSORS.includes(realAndSelectableItems[0].accessor)) ||
      !currentId
    ) {
      submittedItem = realItems[0]
    } else {
      submittedItem = realAndSelectableItems.find((item) => item.id === currentId)
    }

    if (submittedItem) {
      onItemSelect?.(submittedItem)
    }
    // T is a generic
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items, onItemSelect, currentId])

  useKey('ArrowUp', handleArrowUpPress, { target }, [target, currentId, items])
  useKey('ArrowDown', handleArrowDownPress, { target }, [target, currentId, items])
  useKey('Enter', handleSubmit, { target }, [target, currentId, items])
  useKey('Tab', handleSubmit, { target }, [target, currentId, items])

  return currentId
}
