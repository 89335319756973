import { Color, FontWeight } from '@design-system'

import { css } from '@emotion/core'
import numeral from 'numeral'
import React, { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex, FlexProps } from 'rebass'

import { IconCaretDown } from '../../../components-deprecated/Icons'
import { useOnClickOutside } from '../../../hooks'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import { selectDifferenceType } from '../action-creators'
import { bankReconciliationDifferenceTypeTKeys } from '../constants/bankReconciliationDifferenceTypeTKeys'
import { Difference, DifferenceType } from '../types'

export type MatchDifferenceProps = FlexProps & {
  difference: Difference
  matchId: string
}

export const MatchDifference = ({ difference, matchId, ...rest }: MatchDifferenceProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const bankLineMatches = useSelector((state: SpecificState) => state.bankReconciliation.bankLineMatches)
  const buttonRef = useRef<HTMLDivElement | null>(null)

  const bankLineMatch = bankLineMatches.find((match) => match.id === matchId)
  const differenceTypeSet = bankLineMatch?.differenceType

  const { amount, differenceAmount } = difference

  useOnClickOutside(buttonRef, () => setIsOpen(false))

  const handleSelectDifferenceType = (type: DifferenceType) => {
    dispatch(selectDifferenceType({ type, matchId }))
    setIsOpen(false)
  }

  const formatAmount = (amount: number) => numeral(amount).format('0,0[.]00')

  return (
    <Box
      css={css`
        position: relative;
      `}
      className={reactClass('match-difference')}
      ref={buttonRef}
      {...rest}
    >
      <Flex
        onClick={() => setIsOpen(!isOpen)}
        css={css`
          min-width: 0px;
          padding: 8px 10px;
          background-color: ${differenceTypeSet ? 'white' : '#FFF5CC'};
          border: 1px solid ${differenceTypeSet ? '#ccc' : '#FFE75C'};
          border-top: ${differenceTypeSet ? '1px solid #eee' : '0'};
          border-radius: 0px 0px 4px 4px;
          transform: translateY(-3px);
          font-size: 12px;
          line-height: 1.2;
          cursor: pointer;
        `}
      >
        <Box flex="1">
          {differenceTypeSet ? (
            t(bankReconciliationDifferenceTypeTKeys[differenceTypeSet]) +
            `: ${formatAmount(differenceAmount ?? amount)} DKK`
          ) : (
            <Trans
              i18nKey="bankreconciliation.match.difference"
              values={{
                diff: formatAmount(differenceAmount ?? amount),
              }}
            >
              Book <strong>{formatAmount(differenceAmount ?? amount)} DKK</strong> as
            </Trans>
          )}
        </Box>
        <Box
          css={css`
            margin-left: 6px;
            margin-top: -1px;
          `}
        >
          <IconCaretDown width={12} height={12} />
        </Box>
      </Flex>
      <Flex
        as="div"
        css={css`
          position: absolute;
          top: 30px;
          left: 0px;
          width: 100%;
          min-width: 227px;
          padding: 10px;
          background-color: white;
          border-radius: 4px;
          z-index: 2;
          opacity: ${isOpen ? 1 : 0};
          pointer-events: ${isOpen ? 'auto' : 'none'};
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);

          &:before {
            content: '';
            position: absolute;
            top: -12px;
            left: 10px;
            border-right: 8px solid transparent;
            border-top: 8px solid transparent;
            border-left: 8px solid transparent;
            border-bottom: 10px solid white;
          }
        `}
      >
        <Flex flexDirection="column">
          {difference.types.map((type) => (
            <Box
              as="span"
              key={type}
              onClick={() => handleSelectDifferenceType(type)}
              css={css`
                color: ${Color.Green120};
                font-size: 12px;
                font-weight: ${FontWeight.Medium};
                cursor: pointer;

                &:hover {
                  text-decoration: underline;
                }
              `}
            >
              {t(`bankreconciliation.difference_type.${type}`)}
            </Box>
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}
