import { IconName } from '@design-system'

import { Organization } from '../../../../app/organization/types/organization'
import { Umbrella } from '../../../../app/umbrellas/types/umbrella'
import { AvatarProps } from '../OrganizationAvatar'

export const getAvatarProps = (organization?: Organization, umbrella?: Umbrella): AvatarProps => {
  const { iconUrl, name } = organization || {}

  if (iconUrl) {
    return { src: iconUrl, alt: `avatar-${name}` }
  }

  const icon: IconName = umbrella ? 'treeChart' : 'suitcase'
  return { icon }
}
