import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { APIError } from '../../../utils'
import { useUserOrganization } from '../../app/organization'
import { createScoutConsentUrl, CreateScoutConsentUrlResponse } from '../query-api'

interface UseCreateScoutConsentUrlProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: CreateScoutConsentUrlResponse) => void
}

interface UseCreateScoutConsentUrlResponse {
  create: (accountIds: string[]) => void
  isProcessing: boolean
}

export const useCreateScoutConsentUrl = (props: UseCreateScoutConsentUrlProps): UseCreateScoutConsentUrlResponse => {
  const { onError, onSuccess } = props
  const { organization } = useUserOrganization()

  const createConsentUrlMutation = useMutation(
    async (accountIds: string[]) => {
      return (
        organization &&
        (await createScoutConsentUrl({
          accountIds,
          organizationId: organization.id,
          organizationUrl: organization.url,
        }))
      )
    },
    {
      onSuccess: (response) => {
        response && onSuccess && onSuccess(response)
      },
      onError: (error?: APIError) => {
        onError && onError(error)
      },
    },
  )

  const create = useCallback(
    (accountIds: string[]) => {
      return createConsentUrlMutation.mutate(accountIds)
    },
    [createConsentUrlMutation],
  )

  return {
    create,
    isProcessing: createConsentUrlMutation.isLoading,
  }
}
