import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { useUmbrella } from '../../../../app/umbrellas'
import { deleteUmbrellaOrganizationInvitation } from '../../../../app/umbrellas/query-api'

interface UseDeleteOrganizationInvitationArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteOrganizationInvitation = ({ onError, onSuccess }: UseDeleteOrganizationInvitationArgs = {}) => {
  const { umbrella } = useUmbrella()
  const { isLoading: isDeleting, mutate: deleteInvitation } = useMutation(
    (invitationId: string) => deleteUmbrellaOrganizationInvitation(umbrella?.id || '', invitationId),
    { onError, onSuccess },
  )

  return {
    deleteInvitation,
    isDeleting,
  }
}
