import { LazyScrolling, LazyScrollingItem, Link, LoadingPlaceholder } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, BoxProps, Flex } from 'rebass'

import { IconCaretDown } from '../../../components-deprecated/Icons'
import { QueryParamKeys } from '../../../enums/queryParamKeys'
import { useQueryParamsStorage } from '../../../hooks/useQueryParamsStorage'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import { bankLinesAndTransactionsRequested, filtersChanged } from '../action-creators'
import { BankReconcileQueryParams, ExtendedBankLineMatch } from '../types'
import { MatchListRow } from './MatchListRow'

export type MatchListRowContext = {
  bulkSelectionMode: boolean
  isDeleteMode: boolean
  onChangeSelectedIds: (selectedIds: string[]) => void
  selectedIds: string[]
}

type MatchListProps = BoxProps & MatchListRowContext

const LoadingPlaceholders = () => {
  const style = {
    borderRadius: '4px',
    color1: '#F5FAFF',
    color2: '#DEEFFF',
    fullWidth: true,
    lineHeight: '24px',
    lines: 1,
    spacing: '18px',
  }

  return (
    <>
      {new Array(7).fill(true).map((_, index) => (
        <Box
          key={index}
          css={css`
            display: grid;
            grid-template-columns: 280fr 230fr;
            column-gap: 5px;
            margin-bottom: 18px;
          `}
        >
          <LoadingPlaceholder flex="5" {...style} mr="5px" />
          <LoadingPlaceholder flex="3" {...style} />
        </Box>
      ))}
    </>
  )
}

export const MatchList = ({ bulkSelectionMode, isDeleteMode, selectedIds, onChangeSelectedIds }: MatchListProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { setQueryParam } = useQueryParamsStorage<BankReconcileQueryParams>(QueryParamKeys.MatchList)

  const isBalanceBarOpen = useSelector((state: SpecificState) => state.bankReconciliation.isBalanceBarOpen)
  const bankLineMatches = useSelector((state: SpecificState) => state.bankReconciliation.bankLineMatches)
  const bankLinesLoading = useSelector((state: SpecificState) => state.bankReconciliation.bankLinesLoading)
  const bankLinesSortProperty = useSelector(
    (state: SpecificState) => state.bankReconciliation.filters.bankLinesSortProperty,
  )
  const bankLinesSortDirection = useSelector(
    (state: SpecificState) => state.bankReconciliation.filters.bankLinesSortDirection,
  )

  const requestRows = useCallback(() => {
    dispatch(bankLinesAndTransactionsRequested(true))
  }, [dispatch])

  const createRenderMatchFunction = useCallback((contextProps: MatchListRowContext) => {
    return (match: LazyScrollingItem) => (
      <MatchListRow key={match.id} match={match as ExtendedBankLineMatch} context={contextProps} />
    )
  }, [])

  const handleSort = useCallback(
    (title: 'entryDate' | 'sideAmount') => {
      const direction = bankLinesSortProperty === title ? bankLinesSortDirection * -1 : 1

      setQueryParam('bankLinesSortProperty', title)
      setQueryParam('bankLinesSortDirection', direction)

      dispatch(
        filtersChanged({
          bankLinesSortProperty: title,
          bankLinesSortDirection: direction,
        }),
      )
    },
    [dispatch, setQueryParam, bankLinesSortProperty, bankLinesSortDirection],
  )

  return (
    <Box className={reactClass('match-list-container')} mb="10px">
      <Box
        backgroundColor={'white'}
        css={css`
          column-gap: 5px;
          display: grid;
          grid-template-columns: 280fr 230fr;
        `}
      >
        <Flex
          className={reactClass('list-heading')}
          fontSize="14px"
          css={css`
            padding: 10px;
            a {
              display: flex;
              border-bottom: none !important;
              text-decoration: none !important;
            }
          `}
        >
          <Box width="100px">
            <Link onClick={() => handleSort('entryDate')}>
              {t('bankreconciliation.matchgroups.paymentdate')}
              {bankLinesSortProperty === 'entryDate' && (
                <IconCaretDown ml="4px" width={12} height={12} rotate={bankLinesSortDirection === -1} color="primary" />
              )}
            </Link>
          </Box>

          <Link
            disabled
            css={css`
              flex: 1;
              margin-left: 8px;
            `}
          >
            {t('bankreconciliation.matchgroups.description')}
          </Link>
          <Box
            width="64px"
            css={css`
              text-align: right;
            `}
          >
            <Link onClick={() => handleSort('sideAmount')}>
              {t('bankreconciliation.matchgroups.amount')}
              {bankLinesSortProperty === 'sideAmount' && (
                <IconCaretDown ml="4px" width={12} height={12} rotate={bankLinesSortDirection === -1} color="primary" />
              )}
            </Link>
          </Box>
        </Flex>
      </Box>
      <Box
        className={reactClass('match-list')}
        css={css`
          overflow-x: hidden;
          overflow-y: auto;
          height: 100%;
          max-height: calc(100vh - ${isBalanceBarOpen ? 394 : 320}px);
          margin-right: -10px;
          padding-right: 10px;
          padding-bottom: 30px;
        `}
      >
        {bankLinesLoading ? (
          <LoadingPlaceholders />
        ) : (
          <LazyScrolling
            items={bankLineMatches}
            render={createRenderMatchFunction({ bulkSelectionMode, isDeleteMode, onChangeSelectedIds, selectedIds })}
            request={requestRows}
          />
        )}
      </Box>
    </Box>
  )
}
