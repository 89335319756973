import { Form, FormsProps, FormSubmittedData, Input, PopOverFooter, Reset, Submit } from '@components-deprecated'

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import { ContactSelector, ProductSelector } from '../../../modules/app'
import { SpecificState } from '../../types'
import { createInvoiceAndReconcile } from '../action-creators'
import { ExtendedBankLineMatch } from '../types'

export type CreateInvoiceFormProps = FormsProps & {
  bankLineMatch: ExtendedBankLineMatch
  onClose?: () => void
}

export const CreateInvoiceForm = ({ bankLineMatch, onClose, ...rest }: CreateInvoiceFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const account = useSelector((state: SpecificState) => state.app.account)
  const organization = useSelector((state: SpecificState) => state.app.organization)
  const description = bankLineMatch?.bankLines.map((line) => line.description).join(', ')

  const [defaultValues, setDefaultValues] = useState({ description })

  const handleSubmit = (formData: FormSubmittedData) => {
    const { _contact, _product, description } = formData
    dispatch(
      createInvoiceAndReconcile({
        bankLineMatch,
        contactId: _contact.id,
        currencyId: account?.currencyId as string,
        description,
        organizationId: organization?.id as string,
        productId: _product?.id || '',
      }),
    )
    if (onClose) {
      setTimeout(() => onClose(), 100)
    }
  }

  useEffect(() => {
    setDefaultValues({ description })
  }, [description])

  return (
    <Form defaultValues={defaultValues} onSubmit={handleSubmit} {...rest}>
      <ContactSelector name="contact" listWidth="100%" isCustomer required />
      <ProductSelector name="product" listWidth="100%" required />
      <Input name="description" placeholder={t('description_optional')} />
      <PopOverFooter>
        <Reset onClick={onClose} size="l">
          {t('cancel')}
        </Reset>
        <Submit>{t('reconcile')}</Submit>
      </PopOverFooter>
    </Form>
  )
}
