import styled from '@emotion/styled'

import { Color } from '../../enums/color'
import { getDefaultTransition } from '../../utils/getDefaultTransition'
import { skeletonAnimation } from '../../utils/skeletonAnimation'

interface ProgressBarWrapperProps {
  height: number
  withAnimation?: boolean
}

export const ProgressBarWrapper = styled.div<ProgressBarWrapperProps>`
  height: ${({ height }) => height}px;
  width: 100%;
  background-color: ${Color.DeepSeaGreen};
  border-radius: ${({ height }) => height / 2}px;
  overflow: hidden;
  ${({ withAnimation }) => withAnimation && skeletonAnimation()};
`

interface ProgressBarProps {
  progress: number
  color: Color
}

export const Progress = styled.div<ProgressBarProps>`
  height: 100%;
  width: ${({ progress }) => `${progress}%`};
  background-color: ${({ color }) => color};
  position: relative;
  z-index: 1;
  ${getDefaultTransition('background-color', 'width')};
`
