import produce from 'immer'
import { AnyAction } from 'redux'

import { AppState } from '../types'
import { CONTACT_UPDATED, CONTACTS_RECEIVED, CONTACTS_REQUESTED } from './actions'

const contactsReducer = (state: AppState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case CONTACTS_REQUESTED:
      return state

    case CONTACTS_RECEIVED:
      return produce(state, (draftState) => {
        draftState.contacts = payload
      })

    case CONTACT_UPDATED:
      return produce(state, (draftState) => {
        const index = state.contacts?.findIndex((contact) => payload.id === contact.id) || -1
        const contactsUpdated = [...(state.contacts || [])]

        if (contactsUpdated && index !== -1) {
          contactsUpdated[index] = payload
        }

        draftState.contacts = contactsUpdated
      })

    default:
      return state
  }
}

export default contactsReducer
