import React, { ReactElement } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Account } from '../../../../modules/app/accounts/types'
import * as Styled from './styles'

interface AllAccountSelectorProps {
  areAllFilesSaved: boolean
  bulkAccount?: Account
  setBulkAccount(bulkAccount: Account): void
}

export const AllAccountSelector = ({
  areAllFilesSaved,
  bulkAccount,
  setBulkAccount,
}: AllAccountSelectorProps): ReactElement => {
  const form = useFormContext()
  const { t } = useTranslation()
  const {
    setValue,
    formState: { isSubmitting },
  } = form

  const handleBulkAccountSelect = (account: Account) => {
    if (!account) {
      return
    }

    setBulkAccount(account)

    const { items: fileItems } = form.getValues()

    const items = fileItems || []

    for (let i = 0; i < items.length; i++) {
      setValue(`items[${i}].account`, { id: account.id })
    }
  }

  return (
    <Styled.AllAccountSelector
      name="all-account-selector"
      label={t('attachments.select_for_all')}
      onItemSelect={handleBulkAccountSelect}
      preselectedId={bulkAccount?.id}
      disabled={areAllFilesSaved || isSubmitting}
      isPaymentEnabled
      simplifiedList
    />
  )
}
