import { Attachment, AttachmentFile } from '@design-system'

import qs from 'qs'

import { ApiContentType } from '../../enums/apiContentType'
import { Origin } from '../../enums/origin'
import { SortDirection } from '../../enums/sortDirection'
import { FileWithOrigin } from '../../types/fileWithOrigin'
import { Metable } from '../../types/metable'
import { UploadFileHeaders } from '../../types/uploadFilesHeaders'
import { deleteRequest, getRequest, postFileRequest, postV2Request, putRequest } from '../../utils'
import { ReceiptFillType, ReceiptFilterType } from './elements/Receipt'
import { ScoutProvider } from './enums/scoutProvider'
import { ReceiptsSortProperty } from './enums/sort'
import { ScoutConnection } from './types/scoutConnection'

export interface FetchReceiptsResponseData extends Metable {
  attachments: Attachment[]
}

export interface FetchReceiptsOptions {
  include?: string
  organizationId: string
  page?: number
  pageSize?: number
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: ReceiptsSortProperty
  fillType?: ReceiptFillType
  type?: ReceiptFilterType
}

export const fetchReceipts = ({
  include = 'attachment.file:embed,file.variants:embed',
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
  fillType,
  type,
}: FetchReceiptsOptions): Promise<FetchReceiptsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(fillType === ReceiptFillType.Filled ? { handled: 1 } : {}),
    ...(fillType === ReceiptFillType.Nonfilled ? { unhandled: 1 } : {}),
    ...(type !== ReceiptFilterType.All ? { type } : {}),
    ...(include ? { include } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(sortProperty ? { sortProperty } : {}),
    ...(sortDirection ? { sortDirection } : {}),
    ...(searchQuery ? { q: searchQuery } : {}),
  })

  return getRequest(`/v2/attachments?${queryParams}`)
}

export interface FetchReceiptResponseData {
  attachment: Attachment
}

export interface FetchReceiptOptions {
  id: string
  include?: string
}

export const fetchReceipt = ({
  id,
  include = 'attachment.file:embed,file.variants:embed',
}: FetchReceiptOptions): Promise<FetchReceiptResponseData> => {
  const queryParams = qs.stringify({
    ...(include ? { include } : {}),
  })

  return getRequest(`/v2/attachments/${id}?${queryParams}`)
}

export interface UploadFileResponseData {
  files: AttachmentFile[]
}

export const uploadFile = (file: File, apiHeaders?: UploadFileHeaders): Promise<UploadFileResponseData> => {
  const updatedFile: FileWithOrigin = file
  updatedFile.origin = Origin.Web

  return postFileRequest('/v2/files', updatedFile, apiHeaders ? { headers: apiHeaders } : {})
}

export const deleteReceipt = (receiptId: string): Promise<UploadFileResponseData> => {
  return deleteRequest(`/v2/attachments/${receiptId}`)
}

export const deleteReceiptsBulk = (receiptsIds: string[]): Promise<UploadFileResponseData> => {
  const query = qs.stringify({ ids: receiptsIds })
  return deleteRequest(`/v2/attachments?${query}`)
}

export interface FetchAttachmentsCountsResponseData extends Metable {
  counts: {
    unhandledAttachments: string
    unhandledBills: string
    unhandledEDocuments: string
  }
}

export const fetchAttachmentsCount = (organizationId: string): Promise<FetchAttachmentsCountsResponseData> =>
  getRequest(`/v2/attachments/counts?organizationId=${organizationId}`)

export interface UpdateAttachmentPayload extends Partial<Omit<Attachment, 'id'>> {
  id: string
}

export type UpdateAttachmentResponseData = FetchReceiptsResponseData

export const updateAttachment = (payload: UpdateAttachmentPayload): Promise<UpdateAttachmentResponseData> => {
  const { id, ...attachment } = payload
  return putRequest(`/v2/attachments/${id}`, { attachment })
}

export interface RemoveAttachmentOwnerReferencePayload {
  attachmentId: string
  organizationId: string
}

export type RemoveAttachmentOwnerReferenceResponseData = FetchReceiptsResponseData

export const removeAttachmentOwnerReference = (
  payload: RemoveAttachmentOwnerReferencePayload,
): Promise<RemoveAttachmentOwnerReferenceResponseData> => {
  const { attachmentId, organizationId } = payload
  return putRequest(`/v2/attachments/${attachmentId}/removeOwner?organizationId=${organizationId}`, {})
}

interface RejectAttachmentPayload {
  record: {
    reason: string
  }
}

export interface RejectAttachmentProps {
  attachmentId?: string
  organizationId?: string
  reason: string
}

export const rejectAttachment = (props: RejectAttachmentProps) => {
  const { attachmentId, organizationId, reason } = props
  return postV2Request<RejectAttachmentPayload>({
    endpoint: `/v2/organizations/${organizationId}/attachments/${attachmentId}/reject`,
    payload: {
      record: {
        reason,
      },
    },
    options: {
      contentType: ApiContentType.ApplicationJSON,
    },
  })
}

export const fetchScoutConnections = (organizationId: string): Promise<ScoutConnection[]> => {
  return getRequest(`/email-scanning/organization/${organizationId}/connection`)
}

export interface CreateScoutConsentUrlPayload {
  accountIds: string[]
  organizationId: string
  organizationUrl: string
}
export interface CreateScoutConsentUrlResponse {
  [ScoutProvider.Google]: string
}

export const createScoutConsentUrl = (props: CreateScoutConsentUrlPayload): Promise<CreateScoutConsentUrlResponse> => {
  return postV2Request({
    endpoint: `/email-scanning/organization/${props.organizationId}/connection/consent-url`,
    payload: {
      accountIds: props.accountIds,
      organizationUrl: props.organizationUrl,
    },
    options: {
      contentType: ApiContentType.ApplicationJSON,
    },
  })
}

export interface DeleteScoutConnectionPayload {
  connectionId: string
  organizationId: string
}
export interface DeleteScoutConnectionResponse {
  success: boolean
}

export const deleteScoutConnection = (props: DeleteScoutConnectionPayload): Promise<DeleteScoutConnectionResponse> => {
  return deleteRequest(`/email-scanning/organization/${props.organizationId}/connection/${props.connectionId}`)
}
