import { ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { SortDirection } from '../../../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../../../hooks/usePaginationPrefetching'
import { Invoice } from '../../../../../modules/invoices/types/invoice'
import { Paging } from '../../../../../types/metable'
import { useUserOrganization } from '../../../../app/organization'
import { Contact } from '../../../../contacts/types/contact'
import { invoiceStatusToQueryProps } from '../../../constants/invoiceStatusToQueryProps'
import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { fetchInvoices, FetchInvoicesResponseData, InvoicesSortProperty } from '../../../query-api'
import { InvoiceSendingState } from '../../../types/invoiceSendingState'
import { InvoicesQueryProps } from '../../../types/invoicesQueryProps'
import { getInvoicesQueryKey } from '../../../utils/getInvoicesQueryKey'
import { InvoicesSummaryQueryProps } from '../types/InvoicesSummaryQueryProps'

interface UseFetchInvoicesProps {
  contactId?: string
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sendingState?: InvoiceSendingState
  sortDirection?: SortDirection
  sortProperty?: InvoicesSortProperty
  status?: InvoiceStatus
}

interface UseFetchInvoicesResponse {
  invoices: Invoice[]
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchInvoices = ({
  contactId,
  page,
  pageSize,
  period,
  searchQuery,
  sendingState,
  sortDirection,
  sortProperty,
  status,
}: UseFetchInvoicesProps): UseFetchInvoicesResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps: InvoicesSummaryQueryProps = useMemo(
    () => ({
      ...(status ? invoiceStatusToQueryProps[status] : {}),
      ...(contactId ? { contactId } : {}),
      entryDatePeriod: period,
      organizationId: organization?.id as string,
      page,
      pageSize: pageSize as ItemsPerPage,
      sendingState,
      sortDirection,
      sortProperty,
      q: searchQuery,
    }),
    [
      status,
      contactId,
      period,
      organization?.id,
      page,
      pageSize,
      sendingState,
      sortDirection,
      sortProperty,
      searchQuery,
    ],
  )
  const queryKey = useMemo(() => getInvoicesQueryKey(queryProps), [queryProps])

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchInvoices(queryProps), {
    enabled: !!organization,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<InvoicesQueryProps, FetchInvoicesResponseData>({
    getQueryFunction: useMemo(() => fetchInvoices, []),
    getQueryKey: useMemo(() => getInvoicesQueryKey, []),
    isEnabled: !!organization?.id && !isLoading,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    invoices: data?.invoices || [],
    contacts: data?.contacts || [],
    isLoading: isIdle || isLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
