import { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext, useOrganizationSubscription, useWatch } from '../../../../../../../../hooks'
import { isPaidPlan } from '../../../../../../../settings/routes/OrganizationSubscriptionSettings/plansData'
import { SendEmailInvoiceFormSchema } from '../../../../utils/emailInvoiceFormData'
import { ContactPersonSelectWithTags } from '../../../ContactPersonSelectWithTags'

interface ContactPersonSelectFormItemProps {
  contactId: string
}

export const ContactPersonSelectFormItem = ({ contactId }: ContactPersonSelectFormItemProps): ReactElement => {
  const { t } = useTranslation()

  const { isTrial, subscriptionPlan } = useOrganizationSubscription()
  const { FormItem, control } = useFormContext<SendEmailInvoiceFormSchema>()
  const recipients = useWatch({ control, name: 'recipients' })

  const allowMultipleRecipients = useMemo(() => isPaidPlan(subscriptionPlan) && !isTrial, [isTrial, subscriptionPlan])

  return (
    <FormItem
      label={t('modal.send_invoice.form.recipient.label')}
      name="recipients"
      render={({ field: { value, onChange, ...props } }) => (
        <ContactPersonSelectWithTags
          allowMultiple={allowMultipleRecipients}
          onChange={onChange}
          value={recipients || []}
          contactId={contactId}
        />
      )}
    />
  )
}
