import { DeductionComponent } from '../../../../../../../app/taxrates/types'
import { DeductionComponentForm } from '../../../../types/deductionComponentForm'
import { fractionToPercent } from '../../../../utils/fractionToPercent'

export const shareFromFractionToPercent = (deductionComponents: (DeductionComponent | DeductionComponentForm)[]) =>
  deductionComponents.map((item) => {
    return {
      ...item,
      share: fractionToPercent(item.share),
    }
  })
