import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { defaultQuery } from '../contactBalance'
import { CreditorsRoute } from './enums/creditorsRoute'
import { CreditorsList } from './routes/CreditorsList'

export const Creditors = (): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route component={CreditorsList} exact path={CreditorsRoute.List} />
    </Switch>
  )
}
