import { TaxRate } from '../../../../modules/app/taxrates/types'
import { BookkeepingTagQuestionResponseResult } from '../types/bookkeepingTag'

const abbreviationMap: Record<string, string[]> = {
  K: ['K', 'P', 'p_dk_25'],
  KYE: ['KYE', 'PSE', 'p_dk_eu_services_full'],
  KYU: ['KYU', 'PSF', 'p_dk_non_eu_services_full'],
  KVE: ['KVE', 'PGE', 'p_dk_eu_goods_full'],
  KVU: ['KVU', 'PGF', 'p_dk_non_eu_goods_full'],
  F: ['F', 'KF', 'PF', 'p_dk_no_deduction'],
  KK: ['KK', 'KR', 'PR', 'p_dk_representation_full'],
  KVY: ['KVY'],
}

export const getTaxRateFromBookkeepingTagAnswer = (
  answer: BookkeepingTagQuestionResponseResult,
  isVatExempted?: boolean,
  taxRates?: TaxRate[],
): TaxRate | undefined => {
  const taxRateAbbreviation = answer?.tax
  const taxRateAbbreviationAdjusted =
    isVatExempted && (taxRateAbbreviation === 'K' || taxRateAbbreviation === 'KK') ? 'F' : taxRateAbbreviation

  const comparedAbbreviations = abbreviationMap[taxRateAbbreviationAdjusted] || []
  return taxRates?.find((taxRate) => comparedAbbreviations.includes(taxRate.abbreviation))
}
