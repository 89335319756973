import { Button, ButtonsGroup, useModal, withModalConditionalRender } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { useUmbrella } from '../../../../../app/umbrellas'
import { UmbrellaTransferOwnership } from '../../../../../umbrellaTransferOwnership'
import { InviteOrUpdateUmbrellaUserModal } from '../InviteOrUpdateUmbrellaUserModal'

const InviteUmbrellaUserModalConditional = withModalConditionalRender(InviteOrUpdateUmbrellaUserModal)

export const UmbrellaUsersSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { open: openInviteUmbrellaUserModal } = useModal(ModalId.UmbrellaInviteOrUpdateUserModal)

  const handleInviteUserButtonClick = useCallback(() => {
    openInviteUmbrellaUserModal()
  }, [openInviteUmbrellaUserModal])

  return (
    <ButtonsGroup>
      <Button variant="primary" onClick={handleInviteUserButtonClick} icon="plusCircle">
        {t('umbrella_users.invite_user')}
      </Button>
      <UmbrellaTransferOwnership />
      <InviteUmbrellaUserModalConditional id={ModalId.UmbrellaInviteOrUpdateUserModal} umbrellaId={umbrella?.id} />
    </ButtonsGroup>
  )
}
