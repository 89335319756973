import React, { ElementType, forwardRef, KeyboardEvent, MouseEvent, ReactElement, ReactNode, Ref, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { useTheme } from '../../../../hooks/useTheme'
import { Asable } from '../../../../types/asable'
import { LinkProps } from '../../../../types/linkProps'
import { NavItemState } from '../../types/navItemState'
import { getStateToStyles } from '../../utils/getStateToStyles'
import * as Styled from './styles'

interface NavListItemViewProps extends Asable {
  children: ReactNode
  expanded?: boolean
  focused?: boolean
  href?: string
  onClick?: (event: MouseEvent) => void
  onKeyDown?: (event: KeyboardEvent) => void
  readonly?: boolean
  selected?: boolean
  state: NavItemState
  subItems?: ReactNode
  tabIndex?: number
  to?: string
}

export const NavListItemView = forwardRef(
  (
    {
      as,
      children,
      href,
      onClick,
      onKeyDown,
      readonly,
      state,
      subItems,
      tabIndex = 0,
      to,
      ...rest
    }: NavListItemViewProps,
    forwardedRef: Ref<HTMLDivElement>,
  ): ReactElement => {
    const theme = useTheme()
    const { backgroundColor } = getStateToStyles(theme)[state]
    const linkProps: LinkProps | undefined = useMemo(() => {
      if (href) {
        return { as: 'a' as ElementType, href, target: '_blank', rel: 'noopener noreferrer' }
      }

      if (to) {
        return { as: Link as ElementType, to }
      }

      return undefined
    }, [href, to])

    return (
      <Styled.NavListItemViewWrapper as={as}>
        <Styled.NavListItemView
          {...rest}
          {...linkProps}
          backgroundColor={backgroundColor}
          onClick={onClick}
          onKeyDown={onKeyDown}
          readonly={readonly}
          ref={forwardedRef}
          tabIndex={tabIndex}
        >
          {children}
        </Styled.NavListItemView>
        {subItems}
      </Styled.NavListItemViewWrapper>
    )
  },
)
