import { isFuture } from 'date-fns'

import { SubscriptionInfo } from '../modules/app/organization/types/subscriptionInfo'

export const startsInFuture = (subscriptionInfo: SubscriptionInfo | undefined): boolean => {
  return isFuture(new Date(subscriptionInfo?.StartDate || ''))
}

export const isSwitchingPlan = (subscriptionInfo: SubscriptionInfo | undefined): boolean => {
  if (!subscriptionInfo) {
    return false
  }

  if (startsInFuture(subscriptionInfo)) {
    return true
  }

  const currentState = (subscriptionInfo.ProductPlan?.Key + '_' + subscriptionInfo.ProductPlan?.Period).toLowerCase()
  const nextState = subscriptionInfo.nextState?.toLowerCase()

  if (nextState) {
    return nextState !== currentState
  }

  return false
}
