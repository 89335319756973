import {
  clearAllScrollRestorationData,
  getParentNavItem,
  hasNavItemSubItemSelected,
  isNavItemExpandable,
  NavList,
} from '@design-system'

import compact from 'lodash/compact'
import React, { memo, ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useEmberCurrentRoute } from '../../../../contexts/emberCurrentRouteContext'
import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Timeout } from '../../../../types/timeout'
import { useUserOrganization } from '../../../app/organization'
import { useUmbrella } from '../../../app/umbrellas'
import { routeToUmbrella } from '../../../umbrella/utils/routeToUmbrella'
import { SkeletonMenu } from './elements/SkeletonMenu'
import { useMenuItems } from './hooks/useMenuItems'
import * as Styled from './styles'
import { getSelectedMenuItemId } from './utils/getSelectedMenuItemId'

const VALIDATE_ROUTE_AFTER_CLICK_TIMEOUT_MS = 2000

interface MenuItemsProps {
  closeMenu?: () => void
}

export const MenuItems = memo(({ closeMenu }: MenuItemsProps): ReactElement => {
  const [expandedId, setExpandedId] = useState<string>()
  const [selectedId, setSelectedId] = useState<string>()
  const { currentRoutePath } = useEmberCurrentRoute()
  const { umbrella } = useUmbrella()
  const { navigate } = useEmberRouter()
  const { organization } = useUserOrganization()
  const { items, isLoading } = useMenuItems()
  const validateRouteTimeout = useRef<Timeout>()
  const umbrellaLocation = useLocation()

  const isOrganizationLocked = organization?.isLocked

  useEffect(() => {
    if (validateRouteTimeout.current) {
      clearTimeout(validateRouteTimeout.current)
    }

    validateRoute()

    // We don't need to pass them to function as those are global variables,
    // but we need to trigger 'validateRoute' on every change of those
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRoutePath, items, umbrellaLocation.pathname])

  const validateRoute = useCallback(() => {
    const currentPath = umbrella ? umbrellaLocation.pathname : currentRoutePath
    const selectedId = getSelectedMenuItemId(items, currentPath, !!umbrella)
    setExpandedId(undefined)
    setSelectedId(selectedId)
  }, [items, currentRoutePath, umbrellaLocation.pathname, umbrella])

  /*
      We select menu items just after user click (better performance feeling), but sometimes we show the modal guard
      saying "do you want to leave a page". If we stays on the page, we need to invalidate the menu item again:
    */
  const validateRouteAfterClick = useCallback(() => {
    validateRouteTimeout.current = setTimeout(() => {
      validateRoute()
    }, VALIDATE_ROUTE_AFTER_CLICK_TIMEOUT_MS)
  }, [validateRoute])

  const navigateToItem = useCallback(
    (routeTo: EmberRoute) => {
      if (umbrella?.id) {
        routeToUmbrella(routeTo)
        closeMenu?.()
      } else {
        navigate(routeTo, [organization?.url])
      }
    },
    [navigate, organization?.url, umbrella?.id, closeMenu],
  )

  const handleMenuItemClick = useCallback(
    (id: string, routeTo: EmberRoute) => {
      clearAllScrollRestorationData()

      if (validateRouteTimeout.current) {
        clearTimeout(validateRouteTimeout.current)
      }

      const isExpandable = isNavItemExpandable(items, id)

      if (isExpandable) {
        const hasSubItemSelected = hasNavItemSubItemSelected(items, id, selectedId)

        if (hasSubItemSelected) {
          return
        }

        setExpandedId(id === expandedId ? undefined : id)
        return
      }

      setSelectedId(id)
      navigateToItem(routeTo)
      setExpandedId(undefined)
      validateRouteAfterClick()
    },
    [expandedId, items, navigateToItem, selectedId, validateRouteAfterClick],
  )

  const getExpandedIds = useCallback(() => {
    const item = selectedId ? getParentNavItem(items, selectedId) : undefined
    return compact([expandedId, item?.id])
  }, [expandedId, items, selectedId])

  return (
    <Styled.MenuItemsWrapper>
      {isLoading && <SkeletonMenu />}
      {!isLoading && !isOrganizationLocked && !!(organization || umbrella) && (
        <NavList
          items={items}
          selectedId={selectedId}
          expandedIds={getExpandedIds()}
          onItemClick={handleMenuItemClick}
        />
      )}
    </Styled.MenuItemsWrapper>
  )
})
