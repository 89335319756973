import { Company } from '../../../../modules/app/companies/types'

export const getCompanyAddress = (company: Company) => {
  const { city, number, numberTo, street, zipcode } = company

  if (!number) {
    return `${street}, ${zipcode} ${city}`
  }

  return numberTo ? `${street} ${number} - ${numberTo}` : `${street} ${number}, ${zipcode} ${city}`
}
