import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { invoiceStatusToQueryProps } from '../../../constants/invoiceStatusToQueryProps'
import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { fetchInvoicesSummary } from '../../../query-api'
import { InvoiceSendingState } from '../../../types/invoiceSendingState'
import { InvoicesSummaryData } from '../types/invoicesSummaryData'
import { InvoicesSummaryQueryProps } from '../types/InvoicesSummaryQueryProps'

interface UseFetchInvoicesSummaryProps {
  contactId?: string
  period?: string
  sendingState?: InvoiceSendingState
  searchQuery?: string
  status?: InvoiceStatus
}

interface UseFetchInvoicesSummaryResponse {
  data?: InvoicesSummaryData
  isLoading: boolean
}

export const useFetchInvoicesSummary = ({
  contactId,
  period,
  sendingState,
  searchQuery,
  status,
}: UseFetchInvoicesSummaryProps): UseFetchInvoicesSummaryResponse => {
  const { organization } = useUserOrganization()

  const queryProps: InvoicesSummaryQueryProps = useMemo(
    () => ({
      ...(contactId ? { contactId } : {}),
      ...(status ? invoiceStatusToQueryProps[status] : {}),
      entryDatePeriod: period,
      organizationId: organization?.id as string,
      sendingState,
      q: searchQuery,
    }),
    [contactId, status, period, organization?.id, sendingState, searchQuery],
  )

  const queryKey: [QueryKeys, InvoicesSummaryQueryProps] = useMemo(
    () => [QueryKeys.InvoicesSummary, queryProps],
    [queryProps],
  )

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchInvoicesSummary(queryProps), {
    enabled: !!organization,
    keepPreviousData: true,
  })

  return {
    data: data?.summary,
    isLoading: isIdle || isLoading,
  }
}
