import { Input as InputComponent } from '@components-deprecated'
import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import {
  CountrySelector as CountrySelectorComponent,
  CurrencySelector as CurrencySelectorComponent,
} from '../../../../../app'

interface OrganizationManualDataProps {
  visible?: boolean
}

export const OrganizationManualData = styled.div<OrganizationManualDataProps>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  margin-top: ${Spacing.L};
`

export const Input = styled(InputComponent)`
  margin-bottom: ${Spacing.S};
`

export const CurrencySelector = styled(CurrencySelectorComponent)`
  margin-bottom: ${Spacing.S};
  margin-left: ${Spacing.S};
`

export const CountrySelector = styled(CountrySelectorComponent)`
  margin-bottom: ${Spacing.S};
`

export const DualInputsWrapper = styled.div`
  display: flex;

  ${() => Input} {
    width: 50%;
    margin-bottom: 0;
    margin-right: ${Spacing.S};

    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const InputsSection = styled.div`
  margin-bottom: ${Spacing.L};
`
