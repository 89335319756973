import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberModal } from '../../contexts/emberCloseModalContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { VoidInvoiceModal } from '../../modules/invoices/elements/VoidInvoiceModal'

const MODULE_NAME = 'void-invoice-modal-module'

class VoidInvoiceModalWebComponent extends DOMModel {
  @byAttrVal invoiceId: string
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('voidInvoice') voidInvoice: Function | undefined
}

interface VoidInvoiceModalProps {
  invoiceId: string
  organizationId: string
  onNavigate: EmberNavigate
  onCloseEmber: EmberModal
  onVoidInvoice: Function
}

function VoidInvoiceModalModule({
  invoiceId,
  organizationId,
  onNavigate,
  onCloseEmber,
  onVoidInvoice,
}: VoidInvoiceModalProps): ReactElement {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  const voidInvoice = useCallback(() => {
    onVoidInvoice({ detail: null })
  }, [onVoidInvoice])

  return (
    <ThemeProvider>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <QueryClientProvider client={queryClient}>
          <ModalContextProvider>
            <CurrentUserContextProvider>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <VoidInvoiceModal
                  forceRender
                  invoiceId={invoiceId}
                  onModalClose={closeModal}
                  onInvoiceVoid={voidInvoice}
                />
              </UserOrganizationContextProvider>
            </CurrentUserContextProvider>
          </ModalContextProvider>
        </QueryClientProvider>
      </EmberRouterContextProvider>
    </ThemeProvider>
  )
}

const VoidInvoiceModalCustomElement = createCustomElement(
  VoidInvoiceModalModule,
  VoidInvoiceModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, VoidInvoiceModalCustomElement)

export default VoidInvoiceModalCustomElement
