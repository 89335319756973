import { Button, ButtonsGroup, Color, Icon, Link, Modal, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { ModalId } from '../../../enums/modalId'
import { useSegment } from '../../../hooks'
import {
  useUpdateOrganizationSettings,
  useUserOrganization,
  useUserOrganizationSettings,
} from '../../../modules/app/organization'
import { formatDate } from '../../../utils'
import { getImageClassName } from '../../../utils/getClassName'
import { getImgPath } from '../../../utils/getImgPath'
import * as Styled from './styles'

interface TrialEndedModalProps {
  organizationId: string
}

const SETTING = 'endOfTrialBasicModalViewedDate'

const BENEFITS = [
  'modal.trial_ended_modal.bullet.one',
  'modal.trial_ended_modal.bullet.two',
  'modal.trial_ended_modal.bullet.three',
]

export const TrialEndedModal = ({ organizationId }: TrialEndedModalProps): ReactElement | null => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const { update } = useUpdateOrganizationSettings()
  const { track } = useSegment()
  const { navigate } = useEmberRouter()
  const [displayModal, setDisplayModal] = useState(false)
  const [hasViewModal, setHasViewedModal] = useState(false)
  const { close } = useModal(ModalId.TrialEndedModal, {
    onClose: () => updateSetting(),
  })

  useEffect(() => {
    if (!hasViewModal && organization) {
      track('xxx - hamster - basic modal 2022-11 viewed')
      setHasViewedModal(true)
    }
  }, [hasViewModal, organization, track])

  useEffect(() => {
    // This is set by billy-api when trial ends and subscriptionPlan is basic
    setDisplayModal(organizationSettings?.endOfTrialBasicModalViewedDate === 'null')
  }, [organizationSettings, track])

  const updateSetting = useCallback(() => {
    update({
      key: SETTING,
      organizationId,
      value: formatDate(new Date()) as string,
    })
  }, [update, organizationId])

  const handleCloseClick = useCallback(() => {
    track('xxx - hamster - basic modal 2022-11 clicked', { choice: 'free' })
    close()
  }, [close, track])

  const handleConfirmClick = useCallback(() => {
    setDisplayModal(false)
    updateSetting()
    track('xxx - hamster - basic modal 2022-11 clicked', { choice: 'upgrade' })
    navigate(EmberRoute.SettingsSubscriptionsPlansSelection)
  }, [navigate, updateSetting, track])

  if (!displayModal) {
    return null
  }

  const link = t('here')

  return (
    <Modal id={ModalId.TrialEndedModal} forceRender closable={false}>
      <Styled.BodyWrapper>
        <Styled.ImageContainer>
          <img src={getImgPath('/modals/pointing.png')} className={getImageClassName()} alt="billy benefits" />
        </Styled.ImageContainer>

        <Styled.ContentContainer>
          <Text variant="h1">{t('modal.trial_ended_modal.title')}</Text>

          <Space size="xl" />

          <Text>{t('modal.trial_ended_modal.description')}</Text>

          <Space size="xl" />

          <Styled.BulletList>
            {BENEFITS.map((item, i) => {
              return (
                <div key={item + i}>
                  <Styled.BenefitItem>
                    <Styled.IconContainer>
                      <Icon icon="checkCircleSolid" color={Color.Green120} />
                    </Styled.IconContainer>
                    <Text variant="normal" alignment="left">
                      {t(item)}
                    </Text>
                  </Styled.BenefitItem>

                  <Space size="m" />
                </div>
              )
            })}
          </Styled.BulletList>

          <Space size="l" />

          <Text>
            <Trans i18nKey="modal.trial_ended_modal.compare" values={{ link }}>
              You can compare all functions
              <Styled.Text onClick={handleConfirmClick} inline hoverable>
                <Link>{link}</Link>
              </Styled.Text>
            </Trans>
          </Text>

          <Space size="xl" />

          <Styled.ButtonsContainer>
            <ButtonsGroup>
              <Button variant="text" onClick={handleCloseClick}>
                {t('modal.trial_ended_modal.btn.cancel')}
              </Button>

              <Button onClick={handleConfirmClick}>{t('modal.trial_ended_modal.btn.confirm')}</Button>
            </ButtonsGroup>
          </Styled.ButtonsContainer>
        </Styled.ContentContainer>
      </Styled.BodyWrapper>
    </Modal>
  )
}
