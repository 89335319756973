import { NavItem, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../../../enums/sortDirection'
import { useUserOrganization } from '../../../../../../../../app/organization'
import { fetchAdditionalFields, TaxMetaField } from '../../../../../../VatReturnSettings'
import { AdditionalFieldsSortProperty } from '../../../../../../VatReturnSettings/enums/additionalFieldsSortProperty'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getItems } from './utils/getItems'

interface AdditionalFieldSelectProps
  extends Omit<SelectProps<TaxMetaField>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: TaxMetaField) => void
}

export const AdditionalFieldSelect = ({ onSelect, ...selectProps }: AdditionalFieldSelectProps): ReactElement => {
  const { organization } = useUserOrganization()

  const { data: additionalFields, isLoading } = useQuery(
    [QueryKeys.AdditionalFields, organization?.id, AdditionalFieldsSortProperty.Priority, SortDirection.Asc],
    () =>
      fetchAdditionalFields({
        organizationId: organization?.id as string,
        sortProperty: AdditionalFieldsSortProperty.Priority,
        sortDirection: SortDirection.Asc,
      }),
    {
      enabled: !!organization?.id,
    },
  )

  const items = useMemo(() => getItems(additionalFields?.salesTaxMetaFields || []), [additionalFields])

  const getDisplayValue = useCallback((item: NavItem<TaxMetaField>) => getDisplayTitle(item.value), [])

  const handleSelect = useCallback(
    (id?: string, value?: TaxMetaField) => {
      const valueFiltered = omit(value, 'displayData')
      onSelect?.(id, valueFiltered)
    },
    [onSelect],
  )

  return (
    <Select
      {...selectProps}
      dropdownFetching={isLoading}
      getDisplayValue={getDisplayValue}
      items={items}
      dropdownSize="fitTrigger"
      onSelect={handleSelect}
    />
  )
}
