import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../../../../../../app/organization'
import { useDeleteInvoiceTemplate } from '../../../../hooks/useDeleteInvoiceTemplate'

interface DeleteInvoiceTemplateModalProps extends ModalConfirmationProps {
  invoiceTemplateId: string
}

export const DeleteInvoiceTemplateModal = ({
  invoiceTemplateId,
  ...modalProps
}: DeleteInvoiceTemplateModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close: closeDeleteTemplateModal } = useModal(modalProps.id)
  const { delete: deleteTemplate, isProcessing: isDeleting } = useDeleteInvoiceTemplate({
    onSuccess: () => {
      closeDeleteTemplateModal()
      notify({
        id: NotificationKeys.InvoiceTemplateDelete,
        message: t('settings.organization.invoice.templates_settings.table.actions.delete.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.InvoiceTemplates)
    },
    onError: (error) => {
      notify({
        id: NotificationKeys.InvoiceTemplateDelete,
        message: error?.body.errors[0].detail,
        variant: 'warning',
      })
    },
  })

  const handleDeleteButtonClick = useCallback(() => {
    if (!organization?.id || !invoiceTemplateId) {
      return
    }

    deleteTemplate({ organizationId: organization.id, invoiceTemplateId })
  }, [deleteTemplate, organization?.id, invoiceTemplateId])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('settings.organization.invoice.templates_settings.delete_modal.cancel_button')}
      danger
      message={t('settings.organization.invoice.templates_settings.delete_modal.body')}
      okLabel={t('settings.organization.invoice.templates_settings.delete_modal.delete_button')}
      okLoading={isDeleting}
      onOk={handleDeleteButtonClick}
      title={t('settings.organization.invoice.templates_settings.delete_modal.title')}
    />
  )
}
