import { AnyAction } from 'redux'

import { Contact } from '../../contacts/types/contact'
import { CONTACT_UPDATED, CONTACTS_RECEIVED, CONTACTS_REQUESTED } from './actions'

export type ContactsRequestedProps = {
  organizationId: string
  page: { offset: number }
  filters: {
    isCustomer?: boolean
    isVendor?: boolean
    pageSize?: number
    text: string | null
  }
}

export type ContactsRequested =
  | AnyAction
  | {
      payload: {
        organizationId: string
        page: { offset: number }
        filters: { text: string | null; isCustomer?: string; isVendor?: string; pageSize?: number }
      }
      type: typeof CONTACTS_REQUESTED
    }

export type ContactsReceived =
  | AnyAction
  | {
      payload: Contact[]
      type: typeof CONTACTS_RECEIVED
    }

export function contactsRequested({ organizationId, page, filters }: ContactsRequestedProps): ContactsRequested {
  return {
    payload: { organizationId, page, filters },
    type: CONTACTS_REQUESTED,
  }
}

export function contactsReceived(contacts: Contact[]): ContactsReceived {
  return {
    payload: contacts,
    type: CONTACTS_RECEIVED,
  }
}

export function contactUpdated(contact: Contact) {
  return {
    payload: contact,
    type: CONTACT_UPDATED,
  }
}
