import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { deleteOrganizationInvitation, useUserOrganization } from '../../../../app/organization'

interface UseDeleteInvitationArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteInvitation = ({ onError, onSuccess }: UseDeleteInvitationArgs = {}) => {
  const { organization } = useUserOrganization()
  const { isLoading: isSaving, mutate: deleteInvitation } = useMutation(
    (invitationId: string) => deleteOrganizationInvitation(organization?.id || '', invitationId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    deleteInvitation,
    isSaving,
  }
}
