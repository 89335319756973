import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { useUserOrganization } from '../../../../app/organization'
import { QuoteState } from '../../../enums/quoteState'
import { fetchQuotes } from '../../../query-api'
import { QuotesQueryProps } from '../../../types/quotesQueryProps'
import { getQuotesQueryKey } from '../../../utils/getQuotesQueryKey'

export const useIsQuotesListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps: QuotesQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      state: QuoteState.All,
      organization: organization?.id as string,
    }),
    [organization],
  )

  const queryKey = useMemo(() => getQuotesQueryKey(queryProps), [queryProps])

  const { data: quotesCountData, isFetched: quotesCountFetched } = useQuery(queryKey, () => fetchQuotes(queryProps), {
    enabled: !!organization,
    staleTime: defaultStaleTime,
  })

  return quotesCountFetched && quotesCountData?.data.length === 0
}
