import { Attachment, AttachmentFile, getCurrentLocale } from '@design-system'

import qs from 'qs'

import { SortDirection } from '../../enums/sortDirection'
import { Metable } from '../../types/metable'
import { deleteRequest, getRequest, postRequest, putRequest } from '../../utils'
import {
  DifferenceType,
  ReconciliationSuggestion,
  ReconciliationSuggestionsResponse,
} from '../bankReconciliation/types'
import { BillAttachment } from '../bills-depracated/types'
import { Contact } from '../contacts/types/contact'
import { uploadFile } from '../receipts'
import { BillState } from './enums/billState'
import { BillsSummaryData } from './routes/BillsList/types/billsSummaryData'
import { BillsSummaryQueryProps } from './routes/BillsList/types/billsSummaryQueryProps'
import { Bill } from './types/bill'
import { BillLine } from './types/billLine'
import { BillTimeline } from './types/billTimeline'

export enum BillsSortProperty {
  Amount = 'amount',
  Attachments = 'attachments',
  Balance = 'balance',
  ContactName = 'contact.name',
  CreatedTime = 'createdTime',
  DueDate = 'dueDate',
  EntryDate = 'entryDate',
  GrossAmount = 'grossAmount',
  LineDescription = 'lineDescription',
  SuppliersInvoiceNo = 'suppliersInvoiceNo',
  VoucherNo = 'voucherNo',
}

export interface FetchBillsOptions {
  amount?: number
  contactId?: string
  createdBillId?: string
  currencyId?: string
  /* Examples:
   * - entryDatePeriod=all
   * - entryDatePeriod=dates:2019-04-23...2019-06-14
   * - entryDatePeriod=from:2019-05-08
   * - entryDatePeriod=half:2019-2
   * - entryDatePeriod=month:2019-06
   * - entryDatePeriod=quarter:2019-2
   * - entryDatePeriod=through:2019-05-03
   * - entryDatePeriod=year:2019
   * - entryDatePeriod=fiscalyear:2019
   */
  entryDatePeriod?: string
  externalId?: string
  include?: string
  isBare?: boolean
  isPaid?: boolean
  maxDueDate?: string
  maxEntryDate?: string
  minDueDate?: string
  minEntryDate?: string
  organizationId: string
  q?: string
  page?: number
  pageSize?: number
  sortDirection?: SortDirection
  sortProperty?: BillsSortProperty
  state?: BillState | BillState[]
  suppliersInvoiceNo?: string
}

export interface FetchBillsResponseData extends Metable {
  attachments: Attachment[]
  bills: Bill[]
  contacts?: Contact[]
}

export const fetchBills = ({
  contactId,
  include = 'bill.contact,bill.attachments:embed,attachment.file:embed,bill.lines:embed,bill.balanceModifiers.balanceModifier.modifier:embed',
  organizationId,
  page,
  pageSize,
  q,
  state,
  ...rest
}: FetchBillsOptions): Promise<FetchBillsResponseData> => {
  const queryParams = qs.stringify({
    organizationId,
    ...(contactId ? { contactId } : {}),
    ...(include ? { include } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
    ...(state ? { state } : {}),
    ...rest,
  })
  return getRequest(`/v2/bills?${queryParams}`)
}

type FetchBillsSummaryOptions = BillsSummaryQueryProps

interface FetchBillsSummaryResponseData extends Metable {
  summary: BillsSummaryData
}

export const fetchBillsSummary = ({
  contactId,
  entryDatePeriod,
  isOverdue,
  isPaid,
  organizationId,
  q,
}: FetchBillsSummaryOptions): Promise<FetchBillsSummaryResponseData> => {
  const queryParams = qs.stringify({
    ...(contactId ? { contactId } : {}),
    ...(entryDatePeriod ? { entryDatePeriod } : {}),
    ...(isOverdue ? { isOverdue } : {}),
    ...(isPaid ? { isPaid } : {}),
    ...(q ? { q } : {}),
    organizationId,
  })

  return getRequest(`/v2/bills/summary?${queryParams}`)
}

export interface CreateBillPayload extends Partial<Omit<Bill, 'attachments'>> {
  organizationId: string
  attachments?: Partial<BillAttachment>[]
}

export interface CreateBillResponseData {
  bills: Bill[]
  billLines?: BillLine[]
  attachments: BillAttachment[]
  files?: AttachmentFile[]
}

export const createBill = (payload: CreateBillPayload): Promise<CreateBillResponseData> => {
  const locale = getCurrentLocale()
  return postRequest('/v2/bills', { bill: payload }, { 'accept-language': locale })
}

export interface UpdateBillPayload {
  billId: string
  payload: CreateBillPayload
}

export type UpdateBillResponseData = CreateBillResponseData

export const updateBill = ({ billId, payload }: UpdateBillPayload): Promise<UpdateBillResponseData> => {
  const locale = getCurrentLocale()
  return putRequest(`/v2/bills/${billId}`, { bill: payload }, { 'accept-language': locale })
}

export interface GetBillPayload {
  billId: string
  include?: string
}

export interface GetBillResponseData {
  bill: Bill
  billLines: Partial<BillLine>[]
  contacts?: Contact[]
}

export const getBill = ({
  billId,
  include = 'bill.contact,bill.attachments:embed,attachment.file:embed,bill.lines,bill.balanceModifiers.balanceModifier.modifier:embed',
}: GetBillPayload): Promise<GetBillResponseData> => {
  const locale = getCurrentLocale()
  const queryParams = qs.stringify({
    ...(include ? { include } : {}),
  })

  return getRequest(`/v2/bills/${billId}?${queryParams}`, { 'accept-language': locale })
}

export const createFileAndBill = async (file: File, billData: CreateBillPayload): Promise<CreateBillResponseData> => {
  const apiOptions = {
    'X-Should-Scan': true,
    'X-OrganizationId': billData.organizationId,
  }

  const { files } = await uploadFile(file, apiOptions)
  const billDataExtended: CreateBillPayload = {
    ...billData,
    attachments: [{ organizationId: billData.organizationId, fileId: files[0].id }],
  }

  return createBill(billDataExtended)
}

interface GetNextVoucherNumberResponseData {
  nextVoucherNo: number
}

export const getNextVoucherNumber = (organizationId: string): Promise<GetNextVoucherNumberResponseData> => {
  return getRequest(`/v2/organizations/${organizationId}/nextVoucherNo`)
}

export interface GetBillsBySuppliersInvoiceNoPayload {
  organizationId: string
  contactId: string
  suppliersInvoiceNo: string
}

export const getBillsBySuppliersInvoiceNo = ({
  organizationId,
  contactId,
  suppliersInvoiceNo,
}: GetBillsBySuppliersInvoiceNoPayload): Promise<FetchBillsResponseData> => {
  return getRequest(
    `/v2/bills?organizationId=${organizationId}&contactId=${contactId}&suppliersInvoiceNo=${suppliersInvoiceNo}`,
  )
}

export const deleteBill = (billId: string): Promise<void> => {
  return deleteRequest(`/v2/bills/${billId}`)
}

export interface GetBillTimelinePayload {
  billId: string
  organizationId: string
  pageSize?: number
}

export interface GetBillTimelineResponseData {
  data: BillTimeline[]
}

export const getBillTimeline = ({
  billId,
  organizationId,
  pageSize,
}: GetBillTimelinePayload): Promise<GetBillTimelineResponseData> => {
  const queryParams = qs.stringify({
    pageSize: pageSize || 1000,
  })

  return getRequest(`/organizations/${organizationId}/bills/${billId}/timeline?${queryParams}`)
}

export interface CreateBillTimelinePayload {
  billId: string
  comment: string
  organizationId: string
}

export const createBillTimeline = ({ billId, comment, organizationId }: CreateBillTimelinePayload): Promise<void> => {
  return postRequest(`/v2/organizations/${organizationId}/bills/${billId}/timeline`, {
    data: {
      comment,
    },
  })
}

interface GetBillReconciliationMatchesPayload {
  organizationId: string
  billId: string
}

export const getBillReconciliationMatches = ({
  organizationId,
  billId,
}: GetBillReconciliationMatchesPayload): Promise<ReconciliationSuggestionsResponse> => {
  return getRequest(`/api/matching-service/${organizationId}/matches/reconcilable/bill/${billId}`)
}

export interface DeleteBillReconciliationMatchPayload {
  organizationId: string
  suggestion: ReconciliationSuggestion
}

export const deleteBillReconciliationMatch = async ({
  organizationId,
  suggestion,
}: DeleteBillReconciliationMatchPayload): Promise<void> => {
  return postRequest(`/api/matching-service/${organizationId}/matches/reject`, suggestion)
}

export interface AddBillReconciliationMatchPayload {
  matchId: string
  billId: string
  differenceType?: DifferenceType
  defaultBankFeeAccountId?: string | null
}

export const createBillReconciliationMatch = async ({
  matchId,
  billId,
  differenceType,
  defaultBankFeeAccountId,
}: AddBillReconciliationMatchPayload): Promise<void> => {
  await postRequest('/v2/bankLineSubjectAssociations', {
    bankLineSubjectAssociation: {
      matchId,
      subjectReference: `bill:${billId}`,
    },
  })

  return putRequest(`/v2/bankLineMatches/${matchId}`, {
    bankLineMatch: {
      isApproved: true,
      differenceType,
      ...(differenceType === 'bankFee' && { feeAccountId: defaultBankFeeAccountId }),
    },
  })
}

export interface CreateBillsAndBankLinesReconcilationOptions {
  bankLinesIds: string[]
  organizationId: string
  subjects: string[]
}

export const createBillsAndBankLinesReconcilation = ({
  bankLinesIds,
  organizationId,
  subjects,
}: CreateBillsAndBankLinesReconcilationOptions): Promise<void> => {
  return postRequest(`/v2/organizations/${organizationId}/reconcilable`, {
    reconcilable: {
      subjects,
      bankLines: bankLinesIds,
    },
  })
}
