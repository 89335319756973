import { Contact } from '../../../../modules/contacts/types/contact'
import { convertBookkeepingTagNavItemValueToDbValue } from '../../../selects/BookkeepingHeroSelect/utils/convertBookkeepingTagNavItemValueToDbValue'
import { COMPANY_FROM_SEARCH_COUNTRY_ID } from '../constants/companyFromSearchCountruId'
import { ContactForm } from './formData'
import { isPersonContactType } from './isPersonContactType'

export const getCreateSupplierPayload = (contactForm: ContactForm, organizationId: string): Partial<Contact> => {
  const {
    defaultBookkeepingTag,
    companyFromSearch,
    companyName,
    contactType,
    country,
    defaultExpenseAccount,
    defaultProductDescription,
    email,
    paymentTermsMode,
    personFirstName,
    personLastName,
    taxId,
  } = contactForm

  const basePayload: Partial<Contact> = {
    type: contactType,
    isSupplier: true,
    organizationId,
    defaultTaxRateId: paymentTermsMode?.id,
    defaultExpenseAccountId: defaultExpenseAccount?.id,
    defaultExpenseProductDescription: defaultProductDescription,
    defaultBookkeepingTag:
      defaultBookkeepingTag?.tag && defaultBookkeepingTag?.response
        ? JSON.stringify(convertBookkeepingTagNavItemValueToDbValue(defaultBookkeepingTag))
        : null,
  }

  if (isPersonContactType(contactType)) {
    const contactName = `${personFirstName || ''} ${personLastName || ''}`.trim()

    return {
      ...basePayload,
      countryId: country?.id,
      name: contactName,
      contactPersons: [
        {
          firstName: personFirstName,
          lastName: personLastName,
          email,
        },
      ],
    }
  }

  const registrationNo = companyName ? taxId : companyFromSearch?.id

  return {
    ...basePayload,
    registrationNo,
    countryId: companyName ? country?.id : COMPANY_FROM_SEARCH_COUNTRY_ID,
    name: companyName || companyFromSearch?.name,
    cityText: companyFromSearch?.city,
    street: companyFromSearch?.street,
    zipcodeText: companyFromSearch?.zipcode,
    stateText: companyFromSearch?.state,
    phone: companyFromSearch?.phone || undefined,
    vatNo: companyFromSearch?.vatNo || registrationNo,
  }
}
