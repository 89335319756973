import { Button, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { ModalId } from '../../../../../../enums/modalId'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { useSegment } from '../../../../../../hooks'
import { useUserOrganization } from '../../../../../app/organization'
import { useCreateBillReconciliationSuggestion } from '../../../../hooks/useAddBillReconciliationSuggestion'
import { useReconcileBill } from '../../../../hooks/useReconcileBill'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'

export const MatchModalFooter = (): ReactElement => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { initialBill, selectedDifferenceType } = useBillReconciliation()
  const { hasMatchDifference, matchSuggestion } = useMatch()
  const { isProcessing } = useCreateBillReconciliationSuggestion()
  const { close: closeMatchModal } = useModal(ModalId.BillReconciliationModal)

  const { reconcileBill, isProcessing: isProcessingReconcile } = useReconcileBill({
    matchId: matchSuggestion?.match_id,
    billId: initialBill?.id,
    selectedDifferenceType,
    onSuccess: async () => {
      closeMatchModal()
      track('xxx - expenses - Pay and reconcile modal - register payment clicked')
      if (initialBill?.id) {
        await queryClient.cancelQueries({ queryKey: [QueryKeys.BillRecociliationMatches, initialBill.id] })
        queryClient.setQueryData([QueryKeys.BillRecociliationMatches, initialBill.id], undefined)
      }
    },
  })

  const isEnterPaymentButtonDisabled = useMemo(
    () => isProcessing || (hasMatchDifference && !selectedDifferenceType),
    [hasMatchDifference, isProcessing, selectedDifferenceType],
  )

  const handleEnterPayment = useCallback(async () => {
    reconcileBill()
  }, [initialBill?.id, matchSuggestion?.match_id, organization?.defaultBankFeeAccountId, selectedDifferenceType])

  return (
    <Button onClick={handleEnterPayment} loading={isProcessingReconcile} disabled={isEnterPaymentButtonDisabled}>
      {t('bill.payment_reconcile.button.enter_payment_and_reconcile')}
    </Button>
  )
}
