import { differenceInDays } from 'date-fns'

import { Scope } from '../../../../../enums/scope'
import { useOrganizationSubscription } from '../../../../../hooks'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { useUserOrganization } from '../../../../app/organization'

const DAYS_SINCE_ORGANIZATION_CREATED_LIMIT = 30

interface UseShouldDisplayDeleteTrialDataSectionResponse {
  shouldDisplaySection: boolean
  isLoading: boolean
}

export const useShouldDisplayDeleteTrialDataSection = (): UseShouldDisplayDeleteTrialDataSectionResponse => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { isTrial, isLoading: isTrialLoading } = useOrganizationSubscription()

  const isLoading = isOrganizationLoading || isTrialLoading

  if (!organization?.createdTime) {
    return { shouldDisplaySection: false, isLoading }
  }

  const isAuthorizedToResetBookkeeping = isAuthorized(Scope.ResetBookkeeping)
  const createdTime = new Date(organization.createdTime)
  const daysSinceOrganizationCreated = differenceInDays(new Date(), createdTime)
  const isCreationTimeLimitReached = daysSinceOrganizationCreated > DAYS_SINCE_ORGANIZATION_CREATED_LIMIT

  const shouldDisplaySection = isTrial && isAuthorizedToResetBookkeeping && !isCreationTimeLimitReached

  return { shouldDisplaySection, isLoading }
}
