import { amountToDisplayValue, Button, Color, IconButton, Text } from '@design-system'

import { addMonths, isAfter, isBefore, subDays } from 'date-fns/esm'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocalStorage } from 'react-use'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { QueryKeys } from '../../../../enums/queryKeys'
import { Scope } from '../../../../enums/scope'
import { useSegment } from '../../../../hooks'
import { getFiscalYearForYear } from '../../../../utils/getFiscalYearForYear'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { fetchAnnualReports } from '../../../annualReport'
import { useFetchCompanies } from '../../../app/companies/hooks/useFetchCompanies'
import { CompanyType } from '../../../app/companies/types'
import { useUserOrganization } from '../../../app/organization'
import { useIsTrustedPartner } from '../../../app/umbrellas'
import * as Styled from './styles'

const EMV_PRICE = 2495
const OTHER_PRICE = 2795
const SINGLE_PERSON_COMPANY_TYPES: CompanyType[] = [CompanyType.ENK, CompanyType.PMV]
const KEY_PREFIX = 'annual-report-banner-hidden'

export const AnnualReports = (): ReactElement | null => {
  const { t } = useTranslation()
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { isTrustedPartner, isLoading } = useIsTrustedPartner() // 2
  const { navigate } = useEmberRouter()
  const { track } = useSegment()
  const isViewedEventSent = useRef(false)
  const annualReportState = useRef('inactive')

  const { data: annualReportsData, isLoading: isLoadingReports } = useQuery(
    [QueryKeys.AnnualReports, organization?.id],
    () => fetchAnnualReports(organization?.id || ''),
    {
      enabled: !!organization?.id,
    },
  )

  const { companies, isLoading: isLoadingCVR } = useFetchCompanies(organization?.registrationNo)

  const storageKey = useMemo(() => {
    if (!annualReportsData || !organization) {
      return KEY_PREFIX
    }

    const years = annualReportsData?.years || []
    const lastARYear = years[years.length - 1]
    const organizationId = organization.id

    if (!lastARYear) {
      return KEY_PREFIX
    }

    return `${KEY_PREFIX}-${lastARYear.year}-${organizationId}`
  }, [annualReportsData, organization])

  const [isClosed, setIsClosed] = useLocalStorage(storageKey)

  const fiscalYear = useMemo(() => {
    const years = annualReportsData?.years || []
    const lastARYear = years[years.length - 1]

    if (!organization || !lastARYear) {
      return
    }

    const lastYear = lastARYear.year

    const fiscalYear = getFiscalYearForYear(
      lastYear,
      new Date(organization.firstFiscalYearStart),
      new Date(organization.firstFiscalYearEnd),
    )

    return fiscalYear
  }, [annualReportsData, organization])

  // Banner should be shown 15 days before fiscal year ends and 6 months afterwards (AR deadline)
  const isInDateRange = useMemo(() => {
    if (!fiscalYear) {
      return false
    }

    const fiscalYearEnd = fiscalYear.endDate
    const now = new Date()
    const fiscalYearEndMinus15Days = subDays(fiscalYearEnd, 15)
    const fiscalYearEndPlus6Months = addMonths(fiscalYearEnd, 6)

    return isAfter(now, fiscalYearEndMinus15Days) && isBefore(now, fiscalYearEndPlus6Months)
  }, [fiscalYear])

  const isFetchingData = isLoading || isLoadingCVR || isLoadingReports || isLoadingOrganization

  const isAnnualReportReadAuthorized = isAuthorized(Scope.AnnualReportRead, false, !isTrustedPartner)

  const isBannerHidden = useMemo(() => {
    if (
      isFetchingData ||
      isClosed ||
      !isAnnualReportReadAuthorized ||
      organization?.isTrial ||
      !isInDateRange ||
      isAuthorized(Scope.AnnualReportToken)
    ) {
      return true
    }

    const years = annualReportsData?.years || []
    const state = years[years.length - 1]?.state

    if (!state || state === 'reported') {
      return true
    }

    annualReportState.current = state
    return false
  }, [
    isFetchingData,
    isClosed,
    isAnnualReportReadAuthorized,
    organization?.isTrial,
    isInDateRange,
    annualReportsData?.years,
  ])

  const companyType = companies[0]?.companyType || ''

  const price = useMemo(() => {
    return SINGLE_PERSON_COMPANY_TYPES.includes(companyType as CompanyType) ? EMV_PRICE : OTHER_PRICE
  }, [companyType])

  const handleButtonClick = useCallback(() => {
    track('Annual Report Upsell Clicked (FE)')
    navigate(EmberRoute.AnnualReports)
  }, [track, navigate])

  const handleOnClose = useCallback(() => {
    setIsClosed(true)
  }, [setIsClosed])

  const hasStartedAnnualReport = useCallback(() => {
    return annualReportState.current !== 'inactive'
  }, [annualReportState])

  useEffect(() => {
    if (!isBannerHidden && !isViewedEventSent.current) {
      track('Annual Report Upsell Viewed (FE)')
      isViewedEventSent.current = true
    }
  }, [track, isBannerHidden])

  if (isBannerHidden) {
    return null
  }

  return (
    <Styled.AnnualReportsBanner>
      <Styled.CloseIconWrapper>
        <IconButton icon="xSign" size="m" onClick={handleOnClose} />
      </Styled.CloseIconWrapper>
      <Text variant="displayXS" color={Color.DeepSeaGreen}>
        {t('dashboard.banner.annual_reports.title')}
      </Text>
      <Styled.ContentWrapper>
        {hasStartedAnnualReport() ? (
          <Styled.ContinueWrapper>
            <Text color={Color.DeepSeaGreen} weight="medium" alignment="center">
              {t('dashboard.banner.annual_reports.get_reports.continue')}
            </Text>
          </Styled.ContinueWrapper>
        ) : (
          <Styled.TextWrapper>
            <Text variant="large" color={Color.DeepSeaGreen} weight="medium">
              {t('dashboard.banner.annual_reports.get_reports.start')}
            </Text>
            <Text variant="large" color={Color.DeepSeaGreen}>
              {t('dashboard.banner.annual_reports.from_only', { price: amountToDisplayValue(price, true) })}
            </Text>
          </Styled.TextWrapper>
        )}
        <Styled.ButtonWrapper>
          <Button variant="primary" onClick={handleButtonClick}>
            {t(
              hasStartedAnnualReport()
                ? 'dashboard.banner.annual_reports.button.continue'
                : 'dashboard.banner.annual_reports.button.start',
            )}
          </Button>
        </Styled.ButtonWrapper>
      </Styled.ContentWrapper>
    </Styled.AnnualReportsBanner>
  )
}
