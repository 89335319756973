import { BookkeeperSelect } from '@components'

import isEmpty from 'lodash/isEmpty'
import React, { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as InvitationModalSelectBookkeeperIllustration } from '../../../../../../../../../../assets/images/organization-users-settings/invitation-modal-select-bookkeeper.svg'
import { ReactComponent as InvitationModalSendInvitationIllustration } from '../../../../../../../../../../assets/images/organization-users-settings/invitation-modal-send-invitation.svg'
import { useFormContext } from '../../../../../../../../../../hooks'
import { getImageClassName } from '../../../../../../../../../../utils/getClassName'
import { Umbrella } from '../../../../../../../../../app/umbrellas/types/umbrella'
import { BOOKEEPER_SELECT_DROPDOWN_ID } from '../../constants/bookeeperSelectDropdownId'
import { InviteBookkeeperFormSchema } from '../../utils/formData'
import { SelectedBookkeeperSection } from '../SelectedBookkeeperSection'
import * as Styled from './styles'

export const InvitationForm = (): ReactElement => {
  const { t } = useTranslation()
  const [selectedBookkeeper, setSelectedBookkeeper] = useState<Partial<Umbrella>>()
  const { FormItem } = useFormContext<InviteBookkeeperFormSchema>()

  return (
    <>
      <FormItem
        label={t('settings.organization.users.invite_bookkeeper_modal.contact_name')}
        name="umbrellaId"
        render={({ field }) => {
          const { onChange, ...rest } = field

          return (
            <BookkeeperSelect
              {...rest}
              autoFocus
              dropdownId={BOOKEEPER_SELECT_DROPDOWN_ID}
              onSelect={(id, value) => {
                setSelectedBookkeeper(isEmpty(value) ? undefined : value)
                onChange(id)
              }}
              selectedId={selectedBookkeeper?.id}
            />
          )
        }}
      />
      {!!selectedBookkeeper && <SelectedBookkeeperSection bookkeeper={selectedBookkeeper} />}
      <Styled.IllustrationWrapper className={getImageClassName()}>
        {selectedBookkeeper ? (
          <InvitationModalSendInvitationIllustration width={137} />
        ) : (
          <InvitationModalSelectBookkeeperIllustration width={163} />
        )}
      </Styled.IllustrationWrapper>
    </>
  )
}
