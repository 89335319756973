import { Button } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { AssetsModuleHeader } from './elements'

export const AssetsList = () => {
  const { t } = useTranslation()

  return (
    <AssetsModuleHeader title={t('fixed_assets')}>
      <Link to="/create">
        <Button>{t('fixed_assets.create_asset')}</Button>
      </Link>
    </AssetsModuleHeader>
  )
}
