import { getRequest, request } from '../../utils'
import { Umbrella } from '../app/umbrellas/types/umbrella'
import { User } from '../app/user'
import { UmbrellaInvitation } from './types/UmbrellaInvitation'

export interface UmbrellaInvitationData {
  email: string
  existingUser: boolean
  umbrella: Pick<Umbrella, 'id' | 'name'>
  grantAdmin: boolean
  invitedByUser: Pick<User, 'name'>
  invitation: UmbrellaInvitation
}

interface FetchUmbrellaInvitationResponseData {
  data: UmbrellaInvitationData
}

export const fetchUmbrellaInvitation = (token: string): Promise<FetchUmbrellaInvitationResponseData> => {
  return getRequest(`/umbrellaInvitations/${token}`, {}, false)
}

export const acceptUmbrellaInvitation = async (token: string): Promise<void> => {
  await request(
    `/umbrellaInvitations/${token}/acceptance`,
    {
      method: 'POST',
      headers: {
        contentType: 'application/vnd.billy.v2+json',
      },
      body: null,
    },
    {
      withAuth: true,
      returnRawResponse: true,
    },
  )
}
