import { TermAndCondition } from '@components-deprecated'
import { Button } from '@design-system'

import React, { ReactElement, ReactNode, useState } from 'react'

import { TermAndCondition as TermAndConditionType } from '../../../app/payment/types'
import * as Styled from './styles'

export interface CheckoutSummaryProps {
  title: string
  termsAndConditions: TermAndConditionType[]
  onCheckout: () => void
  checkoutButtonText: string
  checkoutUnderButtonText?: string
  children: ReactNode
  isLoading?: boolean
}

const isTermsAndConditionsAccepted = (termsAndConditions: TermAndConditionType[]): boolean => {
  return termsAndConditions.every((termAndCondition) => termAndCondition.accepted)
}

export const CheckoutSummary = ({
  title,
  termsAndConditions: termsAndConditionsFromInitial,
  onCheckout,
  checkoutButtonText,
  checkoutUnderButtonText,
  children,
  isLoading,
}: CheckoutSummaryProps): ReactElement => {
  const [termsAndConditions, setTermsAndConditions] = useState<TermAndConditionType[]>(termsAndConditionsFromInitial)
  const [termsAndConditionsAccepted, setTermsAndConditionsAccepted] = useState(
    isTermsAndConditionsAccepted(termsAndConditionsFromInitial),
  )

  const updateTermAndCondition = (text: string) => {
    const updatedTermsAndConditions = [...termsAndConditions]
    const objIndex = updatedTermsAndConditions.findIndex((obj) => obj.text === text)
    updatedTermsAndConditions[objIndex].accepted = !termsAndConditions[objIndex].accepted
    setTermsAndConditions(updatedTermsAndConditions)
    setTermsAndConditionsAccepted(isTermsAndConditionsAccepted(updatedTermsAndConditions))
  }

  return (
    <Styled.CheckoutSummaryWrapper>
      <Styled.Title>{title}</Styled.Title>

      {children}

      <Styled.TermsAndConditionsWrapper>
        {termsAndConditions.map((item, i) => (
          <TermAndCondition
            key={`${item.text}-${i}`}
            text={item.text}
            accepted={item.accepted}
            onTermsAccept={updateTermAndCondition}
          />
        ))}
      </Styled.TermsAndConditionsWrapper>

      <Button disabled={!termsAndConditionsAccepted} onClick={onCheckout} loading={isLoading}>
        {checkoutButtonText}
      </Button>
      <Styled.BelowButtonText>{checkoutUnderButtonText}</Styled.BelowButtonText>
    </Styled.CheckoutSummaryWrapper>
  )
}
