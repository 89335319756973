import { ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { SortDirection } from '../../../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../../../hooks/usePaginationPrefetching'
import { Paging } from '../../../../../types/metable'
import { useUserOrganization } from '../../../../app/organization'
import { TransactionOriginatorType } from '../../../enums/transactionOriginatorType'
import { TransactionOriginatorTypeGroup } from '../../../enums/transactionOriginatorTypeGroup'
import { fetchTransactions, FetchTransactionsResponseData, TransactionSortProperty } from '../../../query-api'
import { Transaction } from '../../../types/transaction'
import { TransactionsQueryProps } from '../../../types/transactionsQueryProps'
import { getTransactionsQueryKey } from '../../../utils/getTransactionsQueryKey'

interface UseFetchTransactionsProps {
  isDisabled?: boolean
  originatorType?: TransactionOriginatorType[]
  originatorTypeGroup?: TransactionOriginatorTypeGroup[]
  page?: number
  pageSize?: ItemsPerPage
  period?: string
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: TransactionSortProperty
  withVoided?: boolean
  withHasVoucher?: boolean
}

interface UseFetchTransactionsResponse {
  transactions: Transaction[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchTransactions = ({
  isDisabled,
  originatorType,
  originatorTypeGroup,
  page,
  pageSize,
  period,
  searchQuery,
  sortDirection,
  sortProperty,
  withVoided,
  withHasVoucher,
}: UseFetchTransactionsProps): UseFetchTransactionsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps: TransactionsQueryProps = useMemo(() => {
    // If all filters selected it means the same as no filters
    // We don't need to refetch it again in this case
    const isFiltersOnOriginatorTypeGroupEnabled =
      originatorTypeGroup?.length &&
      originatorTypeGroup?.length !== Object.values(TransactionOriginatorTypeGroup).length

    return {
      ...(originatorType ? { originatorType } : {}),
      ...(isFiltersOnOriginatorTypeGroupEnabled ? { originatorTypeGroup } : {}),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(period ? { entryDatePeriod: period } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      ...(withHasVoucher ? { withHasVoucher: 1 } : {}),
      organizationId: organization?.id as string,
      withVoided,
    }
  }, [
    organization?.id,
    originatorType,
    originatorTypeGroup,
    page,
    pageSize,
    period,
    searchQuery,
    sortDirection,
    sortProperty,
    withVoided,
    withHasVoucher,
  ])

  const queryKey = useMemo(() => getTransactionsQueryKey(queryProps), [queryProps])

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchTransactions(queryProps), {
    enabled: !!organization?.id && !isDisabled,
    staleTime: defaultStaleTime,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<TransactionsQueryProps, FetchTransactionsResponseData>({
    getQueryFunction: useMemo(() => fetchTransactions, []),
    getQueryKey: useMemo(() => getTransactionsQueryKey, []),
    isEnabled: !!organization?.id && !isLoading && !isIdle && !isDisabled,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    transactions: data?.transactions || [],
    isLoading: isIdle || isLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
