import { Link, SectionMessage, SkeletonBox, Text } from '@design-system'

import React, { MouseEvent, ReactElement, useCallback } from 'react'
import { Trans } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { ReceiptFilterType } from '../../../../../receipts/elements/Receipt'
import { useReceiptsCounter } from '../../../../../receipts/hooks/useReceiptsCounter'
import * as Styled from './styles'

export const ReceiptsToFill = (): ReactElement | null => {
  const { navigate } = useEmberRouter()
  const { countMap: receiptsCountMap, isLoading: isReceiptsLoading } = useReceiptsCounter()
  const { [ReceiptFilterType.All]: allReceiptsCount } = receiptsCountMap

  const handleFillClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      event.preventDefault()

      navigate(EmberRoute.Uploads)
    },
    [navigate],
  )

  if (!allReceiptsCount && !isReceiptsLoading) {
    return null
  }

  return (
    <Styled.ReceiptsToFillWrapper>
      {isReceiptsLoading ? (
        <SkeletonBox fullWidth height={56} />
      ) : (
        <SectionMessage variant="info">
          <Trans i18nKey="bills.receipts_to_fill" count={allReceiptsCount}>
            You have {{ allReceiptsCount }} receipts ready to be filled!
            <Text as="span" inherit weight="bold">
              <Link href="#" onClick={handleFillClick} underlined>
                Fill now
              </Link>
            </Text>
          </Trans>
        </SectionMessage>
      )}
    </Styled.ReceiptsToFillWrapper>
  )
}
