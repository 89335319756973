import { createContext, ReactNode, useContext } from 'react'

import {
  NotificationSettings,
  OrganizationNotificationSettings,
  UpdateUserNotificationSettingsPayload,
} from '../../../../app/user'
import { useUpdateUserNotificationSettings } from '../../../../app/user/hooks/useUpdateUserNotificationSettings'

interface ContextState {
  name?: string
  organizationId?: string
  iconUrl?: string | null
  isMuted?: boolean
  isSettingsOpen?: boolean
  notificationSettings: NotificationSettings
  updateSettingsChange: (payload: UpdateUserNotificationSettingsPayload) => void
}

const OrganizationNotificationSettingsContext = createContext<ContextState | undefined>(undefined)

interface OrganizationNotificationSettingsContextProps {
  children?: ReactNode
  organizationNotificationSettings: OrganizationNotificationSettings
}

export const OrganizationNotificationSettingsContextProvider = ({
  children,
  organizationNotificationSettings,
}: OrganizationNotificationSettingsContextProps) => {
  const { update: updateSettings } = useUpdateUserNotificationSettings()

  const updateSettingsChange = (payload: UpdateUserNotificationSettingsPayload) => {
    updateSettings(payload)
  }

  return (
    <OrganizationNotificationSettingsContext.Provider
      value={{
        name: organizationNotificationSettings.name,
        organizationId: organizationNotificationSettings.organizationId,
        iconUrl: organizationNotificationSettings.iconUrl,
        isMuted: organizationNotificationSettings.settings.muted === 'yes',
        isSettingsOpen: organizationNotificationSettings.settings.organizationSpecific === 'yes',
        notificationSettings: organizationNotificationSettings.settings,
        updateSettingsChange,
      }}
    >
      {children}
    </OrganizationNotificationSettingsContext.Provider>
  )
}

export const useOrganizationNotificationSettings = () => {
  const context = useContext(OrganizationNotificationSettingsContext)

  if (!context) {
    throw new Error('OrganizationNotificationSettingsContextProvider is missing in the module!')
  }

  return context
}
