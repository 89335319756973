import { Contact } from '../../../../contacts/types/contact'
import { GetBillResponseData } from '../../../query-api'
import { Bill } from '../../../types/bill'

export const getBillQueryData = (
  billId: string,
  bills: Bill[],
  contacts: Contact[],
): GetBillResponseData | undefined => {
  const bill = bills.find(({ id }) => id === billId)

  if (bill) {
    const billContacts = contacts.filter(({ id }) => id === bill?.contactId)

    return {
      bill,
      billLines: bill?.lines || [],
      ...(billContacts.length ? { contacts: billContacts } : {}),
    }
  }

  return undefined
}
