import { amountToDisplayValue } from '@design-system'

import { InvoiceDocumentType } from '../../../../../enums/invoiceDocumentType'
import { Invoice } from '../../../../../types/invoice'
import { InvoicesTableRow } from '../types/invoicesTableRow'

export const getInvoicesTableData = (invoices: Invoice[]): InvoicesTableRow[] => {
  return invoices.map(
    ({
      creditNoteNumber,
      customer,
      description,
      documentType,
      dueDate,
      id,
      invoiceNumber,
      issueDate,
      status,
      summary,
    }) => {
      const isCreditNote = documentType === InvoiceDocumentType.CreditNote

      return {
        id: id.toString(),
        'contact.name': customer.displayName || '',
        issueDate,
        description: {
          isCreditNote,
          value: description,
        },
        documentType,
        dueDate,
        netTotal: `${amountToDisplayValue(summary.foreignNetTotal)} ${summary.foreignCurrency}`,
        invoiceNo: (isCreditNote ? creditNoteNumber : invoiceNumber) || '—',
        status: status?.status,
      }
    },
  )
}
