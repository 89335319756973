import { NavItem } from '@design-system'

import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { useLabsMenuFlags } from '../../../../../hooks/useLabsMenuFlags'
import { useUserOrganizationSettings } from '../../../../app/organization'
import { useUmbrella } from '../../../../app/umbrellas'
import { useCurrentUser } from '../../../../app/user'
import { filterMenuItems } from '../utils/filterMenuItems'
import { getMenuItems } from '../utils/getMenuItems'
import { organizationMenuItems } from '../utils/organizationMenuItems'
import { simplifyMenuItems } from '../utils/simplifyMenuItems'
import { umbrellaMenuItems } from '../utils/umbrellaMenuItems'
import { useShouldSkipMenuItems } from './useShouldSkipMenuItems'

interface FilteredMenuItems {
  items: NavItem<EmberRoute>[]
  isLoading: boolean
}

export const useMenuItems = (): FilteredMenuItems => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { organizationSettings } = useUserOrganizationSettings()
  const { user } = useCurrentUser()
  const labsFlags = useLabsMenuFlags()
  const shouldSkipRenderItem = useShouldSkipMenuItems()

  const isLoading = !user || (!organizationSettings && !umbrella)
  const isInboxEnabled = umbrella ? umbrella?.isInboxEnabled : organizationSettings?.isInboxEnabled === '1'
  const menuItems = umbrella ? umbrellaMenuItems : organizationMenuItems

  const menuItemsFiltered = useMemo(() => {
    if (isLoading) {
      return []
    }
    return filterMenuItems(menuItems, { isInboxEnabled, labsFlags, shouldSkipRenderItem })
  }, [isLoading, menuItems, isInboxEnabled, labsFlags, shouldSkipRenderItem])

  const menuItemsSimplified = useMemo(() => simplifyMenuItems(menuItemsFiltered), [menuItemsFiltered])
  const items = useMemo(() => getMenuItems(menuItemsSimplified, t), [menuItemsSimplified, t])

  return { items, isLoading }
}
