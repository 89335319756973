import { CustomEvent } from '../../../enums/customEvent'
import { QueryKeys } from '../../../enums/queryKeys'

export const customEventToQueryKeys: Partial<Record<CustomEvent, QueryKeys[]>> = {
  [CustomEvent.BillCreated]: [QueryKeys.Bills, QueryKeys.BillsSummary, QueryKeys.SubscriptionMetrics],
  [CustomEvent.BillUpdated]: [
    QueryKeys.AccountStatements,
    QueryKeys.Creditors,
    QueryKeys.Transactions,
    QueryKeys.SubscriptionMetrics,
  ],
  [CustomEvent.ContactUpdated]: [QueryKeys.ContactsAndContactPersons],
  [CustomEvent.InvoiceCreated]: [QueryKeys.Invoices, QueryKeys.InvoicesSummary, QueryKeys.SubscriptionMetrics],
  [CustomEvent.InvoiceUpdated]: [
    QueryKeys.AccountStatements,
    QueryKeys.Debtors,
    QueryKeys.Transactions,
    QueryKeys.SubscriptionMetrics,
  ],
  [CustomEvent.PaymentSaved]: [
    QueryKeys.AccountStatements,
    QueryKeys.Creditors,
    QueryKeys.Debtors,
    QueryKeys.Transactions,
  ],
  [CustomEvent.TransactionCreated]: [
    QueryKeys.AccountStatements,
    QueryKeys.Creditors,
    QueryKeys.Debtors,
    QueryKeys.SubscriptionMetrics,
    QueryKeys.Transactions,
  ],
  [CustomEvent.TransactionUpdated]: [
    QueryKeys.AccountStatements,
    QueryKeys.Creditors,
    QueryKeys.DaybookConsequences,
    QueryKeys.Debtors,
    QueryKeys.SubscriptionMetrics,
    QueryKeys.Transactions,
  ],
  [CustomEvent.UploadsUpdated]: [
    QueryKeys.AccountStatements,
    QueryKeys.AttachmentsCount,
    QueryKeys.Creditors,
    QueryKeys.Debtors,
    QueryKeys.ReceiptsList,
    QueryKeys.Transactions,
  ],
  [CustomEvent.OrganizationSettingsUpdated]: [QueryKeys.UserOrganizationSettings],
  [CustomEvent.TaxRulesetUpdated]: [QueryKeys.VatRatesRulesets],
  [CustomEvent.TaxRuleUpdated]: [QueryKeys.TaxRates],
  [CustomEvent.OrganizationOwnersUpdated]: [QueryKeys.OrganizationOwners],
  [CustomEvent.PaymentMethodsUpdated]: [QueryKeys.PaymentMethods],
}
