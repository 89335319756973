import { Organization } from '../../../../../../app/organization/types/organization'
import { PaymentTermsMode } from '../enums/paymentTermsMode'

export const getPaymentTermsModeFormValue = (organization: Organization): string => {
  if (organization?.paymentTermsMode === PaymentTermsMode.Net && organization?.paymentTermsDays === 0) {
    return PaymentTermsMode.OnReceipt
  }

  return organization.paymentTermsMode
}
