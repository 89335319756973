import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { isFECReportsEnabled } from '../../../../../utils/isFECReportsEnabled'
import {
  useUpdateOrganizationSettings,
  useUserOrganization,
  useUserOrganizationSettings,
} from '../../../../app/organization'
import { shouldShowReminder } from '../utils/shouldShowReminder'

const CURRENT_DATE = new Date()
const CURRENT_YEAR = CURRENT_DATE.getFullYear()

const REMINDER_DATES: Date[] = [
  new Date(CURRENT_YEAR, 0, 1), // 1st January
  new Date(CURRENT_YEAR, 6, 1), // 1st July
]

export const usePeriodicLockReminder = () => {
  const queryClient = useQueryClient()
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { isLoading: isOrganizationSettingsLoading, organizationSettings } = useUserOrganizationSettings()
  const { update: updateSettings } = useUpdateOrganizationSettings({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
    },
  })

  const markAsViewed = useCallback(() => {
    if (organization?.id) {
      updateSettings({
        key: 'periodicLockReminderLastViewedDate',
        organizationId: organization.id,
        value: new Date().toISOString(),
      })
    }
  }, [organization?.id, updateSettings])

  const shouldShowPeriodicReminder = useMemo(() => {
    if (!organization?.id) {
      return false
    }

    if (isOrganizationSettingsLoading || isOrganizationLoading) {
      return false
    }

    if (!isFECReportsEnabled(organization?.countryId)) {
      return false
    }

    if (!organizationSettings?.periodicLockReminderLastViewedDate) {
      markAsViewed()
      return false
    }

    return shouldShowReminder({
      currentDate: CURRENT_DATE,
      reminderDates: REMINDER_DATES,
      periodicLockReminderLastViewedDate: organizationSettings.periodicLockReminderLastViewedDate,
    })
  }, [
    isOrganizationLoading,
    isOrganizationSettingsLoading,
    markAsViewed,
    organization?.countryId,
    organization?.id,
    organizationSettings?.periodicLockReminderLastViewedDate,
  ])

  return {
    shouldShowReminder: shouldShowPeriodicReminder,
    markAsViewed,
  }
}
