import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as FindBookkeeperLogo } from '../../../assets/images/integrations/find-bookkeeper.svg'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { useOrganizationBookkeepers } from '../../settings/routes/OrganizationSettingsUsers/hooks/useOrganizationBookkeepers'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationItem } from '../types/integrationItem'

export const useFindBookkeeperIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { data: bookkeepers, isLoading: isOrganizationBookkeepersLoading } = useOrganizationBookkeepers()
  const hasOrganizationBookkeepers = bookkeepers?.length !== 0

  const buttonHandler = useCallback(() => {
    navigate(EmberRoute.FindAccountant)
  }, [navigate])

  return {
    id: IntegrationId.FindBookkeeper,
    name: t('integrations.card.find_bookkeeper.title'),
    description: t('integrations.card.find_bookkeeper.body'),
    readMoreLink: 'https://www.billy.dk/revisor/find-selv-revisor/',
    imageNode: <FindBookkeeperLogo />,
    isHiddenLoading: isOrganizationBookkeepersLoading,
    isHidden: hasOrganizationBookkeepers,
    buttonHandler,
    buttonText: t('integrations.card.find_bookkeeper.button.activate'),
    statusLabelNode: null,
    isStatusLoading: false,
  }
}
