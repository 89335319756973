import { ModalProps, useModal } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useRef } from 'react'

import { useEmberCloseModal } from '../../../contexts/emberCloseModalContext'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { ModalId } from '../../../enums/modalId'
import { useSegment } from '../../../hooks'
import { useUserOrganization } from '../../../modules/app/organization'
import { useCurrentUser } from '../../../modules/app/user'
import { isSettingsModulePath } from '../../../modules/settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '../../../modules/settings/utils/routeToInternalSettings'
import { WithTrackingContext } from '../../../types/withTrackingContext'
import { NotOwnerModal } from './elements/NotOwnerModal'
import { OwnerModal } from './elements/OwnerModal'

interface UpgradeSubscriptionModalProps extends WithTrackingContext, ModalProps {
  forceRender?: boolean
}

export const UpgradeSubscriptionModal = ({
  id,
  trackingContext,
  forceRender,
}: UpgradeSubscriptionModalProps): ReactElement => {
  const { track } = useSegment()
  const { organization } = useUserOrganization()
  const { closeEmberModal } = useEmberCloseModal()
  const { navigate } = useEmberRouter()
  const { user } = useCurrentUser()
  const isOpeningModalEventFiredRef = useRef(false)

  const modalId = id || ModalId.UpgradeSubscriptionModal

  const trackOpeningModal = useCallback(() => {
    if (isOpeningModalEventFiredRef.current) {
      return
    }

    track('Upgrade Modal Viewed (FE)', {
      context: trackingContext,
    })

    isOpeningModalEventFiredRef.current = true
  }, [track, trackingContext])

  const { close } = useModal(modalId, {
    onClose: () => {
      if (typeof closeEmberModal === 'function') {
        closeEmberModal()
      }
    },
    onOpen: () => {
      trackOpeningModal()
    },
  })

  const isOwner = organization?.ownerUserId === user?.id

  useEffect(() => {
    if (forceRender && !!user && !!organization) {
      trackOpeningModal()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceRender, user, organization])

  const navigateToUpgradeSubscriptionPlan = useCallback(() => {
    if (isSettingsModulePath()) {
      routeToInternalSettings(EmberRoute.SettingsSubscriptionsPlansSelection)
      return
    }

    navigate(EmberRoute.SettingsSubscriptionsPlansSelection)
  }, [navigate])

  const closeModal = useCallback(() => {
    if (typeof closeEmberModal === 'function') {
      closeEmberModal()
    }

    close()
  }, [close, closeEmberModal])

  if (!user || !organization) {
    return <></>
  }

  if (!isOwner) {
    return <NotOwnerModal modalId={modalId} forceRender={forceRender} onModalClose={closeModal} />
  }

  return (
    <OwnerModal
      modalId={modalId}
      trackingContext={trackingContext}
      forceRender={forceRender}
      onUpgradeSubscriptionPlan={navigateToUpgradeSubscriptionPlan}
    />
  )
}
