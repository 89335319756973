import { notify } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useForm } from '../../../../../hooks'
import { setAccessToken } from '../../../../../utils'
import { useChangePassword } from '../../../../app/user/hooks/useChangePassword'
import { useUserAuthorization } from '../context/userAuthorizationContext'
import { getDefaultValues, getValidationSchema, PasswordForm } from '../utils/passwordFormData'

export const usePasswordForm = () => {
  const { t } = useTranslation()
  const { authorizeEmberUser } = useUserAuthorization()
  const { changePassword, isLoading: isSubmitting } = useChangePassword({
    onSuccess: (response) => {
      setAccessToken(response.meta.accessToken)
      authorizeEmberUser(response.meta.accessToken)
      reset(getDefaultValues())
      notify({
        id: NotificationKeys.PasswordChangeAcceptance,
        message: t('change_password.password_changed_successfully'),
        variant: 'success',
      })
    },
    onError: () => {
      setError('oldPassword', {
        type: 'custom',
        message: t('change_password.password_change_rejected'),
      })
    },
  })

  const handleFormSubmit = useCallback(
    (values: PasswordForm) => {
      changePassword({
        oldPassword: values.oldPassword,
        password: values.newPassword,
      })
    },
    [changePassword],
  )

  const { reset, setError, ...form } = useForm({
    defaultValues: useMemo(() => getDefaultValues(), []),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
    onSubmit: handleFormSubmit,
  })

  return { ...form, isSubmitting }
}
