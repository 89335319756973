import groupBy from 'lodash/groupBy'
import sortBy from 'lodash/sortBy'

import { TaxRate } from '../../../../modules/app/taxrates/types'
import { TaxRatesGroupped } from '../types/taxRatesGroupped'

export const groupTaxRates = (taxRates: TaxRate[]): TaxRatesGroupped => {
  const taxRatesSorted = sortBy(taxRates, ['taxRateGroup', 'priority', 'name'])
  const taxRatesGroupped = groupBy(taxRatesSorted, 'taxRateGroup')

  const reorderedTaxRatesGroupped: TaxRatesGroupped = {
    ...(taxRatesGroupped.local ? { local: taxRatesGroupped.local } : {}),
    ...(taxRatesGroupped.eu ? { eu: taxRatesGroupped.eu } : {}),
    ...(taxRatesGroupped.world ? { world: taxRatesGroupped.world } : {}),
    ...(taxRatesGroupped.null ? { null: taxRatesGroupped.null } : {}),
  }

  return reorderedTaxRatesGroupped as unknown as TaxRatesGroupped
}
