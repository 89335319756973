import { EmberRoute } from '../../../enums/emberRoute'
import { EmptyViewType } from '../types/emptyViewType'

export const emptyViewRoutes: Record<EmptyViewType, EmberRoute> = {
  bank: EmberRoute.Bank,
  bankNotConnected: EmberRoute.Bank,
  bankNotConnectedUnauthorized: EmberRoute.BankImport,
  bills: EmberRoute.BillsNew,
  contacts: EmberRoute.ContactsNew,
  creditors: EmberRoute.Creditors,
  debtors: EmberRoute.Debtors,
  einvoicesNotRegistered: EmberRoute.Uploads,
  einvoicesRegistered: EmberRoute.Uploads,
  invoices: EmberRoute.InvoicesNew,
  products: EmberRoute.ProductsNew,
  quotes: EmberRoute.QuotesNew,
  recurringInvoices: EmberRoute.RecurringInvoicesNew,
  transactions: EmberRoute.Transactions,
}
