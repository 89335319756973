import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { FileUploadRefProps } from '../../../../../../components-deprecated/FileUpload'
import { ImageFile, UploadItem } from '../../../../../../components-deprecated/FileUpload/types'
import { currencyInputToString } from '../../../../../../utils/currencyAmountToString'
import { CurrencyInput, CurrencyInputControlledResult } from '../../../../../app'
import { useUserOrganization } from '../../../../../app/organization'
import { Voucher, VoucherInboxState, VoucherUpdatePayloadData } from '../../../../../inbox/types'
import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { useVoucherFileReplaceMutation } from '../../../../hooks/useVoucherFileReplaceMutation'
import { extendVoucherDescription } from '../../../../utils/extendVoucherDescription'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

type FormInputs = {
  amount: CurrencyInputControlledResult
}

const defaultValues: FormInputs = {
  amount: { value: 0, currency: 'DKK' },
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    amount: yup.object().shape({
      value: yup.number().typeError(t('voucher.inbox.form.amount_error')),
      currency: yup.string(),
    }),
  })

interface MissingAmountProps {
  voucher: Voucher
}

export const MissingAmount = ({ voucher }: MissingAmountProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const fileUploadRef = useRef<FileUploadRefProps>(null)
  const form = useForm<FormInputs>({
    defaultValues,
    resolver: useMemo(() => yupResolver(validationSchema(t)), [t]),
  })

  const currentAmount = form.watch('amount')

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)
  const filesReplaceMutation = useVoucherFileReplaceMutation({
    ...mutationBaseData,
    onSuccess: () => {
      const payload = getUpdatePayload(voucher, { amount: currentAmount })
      updateMutation.mutate(payload)
    },
  })

  const isLoading = filesReplaceMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading

  const handleFileUpload = useCallback(
    (_: UploadItem, itemFile?: ImageFile) => {
      if (!itemFile) {
        return
      }

      return filesReplaceMutation.mutateAsync([itemFile])
    },
    [filesReplaceMutation],
  )

  const handleFormSubmit = useCallback(
    (values: FormInputs) => {
      if (!values.amount.value) {
        form.setError('amount.value', { type: 'min', message: t('voucher.inbox.form.amount_error') })
        return
      }

      const payload = getUpdatePayload(voucher, values)
      updateMutation.mutate(payload)
    },
    [form, t, updateMutation, voucher],
  )

  const handleSubmit = useCallback(() => {
    const isSomeFileDropped = fileUploadRef.current?.isSomeFileDropped()

    if (isSomeFileDropped) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fileUploadRef.current?.submitFileUpload()
      return
    }

    form.handleSubmit(handleFormSubmit)()
  }, [fileUploadRef, form, handleFormSubmit])

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  const handleFileDrop = (filesAccepted: File[]) => {
    if (filesAccepted.length > 0) {
      form.clearErrors()
    }
  }

  return (
    <MissingInformationContent
      ref={fileUploadRef}
      disabled={isLoading}
      onFileUpload={handleFileUpload}
      onFileDrop={handleFileDrop}
    >
      <FormProvider {...form}>
        <CurrencyInput formControl={form.control} label={t('amount')} name="amount" disabled={isLoading} />
      </FormProvider>

      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}

function getUpdatePayload(voucher: Voucher, formValues: FormInputs): VoucherUpdatePayloadData {
  const { amount } = formValues
  const amountString = amount.value ? currencyInputToString(amount) : undefined
  const description = amountString
    ? extendVoucherDescription(voucher.description, { amount: amountString })
    : voucher.description

  return { description, inboxState: VoucherInboxState.RECEIVED }
}
