import { Translate } from '@components'
import { Badge, InternalAccessor, Table, TableColumn, Text } from '@design-system'

import orderBy from 'lodash/orderBy'
import pick from 'lodash/pick'
import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortDirection } from '../../../../../../../../enums/sortDirection'
import { getTableColumnIdDirection } from '../../../../../../../../utils/getTableColumnIdDirection'
import { useTaxRates } from '../../../../../../../app/taxrates'
import { PURCHASE_VAT_RATES_FILTER_KEY } from '../../../../constants/purchaseVatRatesFilterKey'
import { VatRatesSortProperty } from '../../../../enums/vatRatesSortProperty'
import { useTemplatesToCreateVatRates } from '../../../../hooks/useTemplatesToCreateVatRates'
import { useVatRatesListFilters } from '../../../../hooks/useVatRatesListFilters'
import { VatRatesTableRow } from '../../../../types/vatRatesTableRow'
import { adjustTaxRatesPriorities } from '../../../../utils/adjustTaxRatesPriorities'
import { fractionToPercent } from '../../../../utils/fractionToPercent'
import { getEditModalId } from '../../../VatRateModal/shared/utils/getEditModalId'
import { VatRatesTableHoverActions } from '../../../VatRatesTableHoverActions'
import { VatRatesTableStaticActions } from '../../../VatRatesTableStaticActions'

interface PurchaseVatRatesTableProps {
  onRateDelete: (rulesetId: string) => void
  showArchived: boolean
}

export const PurchaseVatRatesTable = ({ onRateDelete, showArchived }: PurchaseVatRatesTableProps): ReactElement => {
  const { t } = useTranslation()
  const [{ sortDirection, sortProperty }, setFilters] = useVatRatesListFilters(PURCHASE_VAT_RATES_FILTER_KEY)
  const { taxRatesIncludingDeactivated = [], isLoading } = useTaxRates()
  const { isCreateNewVatRateFromTemplate } = useTemplatesToCreateVatRates()

  const tableData: VatRatesTableRow[] = useMemo(() => {
    const property = sortProperty || VatRatesSortProperty.Priority
    const purchaseTaxRates = taxRatesIncludingDeactivated.filter((item) => {
      if (!item.appliesToPurchases) {
        return false
      }
      if (!showArchived) {
        return item.isActive
      }
      return true
    })
    const purchaseTaxRatesWithAdjustedPriorities = adjustTaxRatesPriorities(purchaseTaxRates)
    return orderBy(
      purchaseTaxRatesWithAdjustedPriorities,
      (item) => item[property],
      sortDirection === SortDirection.Asc ? 'asc' : 'desc',
    ).map((taxRate) => ({
      ...pick(taxRate, [
        'id',
        'isPredefined',
        'description',
        'name',
        'abbreviation',
        'rate',
        'isActive',
        'predefinedTag',
        'isMissingStandardTaxRate',
      ]),
      [InternalAccessor.HoverActions]: true,
      // temporarily ability to delete is disabled for DK organizations
      [InternalAccessor.StaticActions]: isCreateNewVatRateFromTemplate ? false : !taxRate.isPredefined,
    }))
  }, [isCreateNewVatRateFromTemplate, showArchived, sortDirection, sortProperty, taxRatesIncludingDeactivated])

  const columns: TableColumn<VatRatesTableRow>[] = useMemo(
    () => [
      {
        Header: <Translate value={'settings.organization.vat_rates.name'} />,
        accessor: 'name',
        sortable: true,
        size: 'xxxl',
        Cell: ({ value, row, data }) => {
          const { isPredefined, description, isActive, isMissingStandardTaxRate } = data[row.id] as VatRatesTableRow
          return (
            <>
              {value}{' '}
              {isPredefined && (
                <>
                  <Badge>{t('settings.organization.vat_rates.predefined')}</Badge>{' '}
                </>
              )}
              {!isPredefined && isMissingStandardTaxRate && (
                <>
                  <Badge variant="warning">{t('settings.organization.vat_rates.missingMapping')}</Badge>{' '}
                </>
              )}
              {!isActive && <Badge variant="neutral">{t('settings.organization.vat_rates.deactivated')}</Badge>}
              <Text colorVariant="secondary" variant="micro">
                {description}
              </Text>
            </>
          )
        },
      },
      {
        Header: <Translate value={'settings.organization.vat_rates.abbreviation'} />,
        accessor: 'abbreviation',
        size: 's',
        sortable: false,
      },
      {
        Header: <Translate value={'settings.organization.vat_rates.rate'} />,
        accessor: 'rate',
        aligment: 'center',
        size: 'xxxs',
        sortable: false,
        Cell: ({ value }) => `${fractionToPercent(value)}%`,
      },
      {
        Header: '',
        accessor: InternalAccessor.HoverActions,
        sortable: false,
        Cell: ({ row, data }) => {
          const { id, isPredefined } = data[row.id] as VatRatesTableRow

          const isTemplateModal = !isPredefined && isCreateNewVatRateFromTemplate
          const editVatRateModalId = getEditModalId(`purchase-${id}`)

          return (
            <VatRatesTableHoverActions
              vatRateId={id}
              modalId={editVatRateModalId}
              readonly={isPredefined}
              isTemplateModal={isTemplateModal}
            />
          )
        },
      },
      {
        Header: '',
        accessor: InternalAccessor.StaticActions,
        sortable: false,
        Cell: ({ row, data }) => <VatRatesTableStaticActions onDelete={onRateDelete} id={data[row.id].id} />,
      },
    ],
    [isCreateNewVatRateFromTemplate, onRateDelete, t],
  )

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setFilters({
        sortProperty: columnId as VatRatesSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setFilters],
  )

  return (
    <Table
      columns={columns}
      data={tableData}
      isLoading={isLoading}
      skeletonItemsPerPage={5}
      onSort={handleSort}
      sortedColumnId={sortedColumnId}
    />
  )
}
