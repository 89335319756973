import { Input as InputComponent, Text as TextComponent } from '@components-deprecated'
import { Spacing } from '@design-system'

import styled from '@emotion/styled'

import { CompanySelector as CompanySelectorComponent } from '../../../app'

export const Input = styled(InputComponent)`
  margin-bottom: ${Spacing.L};
`

export const CompanySelector = styled(CompanySelectorComponent)`
  margin-bottom: ${Spacing.L};
`

export const DetailsSwitcherWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const SwitcherTitle = styled(TextComponent)`
  margin-right: ${Spacing.S};
`

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.L};
`
