import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { fetchActors, FetchActorsOptions } from '../query-api'
import { Actor } from '../types/actor'

interface UseFetchActorsResponse {
  actors: Actor[]
  isLoading: boolean
}

export const useFetchActors = (): UseFetchActorsResponse => {
  const { organization } = useUserOrganization()

  const queryProps: FetchActorsOptions = useMemo(
    () => ({
      organizationId: organization?.id as string,
      offset: 0,
      pageSize: 100,
    }),
    [organization?.id],
  )

  const {
    data: actorsData,
    isIdle,
    isLoading,
  } = useQuery([QueryKeys.Actors, queryProps], () => fetchActors(queryProps), {
    enabled: !!organization?.id,
  })

  return {
    actors: actorsData?.oAuthActors || [],
    isLoading: isLoading || isIdle,
  }
}
