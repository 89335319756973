import { GridList as GridListComponent, InfiniteTable, TableColumn, TableTotalRow } from '@components-deprecated'

import styled from '@emotion/styled'
import { useTheme } from 'emotion-theming'
import sortBy from 'lodash/sortBy'
import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Side } from '../../../../enums/side'
import { Themable } from '../../../../types/themable'
import { Theme } from '../../../../types/theme'
import { formatDate } from '../../../../utils/date-fns'
import { accountsSelector } from '../../../app'
import { IdentifiedObject, State } from '../../../types'
import { PostingData } from '../../types'

const GridList = styled(GridListComponent)<Themable>`
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: ${({ theme }) => `2px solid ${theme.colors.shade20}`};
`

type Row = IdentifiedObject & {
  accountNumber: string
  accountName: string
  amountDebit?: number
  amountCredit?: number
}

type RecordedAsTransactionViewProps = {
  transactionData: PostingData
}

type TotalAmount = {
  totalDebit: number
  totalCredit: number
}

const getTotalAmount = ({ postings }: PostingData): TotalAmount => {
  let totalDebit = 0
  let totalCredit = 0

  for (const posting of postings) {
    if (posting.side === Side.Credit) {
      totalCredit += posting.amount
    } else if (posting.side === Side.Debit) {
      totalDebit += posting.amount
    }
  }

  return {
    totalDebit,
    totalCredit,
  }
}

export const RecordedAsTransactionView = ({ transactionData }: RecordedAsTransactionViewProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme<Theme>()

  // Selectors

  const accounts = useSelector((state: State) => accountsSelector(state, {}))

  // Table

  const columns: TableColumn<Row>[] = [
    {
      text: t('voucher.inbox.view.account_no'),
      field: 'accountNumber',
      type: 'text',
    },
    {
      text: t('voucher.inbox.view.account_name'),
      field: 'accountName',
      type: 'text',
    },
    {
      text: t('debit'),
      field: 'amountDebit',
      type: 'currency',
    },
    {
      text: t('credit'),
      field: 'amountCredit',
      type: 'currency',
    },
  ]

  const data: Row[] = sortBy(transactionData.postings, 'priority').map((posting): Row => {
    const account = accounts.find((account) => account.id === posting.accountId)

    return {
      id: posting.id,
      accountNumber: account?.accountNo ? account.accountNo.toString() : '',
      accountName: account ? account.name : '-',
      amountDebit: posting.side === Side.Debit ? posting.amount : undefined,
      amountCredit: posting.side === Side.Credit ? posting.amount : undefined,
    }
  })

  const { totalCredit, totalDebit } = getTotalAmount(transactionData)
  const currencyId = transactionData.postings[0].currencyId

  const totalRow: TableTotalRow<Row> = {
    id: 'total',
    accountNumber: '',
    accountName: `${t('total')} (${currencyId}):`,
    amountDebit: totalDebit,
    amountCredit: totalCredit,
    labelColumnName: 'accountName',
  }

  return (
    <>
      <GridList
        truncateValue={false}
        theme={theme}
        items={[
          {
            id: 'payment_date',
            text: t('voucher.inbox.form.payment_date'),
            value: formatDate(transactionData.postings[0].entryDate, 'MMM. d yyyy') || '-',
          },
          {
            id: 'description',
            text: t('voucher.inbox.form.description'),
            value: transactionData.postings[0].text || '-',
          },
        ]}
      />

      <InfiniteTable<Row> data={data} columns={columns} rowSpacing={10} rowTotal={totalRow} total={data.length} />
    </>
  )
}
