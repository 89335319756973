import { UpgradeSubscriptionModal } from '@components'
import { Button, ButtonsGroup, useModal } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../enums/modalId'
import { TrackingContext } from '../../../../../../enums/trackingContext'
import { useWatch } from '../../../../../../hooks'
import { useComponentId } from '../../../../../../hooks/useComponentId'
import { useIsBasicPlan } from '../../../../../../hooks/useIsBasicPlan'
import { useUserOrganization } from '../../../../../app/organization'
import { useEInvoiceContext } from '../../context/eInvoiceContext'
import { useEmailContext } from '../../context/emailContext'

interface SendInvoiceFooterProps {
  submitForm: () => void
  isReadyForSubmit: boolean
  isLoading?: boolean
  mode: 'eInvoice' | 'email'
}

const VALID_REGISTRATION_NO_LENGTH = 8

export const SendInvoiceFooter = ({ submitForm, isReadyForSubmit, isLoading, mode }: SendInvoiceFooterProps) => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.SendInvoiceModal)
  const upgradeModalId = useComponentId(ModalId.UpgradeSubscriptionModal)
  const { open: openUpgradeSubscriptionModal } = useModal(upgradeModalId)
  const { isBasicPlan, isLoading: isLoadingIsBasicPlan } = useIsBasicPlan()

  const handleSendClick = useCallback(() => {
    if (isBasicPlan && mode === 'eInvoice') {
      openUpgradeSubscriptionModal()
    } else {
      submitForm()
    }
  }, [isBasicPlan, mode, openUpgradeSubscriptionModal, submitForm])

  return (
    <>
      <ButtonsGroup>
        <Button variant="text" onClick={close}>
          {t('cancel')}
        </Button>
        <Button loading={isLoading} disabled={!isReadyForSubmit || isLoadingIsBasicPlan} onClick={handleSendClick}>
          {t('modal.send_invoice.button.send')}
        </Button>
      </ButtonsGroup>

      <UpgradeSubscriptionModal id={upgradeModalId} trackingContext={TrackingContext.EInvoice} />
    </>
  )
}

const EInvoiceFooter = () => {
  const { organization } = useUserOrganization()

  const { submitForm, isIdentifierVerified, isLoading } = useEInvoiceContext()

  const isReadyToSubmitForm = useMemo(() => {
    const registrationNo = organization?.registrationNo
    const hasRegistrationNo = !!registrationNo && registrationNo.length >= VALID_REGISTRATION_NO_LENGTH

    return hasRegistrationNo && isIdentifierVerified
  }, [organization?.registrationNo, isIdentifierVerified])

  return (
    <SendInvoiceFooter
      submitForm={submitForm}
      isReadyForSubmit={isReadyToSubmitForm}
      isLoading={isLoading}
      mode="eInvoice"
    />
  )
}

const EmailFooter = (): ReactElement => {
  const { submitForm, isProcessing, formControl } = useEmailContext()
  const recipients = useWatch({ control: formControl, name: 'recipients' })

  const isSomeReceiverSelected = !!recipients && recipients?.length > 0

  return (
    <SendInvoiceFooter
      submitForm={submitForm}
      isReadyForSubmit={isSomeReceiverSelected}
      isLoading={isProcessing}
      mode="email"
    />
  )
}

SendInvoiceFooter.EInvoice = EInvoiceFooter
SendInvoiceFooter.EmailFooter = EmailFooter
