import { IconClose, IconComment, Modal, ModalBody, ModalHeader, Text } from '@components-deprecated'

import { css } from '@emotion/core'
import { useTheme } from 'emotion-theming'
import React, { MouseEvent, useCallback, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FlexProps } from 'rebass'

import { useHover } from '../../../hooks'
import { Theme } from '../../../types/theme'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import { ungroupBankLine } from '../action-creators'
import { BankLine as BankLineType, ReceiptState } from '../types'
import { prefixAmount } from '../utils'
import { LineWrapper } from './LineWrapper'
import { MatchListRowActionOverlay as ActionOverlay } from './MatchListRowActionOverlay'
import * as Styled from './styles'

export type BankLineProps = FlexProps & {
  bankLine: BankLineType
  index: number
  isDraggingOver?: boolean
  isApproved: boolean
  withUngroup: boolean
  collection: BankLineType[]
}

const BADGE_SIZE = 14

export const BankLine = ({
  bankLine: { amount = 0, comments, description, entryDate = '', id, matchId, receiptState, side },
  index,
  isApproved,
  withUngroup,
  ...rest
}: BankLineProps) => {
  const theme = useTheme<Theme>()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isUngrouping, setIsUngrouping] = useState(false)
  const account = useSelector((state: SpecificState) => state.app.account)
  const { hoverRef, isHovered } = useHover()
  const [isCommentModalOpen, setIsCommentModalOpen] = useState(false)
  const isCommentsIconVisible = receiptState !== ReceiptState.MissingReceipt && !!comments?.length

  const handleUngroup = () => {
    if (isUngrouping) {
      return
    }
    setIsUngrouping(true)
    dispatch(
      ungroupBankLine(
        {
          accountId: account?.id as string,
          amount,
          entryDate,
          isApproved: false,
          lineId: id,
          matchId,
          side,
        },
        (err) => {
          if (err) {
            setIsUngrouping(false)
          }
        },
      ),
    )
  }

  const handleModalOpen = useCallback((event: MouseEvent) => {
    event.stopPropagation()
    setIsCommentModalOpen(true)
  }, [])

  const handleModalClose = useCallback(() => {
    setIsCommentModalOpen(false)
  }, [])

  return (
    <>
      <Draggable draggableId={id} index={index} isDragDisabled={isApproved}>
        {(provided, snapshot) => (
          <LineWrapper
            className={reactClass('bankline')}
            groupable
            isDragging={snapshot.isDragging}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'unset',
            }}
            {...rest}
          >
            <div ref={hoverRef} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <ActionOverlay
                className="ungroup-line"
                hoverable={isHovered && withUngroup}
                css={css`
                  top: 0;
                  left: 0;
                `}
              >
                <Box
                  mt={'-2px'}
                  ml={'10px'}
                  css={css`
                    i {
                      cursor: pointer;
                    }
                  `}
                >
                  <IconClose width="8px" height="8px" color={'#3a4a54'} strokeWidth={'2px'} onClick={handleUngroup} />
                </Box>
              </ActionOverlay>
            </div>

            <Text variant="body1" type="date" width="100px">
              {entryDate}
            </Text>
            <Text
              variant="body1"
              css={css`
                flex: 1;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: keep-all;
                white-space: nowrap;
              `}
            >
              {isCommentsIconVisible && (
                <Styled.CommentButton type="button" onClick={handleModalOpen}>
                  <IconComment
                    width={BADGE_SIZE}
                    height={BADGE_SIZE}
                    title={t('things_to_do.missing_receipts')}
                    color={theme.colors.primary}
                  />
                </Styled.CommentButton>
              )}
              {receiptState === ReceiptState.MissingReceipt && (
                <Styled.HelpBadge width={BADGE_SIZE} height={BADGE_SIZE} title={t('things_to_do.missing_receipts')} />
              )}
              {description}
            </Text>
            <Text
              variant="body1"
              type="currency"
              currencyColored
              ml="25px"
              css={css`
                min-width: 60px;
                text-align: right;
              `}
            >
              {prefixAmount({ amount, side })}
            </Text>
          </LineWrapper>
        )}
      </Draggable>
      {isCommentsIconVisible && (
        <Modal isOpen={isCommentModalOpen} onClose={handleModalClose}>
          <ModalHeader heading="Comments" />
          <ModalBody>{comments?.map(({ message }) => <Text variant="body2">{message}</Text>)}</ModalBody>
        </Modal>
      )}
    </>
  )
}
