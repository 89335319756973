import { encodePeriodValue, GlobalPeriodSelect } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodMode } from '../../enums/periodMode'
import { PeriodValue } from '../../types/periodValue'
import { useUserOrganization } from '../app/organization'
import { DownloadButton } from './elements/DownloadButton'
import { SalesTaxTable } from './elements/SalesTaxTable'
import { useSalesTaxListFilters } from './hooks/useSalesTaxListFilters'
import * as Styled from './styled'

export const SalesTax = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const [{ period: queryParamPeriod }, setQueryParams] = useSalesTaxListFilters()

  const handlePeriodChange = useCallback(
    (periodValue: PeriodValue) => {
      if (!organization?.id) {
        return
      }

      setQueryParams({ period: encodePeriodValue(periodValue, organization.id) })
    },
    [organization?.id, setQueryParams],
  )

  return (
    <ModuleLayout sideActions={<DownloadButton />} title={t('sales_tax_returns.eu_sales_without_vat.header')}>
      <Styled.SalesTaxPeriodSelector>
        <GlobalPeriodSelect
          disabledPeriodModes={[PeriodMode.All, PeriodMode.FiscalYear, PeriodMode.CustomDate]}
          encodedPeriod={queryParamPeriod}
          onChange={handlePeriodChange}
          placement="bottom-end"
        />
      </Styled.SalesTaxPeriodSelector>
      <SalesTaxTable />
    </ModuleLayout>
  )
}
