import { useModal } from '@design-system'

import qs from 'qs'
import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-use'

import { ModalId } from '../../../../enums/modalId'
import { ApplicationSentModal } from '../../../financingBanner/elements/ApplicationSentModal'
import { ApprovalModal } from '../../../financingBanner/elements/ApprovalModal'
import { useDebtCollectionIntegration } from '../../hooks/useDebtCollectionIntegration'
import { useInvoiceFinancingIntegration } from '../../hooks/useInvoiceFinancingIntegration'
import { useMobilePayIntegration } from '../../hooks/useMobilePayIntegration'
import { IntegrationItem } from '../../types/integrationItem'
import { DebtCollectionModalsGroup } from '../DebtCollectionModalsGroup'
import { IntegrationsList } from '../IntegrationsList'
import { MobilePayModalsGroup } from '../MobilePayModalsGroup'
import { StripeModalsGroup } from '../StripeModalsGroup'

export const InvoicesIntegrationsList = (): ReactElement => {
  const { t } = useTranslation()
  const mobilePayIntegration = useMobilePayIntegration()
  const invoiceFinancingIntegration = useInvoiceFinancingIntegration()
  const debtCollectionIntegration = useDebtCollectionIntegration()
  const location = useLocation()
  const { open: openApplicationSentModal } = useModal(ModalId.FinancingApplicationSentModal)

  const items: IntegrationItem[] = useMemo(
    () => [mobilePayIntegration, invoiceFinancingIntegration, debtCollectionIntegration],
    [mobilePayIntegration, invoiceFinancingIntegration, debtCollectionIntegration],
  )

  useEffect(() => {
    if (!location.search) {
      return
    }

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    if (queryParams.application_sent === '1') {
      openApplicationSentModal()
    }
  }, [location, openApplicationSentModal])

  return (
    <>
      <IntegrationsList title={t('integrations.list.title.take_care')} items={items} />
      <ApprovalModal />
      <ApplicationSentModal />
      <DebtCollectionModalsGroup />
      <StripeModalsGroup />
      <MobilePayModalsGroup />
    </>
  )
}
