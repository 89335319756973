import { DateInput, FormRow } from '@components-deprecated'

import React, { ReactElement, useCallback, useEffect, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { FileUploadRefProps } from '../../../../../../components-deprecated/FileUpload'
import { ImageFile, UploadItem } from '../../../../../../components-deprecated/FileUpload/types'
import { formatDate } from '../../../../../../utils'
import { useUserOrganization } from '../../../../../app/organization'
import { Voucher, VoucherInboxState, VoucherUpdatePayloadData } from '../../../../../inbox/types'
import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { useVoucherFileReplaceMutation } from '../../../../hooks/useVoucherFileReplaceMutation'
import { extendVoucherDescription } from '../../../../utils/extendVoucherDescription'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

type FormInputs = {
  billDate?: Date | null
  paymentDate?: Date | null
}

const defaultValues: FormInputs = {
  billDate: undefined,
  paymentDate: undefined,
}

interface MissingDateProps {
  voucher: Voucher
}

export const MissingDate = ({ voucher }: MissingDateProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const fileUploadRef = useRef<FileUploadRefProps>(null)
  const form = useForm<FormInputs>({
    defaultValues,
  })

  const [currentBillDate, currentPaymentDate] = form.watch(['billDate', 'paymentDate'])

  // Could not find a better way with yup, so this is the only solution I could find
  useEffect(() => {
    if (currentBillDate || currentPaymentDate) {
      form.clearErrors()
    }
    // we can't pass whole "form" object as it changes on each form's action
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBillDate, currentPaymentDate, form.clearErrors])

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)
  const filesReplaceMutation = useVoucherFileReplaceMutation({
    ...mutationBaseData,
    onSuccess: () => {
      const payload = getUpdatePayload(voucher, { billDate: currentBillDate, paymentDate: currentPaymentDate })
      updateMutation.mutate(payload)
    },
  })

  const isLoading = filesReplaceMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading

  const handleFileUpload = useCallback(
    (_: UploadItem, itemFile?: ImageFile) => {
      if (!itemFile) {
        return
      }

      return filesReplaceMutation.mutateAsync([itemFile])
    },
    [filesReplaceMutation],
  )

  const handleFormSubmit = useCallback(
    (values: FormInputs) => {
      if (!values.billDate && !values.paymentDate) {
        form.setError('billDate', { type: 'required', message: t('things_to_do.validation.payment_date_or_bill_date') })
        return
      }

      const payload = getUpdatePayload(voucher, values)
      updateMutation.mutate(payload)
    },
    // we can't pass whole "form" object as it changes on each form's action
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [voucher, updateMutation, t, form.setError],
  )

  const handleSubmit = useCallback(() => {
    const isSomeFileDropped = fileUploadRef.current?.isSomeFileDropped()

    if (isSomeFileDropped) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      fileUploadRef.current?.submitFileUpload()
      return
    }

    form.handleSubmit(handleFormSubmit)()
  }, [fileUploadRef, form, handleFormSubmit])

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  const handleFileDrop = (filesAccepted: File[]) => {
    if (filesAccepted.length > 0) {
      form.clearErrors()
    }
  }

  return (
    <MissingInformationContent
      ref={fileUploadRef}
      disabled={isLoading}
      onFileUpload={handleFileUpload}
      onFileDrop={handleFileDrop}
    >
      <FormProvider {...form}>
        <FormRow error={form.formState.errors.billDate}>
          <DateInput
            formControl={form.control}
            name="billDate"
            label={t('voucher.inbox.form.bill_date')}
            disabled={isLoading}
            silent
          />
          <DateInput
            formControl={form.control}
            name="paymentDate"
            label={t('voucher.inbox.form.payment_date')}
            disabled={isLoading}
            errorPath="billDate"
            silent
          />
        </FormRow>
      </FormProvider>

      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}

function getUpdatePayload(voucher: Voucher, formValues: FormInputs): VoucherUpdatePayloadData {
  const { billDate, paymentDate } = formValues
  const billDateString = billDate ? formatDate(billDate) : undefined
  const paymentDateString = paymentDate ? formatDate(paymentDate) : undefined
  const shouldExtendDescription = billDateString || paymentDateString

  const description = shouldExtendDescription
    ? extendVoucherDescription(voucher.description, {
        ...(billDateString ? { billDate: billDateString } : {}),
        ...(paymentDateString ? { paymentDate: paymentDateString } : {}),
      })
    : voucher.description

  return { description, inboxState: VoucherInboxState.RECEIVED }
}
