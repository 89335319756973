import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { EInvoiceStatus } from '../../modules/eInvoiceStatus'
import { EInvoiceStatusConnectionContextProvider } from '../../modules/eInvoiceStatus/contexts/eInvoiceStatusEmberConnectionContext'
import { EmberEventFn } from '../../types/emberEventFn'

const MODULE_NAME = 'e-invoice-status-module'

class EInvoiceStatusWebComponent extends DOMModel {
  @byAttrVal invoiceId: string
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('voidInvoice') voidInvoice: Function
  @registerEvent('voidAndDuplicateInvoice') voidAndDuplicateInvoice: Function
  @registerEvent('openSendInvoiceModal') openSendInvoiceModal: Function
}

interface EInvoiceStatusModuleProps {
  invoiceId: string
  onNavigate: EmberNavigate
  onVoidInvoice: EmberEventFn
  onVoidAndDuplicateInvoice: EmberEventFn
  onOpenSendInvoiceModal: EmberEventFn
  organizationId: string
}

const EInvoiceStatusModule = ({
  invoiceId,
  onNavigate,
  onOpenSendInvoiceModal,
  onVoidInvoice,
  onVoidAndDuplicateInvoice,
  organizationId,
}: EInvoiceStatusModuleProps): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <HashRouter>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <CurrentUserContextProvider>
              <ModalContextProvider>
                <EInvoiceStatusConnectionContextProvider
                  onOpenSendInvoiceModal={onOpenSendInvoiceModal}
                  onVoidInvoice={onVoidInvoice}
                  onVoidAndDuplicateInvoice={onVoidAndDuplicateInvoice}
                >
                  <EInvoiceStatus invoiceId={invoiceId} />
                </EInvoiceStatusConnectionContextProvider>
              </ModalContextProvider>
            </CurrentUserContextProvider>
          </UserOrganizationContextProvider>
        </EmberRouterContextProvider>
      </HashRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const EInvoiceStatusCustomElement = createCustomElement(EInvoiceStatusModule, EInvoiceStatusWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, EInvoiceStatusCustomElement)

export default EInvoiceStatusCustomElement
