import { SELECTABLE_ACCESSORS } from '../constants/selectableAccessors'
import { NavItemAccessor } from '../types/navItemAccessor'
import { NavItemNavigable } from '../types/navItemNavigable'

export const getRealAndSelectableItems = <T extends NavItemNavigable>(items: T[]) =>
  items.filter(
    (item) =>
      !item.accessor ||
      (item.accessor && !Object.values(NavItemAccessor).includes(item.accessor)) ||
      SELECTABLE_ACCESSORS.includes(item.accessor),
  )
