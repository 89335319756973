import { Container } from '@components-deprecated'

import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom'

import { CommonFormDataContextProvider } from '../../contexts/commonFormDataContext'
import { useInboxOrganizationId } from '../../contexts/inboxOrganizationIdContext'
import { AccountsContextProvider } from '../app/accounts'
import { contactsRequested } from '../app/contacts/action-creators'
import { UserOrganizationContextProvider } from '../app/organization'
import { accountsRequest, taxRatesRequest } from './action-creators'
import { BillCreate, JournalCreate, PartnerInbox, VoucherView } from './pages'
import { InboxRoutes } from './types'

type InboxProps = {
  customerView?: boolean
  umbrellaId?: string
  userOrganizationId?: string
}

export const Inbox = ({ customerView, umbrellaId, userOrganizationId }: InboxProps) => {
  const [selectedOrganizationId, setSelectedOrganizationId] = useInboxOrganizationId()
  const dispatch = useDispatch()
  const { path } = useRouteMatch()
  // Inbox is used in both umbrella and organization. The path differs for umbrella="/inbox/voucher-inbox/" and org="/"
  const urlPath = umbrellaId ? `${path}/` : path

  useEffect(() => {
    if (customerView && selectedOrganizationId !== userOrganizationId) {
      setSelectedOrganizationId(userOrganizationId)
    }
  }, [customerView, selectedOrganizationId, setSelectedOrganizationId, userOrganizationId])

  useEffect(() => {
    if (!selectedOrganizationId) {
      return
    }

    dispatch(taxRatesRequest(selectedOrganizationId))
    dispatch(accountsRequest(selectedOrganizationId))
    dispatch(
      contactsRequested({
        organizationId: selectedOrganizationId,
        page: { offset: 0 },
        filters: { isVendor: true, text: null },
      }),
    )
  }, [dispatch, selectedOrganizationId])

  return (
    <UserOrganizationContextProvider organizationId={selectedOrganizationId}>
      <AccountsContextProvider organizationId={selectedOrganizationId}>
        <CommonFormDataContextProvider>
          <Container>
            <Switch>
              <Route exact path={path}>
                <PartnerInbox customerView={customerView} umbrellaId={umbrellaId} />
              </Route>
              <Route
                exact
                path={`${urlPath}:voucherId/${InboxRoutes.ViewVoucher}`}
                render={() => selectedOrganizationId && <VoucherView customerView={customerView} />}
              />

              <Route
                exact
                path={`${urlPath}:voucherId/${InboxRoutes.CreateBill}`}
                render={() => {
                  if (customerView) {
                    return <Redirect to="/" />
                  }

                  return <BillCreate customerView={customerView} />
                }}
              />
              <Route
                exact
                path={`${urlPath}:voucherId/${InboxRoutes.CreateJournal}`}
                render={() => {
                  if (customerView) {
                    return <Redirect to="/" />
                  }

                  return selectedOrganizationId && <JournalCreate />
                }}
              />
            </Switch>
          </Container>
        </CommonFormDataContextProvider>
      </AccountsContextProvider>
    </UserOrganizationContextProvider>
  )
}
