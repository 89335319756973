import { Button, ButtonsGroup, Modal, Space, Text, useModal } from '@design-system'

import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../../../../enums/modalId'
import { getImgPath } from '../../../../../../../../utils/getImgPath'
import { SubscriptionPeriod } from '../../../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../../../app/organization/enums/subscriptionPlan'
import * as Styled from './styles'

interface SubscriptionPeriodChangeModalProps {
  subscriptionPlan: SubscriptionPlan
  subscriptionPeriod: SubscriptionPeriod
  price?: number
  onConfirm: () => void
  onClose: () => void
}

type PeriodSwitchType = 'to_yearly' | 'to_monthly'

export const SubscriptionPeriodChangeModal = ({
  subscriptionPlan,
  subscriptionPeriod,
  price,
  onConfirm,
  onClose,
}: SubscriptionPeriodChangeModalProps): ReactElement => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const { close } = useModal(ModalId.SubscriptionPeriodChangeModal, {
    onClose: () => onClose(),
  })

  const handleConfirmClick = () => {
    setIsLoading(true)
    onConfirm()
  }

  const handleCloseClick = useCallback(() => {
    close()
  }, [close])

  const periodSwitchType = useMemo((): PeriodSwitchType => {
    return subscriptionPeriod === SubscriptionPeriod.Yearly ? 'to_yearly' : 'to_monthly'
  }, [subscriptionPeriod])

  // the displayed name of 'basic_paid' plan should always be 'basic'
  const subscriptionPlanTitle = subscriptionPlan === SubscriptionPlan.BasicPaid ? 'basic' : subscriptionPlan

  return (
    <Modal id={ModalId.SubscriptionPeriodChangeModal} size="s" forceRender closable>
      <Modal.Header>
        <Text variant="h2">{t(`modal.subscription__period_change_modal.${periodSwitchType}.title`)}</Text>
      </Modal.Header>
      <Modal.Body>
        <Styled.ImageContainer>
          <img src={getImgPath('/modals/toggle.svg')} alt="billy billing" />
        </Styled.ImageContainer>

        <Space size="xl" />

        {price && (
          <Text>
            {t(`modal.subscription__period_change_modal.${periodSwitchType}.body`, {
              plan: subscriptionPlanTitle,
              price: subscriptionPeriod === SubscriptionPeriod.Yearly ? price * 12 : price,
            })}
          </Text>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button onClick={handleCloseClick} variant="text">
            {t('modal.subscription__period_change_modal.cancel_cta')}
          </Button>
          <Button loading={isLoading} onClick={handleConfirmClick} variant="primary">
            {t(`modal.subscription__period_change_modal.${periodSwitchType}.cta`)}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
