import {
  BookkeepingTagDbValue,
  BookkeepingTagNavItemValue,
  convertBookkeepingTagDbValueToNavItemValue,
  isBookkeepingTagValid,
} from '@components'
import { Badge, Color, getCurrentLocale, Icon, SkeletonBox, Space } from '@design-system'

import React, { ReactElement, useState } from 'react'
import { useAsync } from 'react-use'

import { useUserOrganization } from '../../../../../app/organization'
import * as Styled from './styles'

interface BillBookkeepingTagProps {
  bookkeepingTag: BookkeepingTagDbValue
}

export const BillBookkeepingTag = ({ bookkeepingTag }: BillBookkeepingTagProps): ReactElement | null => {
  const [isFetchingTag, setFetchingTag] = useState(true)
  const locale = getCurrentLocale()
  const [bookkeepingTagValue, setBookkeepingTagValue] = useState<BookkeepingTagNavItemValue>()
  const { organization } = useUserOrganization()

  useAsync(async () => {
    if (!organization?.id) {
      return
    }

    const bookkeepingTagValue = await convertBookkeepingTagDbValueToNavItemValue(
      bookkeepingTag,
      locale,
      organization?.chartOfAccountId,
      organization?.countryId,
    )

    if (bookkeepingTagValue && isBookkeepingTagValid(bookkeepingTagValue)) {
      setBookkeepingTagValue(bookkeepingTagValue)
    }

    setFetchingTag(false)
  }, [bookkeepingTag, organization?.id])

  if (!isFetchingTag && !bookkeepingTagValue) {
    return null
  }

  return (
    <Styled.BillBookkeepingTagWrapper>
      {isFetchingTag ? <SkeletonBox height={20} width={75} /> : <Badge>{bookkeepingTagValue?.tag?.name}</Badge>}
      <Space size="xxs" horizontal />
      <Icon icon="chevronRight" color={Color.DeepSeaGreen} size="small" />
      <Space size="xxs" horizontal />
      {isFetchingTag ? <SkeletonBox height={20} width={200} /> : <Badge>{bookkeepingTagValue?.response?.option}</Badge>}
    </Styled.BillBookkeepingTagWrapper>
  )
}
