import { Account } from '../../../../app/accounts/types'
import { getContact } from '../../../../app/contacts/utils/getContact'
import { ChartOfAccountId } from '../../../../app/organization/enums/chartOfAccountId'
import { TaxRate } from '../../../../app/taxrates/types'
import { autoCompleteBillForm } from './autoCompleteBillForm'
import { BillForm } from './formData'
import { getBillFormFromBohrData } from './getBillFormFromBohrData'
import { getBillFormFromBookkeepingTagForm } from './getBillFormFromBookkeepingTag'
import { getBillFormFromExpenseCategoryForm } from './getBillFormFromExpenseCategory'
import { getBillFormFromVendorData } from './getBillFormFromVendorData'
import { getShouldOverridenParamChange } from './getShouldOverridenParamChange'

interface GetBillFormAutoCompletedProps {
  accounts?: Account[]
  baseCurrencyId?: string
  billForm: BillForm
  chartOfAccountId?: ChartOfAccountId
  isVatExempted?: boolean
  locale: string
  organizationId: string
  taxRates?: TaxRate[]
  withBookkeepingSuggestions?: boolean
  countryId?: string
}

export const getBillFormAutoCompleted = async ({
  accounts,
  baseCurrencyId,
  billForm,
  chartOfAccountId,
  isVatExempted,
  organizationId,
  locale,
  taxRates,
  withBookkeepingSuggestions,
  countryId,
}: GetBillFormAutoCompletedProps): Promise<BillForm> => {
  let billFormUpdated = billForm

  // autocomplete using bohr data
  const attachmentFile = billForm?.attachmentsFiles?.[0]

  if (attachmentFile) {
    const billFormBohr = await getBillFormFromBohrData({
      attachmentFile,
      chartOfAccountId,
      countryId,
      locale,
      organizationId,
      withBookkeepingSuggestions,
    })
    billFormUpdated = autoCompleteBillForm({
      billFormDefault: billFormUpdated,
      billFormOverrides: billFormBohr,
      baseCurrencyId,
      onlyFirstBillLine: true,
      shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
    })
  }

  // autocomplete using vendor
  const vendorId = billFormUpdated?.vendor?.id || billForm?.vendor?.id
  const vendor = vendorId ? await getContact(vendorId) : undefined

  if (vendor) {
    const billFormVendor = await getBillFormFromVendorData({
      accounts,
      billForm: billFormUpdated,
      chartOfAccountId,
      countryId,
      isVatExempted,
      locale,
      taxRates,
      vendor,
    })
    billFormUpdated = autoCompleteBillForm({
      billFormDefault: billFormUpdated,
      billFormOverrides: billFormVendor,
      baseCurrencyId,
      shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
    })
  }

  // autocomplete using bookkeeping hero
  const billFormBookeepingHero = getBillFormFromBookkeepingTagForm({
    billForm: billFormUpdated,
    taxRates,
    accounts,
    isVatExempted,
  })
  billFormUpdated = autoCompleteBillForm({
    billFormDefault: billFormUpdated,
    billFormOverrides: billFormBookeepingHero,
    baseCurrencyId,
    shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: false }),
  })

  // autocomplete using expenses category
  const billFormExpenseCategory = getBillFormFromExpenseCategoryForm(billFormUpdated, accounts, taxRates)
  billFormUpdated = autoCompleteBillForm({
    billFormDefault: billFormUpdated,
    billFormOverrides: billFormExpenseCategory,
    baseCurrencyId,
    shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: false }),
  })

  return billFormUpdated
}
