import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUmbrella } from '../../../../app/umbrellas'
import { fetchUmbrellaUserInvitations } from '../../../../app/umbrellas/query-api'
import { InvitedUmbrellaUser } from '../../../../app/umbrellas/types/invitedUmbrellaUser'

interface UseInvitedUsersResponse {
  data: InvitedUmbrellaUser[]
  isLoading: boolean
}

export const useInvitedUsers = (): UseInvitedUsersResponse => {
  const { umbrella } = useUmbrella()
  const umbrellaId = umbrella?.id || ''

  const {
    data: invitedUsersData,
    isIdle,
    isLoading,
  } = useQuery([QueryKeys.UmbrellaInvitedUsers], () => fetchUmbrellaUserInvitations(umbrellaId), {
    enabled: !!umbrellaId,
  })

  return {
    data: invitedUsersData?.data || [],
    isLoading: isIdle || isLoading,
  }
}
