import { Button, FormItemsGroup, Input, Modal, notify, Spacing, useModal } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { ModalId } from '../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useForm, useFormContext } from '../../../../../hooks'
import { ContactPerson } from '../../../../../modules/contacts/types/contactPerson'
import { useCreateContactPerson } from '../../../../../modules/invoices/elements/SendInvoiceModal/hooks/useCreateContactPerson'
import { PostContactPersonResponse } from '../../../../../modules/invoices/elements/SendInvoiceModal/query-api'
import {
  CreateContactPersonForm,
  CreateContactPersonFormSchema,
  getContactPersonValidationSchema,
} from '../../utils/contactPersonFormData'

interface CreateContactPersonModalProps {
  contactId: string
  onCreateContactPerson: (contactPerson: ContactPerson) => void
}

export const CreateContactPersonModal = ({
  contactId,
  onCreateContactPerson,
}: CreateContactPersonModalProps): ReactElement => {
  const { t } = useTranslation()
  const { close } = useModal(ModalId.CreateContactPersonModal)
  const { FormItem } = useFormContext<CreateContactPersonFormSchema>()
  const { Form, handleSubmit } = useForm({
    validationSchema: useMemo(() => getContactPersonValidationSchema(t), [t]),
  })
  const { createContactPerson, isLoading } = useCreateContactPerson({
    onError: () => {
      notify({ id: NotificationKeys.CreateContactPerson, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: (response: PostContactPersonResponse) => {
      notify({
        id: NotificationKeys.CreateContactPerson,
        message: t('modal.create_contact_person.created'),
        variant: 'success',
      })
      onCreateContactPerson(response.contactPersons[0])
    },
  })

  const handleCreateContactPersonSubmitForm = useCallback(
    ({ firstName, lastName, email }: CreateContactPersonForm) => {
      createContactPerson({ payload: { firstName, lastName: lastName || '', email, contactId } })
    },
    [createContactPerson, contactId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleCreateContactPersonSubmitForm)()
  }, [handleSubmit, handleCreateContactPersonSubmitForm])

  return (
    <Modal id={ModalId.CreateContactPersonModal} size="s">
      <Modal.Header title={t('modal.create_contact_person.title')} />

      <Modal.Body>
        <Form>
          <FormItemsGroup rowSpacing={Spacing.XL}>
            <FormItem
              label={t('modal.create_contact_person.form.first_name')}
              name="firstName"
              render={({ field }) => <Input {...field} autoFocus />}
            />

            <FormItem
              label={t('modal.create_contact_person.form.last_name')}
              name="lastName"
              render={({ field }) => <Input {...field} />}
            />

            <FormItem
              label={t('modal.create_contact_person.form.email')}
              name="email"
              render={({ field }) => <Input {...field} />}
            />
          </FormItemsGroup>
        </Form>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={close} variant="text">
          {t('cancel')}
        </Button>
        <Button loading={isLoading} onClick={submitForm}>
          {t('save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
