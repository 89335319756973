import { EmberRoute } from '../../../enums/emberRoute'
import { SearchGloballyResponseData } from '../../menu/query-api'
import { SearchResultItem } from '../types/searchResultItem'

export const getSearchResultsItems = (data: SearchGloballyResponseData): SearchResultItem[] => {
  const items: SearchResultItem[] = []

  if (data.accounts) {
    for (const account of data.accounts) {
      items.push({
        id: account.id,
        text: account.name,
        category: 'account',
        routeTo: EmberRoute.Account,
        routeId: account.id,
      })
    }
  }

  if (data.bills) {
    for (const bill of data.bills) {
      items.push({
        id: bill.id,
        text: bill.id,
        category: 'bill',
        routeTo: EmberRoute.Bill,
        routeId: bill.id,
      })
    }
  }

  if (data.contacts) {
    for (const contact of data.contacts) {
      items.push({
        id: contact.id,
        text: contact.name,
        category: 'contact',
        routeTo: EmberRoute.Contact,
        routeId: contact.id,
      })
    }
  }

  if (data.invoices) {
    for (const invoice of data.invoices) {
      items.push({
        id: invoice.id,
        text: invoice.invoiceNo,
        category: 'invoice',
        routeTo: EmberRoute.Invoice,
        routeId: invoice.id,
      })
    }
  }

  if (data.organizations) {
    for (const organization of data.organizations) {
      items.push({
        id: organization.id,
        text: organization.name,
        category: 'organization',
        routeTo: EmberRoute.Organization,
        routeId: organization.id,
      })
    }
  }

  if (data.products) {
    for (const product of data.products) {
      items.push({
        id: product.id,
        text: product.name,
        category: 'product',
        routeTo: EmberRoute.Product,
        routeId: product.id,
      })
    }
  }

  return items
}
