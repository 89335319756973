import { TFunction } from 'i18next'
import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup'

import { DeductionSource } from '../../../../../../../app/taxrates/enums/deductionSource'
import { DeductionComponentForm } from '../../../../types/deductionComponentForm'
import { VatRateType } from '../../shared'

export const getCreateOrEditVatRateFormDefaultValues = (appliesTo?: VatRateType): CreateOrEditVatRateForm => ({
  name: '',
  description: '',
  abbreviation: '',
  netAmountMetaFieldId: '',
  appliesTo: appliesTo || VatRateType.Sales,
  isActive: true,
  rate: undefined,
  deductionComponents: undefined,
})

export const getDefaultDeductionComponent = () => ({
  share: 100,
  source: DeductionSource.Tax,
  accountId: '',
})

export interface CreateOrEditVatRateForm {
  name: string
  description: string | undefined
  abbreviation: string | undefined
  netAmountMetaFieldId: string | undefined
  appliesTo: VatRateType | undefined
  isActive: boolean
  rate: number | undefined
  deductionComponents: DeductionComponentForm[] | undefined
}

export type CreateOrEditVatRateFormSchema = SchemaOf<CreateOrEditVatRateForm>

export const getValidationSchema = (t: TFunction): CreateOrEditVatRateFormSchema => {
  return object({
    name: string().required(t('required_field')),
    description: string(),
    abbreviation: string().required(t('required_field')),
    netAmountMetaFieldId: string(),
    appliesTo: mixed<VatRateType>().oneOf(Object.values(VatRateType)),
    isActive: boolean().required(t('required_field')),
    rate: number().required(t('required_field')),
    deductionComponents: array()
      .of(
        object({
          share: number().required(t('required_field')),
          accountId: string().required(t('required_field')),
          source: mixed<DeductionSource>().oneOf(Object.values(DeductionSource)).required(t('required_field')),
        }),
      )
      .optional(),
  })
}
