import { CreditCardProps } from '@components'
import { notify } from '@design-system'

import { isPast } from 'date-fns'
import qs from 'qs'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { BinaryValue } from '../../../../../../types/binaryValue'
import { useUmbrella } from '../../../../../app/umbrellas'
import { useCurrentUser } from '../../../../../app/user'
import { variablesAddCard } from '../../constants/variablesAddCard'
import { useAddPaymentMethod } from '../../hooks/useAddPaymentMethod'
import { usePaymentMethod } from '../../hooks/usePaymentMethod'
import * as Styled from './styles'

interface QueryParams {
  callback: BinaryValue
  cancelled: BinaryValue
  successful: BinaryValue
}

const callbackUrlParams: Partial<QueryParams> = {
  callback: 1,
}

const cancelUrlParams: Partial<QueryParams> = {
  cancelled: 1,
}

const continueUrlParams: Partial<QueryParams> = {
  successful: 1,
}

export const PaymentMethod = (): ReactElement => {
  const { data, isLoading } = usePaymentMethod()
  const { umbrella } = useUmbrella()
  const { user } = useCurrentUser()
  const { t } = useTranslation()
  const { create } = useAddPaymentMethod({
    onSuccess: ({ data: { url: redirectUrl } }) => {
      window.location.href = redirectUrl
    },
  })

  const hasProvidedAddress =
    umbrella?.address.country && umbrella?.address.city && umbrella?.address.street && umbrella?.address.zipcode
  const hasProvidedCVR = umbrella?.registrationNo
  const hasSubscribed = !!data
  const isCardExpired = useMemo(
    () => hasSubscribed && isPast(new Date(data.ExpiryDate)),
    [data?.ExpiryDate, hasSubscribed],
  )

  const cardDetails: CreditCardProps | undefined = useMemo(
    () =>
      hasSubscribed
        ? {
            expirationDate: data.ExpiryDate,
            isInvalid: isCardExpired,
            label: data.FullName,
            type: data.Issuer,
          }
        : undefined,
    [data, hasSubscribed, isCardExpired],
  )

  const handleAddPaymentMethod = useCallback(() => {
    if (!user || !umbrella) {
      return
    }

    if (!hasProvidedAddress || !hasProvidedCVR) {
      notify({
        id: NotificationKeys.UmbrellaInvoicingDetails,
        message: t('umbrella.missing_invoicing_details'),
        variant: 'error',
      })
      return
    }

    create({
      amount: 0,
      callbackUrl: `${window.location.href}?${qs.stringify(callbackUrlParams)}`,
      cancelUrl: `${window.location.href}?${qs.stringify(cancelUrlParams)}`,
      continueUrl: `${window.location.href}?${qs.stringify(continueUrlParams)}`,
      country: umbrella.address.country || '',
      currency: 'DKK',
      language: user.locale,
      reusePaymentMethod: 0,
      startSubscription: 0,
      variables: variablesAddCard,
    })
  }, [create, hasProvidedAddress, hasProvidedCVR, t, umbrella, user])

  return (
    <Styled.PaymentMethodWrapper
      cardDetails={cardDetails}
      isCardExpired={isCardExpired}
      isLoading={isLoading}
      isSubscribed={hasSubscribed}
      onAddPaymentClick={handleAddPaymentMethod}
      onEditPaymentInformationClick={handleAddPaymentMethod}
      onUpdatePaymentClick={handleAddPaymentMethod}
    />
  )
}
