import { useUserOrganization } from '../modules/app/organization'

export const useOrganizationBrand = () => {
  const { organization } = useUserOrganization()

  const isBrandAgeras = organization?.brand === 'ageras'
  const isBrandBilly = organization?.brand === 'billy'

  return { isBrandAgeras, isBrandBilly }
}
