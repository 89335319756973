import produce from 'immer'
import { AnyAction } from 'redux'

import { AppState } from '../types'
import { CITIES_RECEIVED, CITIES_REQUESTED, ZIP_CODES_RECEIVED, ZIP_CODES_REQUESTED } from './actions'
import { AddressState } from './types'

const addressReducer = (state: AppState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case ZIP_CODES_REQUESTED:
      return produce(state, (draftState: AddressState) => {
        if (payload.page.offset !== 0) {
          return
        }
        draftState.zipCodes.list = null
        draftState.zipCodes.page = {
          offset: 0,
          total: null,
        }
      })
    case ZIP_CODES_RECEIVED:
      return produce(state, (draftState: AddressState) => {
        const zipCodes = payload.zipCodes
        if (payload.page.offset !== 0) {
          draftState.zipCodes.list = [...(draftState.zipCodes.list || []), ...zipCodes]
        } else {
          draftState.zipCodes.list = zipCodes
        }
        draftState.zipCodes.page = payload.page
      })
    case CITIES_REQUESTED:
      return produce(state, (draftState: AddressState) => {
        if (payload.page.offset !== 0) {
          return
        }
        draftState.cities.list = null
        draftState.cities.page = {
          offset: 0,
          total: null,
        }
      })
    case CITIES_RECEIVED:
      return produce(state, (draftState: AddressState) => {
        if (payload.page.offset !== 0) {
          draftState.cities.list = [...(draftState.cities.list || []), ...payload.cities]
        } else {
          draftState.cities.list = payload.cities
        }
        draftState.cities.page = payload.page
      })

    default:
      return state
  }
}

export default addressReducer
