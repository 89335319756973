import { BILL_RECEIVED, BILL_REQUESTED, BILLS_RECEIVED, BILLS_REQUESTED } from './actions'
import { IBillReceived, IBillRequested, IBillsReceived, IBillsRequested } from './types'

export function billRequested(billId: string): IBillRequested {
  return {
    payload: billId,
    type: BILL_REQUESTED,
  }
}

export function billReceived(bill: any): IBillReceived {
  return {
    payload: bill,
    type: BILL_RECEIVED,
  }
}

export function billsRequested(organizationId: string): IBillsRequested {
  return {
    payload: organizationId,
    type: BILLS_REQUESTED,
  }
}

export function billsReceived(bills: Array<any>): IBillsReceived {
  return {
    payload: bills,
    type: BILLS_RECEIVED,
  }
}
