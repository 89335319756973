import { Space } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { InvoicePaymentPane as InvoicePaymentPaneComponent } from '../../modules/invoices/elements/InvoicePaymentPane'

const MODULE_NAME = 'invoice-payment-pane-module'

class InvoicePaymentPaneWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @byAttrVal invoiceid: string
  @registerEvent('navigate') navigate: Function
}

type LockedProps = {
  invoiceid: string
  organizationid: string
  onNavigate: EmberNavigate
}

function InvoicePaymentPane({ invoiceid, organizationid, onNavigate }: LockedProps): ReactElement {
  return (
    <ThemeProvider>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <QueryClientProvider client={queryClient}>
          <UserOrganizationContextProvider organizationId={organizationid}>
            <InvoicePaymentPaneComponent invoiceId={invoiceid} />
            <Space size="m" />
          </UserOrganizationContextProvider>
        </QueryClientProvider>
      </EmberRouterContextProvider>
    </ThemeProvider>
  )
}

const InvoicePaymentPaneCustomElement = createCustomElement(
  InvoicePaymentPane,
  InvoicePaymentPaneWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, InvoicePaymentPaneCustomElement)

export default InvoicePaymentPaneCustomElement
