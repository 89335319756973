import { TFunction } from 'i18next'
import { object, SchemaOf, string } from 'yup'

import { Organization } from '../../../../app/organization/types/organization'

export interface EnableEInvoicingForm {
  cvr: string
}

export const getDefaultValues = (organization?: Organization): EnableEInvoicingForm => ({
  cvr: organization?.registrationNo || '',
})

export type EnableEInvoicingFormSchema = SchemaOf<EnableEInvoicingForm>

export const getValidationSchema = (t: TFunction): EnableEInvoicingFormSchema =>
  object({
    cvr: string().required(t('settings.organization.e_invoicing.register.cvr.required')),
  })
