import { ItemsPerPage, useItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { SortDirection } from '../../../../../enums/sortDirection'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { OrganizationsSortProperty } from '../../../../app/umbrellas/query-api'

interface UmbrellaOrganizationsListFilters {
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: OrganizationsSortProperty
}

type UseUmbrellaOrganizationsListFiltersResponse = [
  UmbrellaOrganizationsListFilters,
  (filters: Partial<UmbrellaOrganizationsListFilters>) => void,
]

const defaultQuery: UmbrellaOrganizationsListFilters = {
  page: 1,
  pageSize: undefined,
  searchQuery: undefined,
  sortDirection: undefined,
  sortProperty: undefined,
}

export const useUmbrellaOrganizationsListFilters = (): UseUmbrellaOrganizationsListFiltersResponse => {
  const [pageSize] = useItemsPerPage()
  const [queryParams, setQueryParams] = useQueryParams<UmbrellaOrganizationsListFilters>({
    defaultQuery: {
      ...defaultQuery,
      pageSize,
    },
  })

  return useMemo(
    () => [
      {
        ...queryParams,
        // Sometimes `page` & `pageSize` comes as string. It needs to be always `number` type.
        page: Number(queryParams.page),
        pageSize: Number(queryParams.pageSize) as ItemsPerPage,
      },
      setQueryParams,
    ],
    [queryParams, setQueryParams],
  )
}
