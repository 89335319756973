import { NavItem, NavListItemWithBlockDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { fetchPublicAccounts } from '../../../modules/app/accounts/query-api'
import { AccountNature, PublicAccount } from '../../../modules/app/accounts/types'
import { useUserOrganization } from '../../../modules/app/organization'
import { PublicAccountValue } from './types/publicAccountValue'
import { getDisplayTitle } from './utils/getDisplayTitle'
import { getItems } from './utils/getItems'

interface PublicAccountSelectProps
  extends Omit<SelectProps<PublicAccountValue>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: PublicAccount) => void
  natureId?: AccountNature
}

export const PublicAccountSelect = ({ onSelect, natureId, ...selectProps }: PublicAccountSelectProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const { data: publicAccounts, isLoading } = useQuery(
    [QueryKeys.PublicAccounts, organization?.id],
    () => fetchPublicAccounts(organization?.id as string),
    {
      enabled: !!organization?.id,
    },
  )

  const accountsFiltered = useMemo(() => {
    const accounts = publicAccounts?.publicAccounts || []

    if (!natureId) {
      return accounts
    }

    return accounts.filter((account) => account.nature.id === natureId)
  }, [publicAccounts, natureId])

  const items = useMemo(() => getItems(accountsFiltered, t), [accountsFiltered, t])

  const getDisplayValue = useCallback((item: NavItem<PublicAccountValue>) => getDisplayTitle(item.value), [])

  const handleSelect = useCallback(
    (id?: string, value?: PublicAccountValue) => {
      const valueFiltered = omit(value, 'displayData')
      onSelect?.(id, valueFiltered)
    },
    [onSelect],
  )

  return (
    <Select
      {...selectProps}
      dropdownFetching={isLoading}
      dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
      getDisplayValue={getDisplayValue}
      items={items}
      dropdownSize="fitTrigger"
      onSelect={handleSelect}
    />
  )
}
