export enum NotificationKeys {
  AccessDenied = 'access-denied',
  AccountArchive = 'account-archive',
  AccountDelete = 'account-delete',
  AccountingSettings = 'accounting-settings',
  AccountingSettingsLockDate = 'accounting-settings-lock-date',
  ActorCreate = 'actor-create',
  ActorDelete = 'actor-delete',
  AssetCreate = 'asset-create',
  BankReconciliationAllApprovedMatchesReconcile = 'bank-reconciliation-all-approved-matches-reconcile',
  BankReconciliationBulkReconcile = 'bank-reconciliation-bulk-reconcile',
  BankReconciliationHeaderExport = 'bank-reconciliation-header-export',
  BankReconciliationMatchReconcile = 'bank-reconciliation-match-reconcile',
  BankReconciliationMatchUnreconcile = 'bank-reconciliation-match-unreconcile',
  BankReconciliationTransactionCreatedAndReconcile = 'bank-reconciliation-transaction-created-and-reconcile',
  BillAttachmentFilesUpdate = 'bill-attachment-files-update',
  BillReconciliationError = 'bill-reconciliation-error',
  BillsList = 'bills-list',
  BillReconciliationSuccess = 'bill-reconciliation-success',
  ChangeEmailAddress = 'change-email-address',
  CopyableInfo = 'copyable-info',
  CreateContact = 'create-contact',
  CreateContactPerson = 'create-contact-person',
  CreateOrEditAdditionalFields = 'create-or-edit-additional-fields',
  CreateOrEditRulesets = 'create-or-edit-rulesets',
  CreateOrEditVatAccounts = 'create-or-edit-vat-accounts',
  CreateOrEditVatRates = 'create-or-edit-vat-rates',
  CurrencyDefaultPaymentMethodDelete = 'currency-default-payment-method-delete',
  CurrencyDefaultPaymentMethodUpdate = 'currency-default-payment-method-update',
  CustomersInvitations = 'customers-invitations',
  DashboardCustomization = 'dashboard-customization',
  DashboardWidgetBillsUpload = 'dashboard-widget-bills-upload',
  DashboardWidgetBillsUploadFileRejections = 'dashboard-widget-bills-upload-file-rejections',
  DashboardWidgetTaskApprove = 'dashboard-widget-task-approve',
  DashboardWidgetTaskComplete = 'dashboard-widget-task-complete',
  DashboardWidgetTaskCreate = 'dashboard-widget-task-create',
  DashboardWidgetVat = 'dashboard-widget-vat',
  DebtCollectionServiceDisabled = 'debt-collection-service-disabled',
  DebtCollectionServiceEnabled = 'debt-collection-service-enabled',
  EInvoiceCancel = 'e-invoice-cancel',
  EInvoiceSave = 'e-invoice-save',
  EInvoiceSend = 'e-invoice-send',
  FailureSubmit = 'failure-submit',
  FinancingApprovalError = 'financing-approval-error',
  FinancingLoanRequest = 'financing-loan-request',
  FindAccountant = 'find-accountant',
  InvoiceEmailSend = 'invoice-email-send',
  InvoiceSettingsUpdate = 'invoice-settings-update',
  InvoiceTemplateCreate = 'invoice-template-create',
  InvoiceTemplateDelete = 'invoice-template-delete',
  InvoiceTemplateDuplicate = 'invoice-template-duplicate',
  InvoiceTemplateSetAsDefault = 'invoice-template-set-as-default',
  LoginError = 'login-error',
  ModalDelete = 'modal-delete',
  ModalDiscard = 'modal-discard',
  ModalMoreInfo = 'modal-more-info',
  OrganizationIconDelete = 'organization-icon-delete',
  OrganizationIconUpload = 'organization-icon-upload',
  OrganizationIconUploadRejection = 'organization-icon-upload-rejection',
  OrganizationInvitation = 'organization-invitation',
  OrganizationLogoDelete = 'organization-logo-delete',
  OrganizationLogoUpload = 'organization-logo-upload',
  OrganizationLogoUploadRejection = 'organization-logo-upload-rejection',
  OrganizationReset = 'organization-reset',
  OrganizationSettingsUpdate = 'organization-settings-update',
  OrganizationSubscriptionCouponRedeem = 'organization-subscription-coupon-redeem',
  OrganizationSubscriptionPeriodChange = 'organization-subscription-period-change',
  OrganizationSubscriptionUpdate = 'organization-subscription-update',
  PasswordChangeAcceptance = 'password-change-acceptance',
  PasswordChangeRejection = 'password-change-rejection',
  PaymentMethodDelete = 'payment-method-delete',
  PaymentMethodCreateError = 'payment-method-create-error',
  ProfilePicUploadAcceptance = 'profile-pic-upload-acceptance',
  ProfilePicUploadRejection = 'profile-pic-upload-rejection',
  ReceiptsList = 'receipts-list',
  RecurringInvoicesSpawnEnable = 'recurring-invoices-spawn-enable',
  RemoveMobilePayPaymentMethod = 'remove-mobilepay-payment-method',
  SalesTaxSalesWithoutVat = 'sales-tax-sales-without-vat',
  SettingsUserProfile = 'settings-user-profile',
  SignupUserError = 'signup-user-error',
  SkatRequest = 'skat-request',
  SubscriptionPaymentCancelled = 'subscription-payment-cancelled',
  SubscriptionPaymentFailed = 'subscription-payment-failed',
  SubscriptionPaymentSuccessful = 'subscription-payment-successful',
  SubscriptionStripe = 'subscription-stripe',
  ThingsToDoMissingInfo = 'things-to-do-missing-info',
  ThingsToDoMissingReceipts = 'things-to-do-missing-receipts',
  ThingsToDoMissingReceiptsUpdate = 'things-to-do-missing-receipts-update',
  TransferOwnership = 'transfer-ownership',
  UmbrellaInvoicingDetails = 'umbrella-invoicing-details',
  UmbrellaOrganizationChangeSubscription = 'umbrella-organization-change-subscription',
  UmbrellaOrganizationUpdateVoucherInbox = 'umbrella-organization-update-voucher-inbox',
  UmbrellaOrganizationDelete = 'umbrella-organization-delete',
  UmbrellaOrganizationUpdateConsolidatedBilling = 'umbrella-organization-update-consolidated-billing',
  UmbrellaProfileForm = 'umbrella-profile-form',
  UmbrellaProfileLogoUploadAcceptance = 'umbrella-profile-logo-acceptance',
  UmbrellaProfileLogoUploadRejection = 'umbrella-profile-logo-upload',
  UmbrellaRolesDelete = 'umbrella-roles-delete',
  UmbrellaRoleUpdate = 'umbrella-role-update',
  UmbrellaSubscriptionsPaymentStatus = 'umbrella-subscriptions-payment-status',
  UmbrellaTypeform = 'umbrella-typeform',
  UmbrellaUserDelete = 'umbrella-user-delete',
  UmbrellaUserInvitation = 'umbrella-user-invitation',
  UmbrellaUserInvitationDelete = 'umbrella-user-invitation-delete',
  UmbrellaUserUpdate = 'umbrella-user-update',
  UpdateContact = 'update-contact',
  UploadFiles = 'upload-files',
  VoucherInboxArchive = 'voucher-inbox-archive',
  VoucherInboxBillCreate = 'voucher-inbox-bill-create',
  VoucherInboxBulkDelete = 'voucher-inbox-bulk-delete',
  VoucherInboxDelete = 'voucher-inbox-delete',
  VoucherInboxDiscard = 'voucher-inbox-discard',
  VoucherInboxDublicate = 'voucher-inbox-dublicate',
  VoucherInboxEntryReconcile = 'inbox-entry-reconcile',
  VoucherInboxFetch = 'voucher-inbox-fetch',
  VoucherInboxInfoRequest = 'voucher-inbox-info-request',
  VoucherInboxJounalCreate = 'voucher-inbox-journal-create',
  VoucherInboxListFetch = 'voucher-inbox-list-fetche',
  VoucherInboxPostpone = 'voucher-inbox-postpone',
  VoucherInboxReceive = 'voucher-inbox-receive',
  VoucherInboxUpdate = 'voucher-inbox-update',
}
