import { ButtonPremium, ProtectedComponent } from '@components'
import { notify } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { useEmberRouter } from '../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'
import { useUserOrganization } from '../../../../../../../app/organization'
import { useCreateInvoiceTemplate } from '../../hooks/useCreateInvoiceTemplate'
import { useGetCreateInvoiceTemplatePayload } from '../../hooks/useGetCreateInvoiceTemplatePayload'

export const InvoiceTemplatesSectionSideActions = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { createInvoiceTemplatePayload } = useGetCreateInvoiceTemplatePayload()
  const { navigate } = useEmberRouter()
  const { create: createTemplate, isProcessing: isCreating } = useCreateInvoiceTemplate({
    onSuccess: (createInvoiceTemplateData) => {
      const newTemplateId = createInvoiceTemplateData?.data?.id

      navigate(EmberRoute.InvoiceTemplate, newTemplateId)
      notify({
        id: NotificationKeys.InvoiceTemplateCreate,
        message: t('settings.organization.invoice.templates_settings.table.actions.add_new.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.InvoiceTemplates)
    },
    onError: () => {
      notify({ id: NotificationKeys.InvoiceTemplateCreate, message: t('error_saving'), variant: 'error' })
    },
  })

  const handleNewTemplateButtonClick = useCallback(() => {
    if (!organization?.id || !createInvoiceTemplatePayload) {
      return
    }

    createTemplate({ invoiceTemplate: createInvoiceTemplatePayload, organizationId: organization.id })
  }, [createTemplate, createInvoiceTemplatePayload, organization?.id])

  return (
    <ProtectedComponent scopes={Scope.InvoiceDesignerWrite}>
      <ButtonPremium
        icon="plusCircle"
        disabled={!createInvoiceTemplatePayload}
        loading={isCreating}
        onClick={handleNewTemplateButtonClick}
        trackingContext={TrackingContext.InvoiceTemplates}
        variant="secondary"
      >
        {t('settings.organization.invoice.templates_settings.new_template_button')}
      </ButtonPremium>
    </ProtectedComponent>
  )
}
