import { Input, SkeletonBox } from '@design-system'

import React, { ReactElement, useMemo } from 'react'

import { useAccounts } from '../../../../../../../../../../app/accounts'

interface AccountInfoFieldProps {
  value?: string
}

export const AccountInfoField = ({ value }: AccountInfoFieldProps): ReactElement => {
  const { accounts, isLoading } = useAccounts()

  const displayValue = useMemo(() => {
    if (!accounts || isLoading) {
      return ''
    }

    const account = accounts.find((account) => {
      return account.systemRole === value
    })

    if (!account) {
      return ''
    }

    return `${account.accountNo} - ${account.name}`
  }, [accounts, isLoading, value])

  if (isLoading) {
    return <SkeletonBox height={40} fullWidth />
  }

  return <Input value={displayValue} disabled />
}
