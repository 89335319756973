import { ButtonDropdown, NavItem } from '@design-system'

import { ReactElement, useCallback, useEffect, useMemo } from 'react'

import { Account } from '../../../../../app/accounts/types'
import { useBankWidget } from '../../../../contexts/bankWidgetContext'
import * as Styled from './styles'

interface BankSelectionProps {
  accountId?: string
  bankAccounts?: Account[]
}

export const BankSelection = ({ accountId, bankAccounts }: BankSelectionProps): ReactElement => {
  const { selectedBankAccountId, setSelectedBankAccountId } = useBankWidget()

  useEffect(() => {
    if (accountId && selectedBankAccountId !== accountId) {
      setSelectedBankAccountId(accountId)
    }
  }, [accountId, selectedBankAccountId, setSelectedBankAccountId])

  const items: NavItem[] = useMemo(
    () =>
      bankAccounts?.map((bank) => {
        return {
          id: bank.id,
          children: bank.name,
          value: bank.name,
        }
      }) || [],
    [bankAccounts],
  )

  const handleBankSelection = useCallback(
    (id: string) => {
      setSelectedBankAccountId(id)
    },
    [setSelectedBankAccountId],
  )

  return (
    <Styled.DropdownWrapper>
      {items.length > 1 && (
        <ButtonDropdown
          fullWidth
          items={items}
          onSelect={handleBankSelection}
          selectedId={selectedBankAccountId}
          size="m"
          variant="secondary"
        />
      )}
    </Styled.DropdownWrapper>
  )
}
