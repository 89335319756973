import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { fetchBalanceSheet } from '../query-api'
import { BalanceSheetReportOptions } from '../types/reportOptions'

export const useFetchBalanceSheetReport = (options: BalanceSheetReportOptions) => {
  const { organization } = useUserOrganization()

  const { data, ...rest } = useQuery(
    [QueryKeys.BalanceSheet, { organizationId: organization?.id, ...options }],
    () => fetchBalanceSheet({ organizationId: organization?.id as string, options }),
    {
      enabled: !!organization?.id,
      staleTime: defaultStaleTime,
    },
  )

  return { report: data?.report, ...rest }
}
