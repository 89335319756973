import compact from 'lodash/compact'
import React, {
  cloneElement,
  forwardRef,
  ReactElement,
  Ref,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'

import { Dropdown, DropdownProps, DropdownRef } from '../Dropdown'
import { getRealItems, NavItem, NavItemAccessor, NavList, NavListProps, useNavItemsNavigation } from '../NavList'
import { Search } from './elements/Search'
import { useDropdownHeight } from './hooks/useDropdownHeight'
import * as Styled from './styles'
import { DropdownFooterProps } from './types/dropdownFooterProps'
import { getItemsNavigable } from './utils/getItemsNavigable'
import { getItemsWithSubItemBreadcrumb } from './utils/getItemsWithSubItemBreadcrumb'
import { getParentNavItem } from './utils/getParentNavItem'
import { hasNavItemSubItemSelected } from './utils/hasNavItemSubItemSelected'
import { isNavItemExpandable } from './utils/isNavItemExpandable'

export interface DropdownNavListProps<T>
  extends Pick<
      DropdownProps,
      | 'id'
      | 'autoToggle'
      | 'className'
      | 'trigger'
      | 'maxHeight'
      | 'onClick'
      | 'onClose'
      | 'onKeyDown'
      | 'onMouseDown'
      | 'onOpen'
      | 'placement'
      | 'size'
      | 'width'
    >,
    Pick<
      NavListProps<T>,
      | 'expandedIds'
      | 'isFetching'
      | 'itemRender'
      | 'items'
      | 'notFoundContent'
      | 'onItemClick'
      | 'onScrollEnd'
      | 'scrollEndOffset'
      | 'selectedId'
      | 'subItemsMode'
    > {
  /** footer element below items */
  footer?: ReactElement
  /** header element above items */
  header?: ReactElement
  keepHeightOnSearching?: boolean
  isFetching?: boolean
  /** allows to toggle select from the parent component */
  isOpen?: boolean
  onSearch?: (value: string) => void
  /** if there's only one subitem, select it when clicking on parent  **/
  selectFirstAndOnlySubItem?: boolean
  /** allows to navigate through items using keyboard */
  withNavigation?: boolean
  /** additional search input above items */
  withSearch?: boolean
  onExpandableItemClick?: () => void
}

const DropdownNavListForwarded = <T,>(
  {
    autoToggle = true,
    className,
    expandedIds: expandedIdsControlled,
    footer,
    header,
    keepHeightOnSearching,
    id,
    isFetching,
    isOpen: isOpenControlled = false,
    itemRender,
    items,
    maxHeight = 'fullScreen',
    notFoundContent,
    onClick,
    onExpandableItemClick,
    onMouseDown,
    onClose,
    onItemClick,
    onKeyDown,
    onOpen,
    onScrollEnd,
    onSearch,
    selectFirstAndOnlySubItem = false,
    placement = 'auto',
    scrollEndOffset,
    selectedId,
    size = 'l',
    subItemsMode = 'vertical',
    trigger,
    width,
    withNavigation = true,
    withSearch = false,
  }: DropdownNavListProps<T>,
  forwardedRef: Ref<DropdownRef<T>>,
) => {
  const [expandedIds, setExpandedIds] = useState<string[]>(expandedIdsControlled || [])
  const [isOpen, setIsOpen] = useState(isOpenControlled)
  const [dropdownListElement, setDropdownListElement] = useState<HTMLElement | null>(null)
  const dropdownRef = useRef<DropdownRef<T>>(null)

  const dropdownHeight = useDropdownHeight(dropdownListElement, isOpen && withSearch && keepHeightOnSearching) // it's for keeping height when search is used and amount of items is reduced (we want dropdown to keep its size)

  // Items decorated (currently we add additional breadcrumb header's item to be able to get back to the parent item)
  const itemsDecorated = useMemo(
    () => (subItemsMode === 'horizontal' ? getItemsWithSubItemBreadcrumb(items) : items),
    [items, subItemsMode],
  )
  // Items visible for the user, so we could navigate through them using keyboard
  const itemsNavigable = useMemo(
    () => getItemsNavigable({ items: itemsDecorated, expandedIds, subItemsMode }),
    [itemsDecorated, expandedIds, subItemsMode],
  )

  useImperativeHandle(forwardedRef, () => dropdownRef.current as DropdownRef<T>)

  // Mutations

  useEffect(() => {
    setIsOpen(isOpenControlled)
  }, [isOpenControlled])

  useEffect(() => {
    setExpandedIds(expandedIdsControlled || [])
  }, [expandedIdsControlled])

  useEffect(() => {
    const item = selectedId ? getParentNavItem(items, selectedId) : undefined

    if (item) {
      const expandedIdsUpdated = compact([...expandedIds, item.id])
      setExpandedIds(expandedIdsUpdated)
    } else {
      setExpandedIds(expandedIdsControlled || [])
    }

    // should only fire on "selectedId" and "isOpen" change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId, isOpen])

  useEffect(() => {
    if (dropdownListElement && dropdownHeight) {
      dropdownListElement.style.height = `${dropdownHeight}px`
    } else if (dropdownListElement) {
      dropdownListElement.style.height = 'initial'
    }
  }, [dropdownListElement, dropdownHeight])

  useEffect(() => {
    const dropdownListElement = dropdownRef.current?.dropdown

    if (dropdownListElement) {
      setDropdownListElement(dropdownListElement)
    }
  }, [dropdownRef])

  // Functions

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleOpen = useCallback(() => {
    setIsOpen(true)
    onOpen?.()
  }, [onOpen])

  const handleClose = useCallback(() => {
    setIsOpen(false)
    onClose?.()
  }, [onClose])

  const handleItemClick = useCallback(
    (id: string, value: T) => {
      if (!id) {
        return
      }

      const item = getParentNavItem(items, id)

      // clicking on HeaderBreadcrumb should just get back to the root list
      if (item?.accessor === NavItemAccessor.HeaderBreadcrumb) {
        setExpandedIds([])
        return
      }

      const isExpandable = isNavItemExpandable(items, id)

      if (isExpandable) {
        const hasSubItemSelected = hasNavItemSubItemSelected(items, id, selectedId)

        // if subItem is selected and user clicks on the parent item in 'vertical' mode, than do nothing
        if (hasSubItemSelected && subItemsMode === 'vertical') {
          return
        }

        // select first subItem when there is only one available
        if (selectFirstAndOnlySubItem && subItemsMode === 'horizontal') {
          const item = items.find((item) => item.id === id)
          const realSubItems = item?.subItems?.length ? getRealItems(item.subItems) : undefined

          if (realSubItems?.length === 1) {
            const subItem = realSubItems[0]
            onItemClick?.(subItem.id, subItem.value)
            setExpandedIds([])
            closeDropdown()
            return
          }
        }

        // toggle id
        const expandedIdsUpdated = expandedIds.includes(id)
          ? [...expandedIds.filter((expandedId) => expandedId !== id)]
          : [...expandedIds, id]

        if (expandedIdsUpdated.length) {
          onExpandableItemClick?.()
        }
        setExpandedIds(expandedIdsUpdated)
        return
      }

      onItemClick?.(id, value)
      setExpandedIds([])
      closeDropdown()
    },

    [
      closeDropdown,
      expandedIds,
      items,
      onItemClick,
      onExpandableItemClick,
      selectedId,
      selectFirstAndOnlySubItem,
      subItemsMode,
    ],
  )

  const handleNavItemClick = useCallback(
    (item: NavItem<T>) => {
      const { id, value } = item
      handleItemClick(id, value)
    },
    [handleItemClick],
  )

  const handleFooterItemClick = useCallback(() => {
    closeDropdown()
  }, [closeDropdown])

  // Events hooks

  const focusedId = useNavItemsNavigation({
    items: itemsNavigable,
    isActive: withNavigation && isOpen,
    onItemSelect: handleNavItemClick,
    selectedId,
  })

  return (
    <Dropdown
      autoToggle={autoToggle}
      className={className}
      id={id}
      isOpen={isOpen}
      maxHeight={maxHeight}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onClose={handleClose}
      onKeyDown={onKeyDown}
      onOpen={handleOpen}
      placement={placement}
      ref={dropdownRef}
      size={size}
      trigger={trigger}
      width={width}
    >
      <Styled.NavWrapper>
        {withSearch && <Search onChange={onSearch} />}
        {header && <Styled.NavHeader>{header}</Styled.NavHeader>}

        <NavList<T>
          expandedIds={expandedIds}
          focusedId={withNavigation ? focusedId : undefined}
          isFetching={isFetching}
          itemRender={itemRender}
          items={itemsDecorated}
          notFoundContent={notFoundContent}
          onItemClick={handleItemClick}
          onScrollEnd={onScrollEnd}
          scrollEndOffset={scrollEndOffset}
          selectedId={selectedId}
          subItemsMode={subItemsMode}
        />

        {footer && (
          <Styled.NavFooter>
            {cloneElement(footer, { onFooterItemClick: handleFooterItemClick } as DropdownFooterProps)}
          </Styled.NavFooter>
        )}
      </Styled.NavWrapper>
    </Dropdown>
  )
}

export const DropdownNavList = forwardRef(DropdownNavListForwarded) as <T, K>(
  props: DropdownNavListProps<T> & { ref?: Ref<DropdownRef<K>> },
) => ReactElement
