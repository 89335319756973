import { SelectTaxRatesProps, TaxRate } from '../../../../modules/app/taxrates/types'

export const filterTaxRates = (taxRates: TaxRate[], filters?: SelectTaxRatesProps): TaxRate[] => {
  const { purchase = false, sale = false } = filters || {}

  return taxRates.filter((taxRate) => {
    if (purchase === sale) {
      return true
    }

    if (purchase && taxRate.appliesToPurchases) {
      return true
    }

    if (sale && taxRate.appliesToSales) {
      return true
    }

    return false
  })
}
