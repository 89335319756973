import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { EditableProperties } from 'src/modules/settings/routes/VatRatesSettings/types/editableProperties'

import { queryClient } from '../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../../hooks'
import { APIError } from '../../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../../utils/getErrorMessage'
import { AccountsContextProvider } from '../../../../../../../app/accounts'
import { useUserOrganization } from '../../../../../../../app/organization'
import { useTaxRates } from '../../../../../../../app/taxrates'
import { TaxRate } from '../../../../../../../app/taxrates/types'
import { fetchOneVatRateTemplate } from '../../../../query-api'
import { VatRateFromTemplateFormData } from '../../../../types/vatRateFromTemplateFormData'
import { VatRateTemplateResponseData } from '../../../../types/vatRateTemplateResponseData'
import { fractionToPercent } from '../../../../utils/fractionToPercent'
import { removeIdAndTaxRateId, shareFromFractionToPercent, VatRateType } from '../../shared'
import { useCreateVatRate } from '../hooks/useCreateVatRate'
import { useUpdateVatRate } from '../hooks/useUpdateVatRate'
import {
  CreateOrEditVatRateFromTemplateForm,
  getCreateOrEditVatRateFormDefaultValues,
  getValidationSchema,
} from '../utils/formData'
import { getRateData } from './utils/getRateData'
import { mergeDeductionComponentsFromVatRateAndTemplate } from './utils/mergeDeductionComponentsFromVatRateAndTemplate'

interface EnabledFields extends EditableProperties {
  isActive?: boolean
}

interface ContextState {
  enabledFields: EnabledFields
  Form: FormComponent
  isDisabled: boolean
  isEditMode: boolean
  isLoading: boolean
  isLoadingTemplate: boolean
  isVatRateFormVisible: boolean
  modalBodyElement: HTMLDivElement | undefined
  submitForm: () => void
  template: VatRateTemplateResponseData | undefined
  vatRate: TaxRate | undefined
}

const CreateOrEditVatRateFromTemplateModalContext = createContext<ContextState | undefined>(undefined)

interface CreateOrEditVatRateFromTemplateModalContextProps {
  children: ReactNode
  vatRateId?: string
  scrollContainerElement: HTMLDivElement | undefined
  modalId: string
  disabled?: boolean
  appliesTo?: VatRateType
}

export const CreateOrEditVatRateFromTemplateModalContextProvider = ({
  children,
  vatRateId,
  scrollContainerElement: modalBodyElement,
  modalId,
  disabled,
  appliesTo,
}: CreateOrEditVatRateFromTemplateModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { taxRatesIncludingDeactivated = [] } = useTaxRates()

  const defaultValues = useMemo(() => getCreateOrEditVatRateFormDefaultValues(appliesTo), [appliesTo])

  const { Form, handleSubmit, reset, watch } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const predefinedTag = watch('predefinedTag')

  const { data: template, isLoading: isLoadingTemplate } = useQuery(
    [QueryKeys.VatRatesTemplates, organization?.id, predefinedTag],
    () => fetchOneVatRateTemplate({ organizationId: organization?.id as string, vatRateId: predefinedTag as string }),
    {
      enabled: !!predefinedTag && !!organization?.id,
    },
  )

  const enabledFields: EditableProperties = useMemo(() => {
    if (!template || !template.editableProperties) {
      return {}
    }

    return { ...template.editableProperties, isActive: true }
  }, [template])

  const { close } = useModal(modalId, {
    onOpen: () => {
      reset(defaultValues)
    },
  })

  const vatRate = useMemo(() => {
    return taxRatesIncludingDeactivated.find(({ id }) => id === vatRateId)
  }, [taxRatesIncludingDeactivated, vatRateId])

  const isEditMode = !!vatRateId

  useEffect(() => {
    if (template?.taxRateTemplate && !isEditMode) {
      const { abbreviation, deductionComponents, rate, description, name } = template.taxRateTemplate

      const data: CreateOrEditVatRateFromTemplateForm = {
        predefinedTag,
        abbreviation,
        deductionComponents: shareFromFractionToPercent(deductionComponents),
        description,
        name,
        isActive: true,
        netAmountMetaFieldId: defaultValues.netAmountMetaFieldId,
        rate: fractionToPercent(rate || 0),
        appliesTo: template.taxRateTemplate.taxRateApplication === 'Sales' ? VatRateType.Sales : VatRateType.Purchase,
      }

      reset(data)
    }
  }, [template, reset, predefinedTag, defaultValues.netAmountMetaFieldId, appliesTo, isEditMode])

  useEffect(() => {
    if (!vatRate || isLoadingTemplate) {
      return
    }

    const {
      predefinedTag,
      abbreviation,
      deductionComponents,
      netAmountMetaFieldId,
      rate,
      description,
      name,
      isActive,
      appliesToSales,
    } = vatRate

    // migration from old vat rate using template
    // merge tax rate and template
    if (template) {
      const { taxRateTemplate, editableProperties } = template

      const mergedDeductionComponents = mergeDeductionComponentsFromVatRateAndTemplate(vatRate, template)

      const data: CreateOrEditVatRateFromTemplateForm = {
        predefinedTag: taxRateTemplate.taxRateId,
        abbreviation: taxRateTemplate.abbreviation,
        deductionComponents: mergedDeductionComponents,
        description: taxRateTemplate.description,
        name: taxRateTemplate.name,
        isActive,
        netAmountMetaFieldId: taxRateTemplate.taxSummaryLine?.abbreviation || netAmountMetaFieldId || undefined,
        rate: editableProperties.rate ? fractionToPercent(rate) : fractionToPercent(taxRateTemplate.rate || 0),
        appliesTo: template.taxRateTemplate.taxRateApplication === 'Sales' ? VatRateType.Sales : VatRateType.Purchase,
      }

      return reset(data)
    }

    // load only vat rate
    const filteredDeductionComponents = deductionComponents.map((item) => removeIdAndTaxRateId(item))

    const data: CreateOrEditVatRateFromTemplateForm = {
      predefinedTag: predefinedTag || undefined,
      abbreviation,
      deductionComponents: shareFromFractionToPercent(filteredDeductionComponents),
      description,
      name,
      isActive,
      netAmountMetaFieldId: netAmountMetaFieldId || undefined,
      rate: fractionToPercent(rate),
      appliesTo: appliesToSales ? VatRateType.Sales : VatRateType.Purchase,
    }

    return reset(data)
  }, [reset, taxRatesIncludingDeactivated, vatRate, vatRateId, template, isLoadingTemplate])

  const handleCreateOrEditError = useCallback(
    (error?: APIError) => {
      const message = getErrorMessage(error, 'salesTaxAccount') || t('error_saving')
      notify({ id: NotificationKeys.CreateOrEditVatRates, message, variant: 'error' })
    },
    [t],
  )

  const handleCreateOrEditSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.TaxRates)
    close()
  }, [close])

  const { create, isLoading: isCreateProcessing } = useCreateVatRate({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const { update, isLoading: isEditProcessing } = useUpdateVatRate({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const handleSubmitForm = useCallback(
    (formData: CreateOrEditVatRateFromTemplateForm) => {
      const organizationId = organization?.id

      const { predefinedTag, isActive } = formData

      if (!predefinedTag || !organizationId) {
        throw new Error('No organizationId')
      }

      if (!template) {
        throw new Error('Error downloading rate template')
      }

      const data = getRateData(formData, template)

      if (isEditMode) {
        return update({
          id: vatRateId,

          payload: {
            ...data,
            predefinedTag,
            organizationId,
          },
        })
      }

      const newRate: VatRateFromTemplateFormData = {
        ...data,
        predefinedTag,
        organizationId,
        isActive,
      }

      return create(newRate)
    },
    [organization?.id, isEditMode, template, create, update, vatRateId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  const isLoading = isEditProcessing || isCreateProcessing

  // form is disabled if
  // - intentionally disabled,
  // - template is selected (certain fields can be still enabled using enabledFields)
  // - template is being loded
  // - tax rate is being edited but template (predefinedTag) is not selected - migration scenario
  const isDisabled = !!disabled || !!template || isLoadingTemplate || (isEditMode && !predefinedTag)

  // main form should be visible always if
  // - in edit mode
  // - template (predefinedTag) is selected
  const isVatRateFormVisible = isEditMode || (!isEditMode && !!predefinedTag)

  return (
    <CreateOrEditVatRateFromTemplateModalContext.Provider
      value={{
        enabledFields,
        Form,
        isDisabled,
        isEditMode,
        isLoading,
        isLoadingTemplate,
        isVatRateFormVisible,
        modalBodyElement,
        submitForm,
        template,
        vatRate,
      }}
    >
      <AccountsContextProvider organizationId={organization?.id}>{children}</AccountsContextProvider>
    </CreateOrEditVatRateFromTemplateModalContext.Provider>
  )
}

export const useCreateOrEditVatRateFromTemplateModal = () => {
  const context = useContext(CreateOrEditVatRateFromTemplateModalContext)

  if (!context) {
    throw new Error('CreateOrEditVatRateFromTemplateModalContextProvider is missing in the module!')
  }

  return context
}
