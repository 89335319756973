import { TFunction } from 'i18next'
import { array, boolean, mixed, number, object, SchemaOf, string } from 'yup'

import { DeductionSource } from '../../../../../../../app/taxrates/enums/deductionSource'
import { DeductionComponentForm } from '../../../../types/deductionComponentForm'
import { VatRateType } from '../../shared'

export const getCreateOrEditVatRateFormDefaultValues = (
  appliesTo?: VatRateType,
): CreateOrEditVatRateFromTemplateForm => ({
  predefinedTag: undefined,
  name: '',
  description: '',
  abbreviation: '',
  netAmountMetaFieldId: undefined,
  appliesTo: appliesTo || VatRateType.Sales,
  isActive: true,
  rate: 0,
  deductionComponents: undefined,
})

export const getDefaultDeductionComponent = () => ({
  share: 100,
  source: DeductionSource.Tax,
  accountId: '',
})

export interface CreateOrEditVatRateFromTemplateForm {
  predefinedTag: string | undefined
  name: string
  description: string | undefined
  abbreviation: string | undefined
  netAmountMetaFieldId: string | undefined
  appliesTo: VatRateType | undefined
  isActive: boolean
  rate: number | undefined
  deductionComponents: DeductionComponentForm[] | undefined
}

export type CreateOrEditVatRateFromTemplateFormSchema = SchemaOf<CreateOrEditVatRateFromTemplateForm>

export const getValidationSchema = (t: TFunction): CreateOrEditVatRateFromTemplateFormSchema => {
  return object({
    predefinedTag: string().required(t('required_field')),
    name: string().required(t('required_field')),
    description: string(),
    abbreviation: string(),
    netAmountMetaFieldId: string(),
    appliesTo: mixed<VatRateType>().oneOf(Object.values(VatRateType)),
    isActive: boolean().required(t('required_field')),
    rate: number().required(t('required_field')),
    deductionComponents: array()
      .of(
        object({
          share: number().required(t('required_field')),
          source: mixed<DeductionSource>().oneOf(Object.values(DeductionSource)).required(t('required_field')),
          // no accountId here because we have a path where deduction components are predefined and we don't have accountId there
          // accountId is validated by backend in other case (when user can add deduction components and they are not predefined)
        }),
      )
      .optional(),
  })
}
