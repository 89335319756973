import { CompanySelect } from '@components'
import { Button, ButtonsGroup, Modal, ModalProps, Space, Text, useModal } from '@design-system'

import isEmpty from 'lodash/isEmpty'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { useEmberCloseModal } from '../../../../../../contexts/emberCloseModalContext'
import { getImageClassName } from '../../../../../../utils/getClassName'
import { getImgPath } from '../../../../../../utils/getImgPath'
import { useFetchCompany } from '../../../../../app/companies/hooks/useFetchCompany'
import { Company } from '../../../../../app/companies/types'
import { useUserOrganization } from '../../../../../app/organization'
import { MOBILE_PAY_ACCOUNT_NAME, MOBILE_PAY_ACCOUNT_NUMBER } from '../../../../constants/mobilePayAccountProps'
import { usePaymentMethodAccount } from '../../../../hooks/usePaymentMethodAccount'
import { useActivateMobilePay } from '../../hooks/useActivateMobilePay'
import { showPaymentMethodErrorNotification } from '../../utils/showPaymentMethodErrorNotification'
import * as Styled from './styles'

const getCompanySelectDisplayValue = (company: Partial<Company>): string => {
  return company?.id || 'undefined'
}

export const EnableMobilePayModal = ({ ...modalProps }: ModalProps): ReactElement => {
  const { t } = useTranslation()
  const [cvr, setCvr] = useState('')
  const { account: mobilePayAccount, isLoading: isLoadingMobilePayAccount } = usePaymentMethodAccount({
    accountName: MOBILE_PAY_ACCOUNT_NAME,
    accountNumber: MOBILE_PAY_ACCOUNT_NUMBER,
  })
  const { closeEmberModal } = useEmberCloseModal()
  const { organization } = useUserOrganization()
  const { company, isLoading: isLoadingCompany } = useFetchCompany(cvr)
  const { activateMobilePay, isProcessing } = useActivateMobilePay({
    onSuccess: () => {
      closeEnableMobilePayModal()
    },
    onError: () => {
      showPaymentMethodErrorNotification(t)
    },
  })

  const handleModalClose = useCallback(() => {
    closeEmberModal()
  }, [closeEmberModal])

  const { close: closeEnableMobilePayModal } = useModal(modalProps.id, { onClose: handleModalClose })

  const isActivateButtonDisabled = useMemo(() => {
    return isLoadingCompany || isEmpty(company) || isLoadingMobilePayAccount
  }, [isLoadingCompany, company, isLoadingMobilePayAccount])

  useEffect(() => {
    if (organization?.registrationNo) {
      setCvr(organization.registrationNo)
    }
  }, [organization?.registrationNo])

  const handleActivateButtonClick = useCallback(() => {
    activateMobilePay(mobilePayAccount?.id)
  }, [activateMobilePay, mobilePayAccount?.id])

  const handleCancelButtonClick = useCallback(() => {
    closeEnableMobilePayModal()
  }, [closeEnableMobilePayModal])

  const handleCompanySelect = useCallback((companyId?: string) => {
    if (companyId) {
      setCvr(companyId)
    }
  }, [])

  return (
    <Modal size="s" closable {...modalProps}>
      <Modal.Header title={t('integrations.card.mobile_pay.modal.title')} />
      <Modal.Body>
        <Styled.ModalImageWrapper className={getImageClassName()}>
          <img src={getImgPath('/modals/mobilepay-logo.svg')} alt="MobilePay logo" />
        </Styled.ModalImageWrapper>
        <Space size="xxl" />
        <Trans i18nKey="integrations.card.mobile_pay.modal.body">
          <Text>
            Before you can activate the integration to MobilePay in Billy, you first need to make an account or
            reactivate your existing account at MobilePay. When you have made or activated your account at MobilePay you
            will be led back to Billy again where the system will be active and ready to use.
          </Text>
          <Space size="xxl" />
          <Text>
            If you are a new customer at MobilePay they will be in the process of approving your account. You will
            receive an email from MobilePay, when your account is approved and activated in Billy.
          </Text>
          <Space size="xxl" />
          <Text>
            If you are an existing customer at MobilePay and already have a MobilePay Invoice account then the
            integration will be activated by now.
          </Text>
        </Trans>
        <Space size="xxl" />
        <Text colorVariant="secondary">{t('integrations.card.mobile_pay.modal.cvr_no')}</Text>
        <CompanySelect
          selectedValue={company}
          onSelect={handleCompanySelect}
          getDisplayValue={getCompanySelectDisplayValue}
        />
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button variant="text" onClick={handleCancelButtonClick}>
            {t('integrations.card.mobile_pay.modal.cancel_button')}
          </Button>
          <Button onClick={handleActivateButtonClick} disabled={isActivateButtonDisabled} loading={isProcessing}>
            {t('integrations.card.mobile_pay.modal.activate_button')}
          </Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
