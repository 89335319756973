import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '../../app/organization'
import { useAttachmentsContext } from '../contexts/attachmentsContext'
import { fetchReceipts } from '../query-api'
import { QueryProps } from '../types/queryProps'
import { getAttachmentsQueryKey } from '../utils/getAttachmentsQueryKey'

interface UseFetchAttachments {
  enabled: boolean
}

export const useFetchAttachments = ({ enabled }: UseFetchAttachments) => {
  const { organization } = useUserOrganization()
  const {
    filters: { page, pageSize, searchQuery, sortDirection, sortProperty, type, fillType },
  } = useAttachmentsContext()

  const queryProps: QueryProps = useMemo(
    () => ({
      fillType,
      organizationId: organization?.id as string,
      page,
      pageSize,
      searchQuery,
      sortDirection,
      sortProperty,
      type,
    }),
    [fillType, organization?.id, page, pageSize, searchQuery, sortDirection, sortProperty, type],
  )

  const queryKey = getAttachmentsQueryKey(queryProps)

  const { data, ...rest } = useQuery(queryKey, () => fetchReceipts(queryProps), {
    enabled: !!organization && enabled,
    cacheTime: 0,
  })

  return {
    attachments: data?.attachments || [],
    pagination: data?.meta.paging,
    ...rest,
  }
}
