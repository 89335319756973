// 1. Add new enum:
export enum OrganizationFeature {
  AccountingBasisSetting = 'accounting_basis_setting',
  BillReconciliation = 'bill_reconciliation',
  CustomizeDashboard = 'customize_dashboard',
  NewBills = 'new_bills',
  NewBillsList = 'new_bills_list',
  NewInvoices = 'new_invoices',
  NewInvoicesList = 'new_invoices_list',
  Payroll = 'payroll',
  VatIntegration = 'vat_integration',
  EmailScanning = 'email_scanning',
  FrodaLending = 'froda_lending',
}

// 2. Set up the type:
// "open" - every organization can enable it; no DB work required
// "closed" - only chosen organizations can enable it; DB work required: add feature flag to 'OrganizationSetting' table "beta:[featureName]" with '0' or '1' value
type OrganizationFeatureType = 'open' | 'closed'

export const organizationFeaturesTypes: Record<OrganizationFeature, OrganizationFeatureType> = {
  [OrganizationFeature.AccountingBasisSetting]: 'closed',
  [OrganizationFeature.EmailScanning]: 'closed',
  [OrganizationFeature.BillReconciliation]: 'open',
  [OrganizationFeature.CustomizeDashboard]: 'open',
  [OrganizationFeature.NewBills]: 'open',
  [OrganizationFeature.NewBillsList]: 'open',
  [OrganizationFeature.NewInvoices]: 'open',
  [OrganizationFeature.NewInvoicesList]: 'open',
  [OrganizationFeature.Payroll]: 'open',
  [OrganizationFeature.VatIntegration]: 'open',
  [OrganizationFeature.FrodaLending]: 'closed',
}

// 3. Define translations:
interface FeatureInfo {
  tTitle: string
  tDescription: string
}

export const organizationFeaturesTranslations: Record<OrganizationFeature, FeatureInfo> = {
  [OrganizationFeature.AccountingBasisSetting]: {
    tTitle: 'settings.organization.features.accounting_basis_setting.title',
    tDescription: 'settings.organization.features.accounting_basis_setting.description',
  },
  [OrganizationFeature.BillReconciliation]: {
    tTitle: 'settings.organization.features.bill_reconciliation.title',
    tDescription: 'settings.organization.features.bill_reconciliation.description',
  },
  [OrganizationFeature.CustomizeDashboard]: {
    tTitle: 'settings.organization.features.customize_dashboard.title',
    tDescription: 'settings.organization.features.customize_dashboard.description',
  },
  [OrganizationFeature.NewBills]: {
    tTitle: 'settings.organization.features.new_bills.title',
    tDescription: 'settings.organization.features.new_bills.description',
  },
  [OrganizationFeature.NewBillsList]: {
    tTitle: 'settings.organization.features.new_bills_list.title',
    tDescription: 'settings.organization.features.new_bills_list.description',
  },
  [OrganizationFeature.NewInvoices]: {
    tTitle: 'settings.organization.features.new_invoices.title',
    tDescription: 'settings.organization.features.new_invoices.description',
  },
  [OrganizationFeature.NewInvoicesList]: {
    tTitle: 'settings.organization.features.new_invoices_list.title',
    tDescription: 'settings.organization.features.new_invoices_list.description',
  },
  [OrganizationFeature.Payroll]: {
    tTitle: 'settings.organization.features.payroll.title',
    tDescription: 'settings.organization.features.payroll.description',
  },
  [OrganizationFeature.VatIntegration]: {
    tTitle: 'settings.organization.features.vat_integration.title',
    tDescription: 'settings.organization.features.vat_integration.description',
  },
  [OrganizationFeature.EmailScanning]: {
    tTitle: 'settings.organization.features.email_scanning.title',
    tDescription: 'settings.organization.features.email_scanning.description',
  },
  [OrganizationFeature.FrodaLending]: {
    tTitle: 'settings.organization.features.froda_lending.title',
    tDescription: 'settings.organization.features.froda_lending.description',
  },
}
