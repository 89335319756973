import { useCallback } from 'react'

import { APIError } from '../../../../../utils'
import { useCreateAccount } from '../../../../app/accounts/hooks/useCreateAccount'
import { AccountsResponseData, CreateAccountPayload } from '../../../../app/accounts/query-api'
import { AccountNature } from '../../../../app/accounts/types'
import { useUserOrganization } from '../../../../app/organization'
import { MOBILE_PAY_ACCOUNT_NAME, MOBILE_PAY_ACCOUNT_NUMBER } from '../../../constants/mobilePayAccountProps'

interface UseCreateMobilePayAccountProps {
  onSuccess?: (mobilePayAccountId: string) => void
  onError?: (error?: APIError) => void
}

export const useCreateMobilePayAccount = ({ onSuccess, onError }: UseCreateMobilePayAccountProps) => {
  const { organization } = useUserOrganization()
  const { create: createAccount } = useCreateAccount({
    onSuccess: (responseData: AccountsResponseData) => {
      const accountId = responseData?.accounts[0]?.id

      onSuccess?.(accountId)
    },
    onError: (error?: APIError) => {
      onError?.(error)
    },
  })

  const createMobilePayAccount = useCallback(
    (receivableAccountGroupId: string) => {
      if (!organization?.id) {
        return
      }

      const payload: CreateAccountPayload = {
        accountNo: MOBILE_PAY_ACCOUNT_NUMBER,
        currencyId: 'DKK',
        description: 'For Mobilepay transactions',
        groupId: receivableAccountGroupId,
        isBankAccount: true,
        isPaymentEnabled: true,
        name: MOBILE_PAY_ACCOUNT_NAME,
        natureId: AccountNature.Asset,
      }

      createAccount({ payload, organizationId: organization.id })
    },
    [createAccount, organization?.id],
  )

  return { createMobilePayAccount }
}
