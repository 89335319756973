import { ButtonDropdown } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { useRedirectToAppBuildTheme } from '../../../../hooks'
import { getAppBuildTheme } from '../../../../utils/getAppBuildTheme'
import { useUserOrganization } from '../../../app/organization'
import { useUserOrganizations } from '../../../app/organizations'
import { UserOrganization } from '../../../app/organizations/types'
import { useUmbrella, useUserUmbrellas } from '../../../app/umbrellas'
import { UserUmbrella } from '../../../app/umbrellas/types/userUmbrella'
import { useCurrentUser } from '../../../app/user'
import { AccountSelectorFooter } from './AccountSelectorFooter'
import * as Styled from './styles'
import { getAvatarProps } from './utils/getAvatarProps'
import { getOrganizationItems, OrganizationItemValue } from './utils/getOrganizationItems'

export const MenuAccountSelector = (): ReactElement => {
  const { navigate } = useEmberRouter()
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { user } = useCurrentUser()
  const { organization } = useUserOrganization()
  const { userOrganizations } = useUserOrganizations()
  const { userUmbrellas } = useUserUmbrellas()
  const { redirectToAppBuildTheme } = useRedirectToAppBuildTheme()
  const theme = getAppBuildTheme()

  const isLoading = !organization && !umbrella
  const buttonMainLabel = organization?.name || umbrella?.name
  const buttonSmallLabel = user?.name
  const avatarProps = getAvatarProps(organization, umbrella)
  const selectedId = umbrella?.id || organization?.id

  const items = useMemo(
    () => getOrganizationItems(userUmbrellas, userOrganizations, t),
    [userUmbrellas, userOrganizations, t],
  )

  const goToUmbrella = useCallback(
    (umbrella: UserUmbrella) => {
      navigate(EmberRoute.Umbrella, [umbrella.umbrellaId])
    },
    [navigate],
  )

  const goToOrganization = useCallback(
    (organization: UserOrganization) => {
      if (organization.brand && organization.brand !== theme) {
        redirectToAppBuildTheme(organization.brand, organization.url)
        return
      }

      window.location.replace(`/${organization.url}`)
    },
    [theme, redirectToAppBuildTheme],
  )

  const handleSelect = useCallback(
    (_: string, value: OrganizationItemValue) => {
      const { organization, umbrella } = value || {}

      if (organization) {
        goToOrganization(organization)
      } else if (umbrella) {
        goToUmbrella(umbrella)
      }
    },
    [goToOrganization, goToUmbrella],
  )

  return (
    <Styled.MenuAccountSelectorWrapper>
      {!isLoading && (
        <ButtonDropdown
          items={items}
          variant="secondary"
          icon={avatarProps?.icon ? avatarProps.icon : <Styled.AvatarImage {...avatarProps} />}
          placement="top-start"
          size="xxl"
          dropdownSize="xl"
          onSelect={handleSelect}
          selectedId={selectedId}
          dropdownFooter={<AccountSelectorFooter />}
          dropdownKeepHeightOnSearching
          dropdownMaxHeight="fullScreen"
          getDisplayValue={() => (
            <Styled.ButtonContent>
              <Styled.Text title={buttonMainLabel} weight="medium" truncate inheritColor>
                {buttonMainLabel}
              </Styled.Text>
              <Styled.Text variant="micro" title={buttonSmallLabel} truncate inheritColor>
                {buttonSmallLabel}
              </Styled.Text>
            </Styled.ButtonContent>
          )}
          withSearch
          fullWidth
        />
      )}
    </Styled.MenuAccountSelectorWrapper>
  )
}
