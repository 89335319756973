import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { useUmbrella } from '../../../../app/umbrellas'
import {
  createUmbrellaSubscriptions,
  CreateUmbrellaSubscriptionsPayload,
  CreateUmbrellaSubscriptionsResponse,
} from '../query-api'

interface UseAddPaymentMethodArgs {
  onError?: (error: APIError) => void
  onSuccess?: (response: CreateUmbrellaSubscriptionsResponse) => void
}

export const useAddPaymentMethod = ({ onError, onSuccess }: UseAddPaymentMethodArgs = {}) => {
  const { umbrella } = useUmbrella()
  const { mutate: create, isLoading } = useMutation(
    (payload: CreateUmbrellaSubscriptionsPayload) => createUmbrellaSubscriptions(umbrella?.id as string, payload),
    { onError, onSuccess },
  )

  return {
    create,
    isLoading,
  }
}
