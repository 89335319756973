import produce from 'immer'

import { BILL_RECEIVED, BILLS_RECEIVED } from './actions'

export type IState = {
  bills: Array<any> | null
  bill: any | null
}

const initialState: IState = {
  bill: null,
  bills: null,
}

const reducer = (state: IState = initialState, { type, payload }: any): IState => {
  switch (type) {
    case BILLS_RECEIVED:
      return produce(state, (draftState) => {
        draftState.bills = payload
      })
    case BILL_RECEIVED:
      return produce(state, (draftState) => {
        draftState.bill = payload
      })
    default:
      return state
  }
}

export default reducer
