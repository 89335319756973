import { ModuleLayout } from '@design-system'

import React, { ReactElement } from 'react'
import { useLocation } from 'react-router-dom'

import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'
import { AttachmentsContextProvider } from '../../../receipts/contexts/attachmentsContext'
import { BillAttachmentPageContextProvider } from '../../contexts/billAttachmentPageContext'
import { BillsRoute } from '../../enums/billsRoute'
import { BillEditableActions } from '../BillEditableActions'
import { BillFormContextProvider } from '../BillEditableForm'
import { BillEditableLayout } from '../BillEditableLayout'
import { BillEditableTitle } from '../BillEditableTitle'

interface BillEditableProps {
  attachmentId?: string
  billId?: string
  billIdToCreateCreditNote?: string
  billIdToDuplicate?: string
  contactId?: string
  isBulkEditMode?: boolean
  isNewCreditNote?: boolean
}

export const BillEditable = ({
  attachmentId,
  billId,
  billIdToCreateCreditNote,
  billIdToDuplicate,
  contactId,
  isBulkEditMode,
  isNewCreditNote,
}: BillEditableProps): ReactElement => {
  const location = useLocation()

  const showBillActions = isAuthorized(Scope.BillWrite)

  return (
    <AttachmentsContextProvider>
      <BillAttachmentPageContextProvider>
        <BillFormContextProvider
          attachmentId={attachmentId}
          billId={billId}
          billIdToCreateCreditNote={billIdToCreateCreditNote}
          billIdToDuplicate={billIdToDuplicate}
          contactId={contactId}
          isBulkEditMode={isBulkEditMode}
          isNewCreditNote={isNewCreditNote}
        >
          <ModuleLayout
            fullHeight
            backButtonTo={`${BillsRoute.List}${location.search}`}
            sideActions={showBillActions ? <BillEditableActions /> : null}
            title={<BillEditableTitle />}
            withBackButton={!!billId}
          >
            <BillEditableLayout />
          </ModuleLayout>
        </BillFormContextProvider>
      </BillAttachmentPageContextProvider>
    </AttachmentsContextProvider>
  )
}
