import { Button, notify } from '@design-system'

import React, { ReactElement, useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../enums/notificationKeys'
import { useSegment } from '../../../../hooks'
import { useUserOrganization } from '../../../app/organization'
import { useDownloadReport } from '../../hooks/useDownloadReport'
import { useSalesTaxListFilters } from '../../hooks/useSalesTaxListFilters'
import { useSalesWithoutVat } from '../../hooks/useSalesWithoutVat'
import { getFileName } from '../../utils/getFileName'
import * as Styled from './styles'

export const DownloadButton = (): ReactElement | null => {
  const { t } = useTranslation()
  const downloadElementRef = useRef<HTMLAnchorElement>(null)
  const [downloadUrl, setDownloadUrl] = useState<string>()
  const { organization } = useUserOrganization()
  const { data, isLoading } = useSalesWithoutVat()
  const [{ period: queryParamPeriod }] = useSalesTaxListFilters()
  const { track } = useSegment()
  const { isDownloading, download } = useDownloadReport({
    onError: () => {
      notify({
        id: NotificationKeys.SalesTaxSalesWithoutVat,
        message: t('sales_tax_returns.eu_sales_without_vat.error.download'),
        variant: 'error',
      })
    },
    onSuccess: async (response) => {
      const blob = await response.blob()
      setDownloadUrl(URL.createObjectURL(blob))
      downloadElementRef.current?.click()
    },
  })

  const fileName = getFileName(organization?.name || '', queryParamPeriod, t)

  const handleDownloadClick = useCallback(() => {
    download()
    /* eslint-disable @typescript-eslint/naming-convention */
    track('XXX - Mikkel - Exports', {
      export_type: 'EU-sales without VAT',
      file_format: 'csv',
      period: queryParamPeriod,
    })
    /* eslint-enable @typescript-eslint/naming-convention */
  }, [download, queryParamPeriod])

  if (isLoading || data.length === 0) {
    return null
  }

  return (
    <>
      <Button loading={isDownloading} onClick={handleDownloadClick} variant="secondary">
        {t('sales_tax_returns.eu_sales_without_vat.export_csv')}
      </Button>
      {downloadUrl && <Styled.DownloadLink download={fileName} href={downloadUrl} ref={downloadElementRef} />}
    </>
  )
}
