import { Flex, Spacing, Text } from '@design-system'

import { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface ScoutCardHeaderProps {
  image?: ReactNode
  sideActions?: ReactNode
  subtitle?: ReactNode
  title: ReactNode
}

export const ScoutCardHeader = ({ image, sideActions, subtitle, title }: ScoutCardHeaderProps): ReactElement => {
  return (
    <Flex justifyContent="space-between">
      <Flex alignItems="center" gap={Spacing.XS}>
        {image && <Styled.ImageWrapper>{image}</Styled.ImageWrapper>}
        <Flex flexDirection="column">
          <Text variant="large" weight="bold">
            {title}
          </Text>
          {subtitle && (
            <Text variant="small" colorVariant="secondary">
              {subtitle}
            </Text>
          )}
        </Flex>
      </Flex>
      {sideActions}
    </Flex>
  )
}
