import { Badge, Icon, NavItem, NavItemAccessor, Theme } from '@design-system'

import { TFunction } from 'i18next'

import { Company } from '../../../../modules/app/companies/types'
import { NAV_ITEM_ID_CREATE_COMPANY } from '../constants/navItemIdCreateCompany'
import { CompanyValue } from '../types/companyValue'
import { getCompanyAddress } from './getCompanyAddress'

interface GetItemsProps {
  companies: Company[]
  t: TFunction
  allowCreate?: boolean
  isContactLookupAuthorized?: boolean
  theme: Theme
}

export const getItems = ({
  companies,
  t,
  allowCreate,
  isContactLookupAuthorized = false,
  theme,
}: GetItemsProps): NavItem<CompanyValue>[] => {
  const defaultItems = allowCreate
    ? [
        {
          accessor: NavItemAccessor.WithoutActiveState,
          children: t('company_select.dropdown.create_item.text'),
          iconLeft: <Icon icon="plusCircle" color={theme.ds.colors.base.textPrimary} />,
          id: NAV_ITEM_ID_CREATE_COMPANY,
          value: {
            displayData: {
              title: t('company_select.dropdown.create_item.text'),
            },
          } as CompanyValue,
        },
        ...(companies.length
          ? [
              {
                accessor: NavItemAccessor.Separator,
                children: '',
                id: 'separator',
                value: {} as CompanyValue,
              },
            ]
          : []),
      ]
    : []

  const companiesOther: NavItem<CompanyValue>[] = []

  for (const company of companies) {
    const displayTitle = company.name
    const searchKeywords = [company.id, displayTitle]
    const address = getCompanyAddress(company)

    companiesOther.push({
      id: company.id,
      children: displayTitle,
      searchKeywords,
      value: {
        ...company,
        displayData: {
          title: displayTitle,
          description: [company.id, address],
          suffix: !isContactLookupAuthorized ? <Badge>{t('company_select.dropdown.upgrade')}</Badge> : null,
        },
        isProtected: !isContactLookupAuthorized,
      },
    })
  }

  return [...defaultItems, ...companiesOther]
}
