import { Account } from '../../../../app/accounts/types'
import { TaxRate } from '../../../../inbox/types'
import { BillFormPartial } from './formData'
import { getBillLinesMock } from './getBillLinesMock'

interface GetBillFormFromExpenseCategoryProps {
  expenseCategory: Account | undefined
  lineIndex?: number
  linesTotal?: number
  taxRates?: TaxRate[]
}

export const getBillFormFromExpenseCategory = ({
  expenseCategory,
  lineIndex = 0,
  linesTotal = 1,
  taxRates,
}: GetBillFormFromExpenseCategoryProps): BillFormPartial => {
  const taxRate = expenseCategory?.taxRateId && taxRates?.find(({ id }) => id === expenseCategory?.taxRateId)

  const billLines = getBillLinesMock(linesTotal)

  if (billLines[lineIndex]) {
    billLines[lineIndex].taxRate = taxRate || undefined
  }

  return {
    billLines,
  }
}

export const getBillFormFromExpenseCategoryForm = (
  billForm: BillFormPartial,
  accounts?: Account[],
  taxRates?: TaxRate[],
): BillFormPartial => {
  if (!billForm?.billLines?.length) {
    return {}
  }

  const billFormPartial: BillFormPartial = {
    billLines: [],
  }

  if (billFormPartial.billLines) {
    for (let i = 0; i < billForm.billLines.length; i++) {
      const expenseCategoryId = billForm.billLines[i]?.expenseCategory?.id
      const expenseCategory = expenseCategoryId ? accounts?.find(({ id }) => id === expenseCategoryId) : undefined
      const taxRate = expenseCategory?.taxRateId && taxRates?.find(({ id }) => id === expenseCategory?.taxRateId)

      billFormPartial.billLines[i] = taxRate ? { taxRate } : {}
    }
  }

  return billFormPartial
}
