import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { AccountsContextProvider } from '../../modules/app/accounts'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { SalesTax } from '../../modules/salesTax'

const MODULE_NAME = 'sales-tax-module'

class SalesTaxWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

type SalesTaxProps = {
  organizationId: string
}

function SalesTaxModule({ organizationId }: SalesTaxProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <CurrentUserContextProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <AccountsContextProvider organizationId={organizationId}>
              <HashRouter>
                <GlobalFiscalYearContextProvider organizationId={organizationId}>
                  <SalesTax />
                </GlobalFiscalYearContextProvider>
              </HashRouter>
            </AccountsContextProvider>
          </UserOrganizationContextProvider>
        </CurrentUserContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const SalesTaxCustomElement = createCustomElement(SalesTaxModule, SalesTaxWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, SalesTaxCustomElement)

export default SalesTaxCustomElement
