import { ApiContentType } from '../../enums/apiContentType'
import { Metable } from '../../types/metable'
import { postV2Request } from '../../utils'
import { updateOrganizationOwnership } from '../app/organization'
import { CreateUmbrellaOrganizationSubscription } from './types/CreateUmbrellaOrganizationSubsscription'

export interface CreateUmbrellaOrganizationSubscriptionArgs {
  organizationId: string
  period: string
  plan: string
  umbrellaId: string
}

export interface CreateUmbrellaOrganizationSubscriptionResponse extends Metable {
  data: {
    organizations: CreateUmbrellaOrganizationSubscription
  }
}

export const createUmbrellaOrganizationSubscription = ({
  organizationId,
  period,
  plan,
  umbrellaId,
}: CreateUmbrellaOrganizationSubscriptionArgs): Promise<CreateUmbrellaOrganizationSubscriptionResponse> => {
  return postV2Request({
    endpoint: `/umbrellas/${umbrellaId}/subscriptions/organizations/${organizationId}/create`,
    payload: {
      data: {
        organizationId,
        period,
        plan,
        umbrellaId,
      },
    },
    options: {
      contentType: ApiContentType.ApplicationBillyJSON,
    },
  })
}

type CreateUmbrellaOrganizationSubscriptionAndTransferOwnershipArgs = CreateUmbrellaOrganizationSubscriptionArgs

export const createUmbrellaOrganizationSubscriptionAndTransferOwnership = async ({
  organizationId,
  period,
  plan,
  umbrellaId,
}: CreateUmbrellaOrganizationSubscriptionAndTransferOwnershipArgs) => {
  await updateOrganizationOwnership(organizationId, { ownerUmbrellaId: umbrellaId })
  const response = await createUmbrellaOrganizationSubscription({
    organizationId,
    period,
    plan,
    umbrellaId,
  })

  return response
}
