import uniqBy from 'lodash/uniqBy'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { useOnboarding } from '../../../hooks/useOnboarding'
import { useOrganizationBrand } from '../../../hooks/useOrganizationBrand'
import { useUserOrganizationSettings } from '../../app/organization'
import { useUpdateOrganizationSettings } from '../../app/organization/hooks/useUpdateOrganizationSettings'
import { OrganizationSettingsError } from '../../app/organization/types/organizationSettingsError'
import { defaultAgerasBrandWidgetsList, defaultWidgets, defaultWidgetsAfterDay } from '../constants/defaultWidgetsList'
import { widgetSettingsByWidgetId } from '../constants/widgetSettingsByWidgetId'
import { WidgetId } from '../enums/widgetId'
import { Widget } from '../types/widget'
import {
  cleanupWidgetsOrder,
  CleanUpWidgetsOrderOptions,
  getToggledVisibilityWidgetOrder,
  parseWidgetsList,
} from '../utils'

const DASHBOARD_LAYOUT = 'dashboardLayout'

interface UseWidgetsProps {
  onUpdateError?: (error: OrganizationSettingsError) => void
  onUpdateSettled?: () => void
  onUpdateSuccess?: () => void
  options?: CleanUpWidgetsOrderOptions
  organizationId: string
}

interface UseWidgetsResponse {
  hiddenWidgets: Widget[]
  isLoading: boolean
  isWidgetHidden: (id: WidgetId) => boolean
  resetWidgets: () => void
  setWidgets: (widgets?: Widget[]) => void
  toggleWidgetVisibility: (id: WidgetId, isVisible: boolean) => void
  visibleWidgets: Widget[]
}

export const useWidgets = ({
  options,
  onUpdateError,
  onUpdateSettled,
  onUpdateSuccess,
  organizationId,
}: UseWidgetsProps): UseWidgetsResponse => {
  const {
    isLoading: isOrganizationSettingsLoading,
    isIdle: isOrganizationSettingsIdle,
    organizationSettings,
  } = useUserOrganizationSettings()
  const { isAfterDayFromRegistering } = useOnboarding()
  const { isBrandAgeras } = useOrganizationBrand()

  const defaultWidgetsList = useMemo(() => {
    if (isBrandAgeras) {
      return defaultAgerasBrandWidgetsList
    }

    if (isAfterDayFromRegistering) {
      return defaultWidgetsAfterDay
    }

    return defaultWidgets
  }, [isAfterDayFromRegistering, isBrandAgeras])

  const isLoading = isOrganizationSettingsLoading || isOrganizationSettingsIdle

  const [localWidgets, setLocalWidgets] = useState<Widget[]>(
    organizationSettings?.dashboardLayout ? parseWidgetsList(organizationSettings.dashboardLayout) : defaultWidgetsList,
  )

  const { update } = useUpdateOrganizationSettings({
    onSettled: onUpdateSettled,
    onSuccess: onUpdateSuccess,
    onError: onUpdateError,
  })

  const serverWidgets = useMemo(
    () => (organizationSettings?.dashboardLayout ? parseWidgetsList(organizationSettings.dashboardLayout) : []),
    [organizationSettings],
  )

  const getMergedWidgets = useCallback(
    (newWidgets: Widget[], oldWidgets = localWidgets) => uniqBy([...newWidgets, ...oldWidgets], 'id'),
    [localWidgets],
  )

  const setWidgets = useCallback(
    (widgets: Widget[] = defaultWidgetsList) => {
      const cleanedUpWidgetsOrder = cleanupWidgetsOrder(widgets, options)
      const mergedWidgets = cleanupWidgetsOrder(getMergedWidgets(cleanedUpWidgetsOrder), options)

      setLocalWidgets(mergedWidgets)

      update({
        key: DASHBOARD_LAYOUT,
        organizationId,
        value: JSON.stringify(mergedWidgets),
      })
    },
    [getMergedWidgets, options, organizationId, update, defaultWidgetsList],
  )

  useEffect(() => {
    if (!organizationSettings?.dashboardLayout) {
      return
    }

    setWidgets(getMergedWidgets(serverWidgets, defaultWidgetsList))
    // Update only when those values had change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationSettings?.dashboardLayout, serverWidgets, options])

  const widgets = useMemo(() => {
    if (!organizationSettings?.dashboardLayout) {
      if (isLoading) {
        return []
      }

      return cleanupWidgetsOrder(defaultWidgetsList, options)
    }

    return localWidgets
  }, [defaultWidgetsList, isLoading, localWidgets, options, organizationSettings?.dashboardLayout])

  const visibleWidgets = useMemo(() => widgets.filter(({ isHidden }) => !isHidden), [widgets])
  const hiddenWidgets = useMemo(
    () => widgets.filter(({ id, isHidden }) => isHidden && !widgetSettingsByWidgetId[id]?.isHiddenPermanently),
    [widgets],
  )

  const toggleWidgetVisibility = useCallback(
    (id: WidgetId, isHidden: boolean) => {
      const updatedWidgets = widgets.map((widget) => {
        if (widget.id !== id) {
          return { ...widget }
        }

        return {
          ...widget,
          isHidden,
          order: getToggledVisibilityWidgetOrder(isHidden),
        }
      })

      setWidgets(updatedWidgets)

      return updatedWidgets
    },
    [setWidgets, widgets],
  )

  const resetWidgets = useCallback(() => {
    const resetWidgets = defaultWidgetsList.filter(({ id }) => {
      // do not restore widgets that are permanently dismissed
      // so if the widget is configured as isHiddenPermanently && it was previously dismissed
      if (
        widgetSettingsByWidgetId[id].isHiddenPermanently &&
        widgets.find(({ id: widgetId, isHidden }) => isHidden && widgetId === id)
      ) {
        return false
      }

      return true
    })
    setWidgets(resetWidgets)
  }, [defaultWidgetsList, setWidgets, widgets])

  const isWidgetHidden = useCallback(
    (widgetId: WidgetId): boolean => {
      return Boolean(hiddenWidgets.find(({ id }) => id === widgetId))
    },
    [hiddenWidgets],
  )

  return {
    hiddenWidgets,
    isLoading,
    isWidgetHidden,
    setWidgets,
    toggleWidgetVisibility,
    visibleWidgets,
    resetWidgets,
  }
}
