import { Input } from '@components-deprecated'

import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import React, { ReactElement, useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { useUserOrganization } from '../../../../../app/organization'
import { Voucher, VoucherInboxState } from '../../../../../inbox/types'
import { useDeleteVoucherMutation } from '../../../../hooks/useDeleteVoucherMutation'
import { useUpdateVoucherMutation } from '../../../../hooks/useUpdateVoucherMutation'
import { extendVoucherDescription } from '../../../../utils/extendVoucherDescription'
import { MissingInformationActions } from '../../MissingInformationActions'
import { MissingInformationContent } from '../../MissingInformationContent'

type FormInputs = {
  description: string
}

interface MissingSupplierProps {
  voucher: Voucher
}

const defaultValues: FormInputs = {
  description: '',
}

const validationSchema = (t: TFunction): yup.SchemaOf<FormInputs> =>
  yup.object().shape({
    description: yup.string().required(t('things_to_do.validation.payment_description')),
  })

export const MissingDescription = ({ voucher }: MissingSupplierProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const form = useForm<FormInputs>({
    defaultValues,
    resolver: useMemo(() => yupResolver(validationSchema(t)), [t]),
  })

  const mutationBaseData = {
    organizationId: organization?.id as string,
    voucherId: voucher.id,
  }

  const deleteMutation = useDeleteVoucherMutation(mutationBaseData)
  const updateMutation = useUpdateVoucherMutation(mutationBaseData)

  const isLoading = deleteMutation.isLoading || updateMutation.isLoading

  const handleFormSubmit = useCallback(
    (values: FormInputs) => {
      const payload = {
        description: values.description
          ? extendVoucherDescription(voucher.description, {
              reply: values.description,
            })
          : values.description,
        inboxState: VoucherInboxState.RECEIVED,
      }
      updateMutation.mutate(payload)
    },
    [updateMutation, voucher.description],
  )

  const handleSubmit = useCallback(() => {
    form.handleSubmit(handleFormSubmit)()
  }, [form, handleFormSubmit])

  const handleDelete = useCallback(() => {
    deleteMutation.mutate()
  }, [deleteMutation])

  return (
    <MissingInformationContent disabled={isLoading}>
      <FormProvider {...form}>
        <Input
          formControl={form.control}
          label={t('things_to_do.missing_information.table.header.your_reply')}
          name="description"
          type="textarea"
        />
      </FormProvider>
      <MissingInformationActions onSubmit={handleSubmit} onDelete={handleDelete} disabled={isLoading} />
    </MissingInformationContent>
  )
}
