import { Button, notify, SkeletonText, Text } from '@design-system'

import React, { ReactElement, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { useUserOrganization } from '../../../app/organization'
import { useDashboardSettings } from '../../contexts/dashboardSettingsContext'
import { Widget } from '../../elements/Widget'
import { WidgetId } from '../../enums/widgetId'
import { useVatPeriod } from './hooks/useVatPeriod'
import * as Styled from './styles'
import { TaxDeadlineDisplay } from './taxDeadlineDisplay/TaxDeadlineDisplay'
import { TaxDisplay } from './taxDisplay/TaxDisplay'

export const Vat = (): ReactElement | null => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { navigate } = useEmberRouter()
  const { isWidgetHidden } = useDashboardSettings()

  const isHidden = useMemo(() => isWidgetHidden(WidgetId.Vat), [isWidgetHidden])

  const { isLoading: isVatPeriodLoading, isError, accountReport, salesTaxReturnPeriod } = useVatPeriod(organization?.id)
  const isLoading = !organization || isVatPeriodLoading || isHidden

  useEffect(() => {
    if (isError) {
      notify({
        id: NotificationKeys.DashboardWidgetVat,
        message: t('dashboard.widget.vat.error'),
        variant: 'error',
      })
      console.error(t('dashboard.widget.vat.error'))
    }
  }, [isError, t])

  const formatPeriod = () => {
    if (!salesTaxReturnPeriod?.periodType) {
      return
    }
    const periodType = t(`dashboard.widget.vat.period_type.${salesTaxReturnPeriod.periodType}`)
    const periodYearAndNumber = salesTaxReturnPeriod.period
      .slice(salesTaxReturnPeriod.period.lastIndexOf(':') + 1)
      .split('-')
    return `${periodYearAndNumber[periodYearAndNumber.length - 1]}. ${periodType} ${periodYearAndNumber[0]}`
  }

  const settleVat = () => {
    if (salesTaxReturnPeriod && organization) {
      navigate(EmberRoute.TaxReturn, salesTaxReturnPeriod.id)
    }
  }

  return (
    <Widget
      title={t('dashboard.widget.vat.title')}
      sideActions={
        salesTaxReturnPeriod && (
          <Button onClick={settleVat} variant="secondary">
            {t('dashboard.widget.vat.settle_btn')}
          </Button>
        )
      }
      isHidden={isHidden}
    >
      <>
        <Styled.VatBody>
          <TaxDisplay
            amount={accountReport?.report?.result || 0}
            period={formatPeriod()}
            isLoading={isLoading}
            userHasToPayTax
          />
          <TaxDeadlineDisplay deadlineTimestamp={salesTaxReturnPeriod?.reportDeadline} isLoading={isLoading} />
        </Styled.VatBody>

        <Styled.Footer>
          {isLoading ? (
            <SkeletonText variant="micro" />
          ) : (
            <Text variant="micro" colorVariant="secondary">
              {t('dashboard.widget.vat.explanation_footer')}
            </Text>
          )}
        </Styled.Footer>
      </>
    </Widget>
  )
}
