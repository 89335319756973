import { emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { PeriodMode } from '../../../../enums/periodMode'
import { SortDirection } from '../../../../enums/sortDirection'
import {
  ContactBalanceEmpty,
  ContactBalanceTable,
  ContactBalanceTableActions,
  ContactBalanceTableRow,
  ContactBalanceType,
  useContactBalanceListFilters,
} from '../../../contactBalance'
import { InvoiceStatus } from '../../../invoices/enums/invoiceStatus'
import { InvoicesSortProperty } from '../../../invoices/query-api'
import { InvoicesListFilters } from '../../../invoices/routes/InvoicesList/hooks/useInvoicesListFilters'
import { useDebtors } from './hooks/useDebtors'

export const DebtorsList = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { debtors, isLoading, pagination } = useDebtors()
  const [{ searchQuery }] = useContactBalanceListFilters()

  const showEmptyView = useMemo(
    () => !isLoading && !searchQuery && debtors.length === 0,
    [isLoading, searchQuery, debtors],
  )

  const onTableRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      const { id: contactId, contactName } = row.original

      const invoicesQueryParams: InvoicesListFilters = {
        contactId,
        contactName,
        period: PeriodMode.All,
        sortProperty: InvoicesSortProperty.DueDate,
        sortDirection: SortDirection.Asc,
        status: InvoiceStatus.Unpaid,
      }

      navigate(EmberRoute.Invoices, {
        queryParams: invoicesQueryParams,
      })
    },
    [navigate],
  )

  return (
    <ModuleLayout title={t('debtors')}>
      <ContactBalanceTableActions isLoading={isLoading} title={t('debtors')} total={pagination?.total} />
      {showEmptyView ? (
        <ContactBalanceEmpty {...emptyViewVariants.debtors} route={EmberRoute.InvoicesNew} />
      ) : (
        <ContactBalanceTable
          data={debtors}
          isLoading={isLoading}
          contactBalanceType={ContactBalanceType.Debtor}
          onRowClick={onTableRowClick}
        />
      )}
    </ModuleLayout>
  )
}
