import {
  BookkeepingTagDbValue,
  BookkeepingTagNavItemValue,
  convertBookkeepingTagDbValueToNavItemValue,
} from '@components'

import { BohrScanTagScore } from '../../../../../types/bohrScanTagScore'
import { ChartOfAccountId } from '../../../../app/organization/enums/chartOfAccountId'

export const getBookkeepingTagsFromTagScores = async (
  tagScores: BohrScanTagScore[],
  locale: string,
  chartOfAccountId?: ChartOfAccountId,
  countryId?: string,
): Promise<BookkeepingTagNavItemValue[]> => {
  const bookkeepingTags: BookkeepingTagNavItemValue[] = []

  for (const tagScore of tagScores) {
    const value: BookkeepingTagDbValue = {
      tagId: tagScore.tag_id,
      name: tagScore.tag_name,
    }
    const bookkeepingTag = await convertBookkeepingTagDbValueToNavItemValue(value, locale, chartOfAccountId, countryId)

    if (bookkeepingTag) {
      bookkeepingTags.push(bookkeepingTag)
    }
  }

  return bookkeepingTags
}
