import { SmartSelect } from '@components-deprecated'

import React, { useEffect } from 'react'
import { Control } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { BoxProps } from 'rebass'
import { createSelector } from 'reselect'

import { getItemsForSelect } from '../../../../utils/forms'
import { State } from '../../../types'
import { accountsRequested } from '../action-creators'
import { Account } from '../types'

export type BankAccountSelectorProps = BoxProps & {
  disconnect?: boolean
  formControl?: Control<any>
  onItemSelect?: (account: Account, name?: string) => void
  organizationId?: string
  preselectedId?: string
  withSelectEvent?: boolean
}

export const BankAccountSelector = ({
  disconnect,
  formControl,
  name = 'bank-account-selector',
  onItemSelect = () => null,
  organizationId: providedOrganizationId,
  preselectedId = '',
  withSelectEvent,
  ...rest
}: BankAccountSelectorProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const organizationId = useSelector((state: State) => state.app.organization)?.id || ''
  const accounts: Account[] = useSelector(
    createSelector(
      (state: State) => state.app.accounts,
      (accounts: Account[] | undefined) => accounts?.filter(({ isBankAccount }) => isBankAccount) || [],
    ),
  )

  const handleSelect = (item: Account, name: string) => {
    onItemSelect(item, name)
  }

  useEffect(() => {
    if (organizationId) {
      dispatch(accountsRequested({ organizationId }))
    }
  }, [dispatch, organizationId])

  const items = getItemsForSelect<Account>(accounts)

  return (
    <SmartSelect<Account>
      defaultValueId={preselectedId}
      formControl={formControl}
      items={items}
      minWidth="200px"
      name={name}
      onItemSelect={handleSelect}
      placeholder={t('app.accounts.bankaccount_selector.selectaccount')}
      preselectedId={preselectedId}
      disconnect={!!formControl || disconnect}
      withSelectEvent={withSelectEvent}
      {...rest}
    />
  )
}
