import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'
import { BillsQueryProps } from 'src/modules/bills/types/billsQueryProps'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { useUserOrganization } from '../../../../app/organization'
import { BillState } from '../../../enums/billState'
import { BillStatus } from '../../../enums/billStatus'
import { fetchBills } from '../../../query-api'
import { getBillsQueryKey } from '../../../utils/getBillsQueryKey'

export const useIsBillsListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps: BillsQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      status: BillStatus.All,
      organizationId: organization?.id as string,
      state: [BillState.Approved, BillState.Draft],
    }),
    [organization],
  )

  const queryKey = useMemo(() => getBillsQueryKey(queryProps), [queryProps])

  const { data: billsCountData, isFetched: billsCountFetched } = useQuery(queryKey, () => fetchBills(queryProps), {
    enabled: !!organization,
    staleTime: defaultStaleTime,
  })

  return billsCountFetched && billsCountData?.bills.length === 0
}
