import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { store } from '../../modules'
import { Account } from '../../modules/account'
import { AccountGroupsContextProvider, AccountsContextProvider } from '../../modules/app/accounts'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { TaxRatesContextProvider } from '../../modules/app/taxrates/taxRatesContext'

const MODULE_NAME = 'account-module'

class AccountWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

type AccountProps = {
  organizationId: string
  onNavigate: EmberNavigate
}

function AccountModule({ organizationId, onNavigate }: AccountProps): ReactElement {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider>
        <HashRouter>
          <QueryClientProvider client={queryClient}>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <AccountsContextProvider organizationId={organizationId}>
                  <TaxRatesContextProvider organizationId={organizationId}>
                    <AccountGroupsContextProvider organizationId={organizationId}>
                      <GlobalFiscalYearContextProvider organizationId={organizationId}>
                        <Account />
                      </GlobalFiscalYearContextProvider>
                    </AccountGroupsContextProvider>
                  </TaxRatesContextProvider>
                </AccountsContextProvider>
              </EmberRouterContextProvider>
            </UserOrganizationContextProvider>
          </QueryClientProvider>
        </HashRouter>
      </ThemeProvider>
    </ReduxProvider>
  )
}

const AccountCustomElement = createCustomElement(AccountModule, AccountWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, AccountCustomElement)

export default AccountCustomElement
