import { useIsAnnualReportAvailable } from '../../../../../../annualReport/hooks/useIsAnnualReportAvailable'
import { SubscriptionPlan } from '../../../../../../app/organization/enums/subscriptionPlan'

export const usePaidPlansToShow = (
  isOrganizationInTrial: boolean,
  currentPlan?: SubscriptionPlan,
): SubscriptionPlan[] => {
  const isAnnualReportAvailable = useIsAnnualReportAvailable()

  let paidPlansToShow = [SubscriptionPlan.BasicPaid, SubscriptionPlan.Plus, SubscriptionPlan.Complete]
  const isPlusPlan = currentPlan === SubscriptionPlan.Plus
  const isCompletePlan = currentPlan === SubscriptionPlan.Complete
  const isProPlan = currentPlan === SubscriptionPlan.Pro
  const isPremiumPlan = currentPlan === SubscriptionPlan.Premium

  if (isOrganizationInTrial) {
    return [SubscriptionPlan.BasicPaid, SubscriptionPlan.Plus, SubscriptionPlan.Complete]
  }

  if (!currentPlan) {
    return []
  }

  if (isPlusPlan || isProPlan) {
    paidPlansToShow = [SubscriptionPlan.Plus, SubscriptionPlan.Complete]
  }

  if (isCompletePlan || isPremiumPlan) {
    paidPlansToShow = [SubscriptionPlan.Complete]
  }

  if (!isAnnualReportAvailable) {
    const paidPlansToShowExceptComplete = paidPlansToShow.filter((plan) => plan !== SubscriptionPlan.Complete)

    return paidPlansToShowExceptComplete
  }

  return paidPlansToShow
}
