import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { UmbrellaListItem } from '../../../../modules/app/umbrellas/types/umbrellaListItem'
import { FileAvatar } from '../../../FileAvatar'
import { BookkeeperValue } from '../types/bookkeeperValue'
import { getAddressContent } from './getAddressContent'
import { getContactContent } from './getContactContent'

export const getItems = (bookkeepers: UmbrellaListItem[], t: TFunction): NavItem<BookkeeperValue>[] => {
  return bookkeepers.map((bookkeeper) => {
    const { address, email, logoFileId, id, name, ownerUmbrellaName, phone = '' } = bookkeeper
    const displayTitle = `${name} - ${t('selects.bookkeeper_select.by')} ${ownerUmbrellaName}`
    const displayDescription = [getAddressContent(address), getContactContent({ email, phone: phone || undefined }, t)]

    return {
      children: displayTitle,
      id,
      searchKeywords: [id, name, ownerUmbrellaName, ...(phone ? [phone] : [])],
      value: {
        ...bookkeeper,
        displayData: {
          description: displayDescription,
          suffix: <FileAvatar logoId={logoFileId || undefined} />,
          title: displayTitle,
        },
      },
    }
  })
}
