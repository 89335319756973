import { CookieKeys } from '../enums/cookieKeys'
import { SubscriptionPlan } from '../modules/app/organization/enums/subscriptionPlan'
import { User } from '../modules/app/user'
import { postRequest } from '../utils'
import { getSignupAnalyticsData } from '../utils/getSignupAnalyticsData'

export interface SubmitSignupPayload {
  acceptedMarketing?: boolean
  analytics?: string
  baseCurrencyId?: string
  companyName?: string
  consentGiven?: boolean
  consentText?: string
  countryId?: string
  couponCodeId?: string
  email: string
  invitationToken?: string
  landingPage?: string
  locale?: string
  noOrganization?: boolean
  password: string
  phone?: string
  receiveMarketingEmails?: boolean
  signupCampaign?: string
  signupCouponId?: string
  subscriptionPlan?: SubscriptionPlan
  trialDays?: number
  umbrellaId?: string
  umbrellaName?: string
  yourName?: string
}

export interface SignupMeta {
  accessToken: string
  accessTokenExpiresIn: number
  statusCode: number
  success: boolean
  time: number
}

export interface SignupAuth {
  description: string
  id: string
  name: string
  organizationId: string | null
  userId: string
}

export interface SubmitSignupResponseData {
  user: User
  meta: SignupMeta
  oAuthActors: SignupAuth[]
  analytics: string
}

export enum SignupErrorCode {
  EmailAlreadyExists = 'EMAIL_ALREADY_EXISTS',
}

export const submitSignup = (payload: SubmitSignupPayload): Promise<SubmitSignupResponseData> => {
  const analytics = getSignupAnalyticsData(CookieKeys.BillySignupAnalytics)

  return postRequest('/v2/user/signup', { signup: { ...payload, ...analytics } }, undefined, false)
}
