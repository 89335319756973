import { BorderRadius, Color, Spacing } from '@design-system'

import styled from '@emotion/styled'

import { getImgPath } from '../../../../utils/getImgPath'
import { WIDGET_SPACING } from '../../elements/Widget/styles'

export const AnnualReportsBanner = styled.div`
  width: 100%;
  height: 120px;
  background-color: ${Color.Lavender};
  margin: ${WIDGET_SPACING / 2}px 0;
  padding: ${Spacing.L} ${Spacing.XL} ${Spacing.L};
  position: relative;
  border-radius: ${BorderRadius.L};
  background-image: url('${getImgPath('/annual-reports/banner-annual-reports.svg')}');
  background-position: bottom right 110px;
  background-size: contain;
  background-repeat: no-repeat;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-top: ${Spacing.XS};
`
export const TextWrapper = styled.div`
  margin-right: ${Spacing.XL};
`

export const ContinueWrapper = styled(TextWrapper)`
  align-self: center;
`

export const ButtonWrapper = styled.div`
  margin-top: ${Spacing.XXS};
`
export const CloseIconWrapper = styled.div`
  position: absolute;
  top: ${Spacing.S};
  right: ${Spacing.S};
`
