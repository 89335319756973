import { maxBy } from 'lodash'
import { useMemo } from 'react'

import { InvoiceTemplate } from '../types/invoiceTemplate'
import { useInvoiceTemplates } from './useInvoiceTemplates'
import { useStandardInvoiceTemplate } from './useStandardInvoiceTemplate'

const invoiceTemplateNumberMatchedPhrase = /(\d+)$/g

export const useGetCreateInvoiceTemplatePayload = () => {
  const { standardInvoiceTemplate } = useStandardInvoiceTemplate()
  const { invoiceTemplates } = useInvoiceTemplates()

  return useMemo(() => {
    const standardInvoiceTemplateName = standardInvoiceTemplate?.name || ''

    const invoiceTemplatesWithStandardName = invoiceTemplates?.filter((template) => {
      return template?.name.indexOf(standardInvoiceTemplateName) !== -1
    })

    const maxAppendedStandardTemplateNumber = maxBy(invoiceTemplatesWithStandardName, (template) =>
      template.name.match(invoiceTemplateNumberMatchedPhrase),
    )?.name.match(invoiceTemplateNumberMatchedPhrase)?.[0]

    const newInvoiceTemplateAppendedNumber = maxAppendedStandardTemplateNumber
      ? parseInt(maxAppendedStandardTemplateNumber, 10) + 1
      : 1

    const newInvoiceTemplateName = `${standardInvoiceTemplateName} ${newInvoiceTemplateAppendedNumber}`

    const createInvoiceTemplatePayload: Partial<InvoiceTemplate> = {
      ...standardInvoiceTemplate,
      name: newInvoiceTemplateName,
    }

    return { createInvoiceTemplatePayload }
  }, [standardInvoiceTemplate, invoiceTemplates])
}
