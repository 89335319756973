import toLower from 'lodash/toLower'
import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { useCurrentUser } from '../../app/user'
import { getSalaryConnectionStatus } from '../query-api'

export const useSalaryConnectionStatus = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { user, isLoading: isUserLoading } = useCurrentUser()

  const {
    data: connectionStatus,
    isLoading,
    ...rest
  } = useQuery(
    [QueryKeys.SalaryConnectionStatus, organization?.id, organization?.registrationNo],
    () =>
      getSalaryConnectionStatus(
        organization?.id as string,
        organization?.registrationNo as string,
        user?.email as string,
      ),
    {
      enabled: !!organization?.id && !!organization.registrationNo && !!user?.email,
    },
  )

  const countryId = organization?.countryId
  const { can_install: canInstall, is_connected: isConnected, can_connect: canConnect } = connectionStatus || {}

  const isSalaryAvailable = useMemo(() => {
    const isDanishOrganization = toLower(countryId) === 'dk'
    const hasSalaryIntegration = !!(canInstall || isConnected || canConnect)

    return !isLoading && hasSalaryIntegration && isDanishOrganization
  }, [isLoading, canConnect, canInstall, isConnected, countryId])

  return {
    ...rest,
    isLoading: isLoading || isOrganizationLoading || isUserLoading,
    isSalaryAvailable,
    isConnected,
    canInstall,
    canConnect,
  }
}
