import { NavItem } from '@design-system'

import { TFunction } from 'i18next'
import React from 'react'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { MenuItemText } from '../MenuItemText'
import { MenuItemExtended } from './menuItems'

export const getMenuItems = (items: MenuItemExtended[], t: TFunction): NavItem<EmberRoute>[] =>
  items.map(
    (item) =>
      ({
        id: item.id,
        children: <MenuItemText badge={item.badge}>{t(item.tLabel)}</MenuItemText>,
        title: t(item.tLabel),
        iconLeft: item.icon,
        value: item.routeTo,
        subItems: item.submenu?.length ? getMenuItems(item.submenu, t) : undefined,
      }) as NavItem<EmberRoute>,
  )
