import { TFunction } from 'i18next'
import { boolean, date, mixed, number, object, SchemaOf, string } from 'yup'

import { AccountingBasis } from '../../../../app/organization/types/accountingBasis'
import { Organization } from '../../../../app/organization/types/organization'
import { OrganizationSettings } from '../../../../app/organization/types/organizationSettings'
import { convertDuplicateBillCheckToBoolean } from './convertDuplicateBillCheck'
import { convertSupplierInvoiceNoToBoolean } from './convertSupplierInvoiceNo'
import { getDate, getFiscalYearEnd } from './formHelpers'

export interface DefaultValueOptions {
  shouldShowBankSyncSettings?: boolean
}

export interface OrganizationAccountingForm {
  accountingBasis: AccountingBasis | undefined
  bankSyncStartDate: Date | undefined
  billEmailAddress: string
  defaultBankFeeAccountId: string | undefined
  defaultBillBankAccountId: string
  firstFiscalYearEnd: number
  firstFiscalYearStart: Date | undefined
  fiscalLockingDate: Date | undefined
  fiscalYearEndMonth: number
  hasBillVoucherNo: boolean | undefined
  isDuplicateBillCheckDisabled: boolean | undefined
  isVatExempted: boolean | undefined
  salesTaxPeriod: string
  shouldShowBankSyncSettings: boolean | undefined
  supplierInvoiceNoEnabled: boolean | undefined
}

export type OrganizationAccountingFormSchema = SchemaOf<OrganizationAccountingForm>

export const getValidationSchema = (t: TFunction): OrganizationAccountingFormSchema =>
  object({
    accountingBasis: mixed().oneOf(Object.values(AccountingBasis)),
    bankSyncStartDate: date(),
    billEmailAddress: string().required(),
    defaultBankFeeAccountId: string().when('shouldSeeBankSyncSettings', (shouldSeeBankSyncSettings, schema) =>
      shouldSeeBankSyncSettings ? schema.required() : schema,
    ),
    defaultBillBankAccountId: string().required(),
    firstFiscalYearEnd: number().required(),
    firstFiscalYearStart: date().required(),
    fiscalLockingDate: date(),
    fiscalYearEndMonth: number().required(),
    hasBillVoucherNo: boolean().required(),
    isDuplicateBillCheckDisabled: boolean().required(),
    isVatExempted: boolean(),
    salesTaxPeriod: string().required(),
    shouldShowBankSyncSettings: boolean(),
    supplierInvoiceNoEnabled: boolean().required(),
  })

export const getDefaultValues = (
  organization?: Organization,
  organizationSettings?: OrganizationSettings,
  options?: DefaultValueOptions,
): OrganizationAccountingForm => ({
  accountingBasis: organization?.accountingBasis,
  bankSyncStartDate: getDate(organization?.bankSyncStartDate),
  billEmailAddress: organization?.billEmailAddress || '',
  defaultBankFeeAccountId: organization?.defaultBankFeeAccountId || undefined,
  defaultBillBankAccountId: organization?.defaultBillBankAccountId || '',
  firstFiscalYearEnd: getFiscalYearEnd(organization?.firstFiscalYearEnd),
  firstFiscalYearStart: getDate(organization?.firstFiscalYearStart),
  fiscalLockingDate: getDate(organization?.fiscalLockingDate),
  // BE has numbers 1-12 for months, need to convert to 0-11 range
  fiscalYearEndMonth: organization?.fiscalYearEndMonth ? organization.fiscalYearEndMonth - 1 : 0,
  hasBillVoucherNo: organization?.hasBillVoucherNo,
  isDuplicateBillCheckDisabled: convertDuplicateBillCheckToBoolean(organizationSettings?.isDuplicateBillCheckDisabled),
  isVatExempted: organization?.isVatExempted,
  salesTaxPeriod: organization?.salesTaxPeriod || 'month',
  shouldShowBankSyncSettings: options?.shouldShowBankSyncSettings,
  supplierInvoiceNoEnabled: convertSupplierInvoiceNoToBoolean(organizationSettings?.supplierInvoiceNoEnabled),
})
