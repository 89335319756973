import { Language, languageToLocale, localeToLanguage } from '@design-system'

import i18next from 'i18next'
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useQuery } from 'react-query'
import { useSearchParam } from 'react-use'

import { QueryKeys } from '../../../enums/queryKeys'
import { getUser } from '../../app/user'
import { SignupCouponParams } from '../../types'

const DEFAULT_LANGUAGE = 'da'

export interface EmberActionPayload {
  detail: Record<string, any>
}

export interface RegisterEmberOrganizationProps {
  organizationUrl: string
  onSuccess: () => void
  onError: (error: any) => void
}

interface SignupUser {
  email: string
  name?: string
  id: string
}

interface SignupGuideContextState {
  authorizeEmberUser: (token: string) => void
  isInternalFlow?: boolean
  isInternalUmbrellaFlow?: boolean
  language: Language
  registerEmberOrganization: (props: RegisterEmberOrganizationProps) => void
  routeUmbrellaId?: string
  setLanguage: (language: Language) => void
  setUser: (user: SignupUser) => void
  signupCouponParams: SignupCouponParams
  signupUmbrellaId?: string
  user?: SignupUser
}

export const SignupGuideContext = createContext<SignupGuideContextState | undefined>(undefined)

interface SignupGuideContextProviderProps {
  children: ReactNode
  locale?: string
  onAuthorizeUser: (payload: EmberActionPayload) => void
  onChangeLocale: (payload: EmberActionPayload) => void
  onRegisterOrganization: (payload: EmberActionPayload) => void
  routeUmbrellaId?: string
  signupCouponParams?: string
  signupUmbrellaId?: string
  userEmail?: string
  userId?: string
  userName?: string
}

export const SignupGuideContextProvider = ({
  children,
  locale,
  onAuthorizeUser,
  onChangeLocale,
  onRegisterOrganization,
  routeUmbrellaId,
  signupCouponParams: stringifiedCouponParmas,
  signupUmbrellaId,
  userEmail,
  userId,
  userName,
}: SignupGuideContextProviderProps): ReactElement => {
  const defaultUser = userId && userEmail ? ({ id: userId, email: userEmail, name: userName } as SignupUser) : undefined
  const defaultLanguage = ((locale && localeToLanguage[locale]) || i18next.language || DEFAULT_LANGUAGE) as Language

  const [user, setUser] = useState<SignupUser | undefined>(defaultUser)
  const [language, setLanguage] = useState<Language>(defaultLanguage)
  const [signupCouponParams, setSignupCouponParams] = useState<SignupCouponParams>({})
  const { data: userData } = useQuery([QueryKeys.User], getUser, { enabled: !user })
  const isInternalFlow = useSearchParam('isInternalFlow') // it means that user goes to this view from "My Organizations" page, so in most cases is creating additional organization for the same user
  const isInternalUmbrellaFlow = useSearchParam('isInternalUmbrellaFlow')

  useEffect(() => {
    i18next.changeLanguage(language)

    const locale = languageToLocale[language]

    if (locale) {
      onChangeLocale({ detail: { locale } })
    }
  }, [language, onChangeLocale])

  useEffect(() => {
    if (userData?.user && !user) {
      setUser({
        id: userData.user.id,
        email: userData.user.email,
        name: userData.user.name,
      })

      setLanguage(localeToLanguage[userData.user.locale])
    }
    // we want to be dependent only on api's refetch:
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData])

  // takes optional coupon params and stores as organizationSettings
  useEffect(() => {
    if (!stringifiedCouponParmas) {
      return
    }

    const parsedCouponParams: SignupCouponParams = JSON.parse(stringifiedCouponParmas)

    if (!parsedCouponParams.signupCouponId) {
      return
    }

    setSignupCouponParams(parsedCouponParams)
  }, [stringifiedCouponParmas])

  const registerEmberOrganization = useCallback(
    ({ organizationUrl, onSuccess, onError }: RegisterEmberOrganizationProps) => {
      onRegisterOrganization({ detail: { organizationUrl, onSuccess, onError } })
    },
    [onRegisterOrganization],
  )

  const authorizeEmberUser = useCallback(
    (token: string) => {
      onAuthorizeUser({ detail: { token } })
    },
    [onAuthorizeUser],
  )

  const value = useMemo(
    () => ({
      authorizeEmberUser,
      isInternalFlow: isInternalFlow === 'true', // it's query param so value is type of 'string'
      isInternalUmbrellaFlow: isInternalUmbrellaFlow === 'true',
      language,
      registerEmberOrganization,
      routeUmbrellaId,
      setLanguage,
      setUser,
      signupCouponParams,
      signupUmbrellaId,
      user,
    }),
    [
      authorizeEmberUser,
      isInternalFlow,
      isInternalUmbrellaFlow,
      language,
      registerEmberOrganization,
      routeUmbrellaId,
      signupCouponParams,
      signupUmbrellaId,
      user,
    ],
  )

  return <SignupGuideContext.Provider value={value}>{children}</SignupGuideContext.Provider>
}

export const useSignupGuide = () => {
  const context = useContext(SignupGuideContext)

  if (!context) {
    throw new Error('SignupGuideContextProvider is missing in the module!')
  }

  return context
}
