import { notify } from '@design-system'

import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { useForm } from '../../../../../hooks'
import { useLogin } from '../../../../../hooks/useLogin'
import { SubmitLoginResponseData } from '../../../../../query-api/login-query'
import { APIError } from '../../../../../utils'
import { getLoginFormDefaultValues, getValidationSchema, LoginForm } from '../utils/formData'

interface UseLoginFormProps {
  invitationEmail: string
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitLoginResponseData) => void
}

export const useLoginForm = ({ invitationEmail, onSuccess, onError }: UseLoginFormProps) => {
  const { t } = useTranslation()
  const { Form, FormItem, handleSubmit } = useForm({
    defaultValues: getLoginFormDefaultValues(invitationEmail),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const handleError = useCallback(() => {
    notify({ id: NotificationKeys.LoginError, message: t('error_saving'), variant: 'error' })
  }, [t])

  const { login, isLoading } = useLogin({
    onSuccess,
    onError: onError || handleError,
  })

  const handleSubmitForm = useCallback(
    ({ email, password }: LoginForm) => {
      login({ email, password })
    },
    [login],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    submitForm,
    isLoading,
  }
}
