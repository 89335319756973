import { useMutation } from 'react-query'

import { CustomEvent } from '../../../../enums/customEvent'
import { dispatchCustomEvent } from '../../../../utils/customEvent'
import { OrganizationSettingProps, OrganizationSettingsResponseData, setOrganizationSetting } from '../query-api'
import { OrganizationSettings } from '../types/organizationSettings'
import { OrganizationSettingsError } from '../types/organizationSettingsError'

interface UseUpdateOrganizationSettingsProps {
  onError?: (
    error: OrganizationSettingsError,
    variables?: OrganizationSettingProps,
    context?: OrganizationSettingsResponseData,
  ) => void
  onSettled?: () => void
  onSuccess?: () => void
  onMutate?: (
    variables: OrganizationSettingProps,
  ) => OrganizationSettingsResponseData | Promise<OrganizationSettingsResponseData>
}

export const useUpdateOrganizationSettings = (props?: UseUpdateOrganizationSettingsProps) => {
  const { onError, onMutate, onSuccess, onSettled } = props || {}
  const { mutate: update, ...rest } = useMutation(setOrganizationSetting, {
    onSuccess: (data) => {
      const updateOrganizationSettingsResponseData = data?.settings

      onSuccess?.()

      dispatchCustomEvent<Partial<OrganizationSettings>>(
        CustomEvent.OrganizationSettingsUpdatedInReactApp,
        updateOrganizationSettingsResponseData,
      )
    },
    onError,
    onMutate,
    onSettled,
  })

  return { update, ...rest }
}
