import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { store } from '../../modules'
import { AccountsContextProvider } from '../../modules/app/accounts'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { Assets } from '../../modules/assets'

class AssetsWebComponent extends DOMModel {
  @byAttrVal organizationid: string
}

type AssetsProps = {
  organizationid: string
}

function AssetsModule({ organizationid }: AssetsProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <UserOrganizationContextProvider organizationId={organizationid}>
          <AccountsContextProvider organizationId={organizationid}>
            <ReduxProvider store={store}>
              <Assets organizationId={organizationid} />
            </ReduxProvider>
          </AccountsContextProvider>
        </UserOrganizationContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const AssetsCustomElement = createCustomElement(AssetsModule, AssetsWebComponent, 'element')

customElements.get('assets-module') || customElements.define('assets-module', AssetsCustomElement)

export default AssetsCustomElement
