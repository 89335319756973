import {
  Container,
  Content as ContentComponent,
  ErrorBoundary,
  FiscalDatePicker,
  FiscalYearSelector,
} from '@components-deprecated'
import { Spacing } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { endOfYear, format, startOfYear, subYears } from 'date-fns'
import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'
import { createSelector } from 'reselect'

import { DATE_FORMAT } from '../../components-deprecated/FiscalDatePicker/helpers'
import { QueryParamKeys } from '../../enums/queryParamKeys'
import { useQueryParamsStorage } from '../../hooks/useQueryParamsStorage'
import { bankAccountSelected } from '../app/accounts/action-creators'
import { Account } from '../app/accounts/types'
import { organizationRequested } from '../app/organization/action-creators'
import { selectProvidedOrganizationIdOrDefault } from '../app/organization/selectors'
import { userBootstrapRequested } from '../app/user/action-creators'
import { SpecificState } from '../types'
import {
  bankConnectionRequested,
  bankLineLatestRequested,
  bankLinesAndTransactionsRequested,
  filtersChanged,
} from './action-creators'
import { BalanceBar, BankRecon, ModuleHeader } from './elements'
import { FormattedFiscalDateRange } from './elements/FormattedFiscalDateRange'
import { BankReconcileQueryParams, Filters } from './types'

window['__react-beautiful-dnd-disable-dev-warnings'] = true

const Content = styled(ContentComponent)`
  width: calc(100% - 2 * ${Spacing.XL});
  margin: 0 ${Spacing.XL};
`

type BankReconciliationProps = {
  organizationId: string
  accountId?: string
  onSelectBankAccount?: (account: Account) => void
  onConnectBankAccount?: () => void
  onImport?: () => void
}

const selectFiscalYearContext = createSelector(
  (state: SpecificState) => state.app.organization?.firstFiscalYearStart,
  (state: SpecificState) => state.app.organization?.firstFiscalYearEnd,
  (state: SpecificState) => state.app.organization?.fiscalYearEndMonth,
  (firstFiscalYearStart, firstFiscalYearEnd, fiscalYearEndMonth) => {
    return {
      firstFiscalYearStart,
      firstFiscalYearEnd,
      fiscalYearEndMonth,
    }
  },
)

function BankReconciliation({
  organizationId: providedOrganizationId,
  accountId,
  onSelectBankAccount = () => null,
  onConnectBankAccount = () => null,
  onImport = () => null,
}: BankReconciliationProps): React.ReactElement {
  const dispatch = useDispatch()
  const { queryParams, setQueryParam } = useQueryParamsStorage<BankReconcileQueryParams>(
    QueryParamKeys.BankReconciliation,
  )

  const account = useSelector((state: SpecificState) => state.app.account)
  const accounts = useSelector((state: SpecificState) => state.app.accounts)

  const [currentAccountId, setCurrentAccountId] = useState('')

  const lastYear = subYears(new Date(), 1)
  const {
    firstFiscalYearStart = format(startOfYear(lastYear), DATE_FORMAT),
    firstFiscalYearEnd = format(endOfYear(lastYear), DATE_FORMAT),
    fiscalYearEndMonth = 12,
  } = useSelector(selectFiscalYearContext)

  const organizationId =
    useSelector((state: SpecificState) => {
      return selectProvidedOrganizationIdOrDefault(state, providedOrganizationId)
    }) || ''

  const filters = useSelector((state: SpecificState) => state.bankReconciliation.filters)
  const { fiscalYear } = filters

  const onChangeFiscalYear = useCallback(
    (fiscalYear: number) => {
      setQueryParam('fiscalYear', fiscalYear)
    },
    [setQueryParam],
  )

  const onChangeDateRange = useCallback(
    (dateRange: number[]) => {
      dispatch(filtersChanged({ dateRange }))
    },
    [dispatch],
  )

  useEffect(() => {
    const filters: Partial<Filters> = {}

    if (queryParams.fiscalYear) {
      filters.fiscalYear = +queryParams.fiscalYear
    }

    if (queryParams.bankLinesSortProperty) {
      filters.bankLinesSortProperty = queryParams.bankLinesSortProperty
    }

    if (queryParams.bankLinesSortDirection) {
      filters.bankLinesSortDirection = +queryParams.bankLinesSortDirection
    }

    if (queryParams.transactionsSortProperty) {
      filters.transactionsSortProperty = queryParams.transactionsSortProperty
    }

    if (queryParams.transactionsSortDirection) {
      filters.transactionsSortDirection = +queryParams.transactionsSortDirection
    }

    dispatch(filtersChanged({ ...filters }))
  }, [dispatch, queryParams])

  useEffect(() => {
    if (!organizationId) {
      return
    }

    dispatch(bankLineLatestRequested())
  }, [organizationId, account, dispatch])

  useEffect(() => {
    if (!organizationId) {
      return
    }

    dispatch(bankLinesAndTransactionsRequested())
  }, [dispatch, filters, organizationId])

  useEffect(() => {
    if (!organizationId || !account?.id) {
      return
    }

    if (currentAccountId !== account.id) {
      setCurrentAccountId(account.id)
      dispatch(bankLinesAndTransactionsRequested())
    }
  }, [dispatch, organizationId, currentAccountId, account])

  useEffect(() => {
    dispatch(organizationRequested(organizationId)) // What does this exactly?
  }, [dispatch, organizationId])

  useEffect(() => {
    if (account?.id) {
      dispatch(bankConnectionRequested(organizationId, account.id))
    }
  }, [dispatch, account, organizationId])

  useEffect(() => {
    if (accounts?.length && accountId) {
      const selectedBankAccount = accounts.find((account: Account) => account.id === accountId)

      if (selectedBankAccount) {
        dispatch(bankAccountSelected(selectedBankAccount))
      }
    }
  }, [dispatch, accountId, accounts])

  useEffect(() => {
    if (account) {
      onSelectBankAccount(account)
    }
  }, [account, onSelectBankAccount])

  useEffect(() => {
    dispatch(userBootstrapRequested())
  }, [dispatch])

  return (
    <ErrorBoundary>
      <Container>
        <ModuleHeader onConnectBankAccount={onConnectBankAccount} onImport={onImport} />
        <Content py={0}>
          <BalanceBar />
        </Content>
        <Content bg="primaryBlueLight">
          <Flex justifyContent="space-between" fontSize="14px">
            <FiscalYearSelector
              fiscalYearEndMonth={fiscalYearEndMonth}
              firstFiscalYearStart={firstFiscalYearStart}
              defaultYear={filters.fiscalYear}
              onYearSelect={onChangeFiscalYear}
            />
            <Box
              css={css`
                color: #40494f;
              `}
            >
              <FormattedFiscalDateRange />
            </Box>
          </Flex>
          <FiscalDatePicker
            mt={2}
            fiscalYearEndMonth={fiscalYearEndMonth}
            firstFiscalYearStart={firstFiscalYearStart}
            firstFiscalYearEnd={firstFiscalYearEnd}
            fiscalYear={fiscalYear}
            onDateRangeChange={onChangeDateRange}
          />
        </Content>
        <Content pb="0" height="100%">
          <BankRecon />
        </Content>
      </Container>
    </ErrorBoundary>
  )
}

export default BankReconciliation
