import { DraggableItemList } from '@components'
import { Divider, FormItemsGroup, Module, ModuleGroup, Space, Timeout } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useRef } from 'react'

import { useFieldArray, useFormContext } from '../../../../hooks'
import { useUserOrganizationSettings } from '../../../app/organization'
import { BILL_FORM_BASE_MODULE_ID } from '../../constants/billFormBaseModuleId'
import { useBillForm } from './contexts/billFormContext'
import { BillAddLineButton } from './elements/BillAddLineButton'
import { BillBillDate } from './elements/BillBillDate'
import { BillBookkeepingTagSuggestion } from './elements/BillBookkeepingTagSuggestion'
import { BillCurrencySelect } from './elements/BillCurrencySelect'
import { BillDueDate } from './elements/BillDueDate'
import { BillLine } from './elements/BillLine'
import { BillNumber } from './elements/BillNumber'
import { BillReferenceNumberInput } from './elements/BillReferenceNumberInput'
import { BillRegisterPayment } from './elements/BillRegisterPayment'
import { BillStatusBadge } from './elements/BillStatusBadge'
import { BillSummary } from './elements/BillSummary'
import { BillVendor } from './elements/BillVendor'
import * as Styled from './styles'
import { BillFormLine, BillFormSchema } from './utils/formData'

const AUTO_FOCUS_TIMEOUT = 1000

export const BillEditableForm = (): ReactElement => {
  const { organizationSettings } = useUserOrganizationSettings()
  const { isCreditNote, isNewCreditNote, isCreditNoteFromBill, billId, attachmentId } = useBillForm()
  const billFormWrapperRef = useRef<HTMLDivElement>(null)
  const focusTimeoutRef = useRef<Timeout>()

  const withReferenceNumberField = organizationSettings?.supplierInvoiceNoEnabled === '1'

  const { control } = useFormContext<BillFormSchema>()
  const {
    fields: billLines,
    append: addLine,
    remove: removeLine,
    move: moveLine,
  } = useFieldArray({
    control,
    name: 'billLines',
  })

  const linesTotal = billLines.length

  const handleAddLine = useCallback(
    (billLine: BillFormLine) => {
      addLine(billLine)
    },
    [addLine],
  )

  const handleDeleteLineClick = useCallback(
    (index: number) => () => {
      removeLine(index)
    },
    [removeLine],
  )

  const handleLineOrderChange = useCallback(
    (oldIndex: number, newIndex: number) => {
      moveLine(oldIndex, newIndex)
    },
    [moveLine],
  )

  const focusOnInput = useCallback((inputOrder = 0) => {
    if (!billFormWrapperRef.current) {
      return
    }

    if (!billFormWrapperRef.current.contains(document?.activeElement)) {
      const inputs = billFormWrapperRef.current.getElementsByTagName('input')
      inputs[inputOrder]?.focus()
    }
  }, [])

  const handleNewContactCreated = useCallback(() => {
    focusOnInput(1)
  }, [focusOnInput])

  useEffect(() => {
    focusTimeoutRef.current = setTimeout(() => {
      focusOnInput()
    }, AUTO_FOCUS_TIMEOUT) // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId, attachmentId])

  useEffect(() => {
    return () => {
      if (focusTimeoutRef.current) {
        clearTimeout(focusTimeoutRef.current)
      }
    }
  }, [])

  return (
    <Styled.BillFormWrapper ref={billFormWrapperRef}>
      <ModuleGroup>
        <Module id={BILL_FORM_BASE_MODULE_ID}>
          <Styled.FormHeader>
            <BillNumber />
            <BillStatusBadge />
          </Styled.FormHeader>
          <Space size="s" />

          <FormItemsGroup itemsInRow={withReferenceNumberField ? 4 : 1}>
            <BillVendor onCreate={handleNewContactCreated} withReferenceNumberField={withReferenceNumberField} />
            {withReferenceNumberField && <BillReferenceNumberInput />}
          </FormItemsGroup>

          <FormItemsGroup itemsInRow={4}>
            <BillBillDate />
            <Styled.ItemWrapper>
              <BillDueDate />
            </Styled.ItemWrapper>
            <Styled.ItemWrapper />
            <Styled.ItemWrapper>
              <BillCurrencySelect />
            </Styled.ItemWrapper>
          </FormItemsGroup>

          <BillBookkeepingTagSuggestion />
          <Divider />

          {/* Lines: */}
          <DraggableItemList id="bill-lines" isDraggable={linesTotal > 1} onOrderChange={handleLineOrderChange}>
            {billLines.map((billLine, index) => (
              <BillLine
                id={billLine.id}
                sessionId={billLine.sessionId}
                index={index}
                key={billLine.id}
                linesTotal={linesTotal}
                onLineDelete={handleDeleteLineClick(index)}
              />
            ))}
          </DraggableItemList>

          <Space size="s" />
          <BillAddLineButton onAddLine={handleAddLine} linesTotal={linesTotal} />
          <Space size="s" />

          {!isCreditNote && !isNewCreditNote && !isCreditNoteFromBill && <BillRegisterPayment />}
        </Module>

        <BillSummary />
      </ModuleGroup>
    </Styled.BillFormWrapper>
  )
}
