import { useOrganizationSubscription } from '../../../../../../../hooks'
import { SubscriptionPeriod } from '../../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../../app/organization/enums/subscriptionPlan'

export const useIsCurrentPlan = (plan: SubscriptionPlan) => {
  const { subscriptionPlan: currentPlan, subscriptionPeriod: currentPlanPeriod } = useOrganizationSubscription()

  const isCurrentPlan = currentPlan === plan
  const isCurrentPlanOnYearlyPayment = currentPlanPeriod === SubscriptionPeriod.Yearly

  return { isCurrentPlan, isCurrentPlanOnYearlyPayment }
}
