import { Container, ErrorBoundary } from '@components-deprecated'

import React, { ReactElement } from 'react'

import { AssetForm } from './elements'

type AssetsCreateProps = {
  organizationId: string
}

export const AssetsCreate = ({ organizationId }: AssetsCreateProps): ReactElement => (
  <>
    <ErrorBoundary>
      <Container>
        <AssetForm organizationId={organizationId} />
      </Container>
    </ErrorBoundary>
  </>
)
