import { Button, ButtonsGroup, Modal, Text, useModal } from '@design-system'

import { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { WarningModalRequiredProps } from '../../../../../../hooks/useWarningModals'
import { useUserOrganization } from '../../../../../app/organization'
import { getTranslations } from './utils/getTranslations'

type VATConfirmationModalProps = WarningModalRequiredProps

export const VATConfirmationModal = ({ onOk, id }: VATConfirmationModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close } = useModal(id)
  const currentIsVatExempted = organization ? organization.isVatExempted : false
  const translations = useMemo(() => getTranslations(currentIsVatExempted, t), [currentIsVatExempted, t])

  const handleSave = useCallback(() => {
    onOk?.(id)
    close()
  }, [close, id, onOk])

  const handleCancel = useCallback(() => {
    close()
  }, [close])

  return (
    <Modal closable id={id} size="s">
      <Modal.Header title={t(`${translations.header}`)}></Modal.Header>
      <Modal.Body>
        <Text>{t(`${translations.body}`)}</Text>
      </Modal.Body>
      <Modal.Footer>
        <ButtonsGroup>
          <Button danger onClick={handleCancel}>
            {t('settings.organization.accounting.vat_confirmation_modal.disapprove')}
          </Button>
          <Button onClick={handleSave}>{t('settings.organization.accounting.vat_confirmation_modal.approve')}</Button>
        </ButtonsGroup>
      </Modal.Footer>
    </Modal>
  )
}
