import { css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { Image } from 'rebass'

import { Text } from '../../../components-deprecated/Typography'
import { SpecificState } from '../../types'
import { getBankLogoUrl } from '../utils'

export const BankLogo = () => {
  const { t } = useTranslation()
  const [imageUrl, setImageUrl] = useState<string | null>(null)
  const account = useSelector((state: SpecificState) => state.app.account)

  useEffect(() => {
    ;(async () => {
      setImageUrl(!account?.bankId ? null : await getBankLogoUrl(account.bankId))
    })()
  }, [account])

  if (imageUrl) {
    return (
      <Image
        src={imageUrl}
        height={'30px'}
        maxHeight={'30px'}
        mt={'4px'}
        mb={'9.5px'}
        mx={'auto'}
        opacity={0.5}
        display={'block'}
        css={css`
          filter: grayscale(1);
        `}
      />
    )
  }

  return (
    <Text
      css={css`
        font-size: 24px;
        text-align: center;
      `}
      color={'#364A59'}
      mt={'7px'}
      mb={'12.5px'}
      opacity={0.5}
    >
      {t('bankreconciliation.bank.your_bank')}
    </Text>
  )
}
