import { Radio, RadioGroup, Space, Text } from '@design-system'

import { ChangeEvent, ReactElement, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Difference, DifferenceType } from '../../../../../bankReconciliation/types'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { differenceTypeTKeys } from './constants/differenceTypeTKeys'
import * as Styled from './styles'

interface DifferenceSelectorProps {
  difference: Difference
}

export const DifferenceSelector = ({ difference }: DifferenceSelectorProps): ReactElement => {
  const { t } = useTranslation()
  const { currencyId, contact, selectedDifferenceType, setSelectedDifferenceType } = useBillReconciliation()

  const isDifferencePositive = (difference?.differenceAmount || 0) > 0

  const differenceHeaderTKey = useMemo(() => {
    let key = 'bill.payment_reconcile.difference.'
    if (!isDifferencePositive) {
      key += 'positive.'
    } else {
      key += 'negative.'
    }

    if (!selectedDifferenceType) {
      key += 'not_selected'
      return key
    }

    switch (selectedDifferenceType) {
      case 'bankFee':
        key += 'bank_fee'
        break
      case 'overpayment':
        key += 'overpayment'
        break
      case 'underpayment':
        key += 'underpayment'
        break
      default:
        break
    }

    return key
  }, [isDifferencePositive, selectedDifferenceType])

  const handleDifferenceTypeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value as DifferenceType
      setSelectedDifferenceType(value)
    },
    [setSelectedDifferenceType],
  )

  useEffect(() => {
    setSelectedDifferenceType(undefined)
  }, [difference, setSelectedDifferenceType])

  return (
    <Styled.DifferenceSelectorWrapper>
      <Text>
        {t(differenceHeaderTKey, {
          difference: Math.abs(difference?.differenceAmount || 0),
          currency: currencyId,
          contactName: contact?.name,
        })}
      </Text>
      <Space size="m" />
      <RadioGroup onChange={handleDifferenceTypeChange}>
        {difference.types.map((type) => {
          return (
            <Radio value={type} name={type} key={type} checked={type === selectedDifferenceType}>
              {t(differenceTypeTKeys[type])}
            </Radio>
          )
        })}
      </RadioGroup>
    </Styled.DifferenceSelectorWrapper>
  )
}
