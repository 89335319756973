import { Color, NavItem, NavItemAccessor, Text } from '@design-system'

import { TFunction } from 'i18next'

import { Contact } from '../../../../modules/contacts/types/contact'
import * as Styled from '../styles'
import { ContactValue } from '../types/contactValue'

export const getItems = (contacts: Contact[], t: TFunction, isPagesLimitItem?: boolean): NavItem<ContactValue>[] => {
  const items: NavItem<ContactValue>[] = []

  for (const contact of contacts) {
    const displayTitle = contact.name
    const searchKeywords = [contact.id, displayTitle]

    items.push({
      id: contact.id,
      children: displayTitle,
      searchKeywords,
      value: {
        ...contact,
        displayData: {
          title: displayTitle,
        },
      },
    })
  }

  if (isPagesLimitItem) {
    items.push({
      accessor: NavItemAccessor.Unselectable,
      id: NavItemAccessor.Unselectable,
      children: t('contact_select.footer.pages_limit'),
      readonly: true,
      value: {
        displayData: {
          title: (
            <Styled.DropdownPagesLimit>
              <Text color={Color.Gray60} alignment="center">
                {t('contact_select.footer.pages_limit')}
              </Text>
            </Styled.DropdownPagesLimit>
          ),
        },
      } as ContactValue,
    })
  }

  return items
}
