import { convertBookkeepingTagDbValueToNavItemValue } from '@components'

import { Account } from '../../../../app/accounts/types'
import { ChartOfAccountId } from '../../../../app/organization/enums/chartOfAccountId'
import { TaxRate } from '../../../../app/taxrates/types'
import { Contact } from '../../../../contacts/types/contact'
import { BillFormPartial } from './formData'
import { getDataFromBookkeepingTag } from './getDataFromBookkeepingTag'

interface GetBillFormFromVendorDataProps {
  accounts?: Account[]
  billForm: BillFormPartial
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  isVatExempted?: boolean
  locale: string
  taxRates?: TaxRate[]
  vendor: Partial<Contact>
}

export const getBillFormFromVendorData = async ({
  accounts,
  billForm,
  chartOfAccountId,
  countryId,
  isVatExempted,
  locale,
  taxRates,
  vendor,
}: GetBillFormFromVendorDataProps): Promise<BillFormPartial> => {
  const {
    currencyId,
    defaultExpenseProductDescription: defaultDescription,
    defaultExpenseAccountId: accountId,
    defaultTaxRateId: taxRateId,
    defaultBookkeepingTag: bookkeepingTag,
  } = vendor
  const defaultExpenseCategory = accounts?.find((account) => account.id === accountId)
  const defaultTaxRate = taxRates?.find((taxRate) => taxRate.id === taxRateId)
  const defaultBookkeepingTag = bookkeepingTag
    ? await convertBookkeepingTagDbValueToNavItemValue(JSON.parse(bookkeepingTag), locale, chartOfAccountId, countryId)
    : undefined
  const billLines: BillFormPartial['billLines'] = []

  if (billForm.billLines?.length) {
    for (let i = 0; i < billForm.billLines.length; i++) {
      billLines.push({})
      const billLine = billForm.billLines[i]
      const bookkeepingTagValue = billLine.bookkeepingTag
      const autoCompletedFields = billForm.autoCompletedFields

      // also - set default values for the first line if not set by bookeeping tag
      // otherwise enforce empty
      if (i === 0) {
        billLines[0] = {
          ...billLine,
          description: defaultDescription || undefined,
          expenseCategory: defaultExpenseCategory || undefined,
          taxRate: defaultTaxRate || undefined,
          bookkeepingTag: defaultBookkeepingTag || undefined,
        }
      }

      // if bill line has 'bookkeepingTagValue' set up, re-check it as we may need to change
      // this data based on the new vendor's region
      if (bookkeepingTagValue) {
        const currentBillLine = billLines[i]

        const { account, taxRate } = getDataFromBookkeepingTag({
          accounts,
          bookkeepingTagValue,
          isVatExempted,
          taxRates,
          vendor,
        })

        const expenseCategoryKey = `billLines.${i}.expenseCategory`
        const taxRateKey = `billLines.${i}.taxRate`

        // if vendor has its own defaults, category and tax rate should be already rewritten
        // may be empty by default, thus the autocompletion check
        // otherwise rewrite as it is a user input
        if (account && !currentBillLine.expenseCategory && !autoCompletedFields?.[expenseCategoryKey]) {
          currentBillLine.expenseCategory = account
        }

        if (taxRate && !currentBillLine.taxRate && !autoCompletedFields?.[taxRateKey]) {
          currentBillLine.taxRate = taxRate
        }
      }
    }
  } else {
    // if there wasn't any bill line, set some default ones if available
    billLines.push({
      ...(defaultDescription ? { description: defaultDescription } : {}),
      ...(defaultExpenseCategory ? { expenseCategory: defaultExpenseCategory } : {}),
      ...(defaultTaxRate ? { taxRate: defaultTaxRate } : {}),
      ...(defaultBookkeepingTag ? { bookkeepingTag: defaultBookkeepingTag } : {}),
    })
  }

  return {
    ...(currencyId ? { currencyId } : {}),
    billLines,
  }
}
