import { User } from '../../../../app/user'
import { NameParts } from '../types/nameParts'

// TODO: consider a better approach, e.g. storing name parts separately
export const getUserNameParts = (user: User | undefined): NameParts => {
  const [firstName, lastName] = user?.name?.split(' ', 2) || []

  return {
    firstName: firstName ?? '',
    lastName: lastName ?? '',
  }
}
