import { Logo } from '@components'
import { FlexLeft, FlexRight, SearchBoxIcon, Tooltip } from '@components-deprecated'
import { ButtonsGroup, Spacing } from '@design-system'

import { css } from '@emotion/core'
import React, { useCallback, useState } from 'react'
import { DragDropContext, DragStart, DragUpdate, DropResult } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Flex } from 'rebass'
import { createSelector } from 'reselect'

import { store } from '../../../modules/'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import {
  bankLinesAndTransactionsRequested,
  createPlaceholder,
  deleteBankLines,
  destroyPlaceholders,
  dndBankLines,
  dndBillyTransactions,
  filtersChanged,
} from '../action-creators'
import { updateBankLines } from '../query-api'
import { BankLine, ReceiptState } from '../types'
import { cleanId } from '../utils'
import { BankLineGroupFiltersPanel } from './BankLineGroupFiltersPanel'
import { BankLogo } from './BankLogo'
import { BulkReconciliationButton } from './BulkReconciliationButton'
import { DownloadLinesButton } from './DownloadLinesButton'
import { FilterDropdownButton } from './FilterDropdownButton'
import { MatchList } from './MatchList'
import { ReconcilablePostingFiltersPanel } from './ReconcilablePostingFiltersPanel'
import { ReconcileAllButton } from './ReconcileAllButton'
import { SelectionPanelActions } from './SelectionPanelActions'
import * as Styled from './styles'
import { ToggleDeletionModeButton } from './ToggleDeletionModeButton'
import { ToggleMissingReceiptsModeButton } from './ToggleMissingReceiptsModeButton'
import { TransactionList } from './TransactionList'

export const BankRecon = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [matchDeletionMode, setMatchDeletionMode] = useState<boolean>(false)
  const [selectedIds, setSelectedIds] = useState<string[]>([])
  const [isMissingReceiptsMode, setMissingReceiptsMode] = useState(false)
  const bankLineMatches = useSelector(
    createSelector(
      (state: SpecificState) => state.bankReconciliation.bankLineMatches,
      (bankLineMatches) => bankLineMatches,
    ),
  )
  const bankLineMatchCount = bankLineMatches?.length

  const handleOnDragStart = useCallback(
    ({ source, type }: DragStart) => {
      dispatch(createPlaceholder({ source, type }))
    },
    [dispatch],
  )

  const onChangeBankLineGroupSearchValue = useCallback(
    (bankLineGroupSearchValue: string) => {
      dispatch(filtersChanged({ bankLineGroupSearchValue }))
    },
    [dispatch],
  )

  const onChangeReconcilablePostingSearchValue = useCallback(
    (reconcilablePostingSearchValue: string) => {
      dispatch(filtersChanged({ reconcilablePostingSearchValue }))
    },
    [dispatch],
  )

  const handleOnDragUpdate = useCallback((update: DragUpdate) => null, [])

  const handleOnDragEnd = useCallback(
    ({ destination, source, draggableId, type }: DropResult) => {
      // Destroy any eventual temporary placeholders
      dispatch(destroyPlaceholders())

      // Dropped outside a droppable
      if (!destination) {
        return
      }

      // Dropped in the same group or source
      if (destination.droppableId === source.droppableId) {
        return
      }

      // Otherwise...
      if (type === 'bankline') {
        dispatch(
          dndBankLines({
            id: draggableId,
            matchId: destination.droppableId,
            sourceMatchId: source.droppableId,
          }),
        )
      } else if (type === 'billytransaction') {
        const state = store.getState() as SpecificState
        const billyTransactions = state.bankReconciliation.billyTransactions || []
        const bankLineSubjectAssociations = state.bankReconciliation.bankLineSubjectAssociations || []
        const [, rawSourceMatchId] = cleanId(source.droppableId)
        const billyTransaction = billyTransactions?.find((transaction) => transaction.id === draggableId)
        const bankLineSubjectAssociation = bankLineSubjectAssociations?.find((blsa) => {
          return blsa.matchId === rawSourceMatchId && blsa.subjectReference.split(':')[1] === draggableId
        })

        dispatch(
          dndBillyTransactions({
            id: draggableId,
            matchId: destination.droppableId,
            sourceMatchId: source.droppableId,
            billyTransaction,
            bankLineSubjectAssociation,
          }),
        )
      }
    },
    [dispatch],
  )

  const requestDeleteSelectedLines = useCallback(() => {
    if (selectedIds.length) {
      dispatch(deleteBankLines({ matchIds: selectedIds }))
    }
  }, [dispatch, selectedIds])

  const enableMissingReceiptsMode = useCallback(() => {
    setMissingReceiptsMode(true)
  }, [])

  const getBankLinesForUpdate = useCallback(() => {
    return selectedIds.reduce((accumulator: BankLine[], selectedId) => {
      const { bankLines } = bankLineMatches.find(({ id }) => selectedId === id) || {}
      const result = [...accumulator]

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      if (bankLines) {
        for (const { comments, ...bankLine } of bankLines) {
          result.push({ ...bankLine, receiptState: ReceiptState.MissingReceipt })
        }
      }

      return result
    }, [])
  }, [bankLineMatches, selectedIds])

  const handleEditFinish = useCallback(async () => {
    if (isMissingReceiptsMode) {
      setMissingReceiptsMode(false)
      await updateBankLines(getBankLinesForUpdate())
      dispatch(bankLinesAndTransactionsRequested())
    } else if (matchDeletionMode) {
      setMatchDeletionMode(false)
    }
  }, [dispatch, getBankLinesForUpdate, isMissingReceiptsMode, matchDeletionMode])

  return (
    <DragDropContext onDragStart={handleOnDragStart} onDragUpdate={handleOnDragUpdate} onDragEnd={handleOnDragEnd}>
      <Tooltip />
      <Box
        className={reactClass('bankrecon-lists')}
        css={css`
          height: 100%;
          display: grid;
          grid-template-columns: 520fr 380fr;
          grid-template-rows: 100px 1fr;
          column-gap: 80px;
        `}
      >
        <Box>
          <BankLogo />
          <Flex
            className={reactClass('list-filters')}
            css={css`
              position: relative;
              justify-content: space-between;
            `}
          >
            <Styled.NavSlider visible={matchDeletionMode || isMissingReceiptsMode}>
              {(matchDeletionMode || isMissingReceiptsMode) && (
                <SelectionPanelActions
                  isDeleteMode={matchDeletionMode}
                  onSelectionChange={setSelectedIds}
                  onDelete={matchDeletionMode ? requestDeleteSelectedLines : undefined}
                  onEditFinish={handleEditFinish}
                  selectedIds={selectedIds}
                />
              )}
            </Styled.NavSlider>
            <FlexLeft
              alignItems={'center'}
              css={css`
                font-size: 14px;
              `}
            >
              {t('bankreconciliation.matchgroups.totalgroups', { count: bankLineMatchCount ?? 10 })}
              <Flex alignItems="center" flexDirection="row" ml="20px">
                <ButtonsGroup>
                  <ToggleDeletionModeButton onClick={() => setMatchDeletionMode(true)} />
                  <DownloadLinesButton />
                  <ToggleMissingReceiptsModeButton onClick={enableMissingReceiptsMode} />
                  <BulkReconciliationButton />
                  <ReconcileAllButton />
                </ButtonsGroup>
              </Flex>
            </FlexLeft>
            <SearchBoxIcon
              mr={Spacing.S}
              onValueChange={onChangeBankLineGroupSearchValue}
              placeholder={t('search_box.default_placeholder')}
            />
            <FilterDropdownButton dropdownOrigin={'right'}>
              <BankLineGroupFiltersPanel />
            </FilterDropdownButton>
          </Flex>
        </Box>
        <Box mt={-Spacing.XXXS}>
          <Styled.BankLogoWrapper>
            <Logo />
          </Styled.BankLogoWrapper>
          <FlexRight
            className={reactClass('list-filters')}
            mt={'12.5px'}
            css={css`
              margin-top: 12.5px;
              padding-bottom: 10px;
            `}
          >
            <SearchBoxIcon
              mr={Spacing.S}
              onValueChange={onChangeReconcilablePostingSearchValue}
              placeholder={t('search_box.default_placeholder')}
            />
            <FilterDropdownButton dropdownOrigin={'right'}>
              <ReconcilablePostingFiltersPanel />
            </FilterDropdownButton>
          </FlexRight>
        </Box>
        <MatchList
          bulkSelectionMode={matchDeletionMode || isMissingReceiptsMode}
          isDeleteMode={matchDeletionMode}
          onChangeSelectedIds={setSelectedIds}
          selectedIds={selectedIds}
        />
        <TransactionList />
      </Box>
    </DragDropContext>
  )
}
