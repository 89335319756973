import {
  createContext,
  Dispatch,
  ReactElement,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { Difference } from '../../../../bankReconciliation/types'
import { getBillReconciliationDifference } from '../../../utils/getBillReconciliationDifference'
import { useMultipleBills } from './multipleBillsContext'

interface ContextState {
  hasManualPaymentDifference: boolean
  manualPaymentAmount: number
  manualPaymentDifference: Difference
  setManualPaymentAmount: Dispatch<SetStateAction<number>>
}

const ManualPaymentContext = createContext<ContextState | undefined>(undefined)

interface ManualPaymentContextProps {
  children?: ReactNode
}

export const ManualPaymentContextProvider = ({ children }: ManualPaymentContextProps): ReactElement => {
  const { selectedBillsAmountsSum } = useMultipleBills()
  const [manualPaymentAmount, setManualPaymentAmount] = useState(0)

  const manualPaymentDifference = useMemo(() => {
    return getBillReconciliationDifference({
      billsAmount: selectedBillsAmountsSum,
      reconciliationAmount: manualPaymentAmount,
    })
  }, [manualPaymentAmount, selectedBillsAmountsSum])

  const hasManualPaymentDifference = useMemo(() => {
    return !!manualPaymentDifference?.differenceAmount
  }, [manualPaymentDifference?.differenceAmount])

  useEffect(() => {
    if (selectedBillsAmountsSum) {
      setManualPaymentAmount(selectedBillsAmountsSum)
    }
  }, [selectedBillsAmountsSum])

  return (
    <ManualPaymentContext.Provider
      value={{
        hasManualPaymentDifference,
        manualPaymentAmount,
        manualPaymentDifference,
        setManualPaymentAmount,
      }}
    >
      {children}
    </ManualPaymentContext.Provider>
  )
}

export const useManualPayment = () => {
  const context = useContext(ManualPaymentContext)

  if (!context) {
    throw new Error('ManualPaymentContextProvider is missing in the module!')
  }

  return context
}
