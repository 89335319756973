import { NotificationSettings } from '../../../../../../app/user'
import { NotificationSettingsKey, NotificationSettingsTableRows } from '../../../types/notificationSettingsTypes'

// These are not either not editable or edited from a different button
const notificationSettingsKeysToSkip: NotificationSettingsKey[] = [
  'bankAccountLinesPull',
  'muted',
  'organizationSpecific',
  'sessionRenewalRequired',
]

const filterSettingsKeys = (notificationSettings: NotificationSettings) => {
  const notificationSettingsKeys = Object.keys(notificationSettings) as NotificationSettingsKey[]
  return notificationSettingsKeys.filter(
    (notificationSettingsKey: NotificationSettingsKey) =>
      !notificationSettingsKeysToSkip.includes(notificationSettingsKey),
  )
}

export const convertSettings = (notificationSettings: NotificationSettings) => {
  // Filter out keys that will not be shown
  const filteredKeys = filterSettingsKeys(notificationSettings)

  return filteredKeys.reduce((result: NotificationSettingsTableRows[], key: NotificationSettingsKey) => {
    result.push({
      notificationSettingsKey: key as keyof NotificationSettings,
      value: notificationSettings[key] === 'yes',
    })
    return result
  }, [])
}
