import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { AccountsContextProvider } from '../../modules/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '../../modules/app/organization'
import { IsTrustedPartnerContextProvider } from '../../modules/app/umbrellas'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { AgerasFinanceCustomerContextProvider } from '../../modules/financing/contexts/agerasFinanceContext'
import { Integrations } from '../../modules/integrations'
import {
  EmberActivateIntegration,
  EmberDeactivateIntegration,
  EmberIntegrationContextProvider,
  EmberReloadPaymentMethods,
} from '../../modules/integrations/context/IntegrationContext'

const MODULE_NAME = 'integrations-module'

class IntegrationsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('activateIntegration') activateIntegration: Function
  @registerEvent('deactivateIntegration') deactivateIntegration: Function
  @registerEvent('reloadPaymentMethods') reloadPaymentMethods: Function
}

type IntegrationsProps = {
  organizationId: string
  onNavigate: EmberNavigate
  onActivateIntegration: EmberActivateIntegration
  onDeactivateIntegration: EmberDeactivateIntegration
  onReloadPaymentMethods: EmberReloadPaymentMethods
}

const IntegrationsModule = ({
  organizationId,
  onNavigate,
  onActivateIntegration,
  onDeactivateIntegration,
  onReloadPaymentMethods,
}: IntegrationsProps): ReactElement => (
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <ModalContextProvider>
          <EmberIntegrationContextProvider
            onActivateIntegration={onActivateIntegration}
            onDeactivateIntegration={onDeactivateIntegration}
            onReloadPaymentMethods={onReloadPaymentMethods}
          >
            <CurrentUserContextProvider>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                  <AgerasFinanceCustomerContextProvider organizationId={organizationId}>
                    <IsTrustedPartnerContextProvider organizationId={organizationId}>
                      <AccountsContextProvider organizationId={organizationId}>
                        <Integrations />
                      </AccountsContextProvider>
                    </IsTrustedPartnerContextProvider>
                  </AgerasFinanceCustomerContextProvider>
                </UserOrganizationSettingsContextProvider>
              </UserOrganizationContextProvider>
            </CurrentUserContextProvider>
          </EmberIntegrationContextProvider>
        </ModalContextProvider>
      </EmberRouterContextProvider>
    </QueryClientProvider>
  </ThemeProvider>
)

const IntegrationsCustomElement = createCustomElement(IntegrationsModule, IntegrationsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, IntegrationsCustomElement)

export default IntegrationsCustomElement
