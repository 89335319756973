import { Translate } from '@components'
import { FlipSwitch } from '@components-deprecated'
import { Button, Color } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { getClassName } from '../../../../utils/getClassName'
import { Company } from '../../../app/companies/types'
import { Country } from '../../../app/countries/types'
import { Currency } from '../../../app/currencies/types'
import { useUserUmbrellas } from '../../../app/umbrellas'
import { useSignupGuide } from '../../context/signupGuideContext'
import { useInternalUmbrellaId } from '../../hooks/useInternalUmbrellaId'
import { useTrackEventOnLoad } from '../../hooks/useTrackEventOnLoad'
import { ErrorMessage } from '../ErrorMessage'
import { GuideFormWrapper } from '../GuideFormWrapper'
import { GuideHeader } from '../GuideHeader'
import { GuideLayout } from '../GuideLayout'
import { OrganizationCVRData } from './elements/OrganizationCVRData'
import { OrganizationManualData } from './elements/OrganizationManualData'
import { SubscriptionSection } from './elements/SubscriptionSection'
import { useHandleCreateOrganizationSubmit } from './hooks/useHandleCreateOrganizationSubmit'
import * as Styled from './styles'
import { CreateOrganizationForm, defaultValues, getValidationSchema } from './utils/formData'

const DEFAULT_COUNTRY: Partial<Country> = { id: 'DK' }
const DEFAULT_CURRENCY: Partial<Currency> = { id: 'DKK' }

export const CreateOrganization = (): ReactElement => {
  const { t } = useTranslation()
  const { isInternalFlow, isInternalUmbrellaFlow, user } = useSignupGuide()
  const { userUmbrellas } = useUserUmbrellas()
  const umbrellaId = useInternalUmbrellaId()
  const companySelectTextRef = useRef('')

  const validationSchema = useMemo(() => getValidationSchema(t), [t])

  const form = useForm<CreateOrganizationForm>({
    defaultValues,
    resolver: yupResolver(validationSchema),
  })

  const userName = user?.name
  const userNameForm = form.watch('userName')
  const isUserNameInputHidden = (isInternalFlow || isInternalUmbrellaFlow) && !!userName && !!userNameForm

  const umbrellaName = useMemo(() => {
    if (!isInternalUmbrellaFlow) {
      return
    }

    return userUmbrellas.find((umbrella) => umbrella.umbrellaId === umbrellaId)?.name
  }, [isInternalUmbrellaFlow, umbrellaId, userUmbrellas])

  // Mutations

  const {
    submit,
    errorCode: submitErrorCode,
    isLoading: isSubmitting,
    isError: isSubmitError,
  } = useHandleCreateOrganizationSubmit()

  // Computed values

  const currentFormValue = form.watch()
  const isFormValid = validationSchema.isValidSync(currentFormValue)
  const isManualMode = currentFormValue.isManualMode

  // Lifecycle

  useTrackEventOnLoad('Signup Organization Create Viewed (FE)')

  useEffect(() => {
    if (userName) {
      form.setValue('userName', userName)
    }
  }, [form.setValue, userName, form])

  useEffect(() => {
    if (isInternalUmbrellaFlow) {
      form.setValue('paidBy', 'admin')
    }
    // All required deps are used
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.setValue, isInternalUmbrellaFlow])

  // Functions

  const clearOrganizationData = useCallback(
    (isManualMode: boolean) => {
      if (!isManualMode) {
        companySelectTextRef.current = ''
      }
      const formValues = form.getValues()
      const prevUserName = formValues.userName || userName
      const prevPaidBy = formValues.paidBy
      form.reset({
        ...defaultValues,
        orgName: companySelectTextRef.current,
        userName: prevUserName,
        isManualMode,
        paidBy: prevPaidBy,
        ...(isManualMode ? { orgCountry: DEFAULT_COUNTRY, orgCurrency: DEFAULT_CURRENCY } : {}),
      })
    },
    [form.reset, form.getValues, userName],
  )

  const handleManualModeSwitcherClick = useCallback(() => {
    clearOrganizationData(!isManualMode)
  }, [isManualMode, clearOrganizationData])

  const setFiscalYearData = useCallback(
    (company: Company) => {
      if (company.fiscalYearEnd) {
        const fiscalYearEndMonth = company.fiscalYearEnd.substring(0, 2)
        form.setValue('orgFiscalYearEndMonth', fiscalYearEndMonth)
      }

      if (company.firstFiscalYearStart) {
        form.setValue('orgFirstFiscalYearStart', company.firstFiscalYearStart)
      }

      if (company.firstFiscalYearEnd) {
        form.setValue('orgFirstFiscalYearEnd', company.firstFiscalYearEnd)
      }
    },
    [form.setValue],
  )

  const handleCompanySelectTextChange = useCallback((value: string) => {
    companySelectTextRef.current = value
  }, [])

  const handleCompanySelect = useCallback(
    (company: Company) => {
      if (!company) {
        companySelectTextRef.current = ''
        form.reset(defaultValues)
        return
      }

      companySelectTextRef.current = company.name
      form.setValue('orgName', company.name)
      form.setValue('orgStreet', company.street + ' ' + company.number)
      form.setValue('orgZipcode', company.zipcode)
      form.setValue('orgCity', company.city)
      form.setValue('orgCountry', DEFAULT_COUNTRY)
      form.setValue('orgCurrency', DEFAULT_CURRENCY)
      setFiscalYearData(company)
    },
    [form.setValue, form.reset, setFiscalYearData],
  )

  const handleFormSubmit = useCallback(
    (values: CreateOrganizationForm) => {
      submit(values)
    },
    [submit],
  )

  const getGuideHeaderTitle = useCallback(() => {
    if (isInternalFlow) {
      return t('signup_guide.create_internal_org.title')
    }

    if (isInternalUmbrellaFlow) {
      return t('signup_guide.create_internal_umbrella_org.title')
    }

    return t('signup_guide.create_org.title')
  }, [isInternalFlow, isInternalUmbrellaFlow, t])

  const getGuideHeaderDescription = useCallback(() => {
    if (isInternalFlow) {
      return t('signup_guide.create_internal_org.description')
    }

    if (isInternalUmbrellaFlow) {
      return t('signup_guide.create_internal_umbrella_org.description', { name: umbrellaName })
    }

    return t('signup_guide.create_org.description')
  }, [isInternalFlow, isInternalUmbrellaFlow, t, umbrellaName])

  return (
    <GuideLayout autoFocus>
      <FormProvider {...form}>
        <GuideHeader title={getGuideHeaderTitle()} description={getGuideHeaderDescription()} />

        <GuideFormWrapper onSubmit={form.handleSubmit(handleFormSubmit)}>
          {(!!submitErrorCode || isSubmitError) && (
            <ErrorMessage errorCode={submitErrorCode} defaultMessage={t('signup_guide.create_org.error_create_org')} />
          )}

          <Styled.Input
            formControl={form.control}
            name="userName"
            label={t('signup_guide.create_org.form.name.label')}
            placeholder={t('signup_guide.create_org.form.name.placeholner')}
            disabled={isSubmitting}
            hidden={isUserNameInputHidden}
            inputSize="xl"
          />
          <Styled.CompanySelector
            formControl={form.control}
            onTextChange={handleCompanySelectTextChange}
            onItemSelect={handleCompanySelect}
            name="orgCompany"
            label={t('signup_guide.create_org.form.cvr.label')}
            placeholder={t('signup_guide.create_org.form.cvr.placeholner')}
            disabled={isManualMode || isSubmitting}
            inputSize="xl"
            listWidth="100%"
            withSelectEvent
          />

          <Styled.DetailsSwitcherWrapper>
            <Styled.SwitcherTitle className={getClassName('label-deprecated')} color={Color.Gray90}>
              <Translate value="signup_guide.create_org.form.manual_switcher" />
            </Styled.SwitcherTitle>

            <FlipSwitch
              formControl={form.control}
              name="isManualMode"
              onClick={handleManualModeSwitcherClick}
              checked={isManualMode}
            />
          </Styled.DetailsSwitcherWrapper>

          <OrganizationCVRData formControl={form.control} visible={!isManualMode && !!currentFormValue.orgCompany} />
          <OrganizationManualData formControl={form.control} visible={isManualMode} disabled={isSubmitting} />

          {isInternalUmbrellaFlow && !!umbrellaId && (
            <SubscriptionSection formControl={form.control} disabled={isSubmitting} />
          )}

          <Styled.ButtonWrapper>
            <Button type="submit" size="xl" disabled={!isFormValid} loading={isSubmitting} fullWidth>
              {isInternalFlow || isInternalUmbrellaFlow
                ? t('signup_guide.create_internal_org.form.submit.label')
                : t('signup_guide.create_org.form.submit.label')}
            </Button>
          </Styled.ButtonWrapper>
        </GuideFormWrapper>
      </FormProvider>
    </GuideLayout>
  )
}
