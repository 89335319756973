import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { UmbrellaUserRolesPayload, updateRolesForUmbrellaUser } from '../../../../app/umbrellas/query-api'

interface UseUpdateUserRolesProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UserRolesPayload {
  umbrellaId: string
  userId: string
  payload: UmbrellaUserRolesPayload
}

export const useUpdateUserRoles = (props?: UseUpdateUserRolesProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: updateUserRoles, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, userId, payload }: UserRolesPayload) => updateRolesForUmbrellaUser(umbrellaId, userId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    updateUserRoles,
  }
}
