import { AnyAction } from 'redux'

import { RequestPage, ResponsePage } from '../../types'
import { CITIES_RECEIVED, CITIES_REQUESTED, ZIP_CODES_RECEIVED, ZIP_CODES_REQUESTED } from './actions'
import { City, ZipCode } from './types'

export type ZipCodesRequestedPayload = {
  countryId: string
  page: RequestPage
  filters: {
    text: string | null
  }
}

export type ZipCodesRequestedAction =
  | AnyAction
  | {
      payload: string
      type: typeof ZIP_CODES_REQUESTED
    }

export type ZipCodesReceivedPayload = {
  zipCodes: ZipCode[]
  page: ResponsePage
}

export type ZipCodesReceivedAction =
  | AnyAction
  | {
      payload: ZipCodesReceivedPayload
      type: typeof ZIP_CODES_RECEIVED
    }

export type CitiesRequestedPayload = {
  countryId: string
  page: RequestPage
  filters: {
    text: string | null
  }
}

export type CitiesRequestedAction =
  | AnyAction
  | {
      payload: CitiesRequestedPayload
      type: typeof CITIES_REQUESTED
    }

export type CitiesReceivedPayload = {
  cities: City[]
  page: ResponsePage
}

export type CitiesReceivedAction =
  | AnyAction
  | {
      payload: CitiesReceivedPayload
      type: typeof CITIES_RECEIVED
    }

export function zipCodesRequested(payload: ZipCodesRequestedPayload): ZipCodesRequestedAction {
  return {
    payload,
    type: ZIP_CODES_REQUESTED,
  }
}

export function zipCodesReceived(payload: ZipCodesReceivedPayload): ZipCodesReceivedAction {
  return {
    payload,
    type: ZIP_CODES_RECEIVED,
  }
}

export function citiesRequested(payload: CitiesRequestedPayload): CitiesRequestedAction {
  return {
    payload,
    type: CITIES_REQUESTED,
  }
}

export function citiesReceived(payload: CitiesReceivedPayload): CitiesReceivedAction {
  return {
    payload,
    type: CITIES_RECEIVED,
  }
}
