import { TaxRate } from '../../../../../../../../app/taxrates/types'
import { DeductionComponentForm } from '../../../../../types/deductionComponentForm'
import { VatRateTemplateResponseData } from '../../../../../types/vatRateTemplateResponseData'
import { removeIdAndTaxRateId, shareFromFractionToPercent } from '../../../shared'

export const mergeDeductionComponentsFromVatRateAndTemplate = (
  vatRate: TaxRate,
  template: VatRateTemplateResponseData,
): DeductionComponentForm[] => {
  const { taxRateTemplate, editableProperties } = template

  const deductionComponents = shareFromFractionToPercent(
    vatRate.deductionComponents.map((item) => removeIdAndTaxRateId(item)),
  )
  const templateDeductionComponents = shareFromFractionToPercent(taxRateTemplate.deductionComponents)

  if (deductionComponents.length === 0 && templateDeductionComponents.length > 0) {
    return templateDeductionComponents
  }

  if (deductionComponents.length > 0 && templateDeductionComponents.length === 0) {
    return editableProperties.isDeductionComponentsNumberEditable ? deductionComponents : []
  }

  if (deductionComponents.length > 0 && templateDeductionComponents.length > 0) {
    const mergedDeductionComponents: DeductionComponentForm[] = []

    for (let i = 0; i < templateDeductionComponents.length; i += 1) {
      const fromTemplate = templateDeductionComponents[i]
      const fromRate = deductionComponents[i]

      const merged: DeductionComponentForm = {
        ...fromTemplate,
      }

      if (editableProperties.deductionComponents?.[i].share) {
        merged.share = fromRate.share
      }

      mergedDeductionComponents.push(merged)
    }

    if (
      editableProperties.isDeductionComponentsNumberEditable &&
      deductionComponents.length > templateDeductionComponents.length
    ) {
      for (let i = templateDeductionComponents.length; i < deductionComponents.length; i += 1) {
        const fromRate = deductionComponents[i]

        mergedDeductionComponents.push({
          ...fromRate,
        })
      }
    }

    return mergedDeductionComponents
  }

  // deductionComponents.length === 0 && templateDeductionComponents.length === 0
  return []
}
