import produce from 'immer'

import { ASSETS_TYPES_RECEIVED_ACTION } from './actions'
import { AssetType } from './types'

export type AssetsState = {
  assetTypes: AssetType[]
}

const initialState: AssetsState = {
  assetTypes: [],
}

const reducer = (state: AssetsState = initialState, { type, payload }: any): AssetsState => {
  switch (type) {
    case ASSETS_TYPES_RECEIVED_ACTION:
      return produce(state, (draftState) => {
        draftState.assetTypes = payload
      })
    default:
      return state
  }
}

export default reducer
