import React, { ReactElement } from 'react'

import { Color } from '../../enums/color'
import * as Styled from './styles'
import { Size } from './types/size'
import { sizeToHeight } from './utils/sizeToHeight'

export interface ProgressBarProps {
  progress?: number // [0 - 100]
  color?: Color
  size?: Size
  withAnimation?: boolean
}

export const ProgressBar = ({
  color = Color.ElectricGreen,
  progress = 0,
  size = 'default',
  withAnimation = false,
}: ProgressBarProps): ReactElement => {
  return (
    <Styled.ProgressBarWrapper height={sizeToHeight[size]} withAnimation={withAnimation}>
      <Styled.Progress color={color} progress={progress} />
    </Styled.ProgressBarWrapper>
  )
}
