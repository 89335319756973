import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { useUmbrella } from '../../../../../app/umbrellas'
import { useDeleteOrganizationFromUmbrella } from './hooks/useDeleteOrganizationFromUmbrella'

interface UmbrellaOrganizationsRemoveOrganizationModalProps extends ModalConfirmationProps {
  umbrellaOrganizationId: string
}

export const UmbrellaOrganizationsRemoveOrganizationModal = ({
  umbrellaOrganizationId,
  ...modalProps
}: UmbrellaOrganizationsRemoveOrganizationModalProps) => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const umbrellaId = umbrella?.id
  const { close } = useModal(modalProps.id)

  const { delete: removeOrganization } = useDeleteOrganizationFromUmbrella({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaOrganizations)
    },
    onError: (error) => {
      notify({
        id: NotificationKeys.UmbrellaOrganizationDelete,
        message: error?.body?.errors[0]?.detail || '',
        variant: 'error',
      })
    },
  })

  const handleRemoveOrganizationClick = useCallback(() => {
    if (umbrellaId && umbrellaOrganizationId) {
      removeOrganization({ umbrellaId, umbrellaOrganizationId })
    }

    close()
  }, [removeOrganization, umbrellaId, umbrellaOrganizationId])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('umbrella.organizations_table.remove_organization_modal.cancel')}
      danger
      message={t('umbrella.organizations_table.remove_organization_modal.body')}
      okLabel={t('umbrella.organizations_table.remove_organization_modal.approve')}
      onOk={handleRemoveOrganizationClick}
      title={t('umbrella.organizations_table.remove_organization_modal.title')}
    />
  )
}
