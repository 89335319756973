import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

import { SubscriptionPeriod } from '../../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../../app/organization/enums/subscriptionPlan'

interface ContextState {
  selectPlan: (plan: SubscriptionPlan, period?: SubscriptionPeriod) => void
}

const PlanSelectionContext = createContext<ContextState | undefined>(undefined)

interface PlanSelectionContextProps {
  children?: ReactNode
  onPlanSelect: (plan: SubscriptionPlan, period?: SubscriptionPeriod) => void
}

export const PlanSelectionContextProvider = ({ onPlanSelect, children }: PlanSelectionContextProps): ReactElement => (
  <PlanSelectionContext.Provider
    value={{
      selectPlan: onPlanSelect,
    }}
  >
    {children}
  </PlanSelectionContext.Provider>
)

export const useSelectPlan = () => {
  const context = useContext(PlanSelectionContext)

  if (!context) {
    throw new Error('PlanSelectionContextProvider is missing in the module!')
  }

  return context
}
