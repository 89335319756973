import { getCurrentLocale } from '@design-system'

import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../modules/app/organization'
import { fetchTags, FetchTagsOptions } from '../query-api'

interface UseBookkeepingCategoriesBookySearchProps {
  searchQuery?: string
  isEnabled?: boolean
}

export const useBookkeepingCategoriesBookySearch = ({
  searchQuery = '',
  isEnabled = true,
}: UseBookkeepingCategoriesBookySearchProps) => {
  const { organization } = useUserOrganization()
  const locale = getCurrentLocale()
  const chartOfAccountId = organization?.chartOfAccountId

  const queryParams: FetchTagsOptions = {
    accountType: chartOfAccountId,
    countryId: organization?.countryId,
    locale,
    searchQuery,
  }

  const { data: categoriesData, isLoading } = useQuery(
    [QueryKeys.BookkeepingTag, queryParams],
    () => fetchTags(queryParams),
    {
      enabled: isEnabled,
      cacheTime: 0,
      keepPreviousData: true,
    },
  )

  return {
    suggestedCategories: categoriesData || [],
    isLoading,
  }
}
