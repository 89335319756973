import { Button, ButtonsGroup, Space, Text, useModal } from '@design-system'

import qs from 'qs'
import React, { ReactElement, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-use'

import { ModalId } from '../../../../enums/modalId'
import { useSegment } from '../../../../hooks'
import { EmberEventFn } from '../../../../types/emberEventFn'
import { getImageClassName } from '../../../../utils/getClassName'
import { useUserOrganization, useUserOrganizationSettings } from '../../../app/organization'
import { useAgerasFinanceCustomer } from '../../../financing/contexts/agerasFinanceContext'
import { useAgerasFinancing } from '../../hooks/useAgerasFinancing'
import { ApplicationSentModal } from '../ApplicationSentModal'
import { ApprovalModal } from '../ApprovalModal'
import * as Styled from './styles'

interface FinancingBannerProps {
  onBannerInteraction: EmberEventFn
}

export const FinancingBanner = ({ onBannerInteraction }: FinancingBannerProps): ReactElement | null => {
  const { isLoading: isOrganizationSettingsLoading } = useUserOrganizationSettings()
  const { t } = useTranslation()
  const { track } = useSegment()
  const { open: openApprovalModal } = useModal(ModalId.FinancingApplicationModal)
  const { open: openApplicationSentModal } = useModal(ModalId.FinancingApplicationSentModal)
  const { customer, isCustomerLoading } = useAgerasFinanceCustomer()
  const {
    dismiss,
    isDismissed,
    isLoading: isAgerasFinancingLoading,
    isUpdating: isAgerasFinancingUpdating,
  } = useAgerasFinancing()
  const location = useLocation()
  const { organization } = useUserOrganization()

  useEffect(() => {
    if (!location.search) {
      return
    }

    const queryParams = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    })

    if (queryParams.application_sent === '1') {
      openApplicationSentModal()
    }
  }, [location, openApplicationSentModal])

  const handleApproval = useCallback(() => {
    openApprovalModal()
  }, [openApprovalModal])

  const hide = useCallback(
    (forever = false) => {
      if (!organization?.id) {
        return
      }

      dismiss(forever)
      onBannerInteraction({ detail: null })
    },
    [organization?.id, dismiss, onBannerInteraction],
  )

  const handleHideTemporarily = useCallback(() => {
    hide(false)
    track('Invoice Financing Integration Updated (FE)', {
      action: 'remind_me_later',
    })
  }, [hide, track])

  const handleHideForever = useCallback(() => {
    hide(true)
    track('Invoice Financing Integration Updated (FE)', {
      action: 'not_interested',
    })
  }, [hide, track])

  const isAlreadyACustomer = !isCustomerLoading && customer?.id

  return (
    <>
      {isOrganizationSettingsLoading ||
      isAgerasFinancingLoading ||
      isDismissed ||
      isCustomerLoading ||
      isAlreadyACustomer ? null : (
        <Styled.FinancingBannerWrapper>
          <Styled.BannerImageWrapper className={getImageClassName()}>
            <Styled.BannerImage />
          </Styled.BannerImageWrapper>
          <Styled.BannerContent>
            <Text variant="h2">{t('financing.banner.title')}</Text>
            <Text colorVariant="secondary">{t('financing.banner.description')}</Text>
            <Space size="s" />
            <ButtonsGroup>
              <Button variant="primary" onClick={handleApproval}>
                {t('financing.banner.cta')}
              </Button>
              <Button variant="text" onClick={handleHideTemporarily} disabled={isAgerasFinancingUpdating}>
                {t('financing.banner.later')}
              </Button>
              <Button variant="text" onClick={handleHideForever} disabled={isAgerasFinancingUpdating}>
                {t('financing.banner.not_interested')}
              </Button>
            </ButtonsGroup>
          </Styled.BannerContent>
        </Styled.FinancingBannerWrapper>
      )}

      <ApprovalModal />
      <ApplicationSentModal />
    </>
  )
}
