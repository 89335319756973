import { SubscriptionPeriod } from '../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../app/organization/enums/subscriptionPlan'
import { useFetchSubscriptionPlans } from './useFetchSubscriptionPlans'

export const useSubscriptionPlanPrices = (plan: SubscriptionPlan) => {
  const { subscriptionPlans, isLoading: isLoadingSubscriptionPlans } = useFetchSubscriptionPlans()

  const matchingSubscriptionPlans = subscriptionPlans?.filter(
    (subscriptionPlan) => subscriptionPlan?.product_plan_key === plan,
  )

  const matchedPlanMonthlyPrice = matchingSubscriptionPlans.find(
    (plan) => plan?.product_plan_period === SubscriptionPeriod.Monthly,
  )?.product_plan_price

  const monthlyPrice = matchedPlanMonthlyPrice ? Number(matchedPlanMonthlyPrice) : undefined

  const matchedPlanYearlyPrice = matchingSubscriptionPlans.find(
    (plan) => plan?.product_plan_period === SubscriptionPeriod.Yearly,
  )?.product_plan_price

  const yearlyPricePerMonth = matchedPlanYearlyPrice ? Number(matchedPlanYearlyPrice) / 12 : undefined

  return { monthlyPrice, yearlyPricePerMonth, isLoading: isLoadingSubscriptionPlans }
}
