import { css } from '@emotion/core'
import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Box, Text } from 'rebass'

import { billsRequested } from '../action-creators'

type IBillsProps = {
  organizationId: string
}

function BillList({ organizationId }: IBillsProps): ReactElement {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { bills } = useSelector((state: any) => ({
    bills: state.bills.bills,
  }))

  useEffect(() => {
    dispatch(billsRequested(organizationId))
  }, [dispatch, organizationId])

  return !bills ? (
    <div>Loading</div>
  ) : (
    <div>
      <hr />
      <Text fontSize={[3, 4, 5]} fontWeight="bold" color="primary">
        {t('bills.header', { count: bills.length })}
      </Text>
      <hr />
      <div>
        {bills.map((bill: any, i: number) => {
          return (
            <Box
              css={css`
                display: grid;
                grid-gap: 4;
                grid-column-template: repeat(auto-fit, minmax(128px, 1fr));
              `}
            >
              <Box p={2} bg="muted">
                {bill.subject}
              </Box>
              <Box p={2} color="background" bg="primary">
                {bill.entryDate}
              </Box>
              <Box p={2} bg="muted">
                <Link to={'/' + bill.id + '/view'}>{t('bills.view')}</Link>
              </Box>
              <Box p={2} bg="muted">
                <Link to={'/' + bill.id + '/edit'}>{t('bills.edit')}</Link>
              </Box>
            </Box>
          )
        })}
      </div>
    </div>
  )
}

export default BillList
