import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'

import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ApplicationError } from '../../modules/applicationError'

const MODULE_NAME = 'application-error-module'

class ApplicationErrorWebComponent extends DOMModel {
  @byAttrVal error: string
  @registerEvent('navigate') navigate: Function
}

type ApplicationErrorModuleProps = {
  error: string
  onNavigate: EmberNavigate
}

function ApplicationErrorModule({ error, onNavigate }: ApplicationErrorModuleProps): ReactElement {
  return (
    <ThemeProvider>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <ApplicationError errorMessage={error} />
      </EmberRouterContextProvider>
    </ThemeProvider>
  )
}

const ApplicationErrorElement = createCustomElement(ApplicationErrorModule, ApplicationErrorWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, ApplicationErrorElement)

export default ApplicationErrorElement
