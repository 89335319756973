import { isEmpty, reduce } from 'lodash'

import { SaveAccountPayload } from '../../../query-api'
import { AllowedField, EditAccountFormInputs } from '../types/types'

const requiredFields: (keyof SaveAccountPayload)[] = ['id']

const payloadToFields: Partial<Record<keyof SaveAccountPayload, keyof EditAccountFormInputs>> = {
  bankAccountNo: 'bankAccountNo',
  accountNo: 'accountNo',
  bankIban: 'bankIban',
  bankId: 'bank',
  bankName: 'bank',
  bankRoutingNo: 'routingNumber',
  bankSwift: 'bankSwift',
  currencyId: 'currency',
  description: 'description',
  id: 'id',
  isBankAccount: 'isBankAccount',
  isPaymentEnabled: 'isPaymentEnabled',
  name: 'accountName',
}

export const filterSaveAccountPayload = (
  payload: SaveAccountPayload,
  allowedFields?: AllowedField[],
): SaveAccountPayload => {
  return reduce(
    payload,
    (result, value, key) => {
      if ((typeof value === 'string' && value.length === 0) || value === null) {
        return result
      }

      if (
        allowedFields &&
        !isEmpty(allowedFields) &&
        ![...requiredFields, ...allowedFields].includes(payloadToFields[key])
      ) {
        return result
      }

      return {
        ...result,
        [key]: value,
      }
    },
    {} as SaveAccountPayload,
  )
}
