import { useCallback } from 'react'
import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import {
  setOrganizationAnalytics,
  SetOrganizationAnalyticsPayload,
  SetOrganizationAnalyticsResponseData,
} from '../../../../app/organization'

interface UseSetOrganizationAnalyticsProps {
  onError?: (error?: APIError) => void
  onSuccess?: (response: SetOrganizationAnalyticsResponseData) => void
}

interface UseSetOrganizationAnalytics {
  set: (payload: SetOrganizationAnalyticsPayload) => void
}

export const useSetOrganizationAnalytics = (props?: UseSetOrganizationAnalyticsProps): UseSetOrganizationAnalytics => {
  const { onError, onSuccess } = props || {}

  const seteOrganizaitionAnalyticsMutation = useMutation(setOrganizationAnalytics, {
    onSuccess: (response) => {
      onSuccess && onSuccess(response)
    },
    onError: (error?: APIError) => {
      onError && onError(error)
    },
  })

  const set = useCallback(
    (payload: SetOrganizationAnalyticsPayload) => {
      seteOrganizaitionAnalyticsMutation.mutate(payload)
    },
    [seteOrganizaitionAnalyticsMutation],
  )

  return { set }
}
