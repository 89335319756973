import qs from 'qs'

import { getRequest } from '../../utils'
import { ContactPerson } from '../app/contactPerson/types/contactPerson'

interface ContactPersonsResponse {
  contactPersons: ContactPerson[]
}

export interface FetchContactPersonsOptions {
  contactId: string
  q?: string
  page?: number
  pageSize?: number
}

export const fetchContactPersons = ({
  contactId,
  q,
  page,
  pageSize,
}: FetchContactPersonsOptions): Promise<ContactPersonsResponse> => {
  const queryParams = qs.stringify({
    ...(contactId ? { contactId } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(q ? { q } : {}),
  })

  return getRequest(`/v2/contactPersons?${queryParams}`)
}
