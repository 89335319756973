import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../../../../../hooks'
import { useUserOrganization } from '../../../../../../app/organization'
import { useCurrentUser } from '../../../../../../app/user'
import { useEInvoiceContext } from '../../../context/eInvoiceContext'
import { getDefaultValues, getValidationSchema, RegisterInternationallyForm } from '../utils/formData'

export const useRegisterInternationallyForm = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const { registerInPeppol } = useEInvoiceContext()

  const { handleSubmit, ...form } = useForm({
    defaultValues: useMemo(() => getDefaultValues(organization, user), [organization, user]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const handleSubmitForm = useCallback(
    (values: RegisterInternationallyForm) => {
      if (!organization?.id) {
        return
      }
      registerInPeppol({ ...values, organizationId: organization.id })
    },
    [organization?.id, registerInPeppol],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return { ...form, submitForm }
}
