import pick from 'lodash/pick'

import { SetMultipleOrganizationSettingsPayload } from '../../../../../../app/organization'
import { OrganizationSettingsValue } from '../../../../../../app/organization/types/organizationSettingsValue'
import { InvoiceAndProductSettingsForm } from './formData'

export const getSettingsPayloadFromInvoiceAndProductForm = (
  invoiceAndProductFormValues: Partial<InvoiceAndProductSettingsForm>,
): SetMultipleOrganizationSettingsPayload => {
  const organizationSettingsToUpdate = [
    'isDuplicateInvoiceCheckDisabled',
    'isOrderNoEnabled',
    'isSummaryInclVat',
  ] as const

  const invertedOrganizationSettingsToUpdate = ['isDuplicateInvoiceCheckDisabled']

  const organizationSettingsPayload = pick(
    invoiceAndProductFormValues,
    organizationSettingsToUpdate,
  ) as SetMultipleOrganizationSettingsPayload

  for (const setting of organizationSettingsToUpdate) {
    let value: OrganizationSettingsValue | ''

    // Code below is needed because of inconsistency between isDuplicateInvoiceCheckDisabled checkbox's label and API key
    if (invertedOrganizationSettingsToUpdate.includes(setting)) {
      value = organizationSettingsPayload[setting] ? '' : '1'
    } else {
      value = organizationSettingsPayload[setting] ? '1' : ''
    }

    organizationSettingsPayload[setting] = value
  }

  return organizationSettingsPayload
}
