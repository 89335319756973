import { RouteProtected } from '@components'

import React, { ReactElement } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { Scope } from '../../enums/scope'
import { defaultQuery as defaultBulkQuery } from '../../hooks/useBulkNavigationQueryParams'
import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { BillsRoute } from './enums/billsRoute'
import { useCustomEventsRoutes } from './hooks/useCustomEventsRoutes'
import { BillEdit } from './routes/BillEdit'
import { BillsList, defaultQuery } from './routes/BillsList'
import { BillsListEmpty } from './routes/BillsListEmpty'
import { BillView } from './routes/BillView'
import { getBillDuplicatePath } from './utils/getBillDuplicatePath'
import { getBillEditPath } from './utils/getBillEditPath'
import { getBillViewPath } from './utils/getBillViewPath'
import { getBulkEditAttachmentPath } from './utils/getBulkEditAttachmentPath'
import { getBulkEditBillPath } from './utils/getBulkEditBillPath'
import { getCreateCreditNotePath } from './utils/getCreateCreditNotePath'
import { getNewFromAttachmentPath } from './utils/getNewFromAttachmentPath'
import { getNewFromContactPath } from './utils/getNewFromContactPath'

export const Bills = (): ReactElement | null => {
  const emberQuery = useGetQueryFromEmberRoute({
    queryParamsValidKeys: [...Object.keys(defaultQuery), ...Object.keys(defaultBulkQuery)],
  })

  // Handling all custom events of the routes from external places
  useCustomEventsRoutes()

  return (
    <Switch>
      <Route exact path={BillsRoute.List}>
        {typeof emberQuery?.initialRoute === 'string' ? <Redirect to={emberQuery.initialRoute} /> : <BillsList />}
      </Route>
      <Route component={BillsListEmpty} exact path={BillsRoute.ListEmpty} />
      <RouteProtected
        scopes={Scope.BillWrite}
        exact
        path={[
          BillsRoute.New,
          getBillDuplicatePath(':billIdToDuplicate'),
          getBillEditPath(':billId'),
          getBulkEditAttachmentPath(':attachmentIdBulk'),
          getBulkEditBillPath(':bulkBillId'),
          getCreateCreditNotePath(':billIdToCreateCreditNote'),
          getNewFromAttachmentPath(':attachmentId'),
          getNewFromContactPath(':contactId'),
        ]}
      >
        <BillEdit />
      </RouteProtected>
      <RouteProtected scopes={Scope.BillWrite} exact path={BillsRoute.NewCreditNote}>
        <BillEdit isNewCreditNote />
      </RouteProtected>
      <RouteProtected scopes={Scope.BillRead} exact path={getBillViewPath(':billId')}>
        <BillView />
      </RouteProtected>
    </Switch>
  )
}
