import { AttachmentFile, notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { APIError } from '../../../../../utils'
import { uploadFile, UploadFileResponseData } from '../../../../receipts'

interface UseUploadLogoArgs {
  onError?: (error: APIError) => void
  onSuccess?: (attachmentFile: AttachmentFile) => void
}

export const useUploadLogo = ({ onError, onSuccess }: UseUploadLogoArgs = {}) => {
  const { t } = useTranslation()
  const { mutate: upload, isLoading: isUploading } = useMutation(
    (file: File) =>
      uploadFile(file, {
        'x-image-max-height': 400,
        'x-image-max-width': 400,
      }),
    {
      onError: (error: APIError) => {
        onError?.(error)
        notify({
          id: NotificationKeys.UmbrellaProfileLogoUploadRejection,
          message: t('umbrella.admin_profile.logo_upload.error'),
          variant: 'error',
        })
      },
      onSuccess: ({ files: [file] }: UploadFileResponseData) => {
        onSuccess?.(file)
        notify({
          id: NotificationKeys.UmbrellaProfileLogoUploadAcceptance,
          message: t('umbrella.admin_profile.logo_upload.success'),
          variant: 'success',
        })
      },
    },
  )

  return { upload, isUploading }
}
