import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '../../modules/app/organization'
import { GlobalComponent } from '../../modules/globalComponent'

const MODULE_NAME = 'global-component-module'

class DashboardWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @registerEvent('navigate') navigate: Function
}

interface GlobalComponentModuleProps {
  organizationid: string
  onNavigate: EmberNavigate
}

const GlobalComponentModule = ({ organizationid, onNavigate }: GlobalComponentModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <UserOrganizationContextProvider organizationId={organizationid}>
        <UserOrganizationSettingsContextProvider organizationId={organizationid}>
          <EmberRouterContextProvider onNavigate={onNavigate}>
            <ModalContextProvider>
              <GlobalComponent />
            </ModalContextProvider>
          </EmberRouterContextProvider>
        </UserOrganizationSettingsContextProvider>
      </UserOrganizationContextProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

const MenuElement = createCustomElement(GlobalComponentModule, DashboardWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MenuElement)
