import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'

import { SortDirection } from '../../../enums/sortDirection'
import { useUserOrganization } from '../../app/organization'
import { ReceiptFillType, ReceiptFilterType } from '../elements/Receipt'
import { ReceiptsSortProperty } from '../enums/sort'
import { QueryProps } from '../types/queryProps'

interface UseGetFetchReceiptsQueryProps {
  isDisabled?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortBy?: ReceiptsSortProperty
  sortDirection?: SortDirection
  fillType?: ReceiptFillType
  type?: ReceiptFilterType
}

const RECEIPTS_PER_PAGE = 20

export const useGetFetchReceiptsQueryProps = ({
  fillType = ReceiptFillType.Nonfilled,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortDirection,
  type,
}: UseGetFetchReceiptsQueryProps): QueryProps => {
  const { organization } = useUserOrganization()

  const pageCount = pageSize || RECEIPTS_PER_PAGE

  return useMemo(
    () => ({
      organizationId: organization?.id as string,
      ...(page ? { page } : {}),
      ...(pageCount ? { pageSize: pageCount } : {}),
      ...(searchQuery ? { searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortBy ? { sortProperty: sortBy } : {}),
      ...(fillType ? { fillType } : {}),
      ...(type ? { type } : {}),
    }),
    [organization?.id, page, pageCount, searchQuery, sortBy, sortDirection, fillType, type],
  )
}
