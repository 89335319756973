import React, { ChangeEvent, memo, ReactElement, ReactNode, useCallback, useMemo } from 'react'
import { Row } from 'react-table'

import { TABLE_COLOR, TABLE_ROW_HOVER_COLOR } from '../../constants/tableColors'
import { useSelectRows } from '../../contexts/selectRowsContext'
import { InternalAccessor } from '../../enums/accessors'
import { TABLE_CELL_NOT_CLICKABLE_CLASS } from '../../enums/classes'
import { TableData } from '../../types/data'
import { SelectType } from '../../types/selectType'
import { TableCheckbox } from '../TableCheckbox'
import { TableRadio } from '../TableRadio'
import * as Styled from './styles'

interface TableRowProps<T extends TableData> {
  children: ReactNode
  expandable?: boolean
  expanded?: boolean
  id: string
  isSelectable?: boolean
  isSkeletonView?: boolean
  onClick?: (row: Row<T>) => void
  /*
   * `row` is required when is used differently than `TableEmptyView` component
   * */
  row?: Row<T>
  selectType?: SelectType
  urlExternal?: string
  urlInternal?: string
  withStaticActions?: boolean
}

const TableRowMemoized = memo(
  <T extends TableData>({
    children,
    id,
    isSelectable = false,
    isSkeletonView,
    onClick,
    row,
    selectType,
    urlExternal,
    urlInternal,
    expanded,
    withStaticActions,
    ...rest
  }: TableRowProps<T>): ReactElement => {
    const { areAllRowsSelected, selectedRows, selectRow } = useSelectRows<T>()

    const isExpanded = expanded
    const isLink = !!(urlExternal || urlInternal)
    const isDisabled = !!row?.values[InternalAccessor.Disabled]
    const isClickable = onClick && !row?.values[InternalAccessor.Readonly]
    const isSelected = isSelectable && !!selectedRows.find((selectedRowId) => selectedRowId === id)
    const hasHoverActions = row?.values[InternalAccessor.HoverActions]
    const hasStaticActions = row?.values[InternalAccessor.StaticActions]
    const withStaticActionOffset = withStaticActions && !hasStaticActions

    const handleClick = useCallback(
      (event: React.MouseEvent<HTMLLIElement, MouseEvent> | React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const isClickSkipped = (event.target as HTMLLIElement).closest(`.${TABLE_CELL_NOT_CLICKABLE_CLASS}`)

        if (isDisabled || !row || isClickSkipped) {
          event.preventDefault()
          event.stopPropagation()
          return
        }
        // `onClick` property is required to tell Typescript that's already defined.
        if (isClickable && onClick) {
          onClick(row)
        }
      },
      [isClickable, isDisabled, onClick, row],
    )

    const handleSelect = useCallback(
      (event: ChangeEvent<HTMLInputElement>) => {
        if (row) {
          selectRow(row, event.target.checked)
        }
      },
      [row, selectRow],
    )

    const rowActions = useMemo(
      () => (
        <>
          {hasHoverActions && !isSkeletonView && !isExpanded && (
            <Styled.TableHoverActions
              withStaticActions={hasStaticActions}
              className={TABLE_CELL_NOT_CLICKABLE_CLASS}
              bgColor={isLink || isClickable ? TABLE_ROW_HOVER_COLOR : TABLE_COLOR}
            >
              {row?.allCells.find(({ column }) => column.id === InternalAccessor.HoverActions)?.render('Cell')}
            </Styled.TableHoverActions>
          )}

          {hasStaticActions && !isSkeletonView && (
            <Styled.TableStaticActions className={TABLE_CELL_NOT_CLICKABLE_CLASS}>
              {row?.allCells.find(({ column }) => column.id === InternalAccessor.StaticActions)?.render('Cell')}
            </Styled.TableStaticActions>
          )}
        </>
      ),
      [hasHoverActions, hasStaticActions, isClickable, isExpanded, isLink, isSkeletonView, row?.allCells],
    )

    return (
      <Styled.TableRowWrapper
        {...rest}
        {...row?.getRowProps()}
        data-row-id={id}
        clickable={isClickable}
        expanded={isExpanded}
        disabled={isDisabled}
        hoverable={isLink}
        ignoreSpacing={isLink}
        isSkeletonView={isSkeletonView}
        onClick={urlExternal || urlInternal ? undefined : handleClick}
        selectable={isSelectable}
        withStaticActionOffset={withStaticActionOffset}
      >
        <>
          {isSelectable &&
            (selectType === 'checkbox' ? (
              <TableCheckbox checked={areAllRowsSelected || isSelected} name="row-checkbox" onChange={handleSelect} />
            ) : (
              <TableRadio checked={isSelected} name="row-radio" onChange={handleSelect} />
            ))}
          {useMemo(() => {
            if (urlExternal) {
              return (
                <Styled.TableRowLink onClick={handleClick} href={urlExternal} selectable={isSelectable}>
                  {children}
                  {rowActions}
                </Styled.TableRowLink>
              )
            }

            if (urlInternal) {
              return (
                <Styled.TableRowRouterLink onClick={handleClick} selectable={isSelectable} to={urlInternal}>
                  {children}
                  {rowActions}
                </Styled.TableRowRouterLink>
              )
            }

            return (
              <>
                {children}
                {rowActions}
              </>
            )
          }, [children, handleClick, isSelectable, rowActions, urlExternal, urlInternal])}
        </>
      </Styled.TableRowWrapper>
    )
  },
)

export const TableRow = TableRowMemoized as <T extends TableData>(props: TableRowProps<T>) => ReactElement
