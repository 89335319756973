import { encodePeriodValue, GlobalPeriodSelect } from '@components'

import React, { ReactElement, useCallback } from 'react'

import { PeriodValue } from '../../../../../../types/periodValue'
import { useUserOrganization } from '../../../../../app/organization'
import { useQuotesListFilters } from '../../hooks/useQuotesListFilters'
import * as Styled from './styles'

export const QuotesPeriodSelector = (): ReactElement => {
  const { organization } = useUserOrganization()
  const [{ period: queryParamPeriod }, setQueryParams] = useQuotesListFilters()

  const handlePeriodChange = useCallback(
    (periodValue: PeriodValue) => {
      if (!organization?.id) {
        return
      }

      setQueryParams({ period: encodePeriodValue(periodValue, organization.id), page: 1 })
    },
    [organization, setQueryParams],
  )

  return (
    <Styled.QuotesPeriodSelector>
      <GlobalPeriodSelect encodedPeriod={queryParamPeriod} onChange={handlePeriodChange} placement="bottom-end" />
    </Styled.QuotesPeriodSelector>
  )
}
