import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { useUpdateOrganizationSettings, useUserOrganization } from '../../../../../app/organization'
import { FEATURE_AIS } from '../../constants/FEATURE_AIS'

type DisableDebtCollectionModalProps = ModalConfirmationProps

export const DisableDebtCollectionModal = (modalProps: DisableDebtCollectionModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close: closeDisableDebtCollectionModal } = useModal(modalProps.id)
  const { update: updateSettings, isLoading: isUpdatingSettings } = useUpdateOrganizationSettings({
    onSuccess: () => {
      notify({
        id: NotificationKeys.DebtCollectionServiceDisabled,
        message: t('integrations.card.debt_collection.disabled.notification.success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
      closeDisableDebtCollectionModal()
    },
    onError: () => {
      notify({
        id: NotificationKeys.DebtCollectionServiceDisabled,
        message: t('integrations.card.debt_collection.disabled.notification.error'),
        variant: 'error',
      })
    },
  })

  const handleUninstallButtonClick = useCallback(() => {
    if (organization?.id) {
      updateSettings({
        key: FEATURE_AIS,
        organizationId: organization.id,
        value: '0',
      })
    }
  }, [organization?.id, updateSettings])

  return (
    <ModalConfirmation
      cancelLabel={t('integrations.card.debt_collection.modal_disable.cancel_button')}
      danger
      id={modalProps.id}
      message={t('integrations.card.debt_collection.modal_disable.body')}
      okLabel={t('integrations.card.debt_collection.modal_disable.uninstall_button')}
      okLoading={isUpdatingSettings}
      onOk={handleUninstallButtonClick}
      title={t('integrations.card.debt_collection.modal_disable.title')}
    />
  )
}
