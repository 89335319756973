import produce from 'immer'

import { AppState } from '../types'
import { USER_BOOTSTRAP_RECEIVED, USER_BOOTSTRAP_REQUESTED } from './actions'

const organizationReducer = (
  state: AppState,
  { type, payload }: any, // TODO: Why doesn't Saga like union types??
): AppState => {
  switch (type) {
    case USER_BOOTSTRAP_REQUESTED:
      return state

    case USER_BOOTSTRAP_RECEIVED:
      return produce(state, (draftState) => {
        draftState.user = payload
      })

    default:
      return state
  }
}

export default organizationReducer
