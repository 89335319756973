import { InfoPanel, Input } from '@components-deprecated'
import { Button, notify } from '@design-system'

import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import React, { memo, ReactElement, useCallback } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import * as yup from 'yup'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useCurrentUser } from '../../../../app/user'
import { createBankLineComment, updateBankLine } from '../../../../bankReconciliation/query-api'
import * as Styled from '../styles'

type FormInputs = {
  message: string
}

const defaultValues: FormInputs = {
  message: '',
}

const validationSchema = (t: TFunction) =>
  yup.object().shape({
    message: yup.string().required(t('things_to_do.missing_receipts.form.message.error')),
  })

interface ReceiptWithoutVoucherProps {
  bankLineId: string
}

export const ReceiptWithoutVoucher = memo(({ bankLineId }: ReceiptWithoutVoucherProps): ReactElement => {
  const { t } = useTranslation()
  const form = useForm<FormInputs>({
    defaultValues,
    resolver: yupResolver(validationSchema(t)),
  })
  const { user } = useCurrentUser()

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = form

  const bankLineMutation = useMutation(
    () =>
      updateBankLine({
        id: bankLineId,
        receiptState: null,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKeys.BankLines)
        notify({
          id: NotificationKeys.ThingsToDoMissingReceiptsUpdate,
          message: t('things_to_do.missing_receipts.toast.no_voucher.success'),
          variant: 'success',
        })
      },
      onError: () => {
        notify({
          id: NotificationKeys.ThingsToDoMissingReceiptsUpdate,
          message: t('things_to_do.missing_receipts.toast.no_voucher.error'),
          variant: 'error',
        })
      },
    },
  )

  const commentMutation = useMutation(
    (values: FormInputs) =>
      createBankLineComment({
        message: values.message,
        bankLineId,
        userId: user?.id as string,
      }),
    {
      onSuccess: () => {
        bankLineMutation.mutate()
      },
      onError: () => {
        notify({
          id: NotificationKeys.ThingsToDoMissingReceiptsUpdate,
          message: t('things_to_do.missing_receipts.toast.no_voucher.error'),
          variant: 'error',
        })
      },
    },
  )

  const handleFormSubmit = useCallback(
    (values: FormInputs) => {
      commentMutation.mutateAsync(values)
    },
    [commentMutation],
  )

  return (
    <FormProvider {...form}>
      <Styled.Container noValidate onSubmit={handleSubmit(handleFormSubmit)}>
        <Styled.InfoWrapper>
          <InfoPanel>{t('things_to_do.missing_receipts.form.message.notification_info')}</InfoPanel>
        </Styled.InfoWrapper>

        <Input
          label={t('things_to_do.missing_receipts.form.message.label')}
          placeholder={t('things_to_do.missing_receipts.form.message.placeholder')}
          name="message"
          type="textarea"
          formControl={control}
        />
        <Styled.ButtonWrapper>
          <Button disabled={isSubmitting} size="m" type="submit">
            {t('things_to_do.missing_receipts.form.submit')}
          </Button>
        </Styled.ButtonWrapper>
      </Styled.Container>
    </FormProvider>
  )
})
