import { Label, LabelProps, LabelVariant } from '@components-deprecated'

import { isBefore } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { Account } from '../../app/accounts/types'
import { SpecificState } from '../../types'
import { NordicApiConnectionStatus } from '../types'

export type NordicApiConnectionStatusLabelProps = LabelProps & {
  account: Account
}

export const NordicApiConnectionStatusLabel = ({ account, ...rest }: NordicApiConnectionStatusLabelProps) => {
  const { t } = useTranslation()

  let status: NordicApiConnectionStatus
  const bankConnection = useSelector((state: SpecificState) => state.bankReconciliation.bankConnection)
  const bankConnectionLoading = useSelector((state: SpecificState) => state.bankReconciliation.bankConnectionLoading)
  const bankConnectionResolved = useSelector((state: SpecificState) => state.bankReconciliation.bankConnectionResolved)

  // If we have no information about the bank connection, it is loading or it is not a valid account, simply return
  // an empty component. This is different from there being no bank connection (aka it being null) as we have been
  // notified that we have loaded and it happens to not exist
  if (
    bankConnection === undefined ||
    !bankConnectionResolved ||
    bankConnectionLoading ||
    !(account.name || account.bankName)
  ) {
    return <></>
  }

  if (bankConnection) {
    const expiryDate = bankConnection?.session ? Date.parse(bankConnection.session.loginTokenExpiryTime) : null
    const hasExpired = !expiryDate || isBefore(expiryDate, new Date())
    status = hasExpired ? NordicApiConnectionStatus.NeedsRenewal : NordicApiConnectionStatus.Connected
  } else {
    status = NordicApiConnectionStatus.ManualImport
  }

  let variant: LabelVariant
  switch (status) {
    case NordicApiConnectionStatus.Connected:
      variant = 'success'
      break

    case NordicApiConnectionStatus.ManualImport:
      variant = 'warning'
      break

    case NordicApiConnectionStatus.NeedsRenewal:
      variant = 'danger'
      break
  }

  return (
    <Label variant={variant} {...rest}>
      {t(`bankreconciliation.bank.nordic_api.${status}`)}
    </Label>
  )
}
