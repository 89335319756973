import { Color, getDefaultTransition, rwd, Spacing, zIndex } from '@design-system'

import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { transparentize } from 'polished'

import { SIDE_NAVBAR_WIDTH } from './constants/sideNavbarWidth'

const MENU_WIDTH = '232px'

interface MenuWrapperProps {
  forceOpen?: boolean
  isMovedToRight?: boolean
}

export const MenuWrapper = styled.aside<MenuWrapperProps>`
  display: flex;
  height: 100%;
  width: ${MENU_WIDTH};
  padding: ${Spacing.XL} ${Spacing.L} ${Spacing.M};
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.Menu};
  flex-direction: column;
  background-color: ${Color.White};
  box-shadow: 1px 0 2px ${transparentize(0.6, Color.Gray40)};
  transform: translateX(-${MENU_WIDTH});
  will-change: transform;
  margin-left: ${({ isMovedToRight }) => (isMovedToRight ? `${SIDE_NAVBAR_WIDTH}` : 'initial')};
  ${getDefaultTransition('transform')};

  ${rwd.greaterThan('xlarge')} {
    transform: translateX(0);
  }

  ${({ forceOpen }) => {
    if (forceOpen) {
      return css`
        transform: translateX(0);
      `
    }
  }};
`

interface VisibilityToggledWrapperProps {
  visible?: boolean
  isMovedToRight?: boolean
}

const VisibilityToggledWrapper = styled.div<VisibilityToggledWrapperProps>`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: ${({ visible }) => (visible ? 'auto' : 'none')};
  margin-left: ${({ isMovedToRight }) => (isMovedToRight ? `${SIDE_NAVBAR_WIDTH}` : 'initial')};

  ${getDefaultTransition('opacity')};

  ${rwd.greaterThan('xlarge')} {
    display: none;
  }
`

export const OpenButtonWrapper = styled(VisibilityToggledWrapper)`
  position: absolute;
  top: ${Spacing.L};
  left: ${Spacing.XXXL};
  z-index: ${zIndex.Menu};

  ${rwd.equalOrLessThan('xlarge')} {
    left: ${Spacing.XXL};
  }

  ${rwd.equalOrLessThan('tablet')} {
    left: ${Spacing.L};
  }
`

export const Overlay = styled(VisibilityToggledWrapper)`
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: ${zIndex.MenuOverlay};
  background-color: ${transparentize(0.6, Color.Gray200)};
`
