import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { ContactsEmptyState } from './elements/ContactsEmptyState'
import { ContactsList } from './elements/ContactsList'
import { ContactRoute } from './enum/contactRoute'
import { defaultContactsQuery } from './hooks/useContactsListFilters'

export const Contacts = (): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultContactsQuery),
  })

  return (
    <Switch>
      <Route component={ContactsList} exact path={ContactRoute.List} />
      <Route component={ContactsEmptyState} exact path={ContactRoute.ListEmpty} />
    </Switch>
  )
}
