import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'

import { getAccessToken } from './api'
import { IS_DEVELOPMENT } from './isDevelopment'

const DEV_ZERVANT_BASE_URL = 'https://test.zerv.me'
const APPLICATION_ID = 'billy'
const BASE_ZERVANT_URL = window.ENV.ZERVANT_URL || (IS_DEVELOPMENT ? DEV_ZERVANT_BASE_URL : '')
const BASE_URL = `${BASE_ZERVANT_URL}/rest-invoice/api`

class ApiZervant {
  private instance: AxiosInstance

  constructor() {
    this.instance = axios.create({
      baseURL: BASE_URL,
    })

    this.instance.defaults.headers.common['application-id'] = APPLICATION_ID
  }

  // @todo maybe we should send some custom event from ember to set it up here:
  setAccessToken = () => {
    if (this.instance.defaults.headers.common.Authorization) {
      return
    }

    this.instance.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`
  }

  getRequest = <T>(url: string, config?: AxiosRequestConfig) => {
    this.setAccessToken()
    return this.instance.get<T>(url, config)
  }

  postRequest = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.post<T>(url, data, config)
  }

  putRequest = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.put<T>(url, data, config)
  }

  patchRequest = <T>(url: string, data?: any, config?: AxiosRequestConfig<any>) => {
    this.setAccessToken()
    return this.instance.patch<T>(url, data, config)
  }
}

const api = new ApiZervant()
export default api
