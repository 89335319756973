import { AccountSelect } from '@components'
import { FormItem } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useSegment } from '../../../../../../hooks'
import { Account } from '../../../../../app/accounts/types'
import { useBillReconciliation } from '../../contexts/billReconciliationContext'
import { useMatch } from '../../contexts/matchContext'
import { useModalContentsOperations } from '../../contexts/modalContentsOperationsContext'
import { BillReconciliationModalStateId } from '../../enums/billReconciliationModalStateId'
import { useModalContentToggler } from '../ModalContentToggler'

export const BillAccountSelector = (): ReactElement => {
  const { t } = useTranslation()
  const { track } = useSegment()
  const { activeState, setActiveState } = useModalContentToggler()
  const { selectedAccountId, setSelectedAccountId } = useBillReconciliation()
  const { resetValues } = useModalContentsOperations()
  const { matchSuggestion } = useMatch()

  const isMatchModalState = activeState === BillReconciliationModalStateId.Match
  const isBankLinesModalState = activeState === BillReconciliationModalStateId.BankLines

  const handleAccountChange = useCallback(
    (id?: string, value?: Account) => {
      if (!id) {
        return
      }

      resetValues()
      setSelectedAccountId(id)

      const event = isBankLinesModalState
        ? 'xxx - expenses - Bank line modal - account changed'
        : 'xxx - expenses - Manual payment modal - account changed'

      track(event)

      if (value?.isBankAccount === false) {
        setActiveState(BillReconciliationModalStateId.ManualPayment)
      }
    },
    [isBankLinesModalState, resetValues, setActiveState, setSelectedAccountId, track],
  )

  return (
    <FormItem label={t('account')}>
      <AccountSelect
        disabled={isMatchModalState}
        dropdownSize="fitTrigger"
        isPaymentEnabled
        onSelect={handleAccountChange}
        selectedId={isMatchModalState ? matchSuggestion?.account_id : selectedAccountId}
      />
    </FormItem>
  )
}
