import { ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { SortDirection } from '../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../hooks/usePaginationPrefetching'
import { Paging } from '../../../types/metable'
import { useUserOrganization } from '../../app/organization'
import { ContactsSortProperty, fetchContactsAndContactPersons, FetchContactsResponseData } from '../query-api'
import { Contact } from '../types/contact'
import { getContactsQueryKey } from '../utils/getContactsQueryKey'

export interface ContactsQueryProps {
  isArchived?: boolean
  isCustomer?: boolean
  isSupplier?: boolean
  organizationId?: string
  page?: number
  pageSize?: ItemsPerPage
  q?: string
  sortDirection?: SortDirection
  sortProperty?: ContactsSortProperty
}

interface UseFetchContactsProps {
  isArchived?: boolean
  isCustomer?: boolean
  isSupplier?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: ContactsSortProperty
}

interface UseFetchContactsResponse {
  contacts: Contact[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchContacts = ({
  isArchived,
  isCustomer,
  isSupplier,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
}: UseFetchContactsProps): UseFetchContactsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps: ContactsQueryProps = useMemo(() => {
    return {
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(searchQuery ? { q: searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      organizationId: organization?.id as string,
      isArchived,
      isCustomer,
      isSupplier,
    }
  }, [isArchived, isCustomer, isSupplier, organization?.id, page, pageSize, searchQuery, sortDirection, sortProperty])

  const queryKey = useMemo(() => getContactsQueryKey(queryProps), [queryProps])

  const { data, isLoading, isIdle } = useQuery(queryKey, () => fetchContactsAndContactPersons(queryProps), {
    enabled: !!organization,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<ContactsQueryProps, FetchContactsResponseData>({
    getQueryFunction: useMemo(() => fetchContactsAndContactPersons, []),
    getQueryKey: useMemo(() => getContactsQueryKey, []),
    isEnabled: !!organization?.id && !isLoading && !isIdle,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    contacts: data?.contacts || [],
    isLoading: isIdle || isLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
