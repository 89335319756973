import { notify, TableHoverActions, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../../../config/queryClient'
import { useEmberRouter } from '../../../../../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../../../../../enums/emberRoute'
import { ModalId } from '../../../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../../enums/queryKeys'
import { Scope } from '../../../../../../../../../../enums/scope'
import { useIsBasicPlan } from '../../../../../../../../../../hooks/useIsBasicPlan'
import { isAuthorized } from '../../../../../../../../../../utils/isAuthorized'
import {
  UpdateOrganizationProps,
  useUpdateOrganization,
  useUserOrganization,
} from '../../../../../../../../../app/organization'
import { useCreateInvoiceTemplate } from '../../../../hooks/useCreateInvoiceTemplate'
import { InvoiceTemplate } from '../../../../types/invoiceTemplate'
import { InvoiceTemplateTableRow } from '../../../../types/invoiceTemplateTableRow'

export const InvoiceTemplatesTableHoverActions = ({
  invoiceTemplate,
  invoiceTemplateId,
  isDefaultInvoiceTemplate,
}: Omit<InvoiceTemplateTableRow, 'invoiceTemplateName' | 'id'>): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { isBasicPlan } = useIsBasicPlan()
  const { open: openUpgradeSubscriptionModal } = useModal(ModalId.UpgradeSubscriptionModal)
  const { navigate } = useEmberRouter()
  const { update: updateOrganization } = useUpdateOrganization({
    onSuccess: () => {
      notify({
        id: NotificationKeys.InvoiceTemplateSetAsDefault,
        message: t('settings.organization.invoice.templates_settings.table.actions.set_as_default.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UserOrganization)
    },
    onError: () => {
      notify({ id: NotificationKeys.InvoiceTemplateSetAsDefault, message: t('error_saving'), variant: 'error' })
    },
  })
  const { create: createTemplate } = useCreateInvoiceTemplate({
    onSuccess: () => {
      notify({
        id: NotificationKeys.InvoiceTemplateDuplicate,
        message: t('settings.organization.invoice.templates_settings.table.actions.duplicate.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.InvoiceTemplates)
    },
    onError: () => {
      notify({ id: NotificationKeys.InvoiceTemplateDuplicate, message: t('error_saving'), variant: 'error' })
    },
  })

  const shouldShowUpgradeSubscriptionModal = isBasicPlan || !isAuthorized(Scope.InvoiceDesignerWrite)

  const handleSetAsDefaultClick = useCallback(() => {
    if (!organization?.id || !invoiceTemplateId) {
      return
    }

    if (shouldShowUpgradeSubscriptionModal) {
      openUpgradeSubscriptionModal()
      return
    }

    const updateOrganizationPayload: UpdateOrganizationProps = {
      organizationId: organization.id,
      payload: { defaultTemplateId: invoiceTemplateId },
    }
    updateOrganization(updateOrganizationPayload)
  }, [
    organization?.id,
    invoiceTemplateId,
    shouldShowUpgradeSubscriptionModal,
    updateOrganization,
    openUpgradeSubscriptionModal,
  ])

  const handleDuplicateClick = useCallback(() => {
    const templateWithModifiedName: InvoiceTemplate = {
      ...invoiceTemplate,
      name: t('settings.organization.invoice.templates_settings.table.actions.duplicate.name', {
        templateName: invoiceTemplate.name,
      }),
    }
    const { id, ...createTemplatePayload } = templateWithModifiedName

    if (!organization?.id || !createTemplatePayload) {
      return
    }

    if (shouldShowUpgradeSubscriptionModal) {
      openUpgradeSubscriptionModal()
      return
    }

    createTemplate({ invoiceTemplate: createTemplatePayload, organizationId: organization.id })
  }, [
    createTemplate,
    shouldShowUpgradeSubscriptionModal,
    openUpgradeSubscriptionModal,
    organization?.id,
    t,
    invoiceTemplate,
  ])

  const handleEditClick = useCallback(() => {
    if (shouldShowUpgradeSubscriptionModal) {
      openUpgradeSubscriptionModal()
      return
    }

    navigate(EmberRoute.InvoiceTemplate, invoiceTemplateId)
  }, [shouldShowUpgradeSubscriptionModal, navigate, invoiceTemplateId, openUpgradeSubscriptionModal])

  return (
    <TableHoverActions>
      {!isDefaultInvoiceTemplate && (
        <TableHoverActions.Item
          icon="check"
          label={t('settings.organization.invoice.templates_settings.table.actions.set_as_default.label')}
          onClick={handleSetAsDefaultClick}
        />
      )}
      <TableHoverActions.Item
        icon="twoSquares"
        label={t('settings.organization.invoice.templates_settings.table.actions.duplicate')}
        onClick={handleDuplicateClick}
      />
      <TableHoverActions.Item
        icon="paperWithPencil"
        label={t('settings.organization.invoice.templates_settings.table.actions.edit')}
        onClick={handleEditClick}
      />
    </TableHoverActions>
  )
}
