import { WidgetId } from '../enums/widgetId'
import { WidgetSize } from '../enums/widgetSize'
import { Widget } from '../types/widget'

export const defaultWidgets: Widget[] = [
  {
    id: WidgetId.Overview,
    order: -1,
    isHidden: true,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.OverviewMonthly,
    order: 0,
    size: WidgetSize.FullWidth,
  },
  {
    id: WidgetId.GetStarted,
    order: 1,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Invoices,
    order: 2,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.FindAccountant,
    isHidden: true,
    order: 3,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Upsell,
    isStatic: true,
    order: 4,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Vat,
    isHidden: true,
    order: 5,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.BankIntegration,
    order: 6,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Bills,
    order: 7,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopCustomers,
    isHidden: true,
    order: 8,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Tasks,
    isHidden: true,
    order: 9,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopVendors,
    isHidden: true,
    order: 10,
    size: WidgetSize.HalfWidth,
  },
]

export const defaultWidgetsAfterDay: Widget[] = [
  {
    id: WidgetId.Overview,
    order: -1,
    isHidden: true,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.OverviewMonthly,
    order: 0,
    size: WidgetSize.FullWidth,
  },
  {
    id: WidgetId.GetStarted,
    order: 1,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Invoices,
    order: 2,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.BankIntegration,
    order: 3,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Bills,
    order: 4,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.FindAccountant,
    order: 5,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Upsell,
    isStatic: true,
    order: 6,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Vat,
    order: 7,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopCustomers,
    isHidden: true,
    order: 8,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Tasks,
    order: 9,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopVendors,
    order: 10,
    size: WidgetSize.HalfWidth,
  },
]

export const defaultAgerasBrandWidgetsList: Widget[] = [
  {
    id: WidgetId.Overview,
    order: -1,
    isHidden: true,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.OverviewMonthly,
    order: 0,
    size: WidgetSize.FullWidth,
  },
  {
    id: WidgetId.Upsell,
    isStatic: true,
    order: 1,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.Bills,
    order: 2,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopCustomers,
    order: 3,
    size: WidgetSize.HalfWidth,
  },
  {
    id: WidgetId.TopVendors,
    order: 4,
    size: WidgetSize.HalfWidth,
  },
]
