import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement, useCallback } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberModal } from '../../contexts/emberCloseModalContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { DaybookContextProvider } from '../../modules/daybook/contexts/daybookContext'
import { DaybookConsequencesModal } from '../../modules/daybook/elements/DaybookConsequencesModal'

const MODULE_NAME = 'daybook-consequences-modal'

class DaybookConsequencesModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal daybookId: string
  @registerEvent('closeEmber') closeEmber: Function
}

interface DaybookConsequencesModalProps {
  organizationId: string
  daybookId: string
  onCloseEmber: EmberModal
}

export const DaybookConsequencesModalModule = ({
  organizationId,
  daybookId,
  onCloseEmber,
}: DaybookConsequencesModalProps): ReactElement => {
  const closeModal = useCallback(() => {
    onCloseEmber({ detail: null })
  }, [onCloseEmber])

  return (
    <QueryClientProvider client={queryClient}>
      <HashRouter>
        <ThemeProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <ModalContextProvider>
              <GlobalFiscalYearContextProvider organizationId={organizationId}>
                <DaybookContextProvider daybookId={daybookId}>
                  <DaybookConsequencesModal onModalClose={closeModal} />
                </DaybookContextProvider>
              </GlobalFiscalYearContextProvider>
            </ModalContextProvider>
          </UserOrganizationContextProvider>
        </ThemeProvider>
      </HashRouter>
    </QueryClientProvider>
  )
}

const DaybookConsequencesModalCustomElement = createCustomElement(
  DaybookConsequencesModalModule,
  DaybookConsequencesModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, DaybookConsequencesModalCustomElement)

export default DaybookConsequencesModalCustomElement
