import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { getInvitedUsers } from '../query-api'

export const useUsers = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const organizationId = organization?.id || ''
  const ownerUserId = organization?.ownerUserId || ''

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.OrganizationUsers, organizationId],
    () => getInvitedUsers(organizationId, ownerUserId),
    {
      enabled: !isOrganizationLoading,
      refetchOnWindowFocus: true,
    },
  )

  return {
    data: data || [],
    isLoading: isIdle || isLoading,
  }
}
