import React, { ReactElement, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link, Route, HashRouter as Router, Switch } from 'react-router-dom'

import { actionCreators } from '../../app'
import BillEdit from './BillEdit'
import BillList from './BillList'
import BillView from './BillView'

type BillsProps = {
  organizationId: string
}

const { organizationRequested, userBootstrapRequested } = actionCreators

function Bills({ organizationId }: BillsProps): ReactElement {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(organizationRequested(organizationId))
    dispatch(userBootstrapRequested())
  }, [dispatch, organizationId])

  return (
    <Router>
      <hr />
      <Link to={'/'}>{t('bills.home')}</Link>
      <Switch>
        <Route exact path="/">
          <BillList organizationId={organizationId} />
        </Route>
        <Route exact path="/:billId/view" component={BillView} />
        <Route exact path="/:billId/edit" component={BillEdit} />
      </Switch>
    </Router>
  )
}

export default Bills
