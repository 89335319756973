import {
  ASSETS_ASSET_CREATED,
  ASSETS_CREATE_ASSET,
  ASSETS_GET_ASSETS_TYPES,
  ASSETS_TYPES_RECEIVED_ACTION,
} from './actions'
import { AssetDto, AssetsGetAction, AssetsTypesReceivedAction, AssetType, CreateAssetDto } from './types'

export function getAssetsTypes(): AssetsGetAction {
  return {
    type: ASSETS_GET_ASSETS_TYPES,
  }
}

export function assetsTypesReceived(assetTypes: AssetType[]): AssetsTypesReceivedAction {
  return {
    payload: assetTypes,
    type: ASSETS_TYPES_RECEIVED_ACTION,
  }
}

export function createAsset(asset: CreateAssetDto, organizationId: string) {
  return {
    payload: { asset, organizationId },
    type: ASSETS_CREATE_ASSET,
  }
}

export function assetCreated(asset: AssetDto) {
  return {
    payload: asset,
    type: ASSETS_ASSET_CREATED,
  }
}
