import { Input } from '@design-system'

import React, { ChangeEvent, ReactElement, useCallback, useEffect } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../hooks'
import { useUserOrganization } from '../../../../../app/organization'
import { getBillsBySuppliersInvoiceNo, GetBillsBySuppliersInvoiceNoPayload } from '../../../../query-api'
import { useBillForm } from '../../contexts/billFormContext'
import { BillFormSchema } from '../../utils/formData'

export const BillReferenceNumberInput = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { billId, setIsReferenceNumberError } = useBillForm()
  const { control, FormItem, setError, clearErrors } = useFormContext<BillFormSchema>()
  const vendor = useWatch({ control, name: 'vendor' })
  const referenceNumber = useWatch({ control, name: 'referenceNumber' })

  const showDuplicateError = useCallback(() => {
    setIsReferenceNumberError?.(true)
    setError('referenceNumber', {
      type: 'custom',
      message: t('bill.edit.form.reference_number.error'),
    })
  }, [setError, setIsReferenceNumberError, t])

  const clearDuplicateError = useCallback(() => {
    setIsReferenceNumberError?.(false)
    clearErrors('referenceNumber')
  }, [clearErrors, setIsReferenceNumberError])

  const checkForDuplicates = useCallback(
    async (referenceNumber: string, vendorId: string) => {
      const queryParams: GetBillsBySuppliersInvoiceNoPayload = {
        organizationId: organization?.id as string,
        contactId: vendorId,
        suppliersInvoiceNo: referenceNumber,
      }

      try {
        const result = await getBillsBySuppliersInvoiceNo(queryParams)
        const doesReferenceNumberExist = !!result?.bills.filter(
          ({ suppliersInvoiceNo, id }) => id !== billId && suppliersInvoiceNo === referenceNumber,
        ).length

        if (doesReferenceNumberExist) {
          showDuplicateError()
        } else {
          clearDuplicateError()
        }
      } catch (error) {
        console.error('Cant check for reference number duplicates', error)
      }
    },
    [billId, clearDuplicateError, organization?.id, showDuplicateError],
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target?.value

      if (!!organization?.id && !!value && !!vendor?.id) {
        checkForDuplicates(value, vendor.id)
      } else {
        clearDuplicateError()
      }
    },
    [checkForDuplicates, clearDuplicateError, organization?.id, vendor?.id],
  )

  useEffect(() => {
    if (!!organization?.id && referenceNumber && vendor?.id) {
      checkForDuplicates(referenceNumber, vendor.id)
    } else {
      clearDuplicateError()
    }
    // check only on vendor change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor?.id])

  return (
    <FormItem
      name="referenceNumber"
      label={t('bill.edit.form.reference_number')}
      labelAlignment="right"
      render={({ field }) => (
        <Input alignment="right" weight="medium" autoComplete="off" onChangeDebounced={handleInputChange} {...field} />
      )}
    />
  )
}
