import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { store } from '../../modules'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { Invoices } from '../../modules/invoices'

class InvoicesWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

type InvoicesModuleProps = {
  onNavigate: EmberNavigate
  organizationId: string
}

const InvoicesModule = ({ onNavigate, organizationId }: InvoicesModuleProps): ReactElement => (
  <ThemeProvider>
    {/* @todo remove Provider when ContactSelector will be replaced with a new one */}
    <ReduxProvider store={store}>
      <HashRouter>
        <QueryClientProvider client={queryClient}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <GlobalFiscalYearContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <CurrentUserContextProvider>
                  <ModalContextProvider>
                    <Invoices />
                  </ModalContextProvider>
                </CurrentUserContextProvider>
              </EmberRouterContextProvider>
            </GlobalFiscalYearContextProvider>
          </UserOrganizationContextProvider>
        </QueryClientProvider>
      </HashRouter>
    </ReduxProvider>
  </ThemeProvider>
)

const InvoicesCustomElement = createCustomElement(InvoicesModule, InvoicesWebComponent, 'element')

customElements.get('invoices-module') || customElements.define('invoices-module', InvoicesCustomElement)

export default InvoicesCustomElement
