import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { fetchSubscriptionPlans } from '../services/query-api'

export const useFetchSubscriptionPlans = () => {
  const { organization } = useUserOrganization()

  const { data, ...rest } = useQuery(
    [QueryKeys.SubscriptionPlans, organization?.id],
    () => fetchSubscriptionPlans(organization?.id as string),
    {
      enabled: !!organization?.id,
    },
  )

  return { subscriptionPlans: data || [], ...rest }
}
