import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'
import { AccountsContextProvider } from '../../modules/app/accounts'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { EnableMobilePayModal } from '../../modules/integrations/elements/MobilePayModalsGroup/elements/EnableMobilePayModal'

const MODULE_NAME = 'enable-mobilepay-modal-module'

class EnableMobilePayModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('navigate') navigate: Function
}

interface EnableMobilePayModalProps {
  organizationId: string
  onCloseEmber: EmberModal
  onNavigate: EmberNavigate
}

function EnableMobilePayModalModule({
  organizationId,
  onCloseEmber,
  onNavigate,
}: EnableMobilePayModalProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <ModalContextProvider>
            <CurrentUserContextProvider>
              <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
                <AccountsContextProvider organizationId={organizationId}>
                  <UserOrganizationContextProvider organizationId={organizationId}>
                    <EnableMobilePayModal id={ModalId.EnableMobilePayModal} forceRender />
                  </UserOrganizationContextProvider>
                </AccountsContextProvider>
              </EmberCloseModalContextProvider>
            </CurrentUserContextProvider>
          </ModalContextProvider>
        </EmberRouterContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const EnableMobilePayModalCustomElement = createCustomElement(
  EnableMobilePayModalModule,
  EnableMobilePayModalWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, EnableMobilePayModalCustomElement)

export default EnableMobilePayModalCustomElement
