import { SkeletonText } from '@design-system'

import React, { ReactElement } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../enums/queryKeys'
import { fetchContactById } from '../../modules/app/contacts/query-api'

interface ContactNameAsyncProps {
  contactId: string
}

export const ContactNameAsync = ({ contactId }: ContactNameAsyncProps): ReactElement => {
  const { data, isLoading } = useQuery([QueryKeys.Contacts, contactId], () => fetchContactById(contactId))

  return isLoading ? <SkeletonText variant="h3" /> : <span>{data?.contact.name}</span>
}
