import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '../../../../app/organization'
import { useFetchContactsByIds } from '../../../../contacts/hooks/useFetchContactsByIds'
import { Contact } from '../../../../contacts/types/contact'
import { fetchRecurringInvoices } from '../../../query-api'
import { RecurringInvoice } from '../../../types/recurringInvoice'
import { RecurringInvoicesQueryProps } from '../../../types/recurringInvoicesQueryProps'
import { getRecurringInvoicesQueryKey } from '../../../utils/getRecurringInvoicesQueryKey'

interface UseFetchRecurringInvoicesProps {
  searchQuery?: string
  sort?: string
}

interface UseFetchRecurringInvoicesResponse {
  recurringInvoices: RecurringInvoice[]
  contacts: Contact[]
  isLoading: boolean
}

export const useFetchRecurringInvoices = ({
  searchQuery,
  sort,
}: UseFetchRecurringInvoicesProps): UseFetchRecurringInvoicesResponse => {
  const { organization } = useUserOrganization()

  const queryProps: RecurringInvoicesQueryProps = useMemo(
    () => ({
      organizationId: organization?.id as string,
      q: searchQuery,
      sort,
      pageSize: 1000,
    }),
    [organization?.id, searchQuery, sort],
  )

  const queryKey = useMemo(() => getRecurringInvoicesQueryKey(queryProps), [queryProps])

  const {
    data: recurringInvoicesData,
    isIdle,
    isLoading,
  } = useQuery(queryKey, () => fetchRecurringInvoices(queryProps), {
    enabled: !!organization?.id,
  })

  const uniqueRecurringInvoicesContactIds = useMemo(() => {
    const recurringInvoicesContactIds = recurringInvoicesData?.data?.map(
      (recurringInvoice) => recurringInvoice.contactId,
    )

    return [...new Set(recurringInvoicesContactIds)]
  }, [recurringInvoicesData?.data])

  const { contacts, isLoading: areContactsLoading } = useFetchContactsByIds(uniqueRecurringInvoicesContactIds)

  return {
    recurringInvoices: recurringInvoicesData?.data || [],
    contacts,
    isLoading: isIdle || isLoading || areContactsLoading,
  }
}
