import { notify } from '@design-system'

import { ChartOfAccountId } from '../../../../modules/app/organization/enums/chartOfAccountId'
import { fetchTag } from '../query-api'
import { BookkeepingTagDbValue } from '../types/bookkeepingTagDbValue'
import { BookkeepingTagNavItemValue } from '../types/bookkeepingTagNavItemValue'

export const convertBookkeepingTagDbValueToNavItemValue = async (
  dbValue: BookkeepingTagDbValue,
  locale: string,
  chartOfAccountId?: ChartOfAccountId,
  countryId?: string,
): Promise<BookkeepingTagNavItemValue | undefined> => {
  if (!chartOfAccountId) {
    return undefined
  }

  try {
    const tag = await fetchTag({
      accountType: chartOfAccountId,
      countryId,
      id: dbValue.tagId,
      locale,
    })

    if (!tag) {
      return undefined
    }

    // Select the first response automatically when there's only one:
    const response =
      tag.question.responses.length === 1
        ? tag.question.responses[0]
        : tag.question.responses.find((response) => response.option === dbValue.answer?.option)

    return {
      tag,
      response,
    }
  } catch (e) {
    notify({ id: 'tag-fetch', message: `Could not fetch tag with id="${dbValue.tagId}"`, variant: 'error' })
    return undefined
  }
}
