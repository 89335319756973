import { MenuItemExtended } from './menuItems'

export const simplifyMenuItems = (menuItems: MenuItemExtended[]): MenuItemExtended[] =>
  menuItems.reduce((result, menuItem) => {
    const { submenu, routeTo } = menuItem
    // Skip rendering submenu if it has one element which routes to the same place as the main menu item
    const shouldSkipRenderingSubmenu = submenu?.length === 1 && submenu[0].routeTo === routeTo

    if (shouldSkipRenderingSubmenu) {
      return [...result, { ...menuItem, submenu: undefined }]
    }

    return [...result, menuItem]
  }, [] as MenuItemExtended[])
