import { getRequest, postRequest, putRequest } from '../../../../utils'
import { ContactPerson } from '../../../app/contactPerson/types/contactPerson'

export interface CreateContactPersonPayload {
  contactId: string
  firstName: string
  lastName: string
  email: string
}

export interface PostContactPersonResponse {
  contactPersons: ContactPerson[]
}

export const postContactPerson = (payload: CreateContactPersonPayload): Promise<PostContactPersonResponse> => {
  return postRequest('/v2/contactPersons', {
    contactPerson: payload,
  })
}

interface EInvoiceData {
  organizationId: string
  identifierType: string
  gln?: string
  cvr?: string
}

interface PostRecipientVerificationPayload {
  identifierType: string
  gln?: string
  cvr?: string
}

export interface VerificationResponse {
  isVerified?: boolean
  errorCode?: string
}

export const verifyRecipient = (data: EInvoiceData): Promise<VerificationResponse> => {
  const payload: PostRecipientVerificationPayload = {
    identifierType: data.identifierType.toUpperCase(),
  }

  if (payload.identifierType === 'GLN') {
    payload.gln = data.gln
  } else {
    payload.cvr = data.cvr
  }

  return postRequest(`/integrations/sproom/organizations/${data.organizationId}/recipient-verification`, payload)
}

export const updateContactEan = (contactId: string, ean: string) => {
  return putRequest(`/v2/contacts/${contactId}`, {
    contact: {
      ean,
      id: contactId,
    },
  })
}

export type PeppolVerificationStatus = 'pending' | 'signed' | null

export interface PeppolVerification {
  cvr: string
  status: PeppolVerificationStatus
  isVerified: boolean
}

export const getPeppolVerification = (organizationId: string): Promise<PeppolVerification> => {
  return getRequest(`/integrations/sproom/organizations/${organizationId}/peppol-verification`)
}

export interface RegisterInPeppolPayload {
  cvr: string
  name: string
  email: string
  organizationId: string
}

export const registerInPeppol = ({
  cvr,
  name,
  email,
  organizationId,
}: RegisterInPeppolPayload): Promise<PeppolVerification> => {
  return postRequest(`/integrations/sproom/organizations/${organizationId}/peppol-verification`, {
    cvr,
    name,
    email,
  })
}
