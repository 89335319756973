import { NavItem } from '@design-system'

import { TFunction } from 'i18next'

import { EmberRoute } from '../../../enums/emberRoute'
import { SearchResultItem } from '../types/searchResultItem'
import { getIconName } from './getIconName'

export const getSearchItemsFromMenu = (
  menuItems: NavItem<EmberRoute>[],
  parentItem: NavItem<EmberRoute> | undefined,
  t: TFunction,
): SearchResultItem[] => {
  const items: SearchResultItem[] = []

  for (const menuItem of menuItems) {
    const { id, title, children, value, subItems } = menuItem
    const icon = getIconName(menuItem) || getIconName(parentItem)

    if (subItems?.length) {
      items.push(...getSearchItemsFromMenu(subItems, menuItem, t))
    } else {
      items.push({
        id,
        icon,
        text: `${t('global_search.go_to')}: ${title || children}`,
        category: 'menu',
        routeTo: value,
      })
    }
  }

  return items
}
