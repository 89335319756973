import { QueryKeys } from '../../../enums/queryKeys'
import { VoucherInboxState, VoucherListQueryParams } from '../../inbox/types'

const missingInformationsQueryParams: VoucherListQueryParams = {
  inboxState: VoucherInboxState.NEED_INFO,
}

type VouchersListQueryArrayKeyResult = [QueryKeys, { organizationId: string; params: VoucherListQueryParams }]

export const getVouchersListQueryArrayKey = (
  organizationId = '',
  params?: VoucherListQueryParams,
): VouchersListQueryArrayKeyResult => [
  QueryKeys.VouchersList,
  { organizationId, params: { ...missingInformationsQueryParams, ...(params || {}) } },
]
