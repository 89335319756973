import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import { ReactElement } from 'react'

import { ThemeProvider } from '../../contexts/themeProvider'
import { FirstInvoiceModal } from '../../modules/invoices/elements/FirstInvoiceModal'

class FirstInvoiceModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

interface FirstInvoiceModalProps {
  organizationId: string
}

export function FirstInvoiceModalModule({ organizationId }: FirstInvoiceModalProps): ReactElement {
  return (
    <ThemeProvider>
      <ModalContextProvider>
        <FirstInvoiceModal />
      </ModalContextProvider>
    </ThemeProvider>
  )
}

const FirstInvoiceModalCustomElement = createCustomElement(
  FirstInvoiceModalModule,
  FirstInvoiceModalWebComponent,
  'element',
)

customElements.get('first-invoice-modal-module') ||
  customElements.define('first-invoice-modal-module', FirstInvoiceModalCustomElement)

export default FirstInvoiceModalCustomElement
