import { Color } from '@design-system'

import { lighten } from 'polished'
import { ReactElement } from 'react'

export const getOverviewChartGradient = (): ReactElement => (
  <defs>
    <linearGradient id="gradient-lineShadow" x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={Color.Blue} stopOpacity={0.1} />
      <stop offset="100%" stopColor={Color.Blue60} stopOpacity={0.1} />
    </linearGradient>
    <linearGradient id="gradient-expenses" rotate={90} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={Color.Red10} />
      <stop offset="100%" stopColor={Color.Red} />
    </linearGradient>
    <linearGradient id="gradient-expenses-rev" rotate={-90} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={Color.Red} />
      <stop offset="100%" stopColor={Color.Red10} />
    </linearGradient>
    <linearGradient id="gradient-revenue" rotate={-90} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={Color.Green} />
      <stop offset="100%" stopColor={lighten(0.3, Color.Green)} />
    </linearGradient>
    <linearGradient id="gradient-revenue-rev" rotate={90} x1="0" x2="0" y1="0" y2="1">
      <stop offset="0%" stopColor={lighten(0.3, Color.Green)} />
      <stop offset="100%" stopColor={Color.Green} />
    </linearGradient>
  </defs>
)
