import { Button, IconName } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberConnectBank } from '../../../../../../contexts/emberConnectBankContext'
import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useLocalStorage } from '../../../../../../hooks'
import { useUserOrganization, useUserOrganizationSettings } from '../../../../../app/organization'
import { SubscriptionPlan } from '../../../../../app/organization/enums/subscriptionPlan'

type ButtonActionType = 'error' | 'onPremiumPlan' | 'onBasicPlan' | 'inboxEnabled'

interface ButtonAction {
  tLabel: string
  action: () => void
  icon?: IconName
}

export interface ConnectionCTAProps {
  isError: boolean
  accountId?: string
}

export const ConnectionCTA = ({ isError, accountId }: ConnectionCTAProps): ReactElement => {
  const { t } = useTranslation()
  const { connectBank } = useEmberConnectBank()
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const { navigate } = useEmberRouter()
  const [bankReconciliationFlag] = useLocalStorage<number>('labs-flag:reactBankReconciliation', 0)

  const isInboxEnabled = accountId && organizationSettings?.isInboxEnabled === '1'
  const isPremiumPlan = accountId && organization?.subscriptionPlan === SubscriptionPlan.Premium

  const ctaButtonActions: Record<ButtonActionType, ButtonAction> = useMemo(
    () => ({
      error: {
        tLabel: 'dashboard.widget.bank-integration.renew_connection',
        action: () => {
          connectBank()
        },
        icon: 'roundedArrows',
      },
      inboxEnabled: {
        tLabel: 'dashboard.widget.bank-integration.go_to_things_to_do',
        action: () => {
          navigate(EmberRoute.ThingsToDo)
        },
      },
      onPremiumPlan: {
        tLabel: 'dashboard.widget.bank-integration.go_to_bank_recon',
        action: () => {
          if (accountId) {
            navigate(bankReconciliationFlag ? EmberRoute.BankReact : EmberRoute.Bank, accountId)
          }
        },
      },
      onBasicPlan: {
        tLabel: 'dashboard.widget.bank-integration.upgrade_to_premium',
        action: () => {
          navigate(EmberRoute.SettingsSubscription, organization?.url)
        },
      },
    }),
    [bankReconciliationFlag, organization, accountId, connectBank, navigate],
  )

  const buttonAction = useMemo((): ButtonAction => {
    if (isError) {
      return ctaButtonActions.error
    }

    if (isInboxEnabled) {
      return ctaButtonActions.inboxEnabled
    }

    if (isPremiumPlan) {
      return ctaButtonActions.onPremiumPlan
    }

    return ctaButtonActions.onBasicPlan
  }, [isError, isInboxEnabled, isPremiumPlan, ctaButtonActions])

  return (
    <>
      {organization && (
        <Button
          variant={isError || !isPremiumPlan ? 'primary' : 'secondary'}
          onClick={buttonAction.action}
          icon={buttonAction?.icon}
        >
          {t(buttonAction.tLabel)}
        </Button>
      )}
    </>
  )
}
