import { notify } from '@design-system'

import i18next from 'i18next'
import { all, call, put, takeLatest } from 'redux-saga/effects'

import { NotificationKeys } from '../../enums/notificationKeys'
import { APIError, getUnAuthRequest, postRequest, putRequest } from '../../utils'
import { assetCreated, assetsTypesReceived } from './action-creators'
import { ASSETS_CREATE_ASSET, ASSETS_GET_ASSETS_TYPES } from './actions'
import { AssetDto, AssetType, CreateAssetDto, CreateAssetProps } from './types'

function* createBillForAsset(asset: CreateAssetDto, organizationId: string) {
  const billData = {
    bill: {
      organizationId,
      contactId: asset.vendor,
      entryDate: asset.startDate,
      paymentDate: asset.paymentDate,
      paymentAccountId: asset.paymentAccountId,
      state: 'draft',
      origin: 'web',
    },
  }

  const billsRes: { bills: any[] } = yield call(postRequest, '/v2/bills', billData)

  const bill = billsRes?.bills[0]
  const billId = bill.id
  const lines = [
    {
      billId,
      description: asset.name,
      accountId: asset.paymentAccountId,
      amount: asset.startValue.amount,
    },
  ]

  const updatedBill = Object.assign({}, billData.bill, { lines, state: 'approved' })

  const savedBillsQuery: { bills: any[] } = yield call(putRequest, `/v2/bills/${billId}`, { bill: updatedBill })

  return savedBillsQuery?.bills[0]
}

function* fetchAssetTypes() {
  const res: AssetType[] = yield call(getUnAuthRequest, '/assetTypes')
  yield put(assetsTypesReceived(res))
}

function* createAsset({ payload: { asset, organizationId } }: CreateAssetProps) {
  try {
    const newAsset: { id: any } = yield call(postRequest, `/organizations/${organizationId}/assets`, asset)

    const bill: { type: any; id: any } = yield createBillForAsset(asset, organizationId)

    const assetWithBill = Object.assign({}, asset, {
      additionResourceType: bill.type,
      additionResourceId: bill.id,
    })

    const assetUpdate: AssetDto = yield call(
      putRequest,
      `/organizations/${organizationId}/assets/${newAsset.id}`,
      assetWithBill,
    )
    notify({ id: NotificationKeys.AssetCreate, message: i18next.t('done'), variant: 'success' })
    yield put(assetCreated(assetUpdate))
  } catch (e) {
    if (e instanceof APIError) {
      notify({
        id: NotificationKeys.AssetCreate,
        message: e.body?.errorMessage || i18next.t('something_went_wrong'),
        variant: 'error',
      })
      return
    }

    throw e
  }
}

export default function* (): any {
  yield all([
    yield takeLatest(ASSETS_GET_ASSETS_TYPES, fetchAssetTypes),
    yield takeLatest(ASSETS_CREATE_ASSET, createAsset),
  ])
}
