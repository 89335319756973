import { ItemsPerPage, Locale } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { SortDirection } from '../../../../../enums/sortDirection'
import { useUserOrganization } from '../../../../app/organization'
import {
  ContactBalance,
  ContactBalancePagination,
  ContactBalanceQueryProps,
  ContactBalanceSortProperty,
} from '../../../../contactBalance'
import { fetchDebtors } from '../../../query-api'
import { getDebtorsQueryKey } from '../../../utils/getDebtorsQueryKey'

interface UseFetchDebtorsProps {
  acceptLanguage?: Locale
  date?: string
  page?: number
  pageSize?: number
  searchQuery?: string
  sortDirection?: SortDirection
  sortProperty?: ContactBalanceSortProperty
}

interface UseFetchDebtorsResponse {
  data: ContactBalance[]
  isLoading?: boolean
  pagination?: ContactBalancePagination
}

export const useFetchDebtors = ({
  acceptLanguage,
  date,
  page,
  pageSize,
  searchQuery,
  sortDirection,
  sortProperty,
}: UseFetchDebtorsProps): UseFetchDebtorsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<number | undefined>(0)

  const queryProps: ContactBalanceQueryProps = useMemo(() => {
    return {
      ...(acceptLanguage ? { acceptLanguage } : {}),
      ...(date ? { date } : {}),
      ...(page ? { page } : {}),
      ...(pageSize ? { pageSize: pageSize as ItemsPerPage } : {}),
      ...(searchQuery ? { searchQuery } : {}),
      ...(sortDirection ? { sortDirection } : {}),
      ...(sortProperty ? { sortProperty } : {}),
      organizationId: organization?.id as string,
    }
  }, [acceptLanguage, date, organization?.id, page, pageSize, searchQuery, sortDirection, sortProperty])

  const queryKey = useMemo(() => getDebtorsQueryKey(queryProps), [queryProps])

  const { data, isIdle, isLoading } = useQuery(queryKey, () => fetchDebtors(queryProps), {
    enabled: !!organization,
    staleTime: defaultStaleTime,
  })

  const total = data?.data.length
  const hasTotal = total !== undefined

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (hasTotal) {
      previousPagination.current = total
    }
  }, [total, hasTotal])

  return {
    data: data?.data || [],
    isLoading: isIdle || isLoading,
    pagination: {
      total: hasTotal ? total : previousPagination.current,
    },
  }
}
