import { Container, ErrorBoundary } from '@components-deprecated'

import React, { ReactElement } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

import { AssetsCreate } from './Create'
import { AssetsList } from './List'

type AssetsProps = {
  organizationId: string
}

export const Assets = ({ organizationId }: AssetsProps): ReactElement => {
  return (
    <ErrorBoundary>
      <Container>
        <HashRouter>
          <Switch>
            <Route exact path="/create">
              <AssetsCreate organizationId={organizationId} />
            </Route>
            <Route path="/">
              <AssetsList />
            </Route>
          </Switch>
        </HashRouter>
      </Container>
    </ErrorBoundary>
  )
}

export default Assets
