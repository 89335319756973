import { ItemsPerPage, Table } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { QueryKeys } from '../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { useLocaleCode } from '../../../../../../hooks/useLocaleCode'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { fetchCountries } from '../../../../../app/countries/query-api'
import { useContacts } from '../../../../hooks/useContacts'
import { useContactsListFilters } from '../../../../hooks/useContactsListFilters'
import { ContactsSortProperty } from '../../../../query-api'
import { ContactsTableRow, useContactsTableData } from './hooks/useContactsTableData'
import { getTableColumns } from './utils/getTableColumns'

export const ContactsTable = (): ReactElement => {
  const locale = useLocaleCode()
  const { navigate } = useEmberRouter()
  const { t } = useTranslation()

  const { data } = useQuery([QueryKeys.Countries, locale], () => fetchCountries(locale))
  const { contacts, isLoading, pagination } = useContacts()
  const [{ sortDirection, sortProperty, pageSize, isSupplier }, setFilters] = useContactsListFilters()

  const tableData = useContactsTableData(contacts, t, data?.countries)
  const columns = getTableColumns(t)

  const sortedColumnId = useMemo(() => {
    return getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc)
  }, [sortDirection, sortProperty])

  const handlePageChange = useCallback(
    (page: number) => {
      setFilters({ page })
    },
    [setFilters],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setFilters({ page: 1, pageSize: itemsPerPage })
    },
    [setFilters],
  )

  const handleRowClick = useCallback(
    (row: Row<ContactsTableRow>) => {
      if (isSupplier) {
        navigate(EmberRoute.ContactsSupplier, row.original.id)
        return
      }
      navigate(EmberRoute.Contact, row.original.id)
    },
    [navigate, isSupplier],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setFilters({
        page: 1,
        sortProperty: columnId as ContactsSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
    },
    [setFilters],
  )

  return (
    <Table
      id={TableIds.ContactsTable}
      data={tableData}
      columns={columns}
      isLoading={isLoading}
      currentPage={pagination?.page}
      totalPageItems={pagination?.total}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onRowClick={handleRowClick}
      sortedColumnId={sortedColumnId}
      onSort={handleSort}
      withSkeletonView
      withColumnsFiltering
      withItemsPerPageSelect
    />
  )
}
