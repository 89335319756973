import { Header } from '@components-deprecated'

import React from 'react'

type AssetsModuleHeaderProps = {
  title: string
  children?: any
}

export const AssetsModuleHeader = ({ title, ...rest }: AssetsModuleHeaderProps) => {
  const { children } = rest

  return (
    <Header mb={50} p={0} title={title}>
      {children}
    </Header>
  )
}
