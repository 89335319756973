import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'

import { ThemeProvider } from '../../contexts/themeProvider'
import { OverdueInvoiceLockedAccountView } from '../../modules/OverdueInvoiceLockedAccountView'

const MODULE_NAME = 'organization-not-owner-and-overdue-invoice-locked-box'

class OverdueInvoiceLockedAccountViewWebComponent extends DOMModel {
  @byAttrVal error: string
}

const OverdueInvoiceLockedAccountViewModule = (): ReactElement => (
  <ThemeProvider>
    <OverdueInvoiceLockedAccountView />
  </ThemeProvider>
)

const OverdueInvoiceLockedAccountViewElement = createCustomElement(
  OverdueInvoiceLockedAccountViewModule,
  OverdueInvoiceLockedAccountViewWebComponent,
  'element',
)

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, OverdueInvoiceLockedAccountViewElement)

export default OverdueInvoiceLockedAccountViewElement
