import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '../../modules/app/organization'
import { IsTrustedPartnerContextProvider } from '../../modules/app/umbrellas'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { EditSubscriptionParams } from '../../modules/settings/routes/OrganizationSubscriptionSettings/types'
import TrialExpired from '../../modules/trialExpired/TrialExpired'
import { EmberEventFn } from '../../types/emberEventFn'

class LockedWebComponent extends DOMModel {
  @byAttrVal organizationid: string
  @registerEvent('editSubscription') editSubscription: EmberEventFn
}

type LockedProps = {
  organizationid: string
  onEditSubscription: (detail: EditSubscriptionParams) => void
}

function LockedModule({ onEditSubscription, organizationid }: LockedProps): ReactElement {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <UserOrganizationSettingsContextProvider organizationId={organizationid}>
          <UserOrganizationContextProvider organizationId={organizationid}>
            <IsTrustedPartnerContextProvider organizationId={organizationid}>
              <CurrentUserContextProvider>
                <ModalContextProvider>
                  <TrialExpired organizationId={organizationid} onEditSubscription={onEditSubscription} />
                </ModalContextProvider>
              </CurrentUserContextProvider>
            </IsTrustedPartnerContextProvider>
          </UserOrganizationContextProvider>
        </UserOrganizationSettingsContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const LockedCustomElement = createCustomElement(LockedModule, LockedWebComponent, 'element')

customElements.get('locked-module') || customElements.define('locked-module', LockedCustomElement)

export default LockedCustomElement
