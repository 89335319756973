import { NotesPanel, NotesPanelFormValues, NotesPanelOnSubmitOptions } from '@components'
import { notify } from '@design-system'

import orderBy from 'lodash/orderBy'
import React, { ReactElement, useCallback, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { useUserOrganization } from '../../../app/organization'
import { useAddBillTimeline } from '../../hooks/useAddBillTimeline'
import { useBillTimeline } from '../../hooks/useBillTimeline'
import { getBillTimelineQueryKey } from '../../utils/getBillTimelineQueryKey'
import { getNotesFromTimeline } from '../../utils/getNotesFromTimeline'
import { useAddBillTimelineOptimisticUpdate } from './hooks/useAddBillTimelineOptimisticUpdate'

interface BillNotesPanelPanelProps {
  isOpen?: boolean
  onClose?: () => void
  billId: string
}

export const BillNotesPanel = ({ isOpen, onClose, billId }: BillNotesPanelPanelProps): ReactElement => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { organization } = useUserOrganization()
  const { timeline, isLoading: isFetching } = useBillTimeline(billId)
  const onCreateSuccessRef = useRef<NotesPanelOnSubmitOptions['onSuccess']>()

  // Computed values

  const billTimelineQueryKey = useMemo(
    () => getBillTimelineQueryKey(billId, organization?.id),
    [billId, organization?.id],
  )
  const timelineSorted = useMemo(() => orderBy(timeline, ['number'], ['desc']), [timeline])
  const notes = useMemo(() => (timelineSorted ? getNotesFromTimeline(timelineSorted) : []), [timelineSorted])

  // Mutations

  const { onMutate } = useAddBillTimelineOptimisticUpdate(billId, timelineSorted)
  const { create, isLoading: isSubmitting } = useAddBillTimeline({
    onSuccess: () => {
      if (typeof onCreateSuccessRef.current === 'function') {
        onCreateSuccessRef.current()
        onCreateSuccessRef.current = undefined
      }
    },
    onMutate,
    onError: (error, _, context) => {
      queryClient.setQueryData(billTimelineQueryKey, context)
      notify({ id: 'create-note-error', message: t('note_panel.save_error'), variant: 'error' })
      console.error(error)
    },
  })

  // Handlers

  const handleSubmit = useCallback(
    async (values: NotesPanelFormValues, options?: NotesPanelOnSubmitOptions) => {
      if (!organization?.id) {
        return
      }

      if (typeof options?.onSuccess === 'function') {
        // it's used to clear input after successful mutation
        onCreateSuccessRef.current = options.onSuccess
      }

      await queryClient.cancelQueries(billTimelineQueryKey)
      create({ billId, organizationId: organization.id, comment: values.description })
    },
    [billId, billTimelineQueryKey, create, organization?.id, queryClient],
  )

  const handleClose = useCallback(() => {
    queryClient.invalidateQueries(billTimelineQueryKey)
    onClose?.()
  }, [billTimelineQueryKey, onClose, queryClient])

  return (
    <NotesPanel
      isOpen={isOpen}
      isFetching={isFetching}
      notes={notes}
      onClose={handleClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
    />
  )
}
