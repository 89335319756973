import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'

import { useUserOrganization } from '../../../../../../app/organization'
import { InvoiceTemplate } from '../types/invoiceTemplate'
import { InvoiceTemplateTableRow } from '../types/invoiceTemplateTableRow'

export const useInvoiceTemplatesTableData = (invoiceTemplates?: InvoiceTemplate[]): InvoiceTemplateTableRow[] => {
  const { organization } = useUserOrganization()

  return useMemo(() => {
    const templatesTableData = invoiceTemplates || []
    const defaultTemplateId = organization?.defaultTemplateId

    return templatesTableData.map((invoiceTemplate) => {
      const { id: invoiceTemplateId, name: invoiceTemplateName } = invoiceTemplate
      const isDefaultInvoiceTemplate = invoiceTemplateId === defaultTemplateId

      return {
        id: invoiceTemplateId,
        invoiceTemplate,
        invoiceTemplateId,
        invoiceTemplateName,
        isDefaultInvoiceTemplate,
        [InternalAccessor.HoverActions]: true,
        [InternalAccessor.StaticActions]: true,
      }
    })
  }, [organization?.defaultTemplateId, invoiceTemplates])
}
