import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as AnnualReportGetHelpLogo } from '../../../assets/images/integrations/annual-report-get-help.svg'
import { useEmberRouter } from '../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../enums/emberRoute'
import { Scope } from '../../../enums/scope'
import { isAuthorized } from '../../../utils/isAuthorized'
import { useIsTrustedPartner } from '../../app/umbrellas'
import { IntegrationId } from '../enums/integrationId'
import { IntegrationItem } from '../types/integrationItem'

export const useAnnualReportByAccountIntegration = (): IntegrationItem => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { isTrustedPartner, isLoading: isTrustedPartnerLoading } = useIsTrustedPartner()

  const buttonHandler = useCallback(() => {
    navigate(EmberRoute.AnnualReports)
  }, [navigate])

  const isHidden = !isAuthorized(Scope.AnnualReportRead, false, !isTrustedPartner)

  return {
    id: IntegrationId.AnnualReportByAccountant,
    name: t('integrations.card.annual_report_get_help.title'),
    description: t('integrations.card.annual_report_get_help.body'),
    readMoreLink: 'https://www.billy.dk/aarsregnskab/',
    imageNode: <AnnualReportGetHelpLogo />,
    isHiddenLoading: isTrustedPartnerLoading,
    isHidden,
    buttonHandler,
    buttonText: t('integrations.card.annual_report_get_help.button.activate'),
    statusLabelNode: null,
    isStatusLoading: false,
  }
}
