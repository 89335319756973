import { IconClose, Text } from '@components-deprecated'

import { css } from '@emotion/core'
import React, { useCallback, useEffect } from 'react'
import { Draggable, DroppableStateSnapshot } from 'react-beautiful-dnd'
import { useDispatch, useSelector } from 'react-redux'
import { Box, FlexProps } from 'rebass'
import { createSelector } from 'reselect'

import { useHover } from '../../../hooks'
import { reactClass } from '../../../utils'
import { SpecificState } from '../../types'
import { deleteBankLineSubjectAssociation, toggleTransactionsDroppableArea, unreconcileMatch } from '../action-creators'
import { BankLineSubjectAssociation, BillyTransaction, ExtendedBankLineMatch } from '../types'
import { prefixAmount } from '../utils'
import { LineWrapper } from './LineWrapper'
import { MatchListRowActionOverlay as ActionOverlay } from './MatchListRowActionOverlay'

export type MatchedTransactionProps = FlexProps &
  DroppableStateSnapshot & {
    index: number
    isApproved: boolean
    billyTransaction: BillyTransaction & {
      amount?: number
      balance?: number
      lineDescription?: string
      paymentDate?: string
    }
  }

type ActivateTransactionDroppableAreaProps = {
  isDragging?: boolean
}

const selectDeleteAssociationContext = createSelector(
  (state: SpecificState) => state.bankReconciliation.bankLineSubjectAssociations,
  (state: SpecificState) => state.bankReconciliation.bankLineMatches,
  (_: unknown, referenceId: string) => referenceId,
  (bankLineSubjectAssociations, bankLineMatches, referenceId) => {
    const bankLineSubjectAssociation = bankLineSubjectAssociations.find((blsa: BankLineSubjectAssociation) => {
      return blsa.subjectReference.endsWith(`:${referenceId}`)
    })

    const bankLineMatch = bankLineSubjectAssociation
      ? bankLineMatches.find(
          (bankLineMatch: ExtendedBankLineMatch) => bankLineMatch.id === bankLineSubjectAssociation.matchId,
        )
      : null

    return { bankLineSubjectAssociation, bankLineMatch }
  },
)

const isDragging = ({ isDragging, draggingOverWith }: any) => !isDragging && !draggingOverWith

// This serves the only purpose to remotely activate the Billy transaction droppable area on DND
const ActivateTransactionDroppableArea = ({ isDragging }: ActivateTransactionDroppableAreaProps) => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (isDragging) {
      dispatch(toggleTransactionsDroppableArea({ isActive: true }))
    } else {
      dispatch(toggleTransactionsDroppableArea({ isActive: false }))
    }
    return () => {
      dispatch(toggleTransactionsDroppableArea({ isActive: false }))
    }
  }, [dispatch, isDragging])
  return null
}

export const MatchedTransaction = ({
  billyTransaction: { amount = 0, balance, description, entryDate = '', id, lineDescription, paymentDate, side },
  draggingOverWith,
  index,
  isApproved,
  ...rest
}: MatchedTransactionProps) => {
  const dispatch = useDispatch()
  const { bankLineSubjectAssociation, bankLineMatch } = useSelector((state: SpecificState) => {
    return selectDeleteAssociationContext(state, id)
  })
  const { hoverRef, isHovered } = useHover()

  const deleteAssociation = useCallback(() => {
    if (bankLineMatch?.isApproved) {
      dispatch(unreconcileMatch(bankLineMatch.id))
      return
    }

    if (bankLineSubjectAssociation) {
      dispatch(
        deleteBankLineSubjectAssociation({
          bankLineMatch: bankLineMatch as ExtendedBankLineMatch,
          bankLineSubjectAssociation,
        }),
      )
    }
  }, [dispatch, bankLineSubjectAssociation, bankLineMatch])

  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isApproved}>
      {(provided, snapshot) => (
        <LineWrapper
          className={reactClass('matched-transaction')}
          groupable
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          {...snapshot}
          style={{
            ...provided.draggableProps.style,
            transform: snapshot.isDragging ? provided.draggableProps.style?.transform : 'unset',
            width: snapshot.isDragging ? '290px' : '100%',
          }}
          {...rest}
        >
          <ActivateTransactionDroppableArea {...snapshot} />
          {isDragging({ ...snapshot, draggingOverWith }) && (
            <div ref={hoverRef} style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
              <ActionOverlay
                className="remove-transaction"
                hoverable={isHovered}
                css={css`
                  top: 0;
                  left: 0;
                `}
              >
                <Box
                  css={css`
                    margin-top: -2px;
                    margin-left: 10px;
                    i {
                      cursor: pointer;
                    }
                  `}
                >
                  <IconClose
                    width="8px"
                    height="8px"
                    color={'#3a4a54'}
                    strokeWidth={'2px'}
                    onClick={deleteAssociation}
                  />
                </Box>
              </ActionOverlay>
            </div>
          )}
          <Text variant="body1" type="currency" currencyColored={!isApproved} flex="3">
            {prefixAmount({ amount: balance || amount, side })}
          </Text>
          <Text
            variant="body1"
            flex="5"
            mx={1}
            css={css`
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: keep-all;
              white-space: nowrap;
            `}
          >
            {description || lineDescription || ' '}
          </Text>
          <Text
            variant="body1"
            type="date"
            flex="4"
            css={css`
              overflow: hidden;
              text-overflow: ellipsis;
              word-break: keep-all;
              white-space: nowrap;
              text-align: right;
            `}
          >
            {paymentDate || entryDate}
          </Text>
        </LineWrapper>
      )}
    </Draggable>
  )
}
