import {
  AccountingPackageModal as AccountingPackageModalComponent,
  PurchaseCard,
  PurchaseCardHighlight,
} from '@components'
import { Link, useModal, withModalConditionalRender } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { ModalId } from '../../../../enums/modalId'
import { useAnnualReportPurchasing } from '../../contexts/annualReportPurchasingContext'
import { AnnualReportType } from '../../enums/annualReportType'
import { getARTypeFeatures } from '../../utils/getARTypeFeatures'

const AccountingPackageModal = withModalConditionalRender(AccountingPackageModalComponent)

export const AccountingPackageCard = (): ReactElement => {
  const { t } = useTranslation()
  const { accountingPackagePrice, handlePurchaseAccountingPackage, isAccountingPackageSoldOut, year } =
    useAnnualReportPurchasing()
  const { open: openAccountingPackageModal } = useModal(ModalId.AccountingPackageModal)

  const accountingPackageHighlight: PurchaseCardHighlight | undefined = useMemo(
    () =>
      isAccountingPackageSoldOut
        ? {
            text: t('annual_report.package.we_do_it.sold_out', { year }),
            variant: 'primary',
          }
        : undefined,
    [isAccountingPackageSoldOut, t, year],
  )

  const handleBuyButtonClick = useCallback(() => {
    openAccountingPackageModal()
  }, [openAccountingPackageModal])

  return (
    <PurchaseCard highlight={accountingPackageHighlight}>
      <PurchaseCard.Header
        description={
          <Trans i18nKey="annual_report.package.accounting_package.description">
            Get everything done by our accountant
            <br />
            partner*
          </Trans>
        }
        title={t('annual_report.package.accounting_package.title')}
      />
      <PurchaseCard.Button onClick={handleBuyButtonClick} disabled={isAccountingPackageSoldOut}>
        {t('annual_report.package.buy')}
      </PurchaseCard.Button>
      <PurchaseCard.PriceSection price={accountingPackagePrice} priceSuffix={t('annual_report.package.price_suffix')} />
      <PurchaseCard.Features
        footer={
          <Trans i18nKey="annual_report.package.accounting_package.footer">
            *Our accountant partner
            <Link href="https://aarsregnskab.dk/" underlined withAnimation>
              Årsregnskabet.dk
            </Link>
            will do the work
          </Trans>
        }
        header={t('annual_report.package.included')}
        items={getARTypeFeatures(AnnualReportType.AccountingPackage)}
      />
      <AccountingPackageModal
        id={ModalId.AccountingPackageModal}
        onAcceptButtonClick={handlePurchaseAccountingPackage}
      />
    </PurchaseCard>
  )
}
