import { QueryKeys } from '../../../../../../../enums/queryKeys'
import {
  getTransactionsQueryKey,
  TransactionSortProperty,
  TransactionsQueryProps,
} from '../../../../../../transactions'

interface BillTransactionsQueries {
  queryKey: [QueryKeys, TransactionsQueryProps]
  queryProps: TransactionsQueryProps
}

export const getBillTransactionsQueries = (billId: string): BillTransactionsQueries => {
  const queryProps: TransactionsQueryProps = {
    originatorReference: `bill:${billId}`,
    sortProperty: TransactionSortProperty.TransactionNo,
    withVoided: true,
  }

  return {
    queryKey: getTransactionsQueryKey(queryProps),
    queryProps,
  }
}
