import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../config/queryClient'
import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { fetchIncomeStatements } from '../query-api'
import { IncomeStatementsReportOptions } from '../types/reportOptions'

export const useFetchIncomeStatementsReport = (options?: IncomeStatementsReportOptions) => {
  const { organization } = useUserOrganization()

  const { data, ...rest } = useQuery(
    [QueryKeys.IncomeStatements, { organizationId: organization?.id, ...options }],
    () => fetchIncomeStatements({ organizationId: organization?.id as string, options }),
    {
      enabled: !!organization?.id,
      staleTime: defaultStaleTime,
    },
  )

  return { report: data?.report, ...rest }
}
