import React, {
  Children,
  cloneElement,
  isValidElement,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useHoverDirty } from 'react-use'

import { Color } from '../../../../enums/color'
import { useOnFocusedSubmit } from '../../../../hooks/useOnFocusedSubmit'
import { useScrollToElement } from '../../../../hooks/useScrollToElement'
import { useTheme } from '../../../../hooks/useTheme'
import { getItemState } from '../../utils/getItemState'
import { getStateToStyles } from '../../utils/getStateToStyles'
import { NavListItemProps } from '../NavListItem'
import { NavListItemText } from '../NavListItemText'
import * as Styled from './styles'

type NavListSubItemProps<T> = NavListItemProps<T>

type SubItemChildren<T> = Pick<NavListSubItemProps<T>, 'selectedId' | 'onClick' | 'focusedId' | 'item'> & {
  textColor?: Color
}

export const NavListSubItem = <T,>({ item, selectedId, focusedId, onClick }: NavListSubItemProps<T>): ReactElement => {
  const theme = useTheme()
  const listItemRef = useRef<HTMLDivElement>(null)
  const [listItemElement, setListItemElement] = useState<HTMLDivElement | null>(null)
  const isHovered = useHoverDirty(listItemRef)

  const { id, children, value, title } = item
  const isSelected = selectedId === id
  const isFocused = focusedId === id

  const itemState = useMemo(
    () => getItemState({ selected: isSelected, focused: isFocused, hovered: isHovered }),
    [isSelected, isFocused, isHovered],
  )
  const { textSubitemColor, backgroundColor } = getStateToStyles(theme)[itemState]

  useEffect(() => {
    setListItemElement(listItemRef.current)
  }, [listItemRef])

  const handleItemClick = useCallback(() => {
    onClick && onClick(id, value)
  }, [onClick, id, value])

  const handleItemKeyDown = useOnFocusedSubmit(listItemElement, handleItemClick)

  useScrollToElement(listItemElement, isFocused || isSelected)

  return (
    <Styled.SubItemWrapper>
      <Styled.SubItem
        ref={listItemRef}
        onClick={handleItemClick}
        backgroundColor={backgroundColor}
        onKeyDown={handleItemKeyDown}
        tabIndex={0}
      >
        {typeof children === 'string' ? (
          <NavListItemText color={textSubitemColor} title={title || children}>
            {children}
          </NavListItemText>
        ) : (
          Children.map(children, (child) => {
            if (isValidElement<SubItemChildren<T>>(child)) {
              return cloneElement(child, { selectedId, onClick, focusedId, item, textColor: textSubitemColor })
            }

            return child
          })
        )}
      </Styled.SubItem>
    </Styled.SubItemWrapper>
  )
}
