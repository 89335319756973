export enum LocalStorageKeys {
  AnnualReportsWigetClosed = 'annual-reports-widget-closed',
  BankIntegrationSelection = 'bank-integration-selection',
  BillyWelcomeModal = 'billy-welcome-modal',
  DashboardPeriodDropdown = 'dashboard-period-dropdown',
  HideBookkeepingHero = 'hide-bookkeeping-hero',
  HideIntercomMessenger = 'hideIntercomMessenger',
  InboxOrganizationId = 'inboxOrganizationId',
  MobileExperienceMessageClosed = 'mobile-experience-message-closed',
  SendInvoiceCopyToUser = 'send-invoice-copy-to-user',
}
