import { NavListItemWithBlockDescription, Select, SelectProps } from '@design-system'

import omit from 'lodash/omit'
import React, { forwardRef, ReactElement, Ref, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../../modules/app/organization'
import { fetchSalesTaxRulesets } from './query-api'
import { SalesTaxRuleset } from './types/salesTaxRuleset'
import { SalesTaxRulesetValue } from './types/salesTaxRulesetValue'
import { getItems } from './utils/getItems'

interface TaxRulesetSelectProps
  extends Omit<SelectProps<SalesTaxRulesetValue>, 'items' | 'dropdownItemRender' | 'dropdownFetching' | 'onSelect'> {
  onSelect?: (id?: string, value?: SalesTaxRuleset) => void
}

export const TaxRulesetSelect = forwardRef(
  ({ onSelect, ...selectProps }: TaxRulesetSelectProps, forwardedRef: Ref<HTMLDivElement | null>): ReactElement => {
    const { t } = useTranslation()
    const { organization } = useUserOrganization()
    const { data, isLoading } = useQuery(
      [QueryKeys.SalesTaxRulesets, organization?.id],
      () => fetchSalesTaxRulesets(organization?.id as string),
      {
        enabled: !!organization?.id,
      },
    )

    const items = useMemo(
      () => (data?.salesTaxRulesets ? getItems(data?.salesTaxRulesets, t) : []),
      [data?.salesTaxRulesets, t],
    )

    const handleSelect = useCallback(
      (id?: string, value?: SalesTaxRulesetValue) => {
        const filteredValue = omit(value, 'displayData')
        onSelect?.(id, filteredValue)
      },
      [onSelect],
    )

    return (
      <Select
        {...selectProps}
        dropdownFetching={isLoading}
        dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
        items={items}
        onSelect={handleSelect}
        ref={forwardedRef}
      />
    )
  },
)
