import { VatRateTemplateResponseData } from 'src/modules/settings/routes/VatRatesSettings/types/vatRateTemplateResponseData'

import { DeductionComponentForm } from '../../../../../types/deductionComponentForm'
import { DeductionComponentFromTemplateFormData } from '../../../../../types/deductionComponentFromTemplateFormData'

export const prepareDeductionComponentsPayloadFromTemplatedForm = (
  template: VatRateTemplateResponseData,
  deductionComponentsForm: DeductionComponentForm[],
): DeductionComponentFromTemplateFormData[] => {
  let deductionComponents: DeductionComponentFromTemplateFormData[]

  if (
    template?.editableProperties.deductionComponents &&
    template?.editableProperties.deductionComponents.length === 0
  ) {
    deductionComponents = deductionComponentsForm
  } else {
    deductionComponents = deductionComponentsForm?.map((deductionComponent, index) => {
      const result = {}

      if (!template?.editableProperties.deductionComponents) {
        return {}
      }

      const editablePropertiesOfDeductionComponent =
        template?.editableProperties.deductionComponents && template?.editableProperties.deductionComponents[index]
          ? template?.editableProperties.deductionComponents[index]
          : {
              share: true,
              accountId: true,
              source: true,
            }

      for (const [propertyName, isPropertyEnabled] of Object.entries(editablePropertiesOfDeductionComponent)) {
        if (isPropertyEnabled) {
          result[propertyName] = deductionComponent[propertyName]
        }
      }

      return result
    })
  }

  // convert data to output accepted by the BE
  deductionComponents = deductionComponents?.map((element) => {
    const result = {
      ...element,
    }

    // BE expects fractions and we display percents
    if (element.share) {
      result.share = element.share / 100
    }

    // BE expects null and our select uses empty strings
    if (element.accountId === '') {
      result.accountId = null
    }

    return result
  })

  return deductionComponents
}
