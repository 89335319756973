import { all, call, put, takeLatest } from 'redux-saga/effects'

import { getRequest } from '../../utils'
import { billReceived, billsReceived } from './action-creators'
import { BILL_REQUESTED, BILLS_REQUESTED } from './actions'
import { IBillRequested, IBillsRequested } from './types'

function* fetchBills(action: IBillsRequested) {
  const res: { bills: any } = yield call(getRequest, `/v2/bills?organizationId=${action.payload}`)
  yield put(billsReceived(res.bills))
}

function* fetchBill(action: IBillRequested) {
  const res: { bill: any } = yield call(getRequest, `/v2/bills/${action.payload}`)
  yield put(billReceived(res.bill))
}

export default function* (): any {
  yield all([yield takeLatest(BILLS_REQUESTED, fetchBills), yield takeLatest(BILL_REQUESTED, fetchBill)])
}
