import { DateFormatter, Translate } from '@components'
import { InternalAccessor, ItemsPerPage, Table, TableCellContent, TableColumn } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SortDirection } from '../../../../../../enums/sortDirection'
import { TableIds } from '../../../../../../enums/tableIds'
import { useSegment } from '../../../../../../hooks'
import { getTableColumnIdDirection } from '../../../../../../utils/getTableColumnIdDirection'
import { useUmbrella } from '../../../../../app/umbrellas'
import { OrganizationsSortProperty } from '../../../../../app/umbrellas/query-api'
import { useCurrentUser } from '../../../../../app/user'
import { useUmbrellaOrganizations } from '../../../../../inbox/hooks/useUmbrellaOrganizations'
import { useUmbrellaOrganizationsListFilters } from '../../hooks/useUmbrellaOrganizationsListFilters'
import { UmbrellaOrganizationTable } from '../../types/umbrellaOrganizationTable'
import { UmbrellaOrganizationsListTableStaticActions } from '../UmbrellaOganizationsListTableStaticActions'
import { UmbrellaOrganizationsListTableHoverActions } from '../UmbrellaOrganizationsListTableHoverActions/UmbrellaOrganizationsListTableHoverActions'
import { getStaticActionsForUmbrellaOrganization } from './utils/getStaticActionsForUmbrellaOrganization'

export const UmbrellaOrganizationsListTable = (): ReactElement => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { user } = useCurrentUser()

  const [{ page, pageSize, searchQuery, sortDirection, sortProperty }, setQueryParams] =
    useUmbrellaOrganizationsListFilters()
  const {
    organizations = [],
    isLoading,
    pagination,
  } = useUmbrellaOrganizations({
    page,
    pageSize,
    q: searchQuery,
    sortDirection,
    sortProperty,
  })

  const { track } = useSegment()

  const umbrellaOrganizationsData = useMemo(
    () =>
      organizations.map((organization) => {
        const { organizationId, organizationTaskSummary, ...rest } = organization
        const staticActions = getStaticActionsForUmbrellaOrganization({
          umbrellaOrganizationOwner: organization.owner,
          user,
          umbrella,
          t,
        })
        const hasStaticActions = staticActions.length > 0
        return {
          ...rest,
          id: organizationId,
          organizationId,
          draftDaybookTransactions: organizationTaskSummary.draftDaybookTransactions,
          lastTransactionTime: organizationTaskSummary.lastTransactionTime,
          orphanAttachments: organizationTaskSummary.orphanAttachments,
          reconcilableBankLines: organizationTaskSummary.reconcilableBankLines,
          staticActions,
          [InternalAccessor.StaticActions]: hasStaticActions,
          [InternalAccessor.HoverActions]: true,
        }
      }),
    [organizations, user, umbrella, t],
  )

  const columns: TableColumn<UmbrellaOrganizationTable>[] = useMemo(
    () => [
      {
        accessor: 'name',
        Header: <Translate value="umbrella_organizations.organizations_table.name_column" />,
        sortable: false,
        size: 'xxl',
        truncate: true,
      },
      {
        accessor: 'owner',
        Header: <Translate value="umbrella_organizations.organizations_table.owner_column" />,
        Cell: ({ value }) => <TableCellContent truncate>{value.name}</TableCellContent>,
        sortable: false,
        size: 'xxl',
        truncate: true,
      },
      {
        accessor: 'isTerminated',
        Header: <Translate value="umbrella_organizations.organizations_table.subscription_column" />,
        Cell: ({ data, row }) => {
          const { consolidatedBilling, isTerminated } = data[row.id] as UmbrellaOrganizationTable

          if (isTerminated) {
            return <Translate value="umbrella_organizations.organizations_table.terminated" />
          } else if (consolidatedBilling) {
            return <Translate value="umbrella_organizations.organizations_table.consolidated_billing" />
          }
          return <Translate value="umbrella_organizations.organizations_table.self_paying" />
        },
        size: 'l',
        sortable: false,
        truncate: true,
      },
      {
        accessor: 'reconcilableBankLines',
        Header: <Translate value="umbrella_organizations.organizations_table.open_banklines" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'orphanAttachments',
        Header: <Translate value="umbrella_organizations.organizations_table.uploads" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'draftDaybookTransactions',
        Header: <Translate value="umbrella_organizations.organizations_table.draft_postings" />,
        size: 's',
        sortable: true,
        truncate: true,
      },
      {
        accessor: 'lastTransactionTime',
        Header: <Translate value="umbrella_organizations.organizations_table.last_transaction" />,
        size: 'm',
        Cell: ({ value }) => value && <DateFormatter value={value} />,
        sortable: true,
        truncate: true,
      },
      {
        accessor: InternalAccessor.HoverActions,
        Header: '',
        Cell: ({ row, data }) => {
          const { url } = data[row.id] as UmbrellaOrganizationTable
          return <UmbrellaOrganizationsListTableHoverActions umbrellaOrganizationUrl={url} />
        },
        sortable: false,
      },
      {
        accessor: InternalAccessor.StaticActions,
        Header: '',
        Cell: ({ row, data }) => {
          const { staticActions, organizationId } = data[row.id] as UmbrellaOrganizationTable
          const umbrellaOrganization = organizations.find(
            (organization) => organization.organizationId === organizationId,
          )
          return umbrellaOrganization ? (
            <UmbrellaOrganizationsListTableStaticActions
              umbrellaOrganization={umbrellaOrganization}
              staticActions={staticActions}
            />
          ) : (
            <></>
          )
        },
        sortable: false,
      },
    ],
    [organizations],
  )

  const sortedColumnId = useMemo(
    () => getTableColumnIdDirection(sortProperty, sortDirection === SortDirection.Desc),
    [sortDirection, sortProperty],
  )

  const handlePageChange = useCallback(
    (page: number) => {
      setQueryParams({ page })
    },
    [setQueryParams],
  )

  const handleItemsPerPageChange = useCallback(
    (itemsPerPage: ItemsPerPage) => {
      setQueryParams({ page: 1, pageSize: itemsPerPage })
    },
    [setQueryParams],
  )

  const handleSort = useCallback(
    (columnId: string, isDesc: boolean) => {
      setQueryParams({
        page: 1,
        sortProperty: columnId as OrganizationsSortProperty,
        sortDirection: isDesc ? SortDirection.Desc : SortDirection.Asc,
      })
      track('XXX - Mikkel - Proverview', {
        type: columnId,
      })
    },
    [setQueryParams, track],
  )

  return (
    <Table
      columns={columns}
      currentPage={pagination?.page}
      data={umbrellaOrganizationsData}
      id={TableIds.UmbrellaOrganizationsList}
      isLoading={isLoading}
      itemsPerPage={pageSize}
      onItemsPerPageChange={handleItemsPerPageChange}
      onPageChange={handlePageChange}
      onSort={handleSort}
      totalPageItems={pagination?.total}
      sortedColumnId={sortedColumnId}
      withColumnsFiltering
      withItemsPerPageSelect
      withStickyHeader
    />
  )
}
