import { AmountFormatter, DateFormatter } from '@components'
import { InternalAccessor, notify, Space, Table, TableColumn, Text } from '@design-system'

import { addMonths, subMonths } from 'date-fns'
import sortBy from 'lodash/sortBy'
import React, { ReactElement, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { Row } from 'react-table'

import { QueryKeys } from '../../../../enums/queryKeys'
import { useUserOrganization } from '../../../app/organization'
import { useRequestSalaryLoginUrl } from '../../hooks/useRequestSalaryLoginUrl'
import { getSalaryPayrollList } from '../../query-api'
import { SalarySummary } from '../SalarySummary'
import { PayrollStatusFormatter } from './elements/PayrollStatusFormatter'
import * as Styled from './styles'
import { PayrollListView } from './types/payrollListView'
import { getPayrollSplittedLists } from './utils/getPayrollSplittedLists'
import { getTableData } from './utils/getTableData'

const MONTHS_RANGE = 6

const dateFrom = subMonths(new Date(), MONTHS_RANGE)
const dateTo = addMonths(new Date(), MONTHS_RANGE)

export const SalaryPayrollList = (): ReactElement => {
  const [disabledRowId, setDisabledRowId] = useState<string>()
  const { organization } = useUserOrganization()
  const { t } = useTranslation()

  // Functions

  const handleGetLoginError = useCallback(() => {
    notify({
      id: 'get-salary-login',
      message: 'Could not redirect to the salary page. Please try again later.',
      variant: 'error',
    })
  }, [])

  // Queries

  const { requestLoginUrl } = useRequestSalaryLoginUrl({
    onSuccess: (data) => {
      if (data.login) {
        // @todo check for browser support
        window.open(data.login, '_blank')?.focus()
      } else {
        handleGetLoginError()
      }
    },
    onError: handleGetLoginError,
    onSettled: () => {
      setDisabledRowId(undefined)
    },
  })

  const { data: payrollData, isLoading: isSalaryPayrollLoading } = useQuery(
    [QueryKeys.SalaryPayrolls, organization?.id, organization?.registrationNo],
    () => getSalaryPayrollList(organization?.id as string, organization?.registrationNo as string, dateFrom, dateTo),
    {
      enabled: !!organization,
      refetchOnWindowFocus: true,
    },
  )

  // Computed values from

  const isLoading = !organization || isSalaryPayrollLoading
  const payrollListSorted = useMemo(
    () => sortBy(payrollData?.data, (payroll) => new Date(payroll.dispositionDate)).reverse(),
    [payrollData?.data],
  )
  const { payrollListCurrent, payrollListPrevious } = useMemo(
    () => getPayrollSplittedLists(payrollListSorted),
    [payrollListSorted],
  )

  // Handlers

  const handleRowClick = useCallback(
    (row: Row<PayrollListView>) => {
      setDisabledRowId(row.original.id)
      requestLoginUrl()
    },
    [requestLoginUrl],
  )

  // Table data

  const columns: TableColumn<PayrollListView>[] = useMemo(
    () => [
      {
        Header: t('salary.payroll_list.table.header.status') as string,
        accessor: 'status',
        size: 's',
        Cell: ({ row, data }) => {
          const { status, approvalDeadline } = data[row.id] as PayrollListView
          return <PayrollStatusFormatter status={status} approvalDeadline={approvalDeadline} />
        },
      },
      {
        Header: t('salary.payroll_list.table.header.period') as string,
        accessor: 'salaryPeriod',
        size: 'm',
        Cell: ({ row, data }) => {
          const values = data[row.id] as PayrollListView

          return (
            <>
              <DateFormatter value={values.salaryPeriodFrom} /> ➔ <DateFormatter value={values.salaryPeriodTo} />
            </>
          )
        },
      },
      {
        Header: t('salary.payroll_list.table.header.cycle') as string,
        accessor: 'cycleFrequency',
      },
      {
        Header: t('salary.payroll_list.table.header.total') as string,
        accessor: 'totalAmount',
        alignment: 'right',
        size: 's',
        Cell: ({ value }) => <AmountFormatter value={value || 0} />,
      },
      {
        accessor: InternalAccessor.Disabled,
      },
    ],
    [t],
  )

  const tableDataCurrentPayrolls = useMemo(
    () => getTableData(payrollListCurrent, disabledRowId, t),
    [disabledRowId, payrollListCurrent, t],
  )
  const tableDataPreviusPayrolls = useMemo(
    () => getTableData(payrollListPrevious, disabledRowId, t),
    [disabledRowId, payrollListPrevious, t],
  )

  return (
    <Styled.SalaryPayrollWrapper>
      <SalarySummary />
      <Space size="xxl" />

      <Text variant="h3">{t('salary.payroll_list.table.payrolls_current.title')}</Text>
      <Space size="s" />
      <Table
        columns={columns}
        data={tableDataCurrentPayrolls}
        isClientSidePagination
        isLoading={isLoading}
        itemsPerPage={100}
        skeletonItemsPerPage={10}
        onRowClick={handleRowClick}
        withPagination={false}
      />

      {!isLoading && (
        <>
          <Space size="xxl" />

          <Text variant="h3">{t('salary.payroll_list.table.previous_payrolls.title')}</Text>
          <Space size="s" />
          <Table
            columns={columns}
            data={tableDataPreviusPayrolls}
            isClientSidePagination
            itemsPerPage={100}
            onRowClick={handleRowClick}
          />
        </>
      )}
    </Styled.SalaryPayrollWrapper>
  )
}
