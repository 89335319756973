import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { acceptUmbrellaOrganizationInvitation } from '../../../../app/umbrellas/query-api'

interface UseAcceptOrganizationInvitationArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useAcceptOrganizationInvitation = ({ onError, onSuccess }: UseAcceptOrganizationInvitationArgs = {}) => {
  const { isLoading: isAccepting, mutate: acceptInvitation } = useMutation(
    (token: string) => acceptUmbrellaOrganizationInvitation(token),
    { onError, onSuccess },
  )

  return {
    acceptInvitation,
    isAccepting,
  }
}
