import { SmartSelect } from '@components-deprecated'

import { map, sortBy } from 'lodash'
import React, { useCallback, useMemo } from 'react'
import { Control } from 'react-hook-form'
import { BoxProps } from 'rebass'

import { useAccountGroups } from '../accountGroupsContext'
import { AccountGroup } from '../types'

export type AccountGroupSelectorProps = BoxProps & {
  disconnect?: boolean
  formControl?: Control<any>
  label?: string
  onItemSelect?: (accountGroup: AccountGroup, name?: string) => void
  organizationId?: string
  preselectedId?: string
  withSelectEvent?: boolean
  silent?: boolean
}

export const AccountGroupSelector = ({
  disconnect,
  formControl,
  label,
  name = 'account-group-selector',
  onItemSelect = () => null,
  organizationId: providedOrganizationId,
  preselectedId = '',
  withSelectEvent,
  ...rest
}: AccountGroupSelectorProps) => {
  const { accountGroups } = useAccountGroups()

  const handleSelect = useCallback(
    (item: AccountGroup, name: string) => {
      onItemSelect(item, name)
    },
    [onItemSelect],
  )

  const items = useMemo(() => {
    const sortedGroups = sortBy(accountGroups, ['priority', 'accountNo'])
    const namedGroups = map(sortedGroups, ({ accountNo, allowPaymentAmounts, id, name, natureId }) => ({
      accountNo,
      allowPaymentAmounts,
      id,
      name,
      natureId,
      text: name,
    }))

    return namedGroups
  }, [accountGroups])

  return (
    <SmartSelect<AccountGroup>
      defaultValueId={preselectedId}
      formControl={formControl}
      items={items}
      minWidth="200px"
      name={name}
      onItemSelect={handleSelect}
      preselectedId={preselectedId}
      disconnect={!!formControl || disconnect}
      label={label}
      {...rest}
    />
  )
}
