import qs from 'qs'

import { getRequest } from '../../utils'
import { ContactBalance, ContactBalanceQueryProps } from '../contactBalance'

export interface FetchDebtorsResponseData {
  data: ContactBalance[]
}

export const fetchDebtors = ({
  acceptLanguage,
  date,
  organizationId,
  page,
  pageSize,
  searchQuery,
  sortProperty,
  sortDirection,
}: ContactBalanceQueryProps): Promise<FetchDebtorsResponseData> => {
  const queryParams = qs.stringify({
    acceptLanguage,
    ...(date ? { date } : {}),
    ...(page ? { page } : {}),
    ...(pageSize ? { pageSize } : {}),
    ...(searchQuery ? { search: searchQuery } : {}),
    ...(sortProperty ? { sortBy: sortProperty } : {}),
    ...(sortDirection ? { sortDirection } : {}),
  })

  return getRequest(`/v2/organizations/${organizationId}/debtorList.json?${queryParams}`)
}
