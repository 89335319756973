import { notify } from '@design-system'

import React, { ReactElement, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { NotificationKeys } from '../../enums/notificationKeys'
import { QueryKeys } from '../../enums/queryKeys'
import { Timeout } from '../../types/timeout'
import { getImageClassName } from '../../utils/getClassName'
import { useUserOrganization } from '../app/organization'
import { ConnectionModal } from './elements/connectionModal/ConnectionModal'
import { DisconnectModal } from './elements/disconnectModal/DisconnectModal'
import { SkatBoxContent } from './elements/skatBoxContent/SkatBoxContent'
import { ConnectionEditState } from './enums/connectingEditState'
import { useSkatConnection } from './hooks/useSkatConnection'
import { SkatConnectionParams } from './interfaces'
import * as Styled from './styles'

interface SkatBoxProps {
  skatTransactionId?: string
  onUpdateSkatConnection: (detail: SkatConnectionParams) => void
}

const CONNECTION_CHECK_TIME = 3000
const VALID_REGISTRATION_NUMBER_LENGTH = 8

function SkatBox({ skatTransactionId, onUpdateSkatConnection }: SkatBoxProps): ReactElement {
  const connectionIntervalTimeout = useRef<Timeout>()
  const queryClient = useQueryClient()
  const [isConnectionModalVisible, setIsConnectionModalVisible] = useState(false)
  const [isDisconnectModalVisible, setIsDisconnectModalVisible] = useState(false)
  const [connectionEditState, setConnectionEditState] = useState(ConnectionEditState.Idle)
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const isValidCVR = useMemo(() => {
    return (
      organization?.registrationNo?.length === VALID_REGISTRATION_NUMBER_LENGTH &&
      !isNaN(Number(organization?.registrationNo))
    )
  }, [organization?.registrationNo])

  const {
    skatConnection,
    isLoading: isDataLoading,
    isError,
  } = useSkatConnection({
    skatTransactionId,
    cacheTime: 0, // Need this to make sure UI on ember side is based on correct VAT period, and not a cached one
  })

  useEffect(() => {
    if (connectionIntervalTimeout.current) {
      clearInterval(connectionIntervalTimeout.current)
    }

    if (connectionEditState !== ConnectionEditState.Idle) {
      connectionIntervalTimeout.current = setInterval(() => {
        if (connectionEditState === ConnectionEditState.Connecting && skatConnection?.connected) {
          setConnectionEditState(ConnectionEditState.Idle)
          setIsConnectionModalVisible(false)
          onUpdateSkatConnection({
            detail: { connected: true },
          })
        }

        if (connectionEditState === ConnectionEditState.Disconnecting && !skatConnection?.connected) {
          setConnectionEditState(ConnectionEditState.Idle)
          setIsDisconnectModalVisible(false)
          onUpdateSkatConnection({
            detail: { connected: false },
          })
        }

        queryClient.invalidateQueries(QueryKeys.SkatConnection)
      }, CONNECTION_CHECK_TIME)
    }

    return () => {
      if (connectionIntervalTimeout.current) {
        clearInterval(connectionIntervalTimeout.current)
      }
    }
  }, [connectionEditState])

  if (isError) {
    notify({
      id: NotificationKeys.SkatRequest,
      message: t('skat.request_error.code_default'),
      variant: 'error',
    })
    onUpdateSkatConnection({
      detail: { connected: false },
    })
  }

  if (skatConnection) {
    onUpdateSkatConnection({
      detail: {
        connected: skatConnection.connected,
        deepLink: skatConnection.deep_link,
        approved: skatConnection.is_approved,
      },
    })
  }

  const updateSkatConnection = (editState: ConnectionEditState) => {
    setConnectionEditState(editState)
    window.open(
      'https://pdcs.skat.dk/dcs-atn-gateway/nemlogin?targetUrl=aHR0cHM6Ly9udHNlLnNrYXQuZGsvbnRzZS1mcm9udC9jb250ZW50P2lkPWZyYW1lOjJGQUF1dG9yaXNlcnJldmlzb3JtZmw=',
    )
  }

  const isLoading = isDataLoading || !organization

  const handleCloseConnectionModal = () => {
    setIsConnectionModalVisible(false)
    setConnectionEditState(ConnectionEditState.Idle)
  }

  const handleCloseDisconnectModal = () => {
    setIsDisconnectModalVisible(false)
    setConnectionEditState(ConnectionEditState.Idle)
  }

  return (
    <Styled.SkatBoxWrapper>
      <Styled.SkatLogo className={getImageClassName()} src="/releases/default/assets/images/misc/skatdk_logo.svg" />

      <SkatBoxContent
        isConnected={skatConnection?.connected}
        onConnect={() => setIsConnectionModalVisible(true)}
        onDisconnect={() => setIsDisconnectModalVisible(true)}
        isLoadingConnecting={isLoading && !skatConnection?.connected}
        isValidCVR={isValidCVR}
      />

      {isConnectionModalVisible && (
        <ConnectionModal
          isOpen={isConnectionModalVisible}
          onClose={handleCloseConnectionModal}
          handleConnectionAttempt={() => updateSkatConnection(ConnectionEditState.Connecting)}
          isTryingToConnect={connectionEditState === ConnectionEditState.Connecting}
        />
      )}

      {isDisconnectModalVisible && (
        <DisconnectModal
          isOpen={isDisconnectModalVisible}
          onClose={handleCloseDisconnectModal}
          handleDisconnectAttempt={() => updateSkatConnection(ConnectionEditState.Disconnecting)}
          isTryingToDisconnect={connectionEditState === ConnectionEditState.Disconnecting}
        />
      )}
    </Styled.SkatBoxWrapper>
  )
}

export default SkatBox
