import { Button, Color, Link, Text } from '@design-system'

import find from 'lodash/find'
import React, { ReactElement, useCallback, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'

import { QueryKeys } from '../../enums/queryKeys'
import { EmberEventFn } from '../../types/emberEventFn'
import { useAccountGroupsWithSums } from '../app/accounts'
import { fetchAccount } from '../app/accounts/query-api'
import { EditAccountModal } from '../settings/routes/OrganizationChartOfAccounts/elements/EditAccountModal'
import { EmberSaveAccountProps } from '../settings/routes/OrganizationChartOfAccounts/elements/EditAccountModal/types/types'
import { getEditAccountAllowedFields } from '../settings/routes/OrganizationChartOfAccounts/elements/EditAccountModal/utils/getEditAccountAllowedFields'

type AccountEditorProps = {
  accountId: string
  isLinkButton?: boolean
  text: string
  groupId: string
  onAccountSave: EmberEventFn
}

function AccountEditor({
  accountId,
  isLinkButton = false,
  text,
  groupId,
  onAccountSave,
}: AccountEditorProps): ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const { accountGroups = [] } = useAccountGroupsWithSums()
  const queryClient = useQueryClient()

  const {
    isLoading: isAccountFetchLoading,
    isError: isAccountFetchError,
    error: accountFetchError,
    data,
  } = useQuery([QueryKeys.Account, accountId], () => fetchAccount(accountId), {
    enabled: !!accountId,
  })

  if (isAccountFetchError) {
    console.error(accountFetchError)
  }

  const isEditDisabled = !accountId || isAccountFetchLoading || isAccountFetchError

  const getAccountGroup = useCallback(() => {
    return find(accountGroups, ({ id }) => groupId === id)
  }, [accountGroups, groupId])

  const toggleEditor = () => {
    setIsOpen(!isOpen)
  }

  const handleModalSaved = useCallback(
    (response?: EmberSaveAccountProps) => {
      onAccountSave({ detail: response })
      queryClient.invalidateQueries(QueryKeys.Account)
    },
    [onAccountSave, queryClient],
  )

  return (
    <>
      {isLinkButton ? (
        <Text weight="medium" color={Color.Purple110}>
          <Link disabled={isEditDisabled} onClick={toggleEditor}>
            {text}
          </Link>
        </Text>
      ) : (
        <Button onClick={toggleEditor} disabled={isEditDisabled} variant="secondary">
          {text}
        </Button>
      )}

      <EditAccountModal
        isOpen={isOpen}
        account={data?.account}
        group={groupId ? getAccountGroup() : undefined}
        onClose={toggleEditor}
        onSave={handleModalSaved}
        allowedFields={getEditAccountAllowedFields(!!data?.account?.predefinedAccount)}
      />
    </>
  )
}

export default AccountEditor
