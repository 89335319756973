import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { createUmbrellaUserInvitation, UmbrellaUserInvitationPayload } from '../../../../app/umbrellas/query-api'

interface UseInviteUmbrellaUserProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface InvitedUmbrellaUserPayload {
  umbrellaId: string
  payload: UmbrellaUserInvitationPayload
}

export const useInviteUmbrellaUser = (props?: UseInviteUmbrellaUserProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: inviteUmbrellaUser, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, payload }: InvitedUmbrellaUserPayload) => createUmbrellaUserInvitation(umbrellaId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    isProcessing,
    inviteUmbrellaUser,
  }
}
