import { Button, Modal, ModalProps, SectionMessage, SkeletonBox, Space } from '@design-system'

import { isBefore } from 'date-fns'
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization, useUserOrganizationSettings } from '../../../modules/app/organization'
import { SubscriptionPeriod } from '../../../modules/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../modules/app/organization/enums/subscriptionPlan'
import { useFetchSubscriptionBilling } from '../../../modules/settings/routes/OrganizationSubscriptionSettings/hooks/useFetchSubscriptionBilling'
import {
  isPaidPlan,
  plansWithoutCoupons,
} from '../../../modules/settings/routes/OrganizationSubscriptionSettings/plansData'
import { notifySubscriptionUpdateError } from '../../../modules/settings/routes/OrganizationSubscriptionSettings/utils'
import { formatDate } from '../../../utils'
import * as Styled from './styles'

export interface ConfirmationModalProps extends ModalProps {
  applyUpsellCoupon?: string | null
  onConfirm: (couponCode?: string) => void
  onCouponEligibilityCheck?: (couponCode: string) => void
  onPriceChange: (confirmPrice: number, recurringPrice: number) => void
  planText: string
  price: number
  recurringPrice: number
  subscriptionPeriod: SubscriptionPeriod
  subscriptionPlan: SubscriptionPlan
}

export const PlanConfirmationModal = ({
  applyUpsellCoupon,
  onConfirm,
  onCouponEligibilityCheck,
  onPriceChange,
  planText,
  price,
  recurringPrice,
  subscriptionPeriod,
  subscriptionPlan,
  ...modalProps
}: ConfirmationModalProps): ReactElement => {
  const { t } = useTranslation()
  const [showCouponField, setShowCouponField] = useState(false)
  const [couponCode, setCouponCode] = useState('')
  const [billingStartDate, setBillingStartDate] = useState(new Date())
  const [isProration, setIsProration] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)
  const [isProcessingCoupon, setIsProcessingCoupon] = useState(false)
  const { organization } = useUserOrganization()
  const { organizationSettings } = useUserOrganizationSettings()
  const { signupCouponId, signupPeriod, signupPlan, signupPromoEndDate } = organizationSettings || {}
  const { fetch: fetchSubscriptionBilling, isLoading: isLoadingSubscriptionBilling } = useFetchSubscriptionBilling({
    onSuccess(data) {
      const {
        billingStartDate: fetchedBillingStartDate,
        initialPrice: fetchedInitialPrice,
        isProration: fetchedIsProration,
        recurringPrice: fetchedRecurringPrice,
      } = data

      onPriceChange(fetchedInitialPrice, fetchedRecurringPrice)
      setBillingStartDate(new Date(fetchedBillingStartDate))
      setIsProration(fetchedIsProration)
    },
    onError() {
      notifySubscriptionUpdateError(t)
    },
  })

  const shouldShowCouponOption =
    isPaidPlan(subscriptionPlan) && !plansWithoutCoupons.includes(subscriptionPlan) && !isProration

  const couponCanBeApplied = useMemo(
    () =>
      signupPlan === subscriptionPlan &&
      signupPeriod === subscriptionPeriod &&
      signupPromoEndDate &&
      isBefore(new Date(), new Date(signupPromoEndDate)),
    [signupPeriod, signupPlan, signupPromoEndDate, subscriptionPeriod, subscriptionPlan],
  )

  const handleConfirm = () => {
    if (isProcessing) {
      return
    }

    let confirmedCouponCode = couponCode !== '' && showCouponField ? couponCode : undefined

    if (signupCouponId && couponCanBeApplied) {
      confirmedCouponCode = signupCouponId
    }

    if (applyUpsellCoupon) {
      confirmedCouponCode = applyUpsellCoupon
    }

    setIsProcessing(true)
    onConfirm(confirmedCouponCode)
  }

  const shouldShowCouponContainer = useMemo(
    () => onCouponEligibilityCheck && shouldShowCouponOption && !applyUpsellCoupon,
    [onCouponEligibilityCheck, shouldShowCouponOption, applyUpsellCoupon],
  )

  const hasSelectedCompletePlan = subscriptionPlan === SubscriptionPlan.Complete

  const yearlyCostOfCompletePlan = recurringPrice * 12

  const shouldShowHigherPriceExplanation = price > recurringPrice

  const handleCouponEligibilityCheck = useCallback(() => {
    onCouponEligibilityCheck && onCouponEligibilityCheck(couponCode)
  }, [couponCode, onCouponEligibilityCheck])

  useEffect(() => {
    if (organization?.id) {
      fetchSubscriptionBilling({ organizationId: organization?.id, period: subscriptionPeriod, plan: subscriptionPlan })
    } else {
      notifySubscriptionUpdateError(t)
    }
  }, [fetchSubscriptionBilling, organization?.id, subscriptionPeriod, subscriptionPlan, t])

  // Applies coupon sent in URL from Basic upsell campagin
  useEffect(() => {
    if (!applyUpsellCoupon || isProcessingCoupon) {
      return
    }

    setIsProcessingCoupon(true)
    setCouponCode(applyUpsellCoupon)
    onCouponEligibilityCheck && onCouponEligibilityCheck(applyUpsellCoupon)
  }, [applyUpsellCoupon, couponCode, onCouponEligibilityCheck, isProcessingCoupon])

  // Applies coupons stored in org settings (as in external_signup)
  useEffect(() => {
    // Do not apply coupon if there is no data, there is basic upsell coupon present (which takes precedence) or the coupon params
    // don't match (period, plan) or coupon is outdated
    if (applyUpsellCoupon || isProcessingCoupon || !organizationSettings?.signupCouponId || !couponCanBeApplied) {
      return
    }

    setIsProcessingCoupon(true)
    setCouponCode(organizationSettings.signupCouponId)
    onCouponEligibilityCheck && onCouponEligibilityCheck(organizationSettings.signupCouponId)
  }, [
    applyUpsellCoupon,
    couponCanBeApplied,
    isProcessingCoupon,
    onCouponEligibilityCheck,
    organizationSettings?.signupCouponId,
    organizationSettings?.signupPeriod,
    organizationSettings?.signupPlan,
    subscriptionPeriod,
    subscriptionPlan,
  ])

  return (
    <Modal closable {...modalProps}>
      <Modal.Header title={t('organization_subscription.confirmation_modal.title')} />
      <Modal.Body>
        {isLoadingSubscriptionBilling ? (
          <SkeletonBox height={169} fullWidth />
        ) : (
          <>
            <Styled.LineInfo>
              <Styled.LineInfoKey>{t('organization_subscription.plan')}</Styled.LineInfoKey>
              <Styled.LineInfoPlanValue>{planText}</Styled.LineInfoPlanValue>
            </Styled.LineInfo>

            <Styled.LineInfo>
              <Styled.LineInfoKey>{t(`organization_subscription.payment.${subscriptionPeriod}`)}</Styled.LineInfoKey>
              <Styled.LineInfoPlanValue>{recurringPrice} DKK</Styled.LineInfoPlanValue>
            </Styled.LineInfo>

            <Styled.LineInfo>
              <Styled.LineInfoKey>{t('organization_subscription.confirmation_modal.billing_start')}</Styled.LineInfoKey>
              <Styled.LineInfoValue>{formatDate(billingStartDate, 'd LLL yyyy')}</Styled.LineInfoValue>
            </Styled.LineInfo>

            {hasSelectedCompletePlan && (
              <Styled.LineInfo>
                <Styled.LineInfoKey>
                  {t('organization_subscription.confirmation_modal.complete_plan_minimum_payment')}
                </Styled.LineInfoKey>
                <Styled.LineInfoValue>{yearlyCostOfCompletePlan} DKK</Styled.LineInfoValue>
              </Styled.LineInfo>
            )}

            {shouldShowCouponContainer && !signupCouponId && (
              <Styled.CouponContainer>
                <Styled.LineInfo>
                  <Styled.ClickOption onClick={() => setShowCouponField(!showCouponField)}>
                    {showCouponField
                      ? t('organization_subscription.summary_hide_coupon_code')
                      : t('organization_subscription.summary_coupon_code')}
                  </Styled.ClickOption>
                </Styled.LineInfo>

                {showCouponField && (
                  <Styled.LineInfo>
                    <Styled.Input
                      type="text"
                      onChange={(e: any) => setCouponCode(e.target.value)}
                      value={couponCode}
                      placeholder={t('organization_subscription.summary_enter_coupon')}
                    />
                    <Button variant="secondary" onClick={handleCouponEligibilityCheck}>
                      {t('organization_subscription.summary_redeem_coupon')}
                    </Button>
                  </Styled.LineInfo>
                )}
              </Styled.CouponContainer>
            )}

            {signupCouponId && couponCanBeApplied && (
              <>
                <SectionMessage variant="success" closable={false}>
                  {t('organization_subscription.summary.upsell.coupon_applied', { coupon: signupCouponId })}
                </SectionMessage>
                <Space size="m" />
              </>
            )}

            <Styled.LineSeparator />

            <Styled.LineInfo>
              <Styled.LineInfoTotalKey>
                {t('organization_subscription.confirmation_modal.total')}
              </Styled.LineInfoTotalKey>
              <Styled.LineInfoTotalValue>{Math.round(price)} DKK</Styled.LineInfoTotalValue>
            </Styled.LineInfo>

            {shouldShowHigherPriceExplanation && (
              <SectionMessage variant="info">{t('organization_subscription.summary_price_message')}</SectionMessage>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleConfirm} disabled={isProcessing || isLoadingSubscriptionBilling} loading={isProcessing}>
          {t('continue')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
