import { useQuery } from 'react-query'

import { QueryKeys } from '../enums/queryKeys'
import { useUserOrganization } from '../modules/app/organization'
import { fetchPaymentMethods } from '../query-api/payment-methods-query'

export const usePaymentMethods = () => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const organizationId = organization?.id || ''

  const {
    data: paymentMethods,
    isLoading: isPaymentMethodsLoading,
    ...rest
  } = useQuery([QueryKeys.PaymentMethods, organizationId], () => fetchPaymentMethods(organizationId), {
    enabled: !!organizationId,
  })

  const isLoading = isOrganizationLoading || isPaymentMethodsLoading

  return { paymentMethods, isLoading, ...rest }
}
