import { decodePeriodValue, GlobalPeriodSelect, GlobalPeriodSelectProps } from '@components'
import { FormItem, Space } from '@design-system'

import { differenceInYears, endOfYear, setYear } from 'date-fns'
import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { PeriodMode } from '../../../../enums/periodMode'
import { useUserOrganization } from '../../../app/organization'
import { ReportsComparisonPeriodSelect, ReportsComparisonPeriodSelectProps } from '../ReportsComparisonPeriodSelect'
import { ReportsComparisonSelect, ReportsComparisonSelectProps } from '../ReportsComparisonSelect'
import * as Styled from './styles'

export interface ReportsPeriodFiltersProps {
  comparisonId?: ReportsComparisonSelectProps['selectedId']
  comparisonPeriodId?: ReportsComparisonPeriodSelectProps['selectedId']
  disabledPeriodModes?: GlobalPeriodSelectProps['disabledPeriodModes']
  encodedPeriod?: GlobalPeriodSelectProps['encodedPeriod']
  isComparisonSelectDisabled?: boolean
  maxDate?: GlobalPeriodSelectProps['maxDate']
  minDate?: GlobalPeriodSelectProps['minDate']
  onComparisonPeriodSelect?: ReportsComparisonPeriodSelectProps['onSelect']
  onComparisonSelect?: ReportsComparisonSelectProps['onSelect']
  onPeriodChange?: GlobalPeriodSelectProps['onChange']
  reportsComparisonSelectItems?: ReportsComparisonSelectProps['items']
  withPeriodComparison?: boolean
}

export const ReportsPeriodFilters = ({
  comparisonId,
  comparisonPeriodId,
  disabledPeriodModes: disabledPeriodModesControlled = [],
  encodedPeriod,
  isComparisonSelectDisabled,
  maxDate,
  minDate,
  onComparisonPeriodSelect,
  onComparisonSelect,
  onPeriodChange,
  reportsComparisonSelectItems,
  withPeriodComparison = true,
}: ReportsPeriodFiltersProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const disabledPeriodModes: PeriodMode[] = useMemo(
    () => [PeriodMode.All, ...disabledPeriodModesControlled],
    [disabledPeriodModesControlled],
  )

  const periodDecoded = useMemo(() => (encodedPeriod ? decodePeriodValue(encodedPeriod) : undefined), [encodedPeriod])

  // Check what max periods amount should be possible to select - it's used to block showing too many fiscal years
  const maxPeriods = useMemo(() => {
    if (periodDecoded?.mode !== PeriodMode.FiscalYear || !organization?.firstFiscalYearStart) {
      return undefined
    }

    const dateToCompare = periodDecoded.year !== undefined ? setYear(new Date(), periodDecoded.year) : new Date()
    const firstFiscalYearStart = new Date(organization.firstFiscalYearStart)
    return differenceInYears(endOfYear(dateToCompare), endOfYear(firstFiscalYearStart))
  }, [organization?.firstFiscalYearStart, periodDecoded])

  return (
    <Styled.ReportsPeriodFiltersWrapper>
      <FormItem label={t('reports.filters.period')}>
        <GlobalPeriodSelect
          disabledPeriodModes={disabledPeriodModes}
          encodedPeriod={encodedPeriod}
          fullWidth
          maxDate={maxDate}
          minDate={minDate}
          onChange={onPeriodChange}
        />
      </FormItem>
      <Space size="s" horizontal />
      <FormItem label={t('reports.filters.comparison')}>
        <Styled.ReportsComparisonSelectWrapper>
          <ReportsComparisonSelect
            disabled={isComparisonSelectDisabled}
            fullWidth
            items={reportsComparisonSelectItems}
            onSelect={onComparisonSelect}
            periodMode={periodDecoded?.mode}
            selectedId={comparisonId}
          />
        </Styled.ReportsComparisonSelectWrapper>
      </FormItem>

      {withPeriodComparison && (
        <>
          <Space size="s" horizontal />
          <FormItem label={t('reports.filters.periods_count')}>
            <Styled.ReportsComparisonPeriodSelect>
              <ReportsComparisonPeriodSelect
                fullWidth
                maxPeriods={maxPeriods}
                onSelect={onComparisonPeriodSelect}
                selectedId={comparisonPeriodId}
              />
            </Styled.ReportsComparisonPeriodSelect>
          </FormItem>
        </>
      )}
    </Styled.ReportsPeriodFiltersWrapper>
  )
}
