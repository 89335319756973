import qs from 'qs'

import { ReportsApiUrl } from '../enums/reportsApiUrl'
import {
  BalanceSheetReportOptions,
  IncomeStatementsReportOptions,
  TrialBalanceReportOptions,
} from '../types/reportOptions'
import { convertPeriodValueToDateForBalanceSheet } from './convertPeriodValueToDateForBalanceSheet'

export const getReportRequestQueryParams = (
  reportUrl: ReportsApiUrl,
  options?: IncomeStatementsReportOptions | TrialBalanceReportOptions | BalanceSheetReportOptions,
  queryParams?: Record<string, any>,
): string | undefined => {
  switch (reportUrl) {
    case ReportsApiUrl.ProfitAndLoss:
    case ReportsApiUrl.TrialBalance: {
      const { compareCount, compareWith, hideZeroes, period, inverted, ytd } = (options || {}) as
        | IncomeStatementsReportOptions
        | TrialBalanceReportOptions

      return qs.stringify({
        ...(compareCount && compareWith !== 'internal_changes' ? { compareCount } : {}),
        ...(compareWith && compareWith !== 'internal_changes' ? { compareWith } : {}),
        ...(hideZeroes !== undefined ? { hideZeroes } : {}),
        ...(inverted !== undefined ? { inverted } : {}),
        ...(period ? { period } : {}),
        ...(ytd !== undefined ? { ytd } : {}),
        ...(queryParams || {}),
      })
    }
    case ReportsApiUrl.Balance: {
      const { compareCount, compareWith, hideZeroes, period, inverted } = (options || {}) as BalanceSheetReportOptions
      const monthlyDate =
        period && compareWith !== 'internal_changes' ? convertPeriodValueToDateForBalanceSheet(period) : undefined

      return qs.stringify({
        ...(compareCount && monthlyDate ? { compareCount } : {}),
        ...(compareWith && monthlyDate ? { compareWith } : {}),
        ...(monthlyDate ? { date: monthlyDate } : {}),
        ...(hideZeroes !== undefined ? { hideZeroes } : {}),
        ...(inverted !== undefined ? { inverted } : {}),
        ...(period && !monthlyDate ? { period } : {}),
        ...(queryParams || {}),
      })
    }
    default:
      return undefined
  }
}
