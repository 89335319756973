import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { Provider as ReduxProvider } from 'react-redux'

import { queryClient } from '../../config/queryClient'
import { EmberConnectBank, EmberConnectBankContextProvider } from '../../contexts/emberConnectBankContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { EmberUserTypeContextProvider } from '../../contexts/emberUserTypeContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { store } from '../../modules'
import { AccountsContextProvider } from '../../modules/app/accounts'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '../../modules/app/organization'
import { IsTrustedPartnerContextProvider } from '../../modules/app/umbrellas'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { Dashboard } from '../../modules/dashboard'
import { DashboardSettingsContextProvider } from '../../modules/dashboard/contexts/dashboardSettingsContext'

const MODULE_NAME = 'dashboard-module'

class DashboardWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal userType: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('connectBank') connectBank: Function
}

interface DashboardModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
  onConnectBank: EmberConnectBank
  userType: string
}

const DashboardModule = ({ organizationId, onNavigate, onConnectBank, userType }: DashboardModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <ReduxProvider store={store}>
        <CurrentUserContextProvider>
          <EmberRouterContextProvider onNavigate={onNavigate}>
            <AccountsContextProvider organizationId={organizationId}>
              <EmberConnectBankContextProvider onConnectBank={onConnectBank}>
                <UserOrganizationContextProvider organizationId={organizationId}>
                  <UserOrganizationSettingsContextProvider organizationId={organizationId}>
                    <IsTrustedPartnerContextProvider organizationId={organizationId}>
                      <EmberUserTypeContextProvider userType={userType}>
                        <DashboardSettingsContextProvider>
                          <ModalContextProvider>
                            <Dashboard organizationId={organizationId} />
                          </ModalContextProvider>
                        </DashboardSettingsContextProvider>
                      </EmberUserTypeContextProvider>
                    </IsTrustedPartnerContextProvider>
                  </UserOrganizationSettingsContextProvider>
                </UserOrganizationContextProvider>
              </EmberConnectBankContextProvider>
            </AccountsContextProvider>
          </EmberRouterContextProvider>
        </CurrentUserContextProvider>
      </ReduxProvider>
    </ThemeProvider>
  </QueryClientProvider>
)

const DashboardCustomElement = createCustomElement(DashboardModule, DashboardWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, DashboardCustomElement)

export default DashboardCustomElement
