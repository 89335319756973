import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../enums/sortDirection'
import { useUserOrganization } from '../../../../../../app/organization'
import { AdditionalFieldsSortProperty } from '../../../enums/additionalFieldsSortProperty'
import { fetchAdditionalFields } from '../../../query-api'
import { TaxMetaField } from '../../../types/taxMetaField'

interface UseFetchAdditionalFieldsResponse {
  data: TaxMetaField[]
  isLoading?: boolean
}

interface UseFetchAdditionalFieldsProps {
  sortDirection?: SortDirection
  sortProperty?: AdditionalFieldsSortProperty
}

export const useFetchAdditionalFields = ({
  sortDirection = SortDirection.Asc,
  sortProperty = AdditionalFieldsSortProperty.Priority,
}: UseFetchAdditionalFieldsProps): UseFetchAdditionalFieldsResponse => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()

  const query = useQuery(
    [QueryKeys.AdditionalFields, organization?.id, sortDirection, sortProperty],
    () =>
      fetchAdditionalFields({
        organizationId: organization?.id as string,
        sortDirection,
        sortProperty,
      }),
    {
      enabled: !!organization?.id,
    },
  )

  return {
    ...query,
    data: query?.data?.salesTaxMetaFields || [],
    isLoading: query.isLoading || isOrganizationLoading,
  }
}
