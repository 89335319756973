import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { GlobalFiscalYearContextProvider } from '../../contexts/globalFiscalYearContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { Transactions } from '../../modules/transactions'
import { TransactionsEmberConnectionContextProvider } from '../../modules/transactions/contexts/transactionsEmberConnectionContext'
import { EmberEventFn } from '../../types/emberEventFn'

const MODULE_NAME = 'transactions-module'

class TransactionsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('addTransaction') addTransaction: Function
  @registerEvent('navigate') navigate: Function
}

interface TransactionsModuleProps {
  organizationId: string
  onAddTransaction: EmberEventFn
  onNavigate: EmberNavigate
}

const TransactionsModule = ({
  onAddTransaction,
  onNavigate,
  organizationId,
}: TransactionsModuleProps): ReactElement => (
  <ThemeProvider>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <CurrentUserContextProvider>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <GlobalFiscalYearContextProvider organizationId={organizationId}>
              <EmberRouterContextProvider onNavigate={onNavigate}>
                <TransactionsEmberConnectionContextProvider onAddTransaction={onAddTransaction}>
                  <Transactions />
                </TransactionsEmberConnectionContextProvider>
              </EmberRouterContextProvider>
            </GlobalFiscalYearContextProvider>
          </UserOrganizationContextProvider>
        </CurrentUserContextProvider>
      </QueryClientProvider>
    </HashRouter>
  </ThemeProvider>
)

const TransactionsCustomElement = createCustomElement(TransactionsModule, TransactionsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, TransactionsCustomElement)

export default TransactionsCustomElement
