import { MobileSignupWelcomeModal } from '@components'
import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import styled from '@emotion/styled'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider, EmberModal } from '../../contexts/emberCloseModalContext'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { ApproveFinancingModal } from '../../modules/financingBanner/elements/ApproveFinancingModal'
import { ApproveFinancingSuccessModal } from '../../modules/financingBanner/elements/ApproveFinancingSuccessModal'
import { FirstInvoiceModal } from '../../modules/invoices/elements/FirstInvoiceModal'
import { EmberEventFn } from '../../types/emberEventFn'

class ModalWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal variant: string
  @byAttrVal variables: string
  @registerEvent('navigate') navigate: Function
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('ok') confirm: Function
}

type ModalProps = {
  organizationId: string
  variant: string
  onNavigate: EmberNavigate
  onCloseEmber: EmberModal
  onOk?: EmberEventFn
  variables?: string
}

const ModalWrapper = styled.div`
  width: 100%;
  height: 100%;
  bottom: calc(env(safe-area-inset-bottom) + 16px);
`

export interface ModalComponentProps {
  onOk?: EmberEventFn
  variables?: Record<string, any>
}

type ModalVariant =
  | 'mobileSignupWelcomeModal'
  | 'approveFinancingModal'
  | 'approveFinancingSuccessModal'
  | 'firstInvoiceModal'

const modalVariantToComponent: Record<ModalVariant, (props: ModalComponentProps) => ReactElement | null> = {
  approveFinancingModal: ApproveFinancingModal,
  approveFinancingSuccessModal: ApproveFinancingSuccessModal,
  firstInvoiceModal: FirstInvoiceModal,
  mobileSignupWelcomeModal: MobileSignupWelcomeModal,
}

const modalVariantToId: Record<ModalVariant, ModalId> = {
  approveFinancingModal: ModalId.ApproveFinancingModal,
  approveFinancingSuccessModal: ModalId.FinancingApproveSuccessModal,
  firstInvoiceModal: ModalId.FirstInvoiceModal,
  mobileSignupWelcomeModal: ModalId.MobileSignupWelcomeModal,
}

const parseVariables = (variables: string) => {
  try {
    return JSON.parse(variables)
  } catch (error) {
    console.error('Variables passed to modal need to be a stingified JSON object. Returning empty object.')
    return {}
  }
}

const ModalModule = ({
  variables = '{}',
  variant,
  organizationId,
  onNavigate,
  onCloseEmber,
  onOk = () => null,
}: ModalProps) => {
  const modalVariables = parseVariables(variables)
  const ModalComponent = modalVariantToComponent[variant]
  const lastOpenedModal = modalVariantToId[variant]

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
            <ModalContextProvider defaultOpenedIds={[lastOpenedModal]}>
              <UserOrganizationContextProvider organizationId={organizationId}>
                <ModalWrapper>
                  <ModalComponent key={variant} onOk={onOk} variables={modalVariables} />
                </ModalWrapper>
              </UserOrganizationContextProvider>
            </ModalContextProvider>
          </EmberCloseModalContextProvider>
        </EmberRouterContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const ModalCustomElement = createCustomElement(ModalModule, ModalWebComponent, 'element')

customElements.get('modal-module') || customElements.define('modal-module', ModalCustomElement)

export default ModalCustomElement
