import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { UserUmbrellasContextProvider } from '../../modules/app/umbrellas'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { SignupGuide } from '../../modules/signupGuide'
import { EmberActionPayload, SignupGuideContextProvider } from '../../modules/signupGuide/context/signupGuideContext'

const MODULE_NAME = 'signup-guide-module'

class SigupModel extends DOMModel {
  @byAttrVal locale: string
  @byAttrVal routeUmbrellaId: string
  @byAttrVal signupCouponParams: string
  @byAttrVal signupUmbrellaId: string
  @byAttrVal userEmail: string
  @byAttrVal userId: string
  @byAttrVal userName: string
  @registerEvent('authorizeUser') authorizeUser: Function
  @registerEvent('changeLocale') changeLocale: Function
  @registerEvent('navigate') navigate: Function
  @registerEvent('registerOrganization') registerOrganization: Function
}

interface SignupGuideModuleProps {
  crateOrganizationDescription?: string
  crateOrganizationTitle?: string
  locale?: string
  onAuthorizeUser: (payload: EmberActionPayload) => void
  onChangeLocale: (paylod: EmberActionPayload) => void
  onNavigate: EmberNavigate
  onRegisterOrganization: (payload: EmberActionPayload) => void
  routeUmbrellaId?: string
  signupCouponParams?: string
  signupUmbrellaId?: string
  userEmail?: string
  userId?: string
  userName?: string
}

const SignupGuideModule = ({
  locale,
  onAuthorizeUser,
  onChangeLocale,
  onNavigate,
  onRegisterOrganization,
  routeUmbrellaId,
  signupCouponParams,
  signupUmbrellaId,
  userEmail,
  userId,
  userName,
}: SignupGuideModuleProps) => (
  <QueryClientProvider client={queryClient}>
    <EmberRouterContextProvider onNavigate={onNavigate}>
      <ThemeProvider>
        <HashRouter>
          <UserUmbrellasContextProvider>
            <SignupGuideContextProvider
              locale={locale}
              onAuthorizeUser={onAuthorizeUser}
              onChangeLocale={onChangeLocale}
              onRegisterOrganization={onRegisterOrganization}
              routeUmbrellaId={routeUmbrellaId}
              signupCouponParams={signupCouponParams}
              signupUmbrellaId={signupUmbrellaId}
              userEmail={userEmail}
              userId={userId}
              userName={userName}
            >
              <UserOrganizationContextProvider enabled={false}>
                <CurrentUserContextProvider enabled={false}>
                  <SignupGuide />
                </CurrentUserContextProvider>
              </UserOrganizationContextProvider>
            </SignupGuideContextProvider>
          </UserUmbrellasContextProvider>
        </HashRouter>
      </ThemeProvider>
    </EmberRouterContextProvider>
  </QueryClientProvider>
)

const SignupElement = createCustomElement(SignupGuideModule, SigupModel, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, SignupElement)

export default SignupElement
