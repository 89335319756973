import {
  DropdownNavList,
  DropdownNavListProps,
  DropdownRef,
  findNavItem,
  Icon,
  Input,
  InputProps,
  InputRef,
  Tag,
  Text,
  useTheme,
} from '@design-system'

import { ChangeEvent, KeyboardEvent, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'

import { BillFormFieldsChangeSource, BillFormFieldsChangeSourceMap } from '../../../enums/billFormFieldsChangeSource'
import { KeyboardKey } from '../../../enums/keyboardKey'
import { useSegment } from '../../../hooks'
import { useUserOrganization } from '../../../modules/app/organization'
import { getApiCountryKey } from '../../../utils/getApiCountryKey'
import { MIN_INPUT_SIGNS } from './constants/minInputSigns'
import { useBookkeepingCategorySuggestions } from './hooks/useBookkeepingCategorySuggestions'
import { useLogSearchCategory } from './hooks/useLogCategoryEvent'
import * as Styled from './styles'
import { BookkeepingTag } from './types/bookkeepingTag'
import { BookkeepingTagNavItemValue } from './types/bookkeepingTagNavItemValue'
import { TrainingData } from './types/trainingData'
import { getItems } from './utils/getItems'
import { isBookkeepingHeroAllowed } from './utils/isBookkeepingHeroAllowed'
import { isBookkeepingTagValid } from './utils/isBookkeepingTagValid'

// We reduce the default debounce rate on input for booky to make it more responsive.
// We want to make users feel almost like no requests made.
const BOOKY_SNAPPY_RESPONSE_DEBOUNCE = 200

export interface BookkeepingHeroSelectProps
  extends Pick<DropdownNavListProps<BookkeepingTagNavItemValue>, 'placement'>,
    Pick<
      InputProps,
      | 'alignment'
      | 'autoCompleted'
      | 'autoFocus'
      | 'bordered'
      | 'className'
      | 'disabled'
      | 'error'
      | 'focused'
      | 'hidden'
      | 'id'
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'onChangeDebounced'
      | 'onClear'
      | 'onFocus'
      | 'onKeyDown'
      | 'onKeyPress'
      | 'onMouseDown'
      | 'onPressEnter'
      | 'placeholder'
      | 'readOnly'
      | 'selectOnFocus'
      | 'size'
      | 'success'
      | 'suffix'
      | 'type'
    > {
  // Select props
  allowClear?: boolean
  defaultInputValue?: string
  defaultSelectedValue?: BookkeepingTagNavItemValue
  inputValue?: string
  onSelect?: (value?: BookkeepingTagNavItemValue) => void
  onClearDescription?: () => void
  selectedValue?: BookkeepingTagNavItemValue
  // DropdownNavList props
  dropdownMaxHeight?: DropdownNavListProps<BookkeepingTagNavItemValue>['maxHeight']
  dropdownSize?: DropdownNavListProps<BookkeepingTagNavItemValue>['size']
  formFieldsChangeSource?: BillFormFieldsChangeSourceMap
  setFormFieldsChangeSource?: (source: BillFormFieldsChangeSourceMap) => void
  trainingData?: TrainingData
}

export const BookkeepingHeroSelect = ({
  defaultInputValue,
  defaultSelectedValue,
  inputValue: inputValueControlled,
  onSelect,
  selectedValue: selectedValueControlled,
  // DropdownNavList props
  dropdownMaxHeight = 'default',
  dropdownSize = 'fitTrigger',
  placement = 'bottom-end',
  // Input props
  alignment = 'left',
  autoCompleted,
  autoFocus,
  bordered,
  className,
  disabled,
  error,
  focused = false,
  hidden,
  id,
  name,
  onBlur,
  onChange,
  onClear,
  onFocus,
  onKeyDown,
  onKeyPress,
  onMouseDown,
  onPressEnter,
  placeholder,
  readOnly,
  selectOnFocus,
  size,
  success,
  suffix,
  type,
  trainingData,
  onClearDescription,
  formFieldsChangeSource = { main: BillFormFieldsChangeSource.Search },
  setFormFieldsChangeSource,
}: BookkeepingHeroSelectProps): ReactElement => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedValue, setSelectedValue] = useState<BookkeepingTagNavItemValue | undefined>(
    defaultSelectedValue || selectedValueControlled,
  )
  const [inputValue, setInputValue] = useState(defaultInputValue || inputValueControlled || '')
  const prevInputValue = usePrevious(inputValue)
  const { organization } = useUserOrganization()
  const { track } = useSegment()
  const hasInputValueBeenRemoved = useRef(!!selectedValue && !inputValue)
  const dropdownRef = useRef<DropdownRef<InputRef>>(null)

  const chartOfAccountId = organization?.chartOfAccountId
  const countryKey = getApiCountryKey(organization?.countryId)

  const isQueryEnabled =
    !!chartOfAccountId &&
    isBookkeepingHeroAllowed(chartOfAccountId) &&
    inputValue.length >= MIN_INPUT_SIGNS &&
    !selectedValueControlled

  const { isLoading, queryId, suggestedCategories } = useBookkeepingCategorySuggestions({
    isEnabled: isQueryEnabled,
    searchQuery: inputValue,
    trainingData,
  })

  const { logCategoryEvent } = useLogSearchCategory()

  const selectedId = selectedValue?.response?.option
  const expandedId = !selectedId && !inputValue && !selectedValue?.response?.option && selectedValue?.tag?.id.toString()
  const isValidTag = selectedValue?.tag && isBookkeepingTagValid(selectedValue)

  const items = useMemo(
    () => {
      let bookkeepingTags: BookkeepingTag[] = []

      if (isQueryEnabled && suggestedCategories?.length) {
        bookkeepingTags = suggestedCategories
      } else if (selectedValue?.tag) {
        bookkeepingTags = [selectedValue?.tag]
      }

      return isQueryEnabled || !!selectedValue?.tag ? getItems(bookkeepingTags, t) : []
    }, // we want to at least have a currently selected tag to be able to change the response
    [suggestedCategories, isQueryEnabled, selectedValue?.tag, t],
  )

  const validateSelectedValue = useCallback(
    (selectedValue?: BookkeepingTagNavItemValue) => {
      if (selectedValue && !isBookkeepingTagValid(selectedValue)) {
        setSelectedValue(undefined)
        onSelect?.(undefined)
      }
    },
    [onSelect],
  )

  const openDropdown = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeDropdown = useCallback(() => {
    setIsOpen(false)
  }, [])

  const handleDropdownToggling = useCallback(
    (inputValue: string) => {
      if (!isOpen && !isLoading && items.length > 0 && !selectedValue?.tag && inputValue.length >= MIN_INPUT_SIGNS) {
        if (formFieldsChangeSource.main !== BillFormFieldsChangeSource.Search) {
          setFormFieldsChangeSource?.({ ...formFieldsChangeSource, main: BillFormFieldsChangeSource.Search })
        }

        openDropdown()
      } else if (isOpen && !items.length) {
        closeDropdown()
      }
    },
    [
      isOpen,
      isLoading,
      items.length,
      selectedValue?.tag,
      formFieldsChangeSource,
      openDropdown,
      setFormFieldsChangeSource,
      closeDropdown,
    ],
  )

  const selectItem = useCallback(
    (id: string | undefined, withSelectEvent = true) => {
      const item = findNavItem(items, id)
      const sessionId = trainingData?.id
      // to only track data for the ML-powered search, temporarily FR only
      const isMLAnalyticsEnabled = sessionId && countryKey === 'fr' && queryId

      if (item) {
        setSelectedValue(item.value)
        handleDropdownToggling('')
        track('xxx - expense - create expense - question clicked', { context: formFieldsChangeSource.main })

        if (isMLAnalyticsEnabled) {
          const categoryId = item.value?.tag?.id || null
          logCategoryEvent({ categoryId, eventType: 'selected', items, queryId, sessionId })
        }
      } else {
        if (isMLAnalyticsEnabled) {
          const categoryId = selectedValue?.tag?.id || null
          logCategoryEvent({ categoryId, eventType: 'removed', queryId, sessionId })
        }

        onClearDescription?.()
        setSelectedValue(undefined)
        track('xxx - expense - create expense - category removed', { context: formFieldsChangeSource.main })
      }

      if (withSelectEvent) {
        onSelect?.(item?.value)
      }

      closeDropdown()
    },
    [
      closeDropdown,
      countryKey,
      formFieldsChangeSource,
      handleDropdownToggling,
      items,
      logCategoryEvent,
      onClearDescription,
      onSelect,
      queryId,
      selectedValue?.tag?.id,
      track,
      trainingData?.id,
    ],
  )

  const handleItemClick = useCallback(
    (id: string | undefined) => {
      selectItem(id)
    },
    [selectItem],
  )

  const handleCategoryClick = useCallback(() => {
    track('xxx - expense - create expense - category selected', { context: BillFormFieldsChangeSource.Search })
  }, [track])

  const handleRemoveTag = useCallback(() => {
    selectItem(undefined)
  }, [selectItem])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event?.target.value
      setInputValue(value)
      onChange?.(event)
    },
    [onChange],
  )

  const handleResponseClick = useCallback(() => {
    openDropdown()
  }, [openDropdown])

  const removeTagOnInputValueRemove = useCallback(() => {
    if (!!selectedValue?.tag && hasInputValueBeenRemoved.current) {
      hasInputValueBeenRemoved.current = false
      handleRemoveTag()
    }
  }, [handleRemoveTag, selectedValue?.tag])

  const handleInputKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === KeyboardKey.Backspace) {
        removeTagOnInputValueRemove()
      }

      onKeyDown?.(event)
    },
    [onKeyDown, removeTagOnInputValueRemove],
  )

  const handleDropdownClose = useCallback(() => {
    closeDropdown()
    validateSelectedValue(selectedValue)
  }, [closeDropdown, selectedValue, validateSelectedValue])

  useEffect(() => {
    // auto open only when focused inside
    if (dropdownRef.current?.trigger?.input === document.activeElement) {
      handleDropdownToggling(inputValue)
    }

    if (isOpen && !items.length) {
      closeDropdown()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  useEffect(() => {
    if (!!prevInputValue && !inputValue) {
      hasInputValueBeenRemoved.current = true
    } else if (!prevInputValue && inputValue) {
      hasInputValueBeenRemoved.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue])

  useEffect(() => {
    if (
      selectedValueControlled &&
      (selectedValueControlled?.response?.option !== selectedValue?.response?.option ||
        selectedValueControlled.tag?.id !== selectedValue?.tag?.id)
    ) {
      setSelectedValue(selectedValueControlled)

      // Open dropdown automatically when tag is selected but there's no response chosen
      if (selectedValueControlled.tag?.id && !selectedValueControlled?.response?.option) {
        setFormFieldsChangeSource?.({ ...formFieldsChangeSource, main: BillFormFieldsChangeSource.Bohr })
        openDropdown()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValueControlled?.response?.option, selectedValueControlled?.tag?.id])

  useEffect(() => {
    if (inputValueControlled !== inputValue) {
      setInputValue(inputValueControlled || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValueControlled])

  return (
    <>
      <DropdownNavList
        ref={dropdownRef}
        autoToggle={false}
        isOpen={isOpen}
        items={items}
        maxHeight={dropdownMaxHeight}
        onClose={handleDropdownClose}
        expandedIds={expandedId ? [expandedId] : undefined}
        onItemClick={handleItemClick}
        onExpandableItemClick={handleCategoryClick}
        onKeyDown={onKeyDown}
        placement={placement}
        selectedId={selectedId}
        size={dropdownSize}
        trigger={
          <Input
            alignment={alignment}
            autoComplete="off"
            autoCompleted={autoCompleted}
            autoFocus={autoFocus}
            bordered={bordered}
            className={className}
            debounceRate={BOOKY_SNAPPY_RESPONSE_DEBOUNCE}
            disabled={disabled}
            error={error}
            focused={focused}
            hidden={hidden}
            id={id}
            name={name}
            onBlur={onBlur}
            onChangeDebounced={handleInputChange}
            onClear={onClear}
            onFocus={onFocus}
            onKeyDown={handleInputKeyDown}
            onKeyPress={onKeyPress}
            onMouseDown={onMouseDown}
            onPressEnter={onPressEnter}
            placeholder={
              placeholder ||
              (isValidTag
                ? t('select.bookkeeping_hero.placeholder_with_tag')
                : t('select.bookkeeping_hero.placeholder_no_tag'))
            }
            prefix={
              isValidTag &&
              selectedValue?.tag && (
                <Tag closable onClose={handleRemoveTag}>
                  {selectedValue?.tag.name}
                </Tag>
              )
            }
            readOnly={readOnly}
            selectOnFocus={selectOnFocus}
            size={size}
            success={success}
            suffix={suffix}
            title={inputValue}
            truncate
            type={type}
            value={inputValue}
          />
        }
        subItemsMode="horizontal"
        selectFirstAndOnlySubItem
        withNavigation
      />
      {selectedValue?.response && (
        <Styled.ResponseWrapperButton type="button" onClick={handleResponseClick}>
          <Styled.IconWrapper>
            <Icon icon="chevronRight" color={theme.ds.colors.base.textPrimary} />
          </Styled.IconWrapper>
          <Text variant="micro" weight="medium" title={selectedValue.response.option} truncate>
            {selectedValue.response.option}
          </Text>
        </Styled.ResponseWrapperButton>
      )}
    </>
  )
}
