import { VatRateTemplateResponseData } from 'src/modules/settings/routes/VatRatesSettings/types/vatRateTemplateResponseData'

import { VatRateFromTemplateFormData } from '../../../../../types/vatRateFromTemplateFormData'
import { percentToFraction } from '../../../../../utils/percentToFraction'
import { CreateOrEditVatRateFromTemplateForm } from '../../utils/formData'
import { prepareDeductionComponentsPayloadFromTemplatedForm } from './prepareDeductionComponentsPayloadFromTemplatedForm'

export const getRateData = (formData: CreateOrEditVatRateFromTemplateForm, template: VatRateTemplateResponseData) => {
  const { isActive, rate, deductionComponents: deductionComponentsForms, netAmountMetaFieldId } = formData

  const {
    editableProperties: { taxSummaryLine },
  } = template

  if (!deductionComponentsForms) {
    throw new Error('Deduction Components Forms are not defined!')
  }

  const deductionComponents = prepareDeductionComponentsPayloadFromTemplatedForm(template, deductionComponentsForms)

  const data: Partial<VatRateFromTemplateFormData> = {
    deductionComponents: deductionComponents || [],
    isActive,
  }

  if (template?.editableProperties.rate) {
    data.rate = percentToFraction(rate || 0)
  }

  if (taxSummaryLine) {
    data.netAmountMetaFieldId = netAmountMetaFieldId
  }

  return data
}
