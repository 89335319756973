import { UploadFilesErrorMessage } from '@components'
import { Dropzone, getRejectedFilesList, notify, SkeletonBox, useModal } from '@design-system'

import { ReactElement, useCallback } from 'react'
import { FileRejection } from 'react-dropzone'
import { useTranslation } from 'react-i18next'

import { FilesPreview } from '../../../../../../../../components-deprecated'
import { ACCEPTED_PICTURE_FILE_FORMATS } from '../../../../../../../../constants/acceptedPictureFileFormats'
import { ModalId } from '../../../../../../../../enums/modalId'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { formatMegabytesToBytes } from '../../../../../../../../utils/formatMegabytesToBytes'
import { useUserOrganization } from '../../../../../../../app/organization'
import { useOrganizationImage } from '../../../../hooks/useOrganizationImage'
import { useUploadOrganizationIcon } from '../../../../hooks/useUploadOrganizationIcon'

export const OrganizationIcon = (): ReactElement => {
  const { t } = useTranslation()
  const { organization, isLoading: isLoadingOrganization } = useUserOrganization()
  const { upload, isLoading: isUploadingOrganizationIcon } = useUploadOrganizationIcon()
  const { open: openDeleteOrganizationIconModal } = useModal(ModalId.DeleteOrganizationIconModal)
  const { url: iconUrl, isLoading: isLoadingIcon } = useOrganizationImage(organization?.iconFileId || '')

  const handleDropAccepted = useCallback(
    ([file]: File[]) => {
      upload(file)
    },
    [upload],
  )

  const handleDropRejected = useCallback(
    (filesRejected: FileRejection[]) => {
      const uploadFilesErrors = getRejectedFilesList(filesRejected)
      notify({
        id: NotificationKeys.OrganizationIconUploadRejection,
        message: UploadFilesErrorMessage({ uploadFilesErrors }),
        title: t('file.rejected.count', { count: filesRejected.length }),
        variant: 'warning',
      })
    },
    [t],
  )

  const handleEditButtonClick = useCallback(
    (file: File) => {
      upload(file)
    },
    [upload],
  )

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteOrganizationIconModal()
  }, [openDeleteOrganizationIconModal])

  if (isLoadingIcon || isLoadingOrganization) {
    return <SkeletonBox height={154} fullWidth />
  }

  if (iconUrl && organization?.iconFileId) {
    return (
      <FilesPreview
        bordered
        files={[{ src: iconUrl }]}
        fitToHeight
        fitType="contain"
        onDelete={handleDeleteButtonClick}
        onEdit={handleEditButtonClick}
        withDeleteButton
        withEditButton
      />
    )
  }

  return (
    <Dropzone
      accept={ACCEPTED_PICTURE_FILE_FORMATS}
      contentText={t('settings.organization.organization_info.icon.dropzone_placeholder')}
      isUploading={isUploadingOrganizationIcon}
      maxFiles={1}
      maxSize={formatMegabytesToBytes(5)}
      onDropAccepted={handleDropAccepted}
      onDropRejected={handleDropRejected}
    />
  )
}
