import i18next from 'i18next'
import numeral from 'numeral'
import { createSelector } from 'reselect'

import { State } from '../../../types'
import { SelectTaxRatesProps, TaxRate } from '../types'

let id = 0

export const taxRateSelector = createSelector(
  (state: State) => state.app.taxRates,
  (_: unknown, props: SelectTaxRatesProps) => props,
  (taxRates: TaxRate[] | undefined, { purchase, sale }: SelectTaxRatesProps) => {
    let heading = ''
    const headings = {
      local: i18next.t('app.taxrates.taxrate_selector.groups.local'),
      eu: i18next.t('app.taxrates.taxrate_selector.groups.eu'),
      world: i18next.t('app.taxrates.taxrate_selector.groups.world'),
      zzz: i18next.t('app.taxrates.taxrate_selector.groups.other'),
    }

    return (
      (taxRates || [])
        .filter((rate: TaxRate) =>
          purchase === sale
            ? // filter applicable to sales and/or purchases
              // API does not currently support these filters
              true
            : (purchase && rate.appliesToPurchases) || (sale && rate.appliesToSales),
        )
        // map translations
        .map((rate: TaxRate) => ({
          ...rate,
          text: `${rate.name} (${numeral(rate.deductionRate).format('0.[00]%')})`,
          description: rate.description,
          taxRateGroup: rate.taxRateGroup || 'zzz',
        }))
        // insert headings
        .reduce((processedTaxRates: TaxRate[], rate: TaxRate) => {
          if (heading !== rate.taxRateGroup) {
            heading = rate.taxRateGroup
            return [
              ...processedTaxRates,
              {
                id: (id++).toString(),
                text: headings[heading],
                isHeading: true,
              } as unknown as TaxRate,
              rate,
            ]
          }
          return [...processedTaxRates, rate]
        }, [])
    )
  },
)
