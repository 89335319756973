import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../hooks'
import { APIError } from '../../../../../../../utils/api'
import { OrganizationSettingsValue } from '../../../../../../app/organization/types/organizationSettingsValue'
import { useUserOrganizationSettings } from '../../../../../../app/organization/userOrganizationSettingsContext'
import { useUpdateVoucherInbox } from '../hooks/useUpdateVoucherInbox'
import { getDefaultValues, getValidationSchema, UmbrellaVoucherInboxForm } from '../util/formData'

interface ContextState {
  Form: FormComponent
  isLoading: boolean
  submitForm: () => void
}

const UmbrellaOrganizationVoucherInboxContext = createContext<ContextState | undefined>(undefined)

interface UmbrellaOrganizationVoucherInboxContextProps {
  modalId: string
  umbrellaOrganizationId: string
  children?: ReactNode
}

export const UmbrellaOrganizationVoucherInboxContextProvider = ({
  modalId,
  umbrellaOrganizationId,
  children,
}: UmbrellaOrganizationVoucherInboxContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organizationSettings, isLoading } = useUserOrganizationSettings()
  const { close } = useModal(modalId)
  const { update: updateVoucherInbox } = useUpdateVoucherInbox({
    onSettled: () => {
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
    },
    onSuccess: () => {
      notify({
        id: NotificationKeys.UmbrellaOrganizationUpdateVoucherInbox,
        message: t('umbrella.organizations_table.update_voucher_inbox_modal.saved'),
        variant: 'success',
      })
      close()
    },
    onError: (error: APIError | undefined) => {
      notify({
        id: NotificationKeys.UmbrellaOrganizationUpdateVoucherInbox,
        message: error?.message,
        variant: 'error',
      })
    },
  })

  const handleUpdateVoucherInbox = useCallback((values: UmbrellaVoucherInboxForm) => {
    const isInboxEnabled: OrganizationSettingsValue = values.isInboxEnabled ? '1' : '0'
    updateVoucherInbox({ organizationId: umbrellaOrganizationId, isInboxEnabled })
  }, [])

  const { reset, Form, handleSubmit } = useForm({
    defaultValues: useMemo(() => getDefaultValues(organizationSettings), [organizationSettings]),
    validationSchema: useMemo(() => getValidationSchema(), []),
  })

  const submitForm = useCallback(() => {
    handleSubmit(handleUpdateVoucherInbox)()
  }, [handleSubmit, handleUpdateVoucherInbox])

  useEffect(() => {
    if (!isLoading) {
      reset(getDefaultValues(organizationSettings))
    }
  }, [isLoading, organizationSettings])

  return (
    <UmbrellaOrganizationVoucherInboxContext.Provider value={{ Form, submitForm, isLoading }}>
      {children}
    </UmbrellaOrganizationVoucherInboxContext.Provider>
  )
}

export const useUmbrellaOrganizationVoucherInbox = () => {
  const context = useContext(UmbrellaOrganizationVoucherInboxContext)

  if (!context) {
    throw new Error('UmbrellaOrganizationVoucherInboxContext is missing in the module!')
  }

  return context
}
