import { encodePeriodValue } from '@components'

import { getMonth, getYear } from 'date-fns'
import { useEffect, useMemo } from 'react'

import { PeriodMode } from '../../../../../enums/periodMode'
import { useLocalStorageUncontrolled } from '../../../../../hooks/useLocalStorageUncontrolled'
import { useQueryParams } from '../../../../../hooks/useQueryParams'
import { PeriodValue } from '../../../../../types/periodValue'
import { useUserOrganization } from '../../../../app/organization'
import { ReportsRoute } from '../../../enums/reportsRoute'
import { BalanceSheetReportOptions } from '../../../types/reportOptions'
import { getLocalStorageFiltersValidated } from '../../../utils/getLocalStorageFiltersValidated'
import { getReportsFiltersStorageKey } from '../../../utils/getReportsFiltersStorageKey'

type UseBalanceSheetResponse = [
  BalanceSheetReportOptions,
  (
    filters:
      | Partial<BalanceSheetReportOptions>
      | ((prevParams: Partial<BalanceSheetReportOptions>) => Partial<BalanceSheetReportOptions>),
  ) => void,
]

export const defaultPeriodValue: PeriodValue = {
  mode: PeriodMode.Month,
  value: getMonth(new Date()),
  year: getYear(new Date()),
}

const defaultReportsQuery: BalanceSheetReportOptions = {
  compareCount: 1,
  compareWith: 'month',
  hideZeroes: true,
  inverted: false,
  period: undefined,
}

export const useBalanceFilters = (): UseBalanceSheetResponse => {
  const { organization } = useUserOrganization()
  const { setItem, getItem } = useLocalStorageUncontrolled(
    getReportsFiltersStorageKey(ReportsRoute.Balance, organization?.id),
  )
  const [queryParams, setQueryParams] = useQueryParams<BalanceSheetReportOptions>({
    defaultQuery: {
      ...defaultReportsQuery,
      period: organization?.id ? encodePeriodValue(defaultPeriodValue, organization?.id) || '' : '',
      ...getLocalStorageFiltersValidated(defaultReportsQuery, getItem()),
    },
  })

  useEffect(() => {
    if (organization?.id) {
      setItem(JSON.stringify(queryParams))
    }
  }, [organization?.id, queryParams, setItem])

  return useMemo(() => {
    const { hideZeroes, inverted } = queryParams

    return [
      {
        ...queryParams,
        hideZeroes: typeof hideZeroes === 'string' ? hideZeroes === 'true' : Boolean(hideZeroes),
        inverted: typeof inverted === 'string' ? inverted === 'true' : Boolean(inverted),
      },
      setQueryParams,
    ]
  }, [queryParams, setQueryParams])
}
