import { Space } from '@design-system'

import { ReactElement } from 'react'

import { useUserOrganization } from '../../../app/organization'
import { PurchaseVatRates } from './elements/PurchaseVatRates'
import { Rulesets } from './elements/Rulesets'
import { SalesVatRates } from './elements/SalesVatRates'

export const VatRatesSettings = (): ReactElement => {
  const { organization } = useUserOrganization()

  return (
    <>
      {organization?.source === 'billy' && (
        <>
          <Rulesets />
          <Space size="xl" />
        </>
      )}
      <SalesVatRates />
      <Space size="xl" />
      <PurchaseVatRates />
    </>
  )
}
