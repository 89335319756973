import { Attachment, ItemsPerPage } from '@design-system'

import { useEffect, useMemo, useRef } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../config/queryClient'
import { SortDirection } from '../../../enums/sortDirection'
import { usePaginationPrefetching } from '../../../hooks/usePaginationPrefetching'
import { Paging } from '../../../types/metable'
import { useUserOrganization } from '../../app/organization'
import { ReceiptFillType, ReceiptFilterType } from '../elements/Receipt'
import { ReceiptsSortProperty } from '../enums/sort'
import { fetchReceipts, FetchReceiptsResponseData } from '../query-api'
import { QueryProps } from '../types/queryProps'
import { getReceiptsQueryKey } from '../utils/getReceiptsQueryKey'
import { useGetFetchReceiptsQueryProps } from './useGetFetchReceiptsQueryProps'

interface UseFetchReceiptsProps {
  isDisabled?: boolean
  page?: number
  pageSize?: ItemsPerPage
  searchQuery?: string
  sortBy?: ReceiptsSortProperty
  sortDirection?: SortDirection
  fillType?: ReceiptFillType
  type?: ReceiptFilterType
}

interface UseFetchReceiptsResponse {
  attachments: Attachment[]
  isLoading: boolean
  pagination?: Paging
}

export const useFetchReceipts = ({
  isDisabled,
  page,
  pageSize,
  searchQuery,
  sortBy,
  sortDirection,
  fillType,
  type,
}: UseFetchReceiptsProps): UseFetchReceiptsResponse => {
  const { organization } = useUserOrganization()
  const previousPagination = useRef<Paging | undefined>(undefined)

  const queryProps = useGetFetchReceiptsQueryProps({
    page,
    pageSize,
    searchQuery,
    sortBy,
    sortDirection,
    fillType,
    type,
  })

  const queryKey = useMemo(() => getReceiptsQueryKey(queryProps), [queryProps])

  const {
    data,
    isIdle: isDataIdle,
    isLoading: isDataLoading,
  } = useQuery(queryKey, () => fetchReceipts(queryProps), {
    enabled: !!organization?.id && !isDisabled,
    staleTime: defaultStaleTime,
  })

  // If pagination exists then copy the value to reference and use it when original pagination during loading returns `undefined` value.
  useEffect(() => {
    if (data?.meta.paging) {
      previousPagination.current = { ...data?.meta.paging }
    }
  }, [data?.meta.paging])

  usePaginationPrefetching<QueryProps, FetchReceiptsResponseData>({
    getQueryFunction: useMemo(() => fetchReceipts, []),
    getQueryKey: useMemo(() => getReceiptsQueryKey, []),
    isEnabled: !!organization?.id && !isDataLoading && !isDisabled,
    queryProps,
    totalPages: data?.meta.paging.pageCount as number,
  })

  return {
    attachments: data?.attachments || [],
    isLoading: isDataIdle || isDataLoading,
    pagination: data?.meta.paging || previousPagination.current,
  }
}
