import { MonthSelect, ProcessingOverlay } from '@components'
import { Checkbox, Color, DateInput, FormItemsGroup, InputNumber, Link, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../enums/scope'
import { useFormContext } from '../../../../../../hooks'
import { isAuthorized } from '../../../../../../utils/isAuthorized'
import { useUserOrganization } from '../../../../../app/organization'
import { ChartOfAccountId } from '../../../../../app/organization/enums/chartOfAccountId'
import { useIsOrganizationOwner } from '../../../../../app/organization/hooks/useIsOrganizationOwner'
import { useOrganizationAccountingSettings } from '../../contexts/organizationAccountingSettingsContext'
import { OrganizationAccountingFormSchema } from '../../utils/organizationAccountingSettingsFormData'
import { AccountingBasisFormItem } from './elements/AccountingBasisFormItem'
import { SalesTaxPeriodFormItem } from './elements/SalesTaxPeriodFormItem'
import * as Styled from './styles'

export const TaxSettings = (): ReactElement => {
  const { t } = useTranslation()
  const { FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const { organization } = useUserOrganization()
  const { isOrganizationOwner } = useIsOrganizationOwner()
  const showVatExemptCheckbox = organization?.chartOfAccountId !== ChartOfAccountId.Manual
  const { isProcessing, isLoading } = useOrganizationAccountingSettings()
  const shouldShowAccountingBasis =
    isOrganizationOwner && organization?.countryId === 'FR' && isAuthorized(Scope.SettingAccountingBasis)

  return (
    <Styled.FormFieldsWrapper>
      {(isProcessing || isLoading) && <ProcessingOverlay color={Color.White} />}
      <FormItemsGroup>
        <FormItem
          name="fiscalYearEndMonth"
          label={t('settings.organization.accounting.fiscal_year_end')}
          render={({ field: { onChange, value, ...rest } }) => (
            <MonthSelect selectedId={String(value)} onSelect={onChange} {...rest} />
          )}
        />
        <FormItem
          name="firstFiscalYearStart"
          label={t('settings.organization.accounting.fiscal_year_date_start')}
          render={({ field }) => <DateInput {...field} />}
        />
        <FormItem
          name="firstFiscalYearEnd"
          label={t('settings.organization.accounting.first_fiscal_year_end')}
          render={({ field }) => <InputNumber {...field} keyboard step={1} withFormatting={false} />}
        />
        {t('settings.organization.accounting.help_text') && (
          <Text colorVariant="secondary">
            <Trans i18nKey="settings.organization.accounting.help_text" value={'test'}>
              Er du i tvivl?
              <Link
                href="https://www.billy.dk/support/article/regnskabsindstillinger/"
                target="_blank"
                rel="noreferrer"
                withAnimation
              >
                Læs vores hjælpeartikel om regnskabsår
              </Link>
            </Trans>
          </Text>
        )}
        <SalesTaxPeriodFormItem />
        {showVatExemptCheckbox && !isLoading && (
          <FormItem
            name="isVatExempted"
            render={({ field: { onChange, value, ...rest } }) => (
              <Checkbox checked={value} onChange={onChange} {...rest}>
                {t('settings.organization.accounting.vat_exempted')}
              </Checkbox>
            )}
          />
        )}
        <FormItem
          name="fiscalLockingDate"
          extra={t('settings.organization.accounting.fiscal_locking_date_tip')}
          label={t('settings.organization.accounting.fiscal_locking_date')}
          render={({ field }) => <DateInput allowClear {...field} />}
        />
        {shouldShowAccountingBasis && <AccountingBasisFormItem />}
      </FormItemsGroup>
    </Styled.FormFieldsWrapper>
  )
}
