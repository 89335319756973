import { useUserOrganization } from '../../../app/organization'
import { OrganizationFeature, useOrganizationFeature } from '../../../settings/routes/OrganizationFeaturesSettings'
import { useBillReconciliationSuggestions } from '../../hooks/useBillReconciliationSuggestions'
import { BillOverviewReconciliationSuggestionContent } from '../BillOverviewReconciliationSuggestionContent'
import { useBillView } from '../BillReadableForm'
import { BillOverviewReconciliationSuggestionSkeleton } from './elements/BillOverviewReconciliationSuggestionSkeleton'

export const BillOverviewReconciliationSuggestion = () => {
  const { organization } = useUserOrganization()
  const { hasFeature } = useOrganizationFeature()
  const hasBillReconciliationFeature = hasFeature(OrganizationFeature.BillReconciliation)
  const { billId, billData, isFetching: isFetchingBill } = useBillView()
  const { suggestions, isLoading: isLoadingSuggestions } = useBillReconciliationSuggestions(billId)
  const bill = billData?.bill

  if (isLoadingSuggestions || isFetchingBill) {
    return <BillOverviewReconciliationSuggestionSkeleton />
  }

  if (!hasBillReconciliationFeature || !organization?.randomBucket || !suggestions?.length || bill?.isPaid) {
    return null
  }

  return <BillOverviewReconciliationSuggestionContent />
}
