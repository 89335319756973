import produce from 'immer'

import { AppState } from '../types'
import { UMBRELLA_RECEIVED, USER_UMBRELLAS_RECEIVED } from './actions'
import { UmbrellaState } from './types/umbrellaState'

export default (state: AppState, { type, payload }: any): AppState => {
  switch (type) {
    case USER_UMBRELLAS_RECEIVED:
      return produce(state, (draftState: UmbrellaState) => {
        draftState.userUmbrellas = payload
      })
    case UMBRELLA_RECEIVED:
      return produce(state, (draftState: UmbrellaState) => {
        draftState.umbrella = payload
      })
    default:
      return state
  }
}
