import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberUserType, useEmberUserType } from '../../../../../contexts/emberUserTypeContext'
import { Scope } from '../../../../../enums/scope'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { SettingsRoute } from '../../../enums/settingsRoute'
import { useOrganizationFeature } from '../../../routes/OrganizationFeaturesSettings'
import { SettingsTabItem, UseSettingsTabItems } from '../types'
import { filterSettingsTabItems } from '../utils/filterSettingsTabItems'

export const useOrganizationSettingsTabItems = (): UseSettingsTabItems => {
  const { t } = useTranslation()
  const { userType } = useEmberUserType()

  const ownerUserTypes: EmberUserType[] = ['Accountant', 'Business Owner']
  const userIsOrganizationOwner = ownerUserTypes.includes(userType)
  const { currentFeatures } = useOrganizationFeature()

  const items: SettingsTabItem[] = useMemo(
    () => [
      {
        icon: 'suitcase',
        route: SettingsRoute.Organization,
        text: t('settings.organization.profile'),
        scopes: Scope.AccountRead,
      },
      {
        icon: 'barChart',
        route: SettingsRoute.OrganizationAccounting,
        childRoutes: [
          SettingsRoute.OrganizationAccountingVatReturn,
          SettingsRoute.OrganizationAccountingChartOfAccounts,
        ],
        text: t('settings.organization.accounting'),
        // TODO: Replace with a `scopes` property when all users are migrated to the new scope version
        hidden: !isAuthorized(Scope.SettingTaxWrite, true),
      },
      {
        icon: 'donutChart',
        route: SettingsRoute.OrganizationVatRates,
        text: t('settings.organization.vat_rates'),
        hidden: !isAuthorized(Scope.SettingVatWrite, true),
      },
      {
        icon: 'invoice',
        route: SettingsRoute.OrganizationInvoicing,
        text: t('settings.organization.invoicing'),
        hidden: !isAuthorized(Scope.InvoiceWrite),
      },
      {
        icon: 'users',
        route: SettingsRoute.OrganizationUsers,
        text: t('settings.organization.users'),
        hidden: !isAuthorized(Scope.UserRead, false, userIsOrganizationOwner),
      },
      {
        icon: 'dollarWithArrows',
        route: SettingsRoute.OrganizationSubscription,
        childRoutes: [SettingsRoute.OrganizationSubscriptionPlansSelection],
        text: t('settings.organization.subscriptions'),
        hidden: !isAuthorized(Scope.OrganizationSubscriptionWrite, false, userIsOrganizationOwner),
      },
      {
        icon: 'bubbleChart',
        route: SettingsRoute.OrganizationAccessTokens,
        text: t('settings.organization.apiTokens'),
        hidden: !isAuthorized(Scope.TokensRead, false, userIsOrganizationOwner),
      },
      {
        icon: 'bulb',
        route: SettingsRoute.OrganizationBetas,
        text: t('settings.organization.features'),
        badgeCounter: currentFeatures.length,
        hidden: !userIsOrganizationOwner || !isAuthorized(Scope.BetaRead),
      },
    ],
    [t, userIsOrganizationOwner, currentFeatures],
  )

  const itemsFiltered = useMemo(() => {
    return filterSettingsTabItems(items, { isAuthorized })
  }, [items])

  return { items: itemsFiltered }
}
