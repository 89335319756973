import produce from 'immer'
import { AnyAction } from 'redux'

import { AppState } from '../types'
import { COMPANIES_RECEIVED } from './actions'
import { CompaniesState } from './types'

export default (state: AppState, { type, payload }: AnyAction): AppState => {
  switch (type) {
    case COMPANIES_RECEIVED:
      return produce(state, (draftState: CompaniesState) => {
        draftState.companies.list = payload.companies
      })
  }

  return state
}
