import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { useGetQueryFromEmberRoute } from '../../hooks/useGetQueryFromEmberRoute'
import { QuotesRoute } from './enums/quotesRoute'
import { QuotesList } from './routes/QuotesList'
import { defaultQuery } from './routes/QuotesList/hooks/useQuotesListFilters'
import { QuotesListEmpty } from './routes/QuotesListEmpty'

export const Quotes = (): ReactElement => {
  useGetQueryFromEmberRoute({
    queryParamsValidKeys: Object.keys(defaultQuery),
  })

  return (
    <Switch>
      <Route component={QuotesList} exact path={QuotesRoute.List} />
      <Route component={QuotesListEmpty} exact path={QuotesRoute.ListEmpty} />
    </Switch>
  )
}
