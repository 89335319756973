import noop from 'lodash/noop'
import { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'

import { EmberActionPayload } from '../../signupGuide/context/signupGuideContext'
import { useFetchUmbrellaInvitation } from '../hooks/useFetchUmbrellaInvitation'
import { UmbrellaInvitationData } from '../query-api'

interface UmbrellaInvitationContextState {
  token: string
  isLoading: boolean
  data?: UmbrellaInvitationData
  error?: any
  authorizeEmberUser: (token: string) => void
}

const defaultValue = {
  token: '',
  isLoading: false,
  data: undefined,
  error: undefined,
  authorizeEmberUser: noop,
}

export const UmbrellaInvitationContext = createContext<UmbrellaInvitationContextState>(defaultValue)

interface UmbrellaInvitationsProviderProps {
  children: ReactNode
  token: string
  onAuthorizeUser: (payload: EmberActionPayload) => void
}

export const UmbrellaInvitationProvider = ({
  children,
  token,
  onAuthorizeUser,
}: UmbrellaInvitationsProviderProps): ReactElement => {
  const { isLoading, data, error } = useFetchUmbrellaInvitation({ token })

  const authorizeEmberUser = useCallback(
    (token: string) => {
      onAuthorizeUser({ detail: { token } })
    },
    [onAuthorizeUser],
  )

  const value = useMemo(
    () => ({
      token,
      isLoading,
      data,
      error,
      authorizeEmberUser,
    }),
    [token, isLoading, data, error, authorizeEmberUser],
  )

  return <UmbrellaInvitationContext.Provider value={value}>{children}</UmbrellaInvitationContext.Provider>
}

export const useUmbrellaInvitation = () => useContext(UmbrellaInvitationContext)
