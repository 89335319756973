import produce from 'immer'

import { AppState } from '../types'
import { ACCOUNTS_RECEIVED, ACCOUNTS_REQUESTED, BANK_ACCOUNT_SELECTED } from './actions'
import { AccountsState } from './types'

const accountsReducer = (state: AppState, { type, payload }: any): AppState => {
  switch (type) {
    case ACCOUNTS_REQUESTED:
      return produce(state, (draftState: AccountsState) => {
        draftState.accountResolved = false
      })

    case ACCOUNTS_RECEIVED:
      return produce(state, (draftState: AccountsState) => {
        draftState.accounts = payload
      })

    case BANK_ACCOUNT_SELECTED:
      return produce(state, (draftState: AccountsState) => {
        draftState.account = payload
        draftState.accountResolved = true
      })

    default:
      return state
  }
}

export default accountsReducer
