import { Contact } from '../../contacts/types/contact'
import { Bill } from '../types/bill'

export const getContactFromBill = (bill: Bill, contacts?: Contact[]): Contact | undefined => {
  const contactId = bill.contactId

  if (!contactId) {
    return undefined
  }

  return contacts?.find((contact) => contactId === contact.id)
}
