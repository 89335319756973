import { Attachment, ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement, useCallback } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberCloseModalContextProvider } from '../../contexts/emberCloseModalContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { ModalId } from '../../enums/modalId'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { AttachmentsContextProvider } from '../../modules/receipts/contexts/attachmentsContext'
import { ChooseReceiptModal } from '../../modules/receipts/elements/ChooseReceiptModal'
import { EmberEventFn } from '../../types/emberEventFn'

class ChooseReceiptModalModuleWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal idsToSkipStringified: string
  @registerEvent('closeEmber') closeEmber: Function
  @registerEvent('receiptSelect') receiptSelect: Function
}

type ChooseReceiptModalProps = {
  idsToSkipStringified?: string
  onCloseEmber: EmberEventFn
  onReceiptSelect: EmberEventFn
  organizationId?: string
}

export const ChooseReceiptModalModule = ({
  idsToSkipStringified,
  onCloseEmber,
  onReceiptSelect,
  organizationId,
}: ChooseReceiptModalProps): ReactElement => {
  const idsToSkip = idsToSkipStringified ? JSON.parse(idsToSkipStringified) : []

  const handleReceiptSelect = useCallback(
    (attachment: Attachment) => {
      onReceiptSelect({ detail: { attachment } })
    },
    [onReceiptSelect],
  )

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <EmberCloseModalContextProvider onCloseModal={onCloseEmber}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <AttachmentsContextProvider>
              <ModalContextProvider defaultOpenedIds={[ModalId.AttachmentsModal]}>
                <ChooseReceiptModal
                  onSelect={handleReceiptSelect}
                  idsToSkip={Array.isArray(idsToSkip) ? idsToSkip : []}
                />
              </ModalContextProvider>
            </AttachmentsContextProvider>
          </UserOrganizationContextProvider>
        </EmberCloseModalContextProvider>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const ChooseReceiptModalCustomElement = createCustomElement(
  ChooseReceiptModalModule,
  ChooseReceiptModalModuleWebComponent,
  'element',
)

customElements.get('choose-receipt-modal-module') ||
  customElements.define('choose-receipt-modal-module', ChooseReceiptModalCustomElement)

export default ChooseReceiptModalCustomElement
