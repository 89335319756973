import { TableActionsWrapper, TableBadgeLabel } from '@components'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { useUmbrellaOrganizations } from '../../../../../inbox/hooks/useUmbrellaOrganizations'
import { useUmbrellaOrganizationsListFilters } from '../../hooks/useUmbrellaOrganizationsListFilters'
import { UmbrellaOrganizationsListSearch } from '../UmbrellaOrganizationsListSearch'

export const UmbrellaOrganizationsListTableActions = (): ReactElement => {
  const [{ page, pageSize, searchQuery }] = useUmbrellaOrganizationsListFilters()
  const { isLoading, pagination } = useUmbrellaOrganizations({
    page,
    pageSize,
    q: searchQuery,
  })
  const { t } = useTranslation()

  return (
    <TableActionsWrapper>
      <TableBadgeLabel amount={pagination?.total} isLoading={isLoading} variant="neutral">
        {t('umbrella_organizations.organizations_table.customers')}
      </TableBadgeLabel>
      <UmbrellaOrganizationsListSearch />
    </TableActionsWrapper>
  )
}
