import { OrganizationSettings } from '../../../../app/organization/types/organizationSettings'
import { OrganizationFeature } from '../enums/organizationFeature'
import { getOrganizationSettingBetaKey } from './getOrganizationSettingBetaKey'

export const isFeatureDefinedInOrganizationSettings = (
  organizationSettings: OrganizationSettings | undefined,
  featureName: OrganizationFeature,
): Boolean => {
  if (!organizationSettings) {
    return false
  }

  return Boolean(
    Object.keys(organizationSettings).find(
      (organizationSetting) => organizationSetting === getOrganizationSettingBetaKey(featureName),
    ),
  )
}
