import React, { createContext, ReactElement, ReactNode, useContext } from 'react'

interface ContextState {
  daybookId: string
}

const DaybookContext = createContext<ContextState | undefined>(undefined)

interface DaybookContextProviderProps {
  daybookId: string
  children?: ReactNode
}

export const DaybookContextProvider = ({ children, daybookId }: DaybookContextProviderProps): ReactElement => {
  return <DaybookContext.Provider value={{ daybookId }}>{children}</DaybookContext.Provider>
}

export const useDaybook = () => {
  const context = useContext(DaybookContext)

  if (!context) {
    throw new Error('DaybookContextProvider is missing in the module!')
  }

  return context
}
