import { CountryIsoCode } from '../../../../../types/countryIsoCode'
import { useUserOrganization } from '../../../../app/organization'

const COUNTRIES_TO_USE_TEMPLATES: CountryIsoCode[] = ['dk', 'fr']

export const useTemplatesToCreateVatRates = () => {
  const { organization } = useUserOrganization()
  const countryIsoCode = organization?.countryId.toLowerCase() as CountryIsoCode | undefined

  return {
    isCreateNewVatRateFromTemplate: Boolean(countryIsoCode && COUNTRIES_TO_USE_TEMPLATES.includes(countryIsoCode)),
  }
}
