import { useMutation } from 'react-query'

import { APIError } from '../../../../../../../../../utils'
import { postOrganizationInvitation, PostOrganizationInvitationPayload } from '../../../../../../../../app/organization'

interface UseInviteUserProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface InviteUserProps {
  organizationId: string
  payload: PostOrganizationInvitationPayload
}

export const useInviteUser = (props?: UseInviteUserProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: inviteUser, isLoading } = useMutation(
    ({ organizationId, payload }: InviteUserProps) => postOrganizationInvitation(organizationId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return { isLoading, inviteUser }
}
