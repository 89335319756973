import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'

import { ThemeProvider } from '../../contexts/themeProvider'
import { MaintenanceBar } from '../../modules/maintenance'

class MaintenanceBarWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

const MODULE_NAME = 'maintenance-bar-module'

const MaintenanceBarModule = (): ReactElement => (
  <ThemeProvider>
    <MaintenanceBar />
  </ThemeProvider>
)

const MaintenanceBarCustomElement = createCustomElement(MaintenanceBarModule, MaintenanceBarWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, MaintenanceBarCustomElement)

export default MaintenanceBarCustomElement
