import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { UmbrellaRolePayload, updateUmbrellaRole } from '../../../../app/umbrellas/query-api'

interface UseUpdateUmbrellaRoleProps {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export interface UpdateUmbrellaRolePayload {
  payload: UmbrellaRolePayload
  roleId: string
  umbrellaId: string
}

export const useUpdateUmbrellaRole = (props?: UseUpdateUmbrellaRoleProps) => {
  const { onError, onSuccess } = props || {}

  const { mutate: updateRole, isLoading: isProcessing } = useMutation(
    ({ umbrellaId, roleId, payload }: UpdateUmbrellaRolePayload) => updateUmbrellaRole(umbrellaId, roleId, payload),
    {
      onError,
      onSuccess,
    },
  )

  return {
    updateRole,
    isProcessing,
  }
}
