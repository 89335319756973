import { NavItem } from '@design-system'

import { TFunction } from 'i18next'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { EmberRoute } from '../../../../../enums/emberRoute'
import { Scope } from '../../../../../enums/scope'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { useUserOrganizationSettings } from '../../../../app/organization'
import { useUmbrella } from '../../../../app/umbrellas'

interface FastMenuItems {
  items: NavItem<EmberRoute>[]
  isLoading: boolean
}

type FastItem = 'newInvoice' | 'newBill' | 'newQuote'

type FastItems = Record<FastItem, NavItem<EmberRoute>>

const getItems = (t: TFunction): FastItems => ({
  newInvoice: {
    id: 'new-invoice',
    children: t('invoice'),
    value: EmberRoute.InvoicesNew,
    iconLeft: 'invoice',
  },
  newBill: {
    id: 'new-bill',
    children: t('bill'),
    value: EmberRoute.BillsNew,
    iconLeft: 'bill',
  },
  newQuote: {
    id: 'new-quote',
    children: t('quote'),
    value: EmberRoute.QuotesNew,
    iconLeft: 'invoiceWithPencil',
  },
})

const itemsInboxEnabled: FastItem[] = ['newInvoice', 'newQuote']
const itemsInboxDisabled: FastItem[] = ['newInvoice', 'newBill', 'newQuote']

const fastItemsScopes: Record<FastItem, Scope | Scope[] | null> = {
  newInvoice: Scope.InvoiceWrite,
  newBill: Scope.BillWrite,
  newQuote: Scope.OfferWrite,
}

const getFilteredItems = (
  fastItems: FastItems,
  isInboxEnabled?: boolean,
  isUmbrellaContext?: boolean,
): NavItem<EmberRoute>[] => {
  if (isUmbrellaContext) {
    return []
  }

  const itemsKeys = isInboxEnabled ? itemsInboxEnabled : itemsInboxDisabled

  // TODO: Check why scopes are not filtered at this point
  return itemsKeys.reduce((items: NavItem<EmberRoute>[], key) => {
    const itemScopes = fastItemsScopes[key]
    if (itemScopes && !isAuthorized(itemScopes)) {
      return items
    }

    return [...items, fastItems[key]]
  }, [])
}

export const useFastMenuItems = (): FastMenuItems => {
  const { t } = useTranslation()
  const { organizationSettings, isLoading } = useUserOrganizationSettings()
  const { umbrella } = useUmbrella()
  const isInboxEnabled = organizationSettings?.isInboxEnabled === '1'

  const fastItems = useMemo(() => getItems(t), [t])
  // getFilteredItems not memoized in purpose as it uses the global object inside
  const items = getFilteredItems(fastItems, isInboxEnabled, !!umbrella)

  return { items, isLoading }
}
