import { SkeletonBox } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useUserOrganization } from '../../../app/organization'
import { BusinessInfoData } from '../../types/businessInfoData'
import * as Styled from './styles'

export const BusinessInfo = (): ReactElement => {
  const { t } = useTranslation()
  const { isLoading, organization } = useUserOrganization()

  const businessInfoData: BusinessInfoData[] = useMemo(() => {
    return [
      {
        label: t('find_accountant.questions.three.company_name'),
        data: organization?.name || '-',
      },
      {
        label: t('find_accountant.questions.three.address'),
        data: `${organization?.street}, ${organization?.zipcode} ${organization?.city}`,
      },
      {
        label: t('find_accountant.questions.three.company_type'),
        data: organization?.companyType || '-',
      },
      {
        label: t('find_accountant.questions.three.cvr'),
        data: organization?.registrationNo || '-',
      },
      {
        label: t('find_accountant.questions.three.phone'),
        data: organization?.phone || '-',
      },
      {
        label: t('find_accountant.questions.three.email'),
        data: organization?.email || '-',
      },
    ]
  }, [organization, t])

  if (isLoading) {
    return <SkeletonBox fullWidth height={144} />
  }

  return (
    <Styled.BusinessInfoWrapper>
      {businessInfoData.map(({ data, label }) => (
        <Styled.Item key={label}>
          <Styled.Label colorVariant="secondary">{label}</Styled.Label>
          <Styled.Data colorVariant="primary" weight="medium" variant="large">
            {data}
          </Styled.Data>
        </Styled.Item>
      ))}
    </Styled.BusinessInfoWrapper>
  )
}
