import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { FindAccountant } from '../../modules/findAccountant'
import { FindAccountantContextProvider } from '../../modules/findAccountant/contexts/findAccountantContext'

const MODULE_NAME = 'find-accountant-module'

class FindAccountantWebComponent extends DOMModel {
  @byAttrVal bankAccountId: string
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface FindAccountantModuleProps {
  bankAccountId: string
  onNavigate: EmberNavigate
  organizationId: string
}

const FindAccountantModule = ({
  bankAccountId,
  onNavigate,
  organizationId,
}: FindAccountantModuleProps): ReactElement => (
  <ThemeProvider>
    <QueryClientProvider client={queryClient}>
      <EmberRouterContextProvider onNavigate={onNavigate}>
        <UserOrganizationContextProvider organizationId={organizationId}>
          <CurrentUserContextProvider>
            <FindAccountantContextProvider bankAccountId={bankAccountId}>
              <FindAccountant />
            </FindAccountantContextProvider>
          </CurrentUserContextProvider>
        </UserOrganizationContextProvider>
      </EmberRouterContextProvider>
    </QueryClientProvider>
  </ThemeProvider>
)

const FindAccountantCustomElement = createCustomElement(FindAccountantModule, FindAccountantWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, FindAccountantCustomElement)

export default FindAccountantCustomElement
