import { ButtonDropdown, IconButtonWithLabel } from '@design-system'

import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../../../enums/emberRoute'
import { useUserOrganization } from '../../../../../app/organization'
import { useUmbrella } from '../../../../../app/umbrellas'
import { isSettingsModulePath } from '../../../../../settings/utils/isSettingsModulePath'
import { routeToInternalSettings } from '../../../../../settings/utils/routeToInternalSettings'
import { routeToUmbrella } from '../../../../../umbrella/utils/routeToUmbrella'
import { getUserItems } from '../../utils/getUserItems'
import { UserEmailInfo } from './elements/UserEmailInfo'

export const UserDropdown = () => {
  const { t } = useTranslation()
  const { umbrella } = useUmbrella()
  const { organization } = useUserOrganization()

  const isLoading = !umbrella && !organization
  const shouldShowUserSettings = !isLoading && !organization?.isLocked
  const userItems = useMemo(
    () => getUserItems(t, Boolean(umbrella), shouldShowUserSettings),
    [t, umbrella, shouldShowUserSettings],
  )
  const { navigate } = useEmberRouter()

  const handleSelect = useCallback(
    (_: string, value: EmberRoute) => {
      if (value === EmberRoute.SettingsUser && isSettingsModulePath()) {
        routeToInternalSettings(EmberRoute.SettingsUser)
        return
      }

      if (value === EmberRoute.SettingsUserUmbrella) {
        routeToUmbrella(EmberRoute.SettingsUserUmbrella)
        return
      }

      navigate(value)
    },
    [navigate],
  )

  return (
    <ButtonDropdown
      items={userItems}
      onSelect={handleSelect}
      triggerElement={<IconButtonWithLabel icon="user">{t('menu.footer.user_action')}</IconButtonWithLabel>}
      placement="top-start"
      dropdownHeader={<UserEmailInfo />}
    />
  )
}
