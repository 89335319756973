import { useCallback } from 'react'

import { BillFormFieldsChangeSource } from '../../../../../enums/billFormFieldsChangeSource'
import { useFormContext } from '../../../../../hooks/useForm'
import { Account } from '../../../../app/accounts/types'
import { useUserOrganization } from '../../../../app/organization'
import { useTaxRates } from '../../../../app/taxrates'
import { useBillForm } from '../contexts/billFormContext'
import { autoCompleteBillForm } from '../utils/autoCompleteBillForm'
import { BillFormSchema } from '../utils/formData'
import { getBillFormFromExpenseCategory } from '../utils/getBillFormFromExpenseCategory'
import { getShouldOverridenParamChange } from '../utils/getShouldOverridenParamChange'
import { useTrackAutocompletedFields } from '../utils/trackAutocompletedFields'

export const useExpenseCategoryAutoComplete = () => {
  const { organization } = useUserOrganization()
  const { getValues, reset: resetForm, setValue } = useFormContext<BillFormSchema>()
  const { taxRates } = useTaxRates()
  const { trackAutocompletedFields } = useTrackAutocompletedFields()
  const { formFieldsChangeSource, setFormFieldsChangeSource } = useBillForm()

  const autoCompleteWithExpenseCategoryData = useCallback(
    (expenseCategory: Account | undefined, lineIndex: number, source: BillFormFieldsChangeSource) => {
      const billForm = getValues()
      const billFormExpense = getBillFormFromExpenseCategory({
        expenseCategory,
        lineIndex,
        linesTotal: billForm.billLines?.length,
        taxRates,
      })

      const billFormUpdated = autoCompleteBillForm({
        billFormDefault: billForm,
        billFormOverrides: billFormExpense,
        baseCurrencyId: organization?.baseCurrencyId,
        shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
      })
      resetForm(billFormUpdated, { keepDefaultValues: true })

      trackAutocompletedFields({
        billLines: billForm?.billLines?.[lineIndex],
        updatedBillLines: billFormUpdated?.billLines?.[lineIndex],
        context: source,
        formFieldsChangeSource,
        setFormFieldsChangeSource,
      })

      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })
    },
    [
      formFieldsChangeSource,
      getValues,
      organization?.baseCurrencyId,
      resetForm,
      setFormFieldsChangeSource,
      setValue,
      taxRates,
      trackAutocompletedFields,
    ],
  )

  return { autoCompleteWithExpenseCategoryData }
}
