import { ErrorStacktraceModal, ProcessingOverlay, TimelineStatus } from '@components'
import { Button, ButtonsGroup, Color, ModalConfirmation, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'

import { ModalId } from '../../../../enums/modalId'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../enums/queryKeys'
import { dateStringToUTC } from '../../../../utils/dateStringToUTC'
import { useUserOrganization } from '../../../app/organization'
import { useCurrentUser } from '../../../app/user'
import { InvoiceDelivery, InvoiceLog, useSendEInvoice } from '../../../invoices'
import { useCancelSendingEInvoice } from '../../../invoices/hooks/useCancelSendingEInvoice'
import { fixableSendingStates } from '../../constants/fixableSendingStates'
import { useEInvoiceStatusConnection } from '../../contexts/eInvoiceStatusEmberConnectionContext'
import { ActionType, errorKeyToAction } from '../../utils/errorKeyToAction'
import { errorKeyToErrorMessageTKey } from '../../utils/errorKeyToErrorMessageTKey'
import { sendingStateToMessageTKey } from '../../utils/sendingStateToMessageTKey'
import { sendingStateToTimelineStatus } from '../../utils/sendingStateToTimelineStatus'
import { sendingStateToTitleTKey } from '../../utils/sendingStateToTitleTKey'
import { RejectedStatusDescription } from './elements/RejectedStatusDescription'

interface TimelineStatusItemProps {
  invoiceLog: InvoiceLog
  invoiceDelivery?: InvoiceDelivery
  skipShowingActions?: boolean
}

export const TimelineStatusItem = ({
  invoiceLog,
  invoiceDelivery,
  skipShowingActions,
  ...rest
}: TimelineStatusItemProps): ReactElement => {
  const errorStacktraceModalId = `error-stacktrace-modal-${invoiceLog.id}`
  const cancelSendingEinvoiceModalId = `cancel-sending-einvoice-modal-${invoiceLog.id}`
  const { t } = useTranslation()
  const { open: openErrorStacktraceModal } = useModal(errorStacktraceModalId)
  const { open: openVoidInvoiceModal } = useModal(ModalId.VoidInvoiceModal)
  const { open: openCancelSendingEInvoiceModal, close: closeSendingEInvoiceModal } =
    useModal(cancelSendingEinvoiceModalId)
  const { openSendInvoiceModal } = useEInvoiceStatusConnection()
  const { organization } = useUserOrganization()
  const { user } = useCurrentUser()
  const queryClient = useQueryClient()

  const { sendEInvoice, isLoading: isSendingInvoice } = useSendEInvoice({
    onError: () => {
      notify({ id: NotificationKeys.EInvoiceSave, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: () => {
      notify({
        id: NotificationKeys.EInvoiceSave,
        message: t('modal.send_invoice.sent'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.InvoiceLogs)
    },
  })

  const { cancelSendingEInvoice, isProcessing: isCancelingSendingEInvoice } = useCancelSendingEInvoice({
    onError: () => {
      notify({ id: NotificationKeys.EInvoiceCancel, message: t('error_saving'), variant: 'error' })
    },
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.InvoiceLogs)
      closeSendingEInvoiceModal()
    },
  })

  const { eventTime, messageKey, message, type, invoiceId } = invoiceLog
  const { canResend } = invoiceDelivery || {}
  // DB datetime string is UTC time, but it's not in a format that's recognizable as UTC by Date()
  const eventTimeUTC = dateStringToUTC(eventTime)

  const resendEInvoice = useCallback(() => {
    if (!organization?.id || !invoiceDelivery || !user?.id) {
      return
    }

    const { receiverGln: gln, receiverIdType: identifierType } = invoiceDelivery || {}
    sendEInvoice({
      senderUserId: user?.id,
      organizationId: organization.id,
      invoiceId,
      identifierType,
      gln: gln || undefined,
    })
    // we need to call POST InvoiceDelivery endpoint for this Invoice again, just as for the first time.
  }, [invoiceDelivery, invoiceId, organization?.id, user?.id, sendEInvoice])

  const handleActionButtonClick = useCallback(
    (action: ActionType) => () => {
      switch (action) {
        case 'openErrorDetailsModal':
          openErrorStacktraceModal()
          break
        case 'voidInvoice':
          openVoidInvoiceModal()
          break
        case 'cancelSendingEInvoice':
          openCancelSendingEInvoiceModal()
          break
        case 'resendEInvoice':
          resendEInvoice()
          break

        case 'openSendModal':
          openSendInvoiceModal()
          break
      }
    },
    [
      cancelSendingEInvoice,
      invoiceDelivery?.id,
      openErrorStacktraceModal,
      openSendInvoiceModal,
      openVoidInvoiceModal,
      resendEInvoice,
    ],
  )

  const shouldAllowAction = useCallback(
    (action: ActionType) => {
      if (['openSendModal', 'resendEInvoice'].includes(action) && !canResend) {
        return false
      }

      return true
    },
    [canResend],
  )

  const description = useMemo(() => {
    if (messageKey === 'rejected' && message) {
      return <RejectedStatusDescription reason={message} />
    }

    const tKey = (messageKey && errorKeyToErrorMessageTKey[messageKey]) || sendingStateToMessageTKey[type]
    return tKey ? t(tKey) : undefined
  }, [message, messageKey, t, type])

  const stateActions = useMemo(() => {
    let actions = (messageKey && errorKeyToAction[messageKey]) || []

    if (fixableSendingStates.includes(type)) {
      actions = [
        ...actions,
        {
          action: 'cancelSendingEInvoice',
          tLabel: 'einvoce.status.action.cancel_sending',
          buttonVariant: 'secondary',
        },
      ]
    }

    return actions
  }, [messageKey, type])

  const actions = useMemo(() => {
    if (skipShowingActions || !stateActions?.length) {
      return null
    }

    const areButtonsVertical = !!stateActions && stateActions.length > 1

    return (
      <ButtonsGroup direction={areButtonsVertical ? 'column' : 'row'}>
        {stateActions
          .filter(({ action }) => shouldAllowAction(action))
          .map(({ action, tLabel, buttonVariant }) => (
            <Button
              key={action}
              fullWidth={areButtonsVertical}
              onClick={handleActionButtonClick(action)}
              variant={buttonVariant}
            >
              {t(tLabel)}
            </Button>
          ))}
      </ButtonsGroup>
    )
  }, [handleActionButtonClick, messageKey, skipShowingActions, shouldAllowAction, t])

  const handleCancelSendingEInvoice = useCallback(() => {
    cancelSendingEInvoice({ deliveryId: invoiceDelivery?.id })
  }, [cancelSendingEInvoice, invoiceDelivery?.id])

  const title = useMemo(() => {
    if (messageKey === 'rejected') {
      return t('einvoce.status.title.rejected') // for rejected invoice we need to display title that refers to key not status
    }

    return t(sendingStateToTitleTKey[type])
  }, [messageKey, t, type])

  const isProcessing = isSendingInvoice || isCancelingSendingEInvoice

  return (
    <>
      {isProcessing && <ProcessingOverlay color={Color.White} />}

      <TimelineStatus.Item
        date={eventTimeUTC}
        description={description}
        status={sendingStateToTimelineStatus[type]}
        title={title}
        {...rest} // we have to pass the rest properties and those are the one which 'TimelineStatusItemsList' component passes to the children
      >
        {actions}
      </TimelineStatus.Item>

      <ErrorStacktraceModal
        id={errorStacktraceModalId}
        stackTrace={invoiceLog.message || ''}
        title={t('einvoce.error_stack_trace_modal.title')}
      />

      <ModalConfirmation
        id={cancelSendingEinvoiceModalId}
        title={t('einvoce.cancel_sending_einvoice_modal.title')}
        message={t('einvoce.cancel_sending_einvoice_modal.message')}
        okLabel={t('einvoce.cancel_sending_einvoice_modal.confirm')}
        cancelLabel={t('einvoce.cancel_sending_einvoice_modal.dismiss')}
        onOk={handleCancelSendingEInvoice}
      />
    </>
  )
}
