import { amountToDisplayValue, Divider, Link, Module, Space, Text } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationSubscription, useSubscriptionLimits } from '../../../../../../hooks'
import { SubscriptionPlan } from '../../../../../app/organization/enums/subscriptionPlan'
import { plansWithLimits, plansWithNoUsageInfo } from '../../plansData'
import { ProgressChartComponent } from '../ProgressChartComponent'
import { SummaryBoxesComponent } from '../SummaryBoxesComponent'
import * as Styled from './styles'

export interface SectionPlanUsageProps {
  subscriptionPlan: SubscriptionPlan
  postings: number
  postingsLimit: number | null
  revenue: number
  revenueLimit: number | null
  isRevenueEstimated: boolean
  onClickChangePlan: () => void
  hasSufficientData: boolean
  isStandardPlan: boolean
}

export const SectionPlanUsageComponent = ({
  onClickChangePlan,
  subscriptionPlan,
  postings,
  postingsLimit,
  revenue,
  revenueLimit,
  isRevenueEstimated,
  hasSufficientData,
  isStandardPlan,
}: SectionPlanUsageProps) => {
  const { t } = useTranslation()
  const { isTrial } = useOrganizationSubscription()
  const { invoices, invoicesLimit, expenses, expensesLimit } = useSubscriptionLimits()
  const isPremium = subscriptionPlan === SubscriptionPlan.Premium
  const isFree = subscriptionPlan === SubscriptionPlan.Free
  const isLimitedPlan = plansWithLimits.includes(subscriptionPlan)

  const summaryItems = React.useMemo(
    () => [
      {
        name: t('organization_subscription.postings_number'),
        value: postings,
      },
      {
        name: t('organization_subscription.revenue'),
        value: `${amountToDisplayValue(revenue)} DKK`,
      },
    ],
    [t, postings, revenue],
  )

  const getRevenueLabel = (value: number | string, total: number | string | null) => {
    if (!hasSufficientData) {
      return t('organization_subscription.insufficient_data')
    }

    return total ? `${value}/${total}` : value
  }

  const postingsRightLabel = `${postings}/${postingsLimit}`
  const revenueRightLabel = getRevenueLabel(
    amountToDisplayValue(revenue),
    revenueLimit ? amountToDisplayValue(revenueLimit) : '',
  )
  const shouldShowPostingsData = !!postingsLimit
  const shouldShowRevenueData = !!revenueLimit

  if (plansWithNoUsageInfo.includes(subscriptionPlan) || isTrial) {
    return null
  }

  return (
    <>
      <Space size="l" />
      <Divider />
      <Space size="l" />
      <Module.Section title={t('organization_subscription.plan_usage')}>
        <Styled.UsageUpsellWrapper>
          <Styled.UsageWrapper>
            {isPremium || !isStandardPlan ? (
              <SummaryBoxesComponent items={summaryItems} />
            ) : (
              <>
                {/* for the time being just a fake progress bar before we implement limits */}
                {isFree && (
                  <ProgressChartComponent
                    leftLabel={t('organization_subscription.invoices')}
                    rightLabel={`${invoices}/${invoicesLimit}`}
                    value={invoices || 0}
                    total={invoicesLimit || 0}
                  />
                )}
                {/* for the time being just a fake progress bar before we implement limits */}
                {isFree && (
                  <ProgressChartComponent
                    leftLabel={t('organization_subscription.expenses')}
                    rightLabel={`${expenses}/${expensesLimit}`}
                    value={expenses || 0}
                    total={expensesLimit || 0}
                  />
                )}
                {shouldShowRevenueData && (
                  <ProgressChartComponent
                    leftLabel={
                      isRevenueEstimated || !hasSufficientData
                        ? t('organization_subscription.revenue_estimated')
                        : t('organization_subscription.revenue')
                    }
                    rightLabel={revenueRightLabel}
                    value={hasSufficientData ? revenue : 0}
                    total={revenueLimit}
                  />
                )}
                {shouldShowPostingsData && (
                  <ProgressChartComponent
                    leftLabel={t('organization_subscription.postings')}
                    rightLabel={postingsRightLabel}
                    value={postings}
                    total={postingsLimit}
                  />
                )}
              </>
            )}
          </Styled.UsageWrapper>
          <Styled.UpsellWrapper>
            <Styled.UpsellText flexible={isPremium}>
              {isPremium ? (
                <Styled.NoLimitationsText variant="micro" colorVariant="secondary">
                  <span role="img" aria-label="Tada!">
                    🎉
                  </span>
                  {t('organization_subscription.no_limitations')}
                </Styled.NoLimitationsText>
              ) : null}

              {isLimitedPlan ? (
                <>
                  <Text variant="micro" inline>
                    <Link underlined withAnimation onClick={onClickChangePlan}>
                      {t('organization_subscription.upgrade_your_plan')}{' '}
                    </Link>
                  </Text>
                  <Text variant="micro" inline>
                    {t('organization_subscription.upgrade_your_plan.to_prevent')}
                  </Text>
                </>
              ) : null}
            </Styled.UpsellText>
          </Styled.UpsellWrapper>
        </Styled.UsageUpsellWrapper>
      </Module.Section>
    </>
  )
}
