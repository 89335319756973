import { TFunction } from 'i18next'
import { boolean, number, object, SchemaOf, string } from 'yup'

export interface InvoiceAndProductSettingsForm {
  defaultInvoiceBankAccountId: string
  defaultSalesAccountId: string
  defaultSalesTaxRulesetId: string
  defaultTaxMode: string
  emailAttachmentDeliveryMode: string
  invoiceNoMode: string
  isDuplicateInvoiceCheckDisabled: boolean | undefined
  isOrderNoEnabled: boolean | undefined
  isSummaryInclVat: boolean | undefined
  localeId: string
  nextInvoiceNo: number | undefined
  paymentTermsDays: number
  paymentTermsMode: string
  shouldShowNextInvoiceNoField: boolean | undefined
}

export const defaultValues: InvoiceAndProductSettingsForm = {
  defaultInvoiceBankAccountId: '',
  defaultSalesAccountId: '',
  defaultSalesTaxRulesetId: '',
  defaultTaxMode: '',
  emailAttachmentDeliveryMode: '',
  invoiceNoMode: '',
  isDuplicateInvoiceCheckDisabled: false,
  isOrderNoEnabled: false,
  isSummaryInclVat: false,
  localeId: '',
  nextInvoiceNo: undefined,
  paymentTermsDays: 0,
  paymentTermsMode: '',
  shouldShowNextInvoiceNoField: undefined,
}

export type InvoiceAndProductSettingsFormSchema = SchemaOf<InvoiceAndProductSettingsForm>

export const getValidationSchema = (t: TFunction, minNextInvoiceNo: number): InvoiceAndProductSettingsFormSchema => {
  const requiredString = string().required(t('required_field'))
  const requiredBoolean = boolean().required(t('required_field'))
  const requiredNumber = number().required(t('required_field'))

  return object({
    defaultInvoiceBankAccountId: requiredString,
    defaultSalesAccountId: requiredString,
    defaultSalesTaxRulesetId: requiredString,
    defaultTaxMode: requiredString,
    emailAttachmentDeliveryMode: requiredString,
    invoiceNoMode: requiredString,
    isDuplicateInvoiceCheckDisabled: requiredBoolean,
    isOrderNoEnabled: requiredBoolean,
    isSummaryInclVat: requiredBoolean,
    localeId: requiredString,
    nextInvoiceNo: number().when('shouldShowNextInvoiceNoField', (shouldShowNextInvoiceNoField, schema) =>
      shouldShowNextInvoiceNoField
        ? schema
            .required()
            .typeError(t('settings.organization.invoice.invoice_settings.next_invoice_no.error_message.type'))
            .integer(t('settings.organization.invoice.invoice_settings.next_invoice_no.error_message.integer'))
            .min(
              minNextInvoiceNo,
              t('settings.organization.invoice.invoice_settings.next_invoice_no.error_message.min_number', {
                count: minNextInvoiceNo,
              }),
            )
        : schema,
    ),
    paymentTermsDays: requiredNumber.typeError(
      t('settings.organization.invoice.invoice_settings.payment_terms_days.error_message.type'),
    ),
    paymentTermsMode: requiredString,
    shouldShowNextInvoiceNoField: boolean(),
  })
}
