import { Button, Input, InputPassword, Space, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import { SubmitLoginResponseData } from '../../../../query-api/login-query'
import { APIError } from '../../../../utils'
import { useLoginForm } from './hooks/useLoginForm'

interface LoginFormProps {
  invitationEmail: string
  title: string
  onError?: (error?: APIError) => void
  onSuccess?: (response: SubmitLoginResponseData) => void
}

export const LoginForm = ({ invitationEmail, title, onSuccess, onError }: LoginFormProps): ReactElement => {
  const { t } = useTranslation()
  const { Form, FormItem, submitForm, isLoading } = useLoginForm({
    invitationEmail,
    onSuccess,
    onError,
  })

  return (
    <>
      <Text variant="h1">{title}</Text>
      <Space size="xl" />
      <Text variant="h3">{t('organization_invitation.form.login_sub_title')}</Text>
      <Space size="xxl" />
      <Form>
        <FormItem
          label={t('organization_invitation.form.label.email')}
          name="email"
          render={({ field }) => (
            <Input
              {...field}
              disabled={!!invitationEmail}
              placeholder={t('organization_invitation.form.placeholder.email')}
              size="xl"
            />
          )}
        />
        <Space />
        <FormItem
          label={t('organization_invitation.form.label.password')}
          name="password"
          render={({ field }) => (
            <InputPassword {...field} placeholder={t('organization_invitation.form.placeholder.password')} size="xl" />
          )}
        />
        <Space size="xl" />
        <Button type="submit" size="xl" fullWidth onClick={submitForm} loading={isLoading}>
          {t('organization_invitation.form.accept_invitation')}
        </Button>
      </Form>
    </>
  )
}
