import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { SkatConnectionParams } from 'src/modules/skatBox/interfaces'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import SkatBox from '../../modules/skatBox/SkatBox'
import { EmberEventFn } from '../../types/emberEventFn'

class SkatBoxWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @byAttrVal skatTransactionId: string
  @registerEvent('updateSkatConnection') updateSkatConnection: EmberEventFn
}

type SkatBoxProps = {
  organizationId: string
  skatTransactionId: string
  onUpdateSkatConnection: (detail: SkatConnectionParams) => void
}

function SkatBoxModule({ organizationId, skatTransactionId, onUpdateSkatConnection }: SkatBoxProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <UserOrganizationContextProvider organizationId={organizationId}>
        <ThemeProvider>
          <SkatBox skatTransactionId={skatTransactionId} onUpdateSkatConnection={onUpdateSkatConnection} />
        </ThemeProvider>
      </UserOrganizationContextProvider>
    </QueryClientProvider>
  )
}

const SkatBoxCustomElement = createCustomElement(SkatBoxModule, SkatBoxWebComponent, 'element')

customElements.get('skat-box-module') || customElements.define('skat-box-module', SkatBoxCustomElement)

export default SkatBoxCustomElement
