import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { getUmbrellaOrganizationsProductPlans } from '../../app/umbrellas/query-api'

export const useUmbrellaOrganizationsProductPlans = (umbrellaId?: string) => {
  const { data, isIdle, isLoading } = useQuery(
    QueryKeys.UmbrellaOrganizationsProductPlans,
    () => getUmbrellaOrganizationsProductPlans(umbrellaId as string),
    {
      enabled: !!umbrellaId,
    },
  )

  return {
    productPlans: data?.data || [],
    isLoading: isIdle || isLoading,
  }
}
