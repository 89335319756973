import { EmptyProps, EmptyView } from '@components'

import React, { ReactElement, useCallback } from 'react'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { Scope } from '../../../../enums/scope'
import { isAuthorized } from '../../../../utils/isAuthorized'

interface ContactBalanceEmptyProps extends EmptyProps {
  route: EmberRoute
}

export const ContactBalanceEmpty = ({
  animation,
  buttonText,
  description,
  title,
  route,
}: ContactBalanceEmptyProps): ReactElement => {
  const { navigate } = useEmberRouter()

  const shouldSeeCreateButton = isAuthorized(Scope.InvoiceWrite)

  const handleEmptyViewClick = useCallback(() => {
    navigate(route)
  }, [navigate, route])

  return (
    <EmptyView
      animation={animation}
      onClick={handleEmptyViewClick}
      tButton={buttonText}
      tDescription={description}
      tTitle={title}
      showCTA={shouldSeeCreateButton}
    />
  )
}
