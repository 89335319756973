import isEqual from 'lodash/isEqual'
import { useCallback } from 'react'

import { useSegment } from '../../../../../../src/hooks'
import {
  BillFormFieldsChangeSource,
  BillFormFieldsChangeSourceMap,
} from '../../../../../enums/billFormFieldsChangeSource'
import { isUndefinedOrEmptyObject } from '../../../../../utils/isUndefinedOrEmptyObject'
import { BillFormLine } from './formData'

interface TrackAutocompletedFieldsProps {
  billLines: BillFormLine | undefined
  updatedBillLines: BillFormLine | undefined
  context: BillFormFieldsChangeSource
  formFieldsChangeSource: BillFormFieldsChangeSourceMap
  setFormFieldsChangeSource: (value: BillFormFieldsChangeSourceMap) => void
}

const AUTOCOMPLETE_EVENTS = {
  expenseCategory: {
    removed: 'xxx - expense - create expense - expense account removed',
    selected: 'xxx - expense - create expense - expense account selected',
  },
  taxRate: {
    removed: 'xxx - expense - create expense - tax rate removed',
    selected: 'xxx - expense - create expense - tax rate selected',
  },
}

export const useTrackAutocompletedFields = () => {
  const { track } = useSegment()

  const trackAutocompletedFields = useCallback(
    ({
      billLines,
      context,
      formFieldsChangeSource,
      setFormFieldsChangeSource,
      updatedBillLines,
    }: TrackAutocompletedFieldsProps) => {
      if (!billLines || !updatedBillLines) {
        return
      }

      const { expenseCategory, taxRate } = billLines
      const { expenseCategory: expenseCategoryOverwrite, taxRate: taxRateOverwrite } = updatedBillLines

      const updatedChangeSource = { ...formFieldsChangeSource }

      if (!isEqual(expenseCategory, expenseCategoryOverwrite)) {
        // if overwriting existing value with anything empty or not, first send the removal event with the previous context
        if (!isUndefinedOrEmptyObject(expenseCategory) && expenseCategoryOverwrite) {
          track(AUTOCOMPLETE_EVENTS.expenseCategory.removed, {
            context: formFieldsChangeSource.expenseCategory || formFieldsChangeSource.main,
          })
        }

        if (expenseCategoryOverwrite) {
          track(AUTOCOMPLETE_EVENTS.expenseCategory.selected, {
            context,
          })
          updatedChangeSource.expenseCategory = context
        } else {
          track(AUTOCOMPLETE_EVENTS.expenseCategory.removed, {
            context: formFieldsChangeSource.expenseCategory || formFieldsChangeSource.main,
          })
          delete updatedChangeSource.expenseCategory
        }
      }

      if (!isEqual(taxRate, taxRateOverwrite)) {
        // if overwriting existing value with anything, empty or not, first send the removal event with the previous context
        if (!isUndefinedOrEmptyObject(taxRate) && taxRateOverwrite) {
          track(AUTOCOMPLETE_EVENTS.taxRate.removed, {
            context: formFieldsChangeSource.taxRate || formFieldsChangeSource.main,
          })
        }

        if (taxRateOverwrite) {
          track(AUTOCOMPLETE_EVENTS.taxRate.selected, { context })
          updatedChangeSource.taxRate = context
        } else {
          track(AUTOCOMPLETE_EVENTS.taxRate.removed, {
            context: formFieldsChangeSource.taxRate || formFieldsChangeSource.main,
          })
          delete updatedChangeSource.taxRate
        }
      }

      setFormFieldsChangeSource(updatedChangeSource)
    },
    [track],
  )

  return { trackAutocompletedFields }
}
