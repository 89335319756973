import { ModalContextProvider } from '@design-system'

import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import {
  UserOrganizationContextProvider,
  UserOrganizationSettingsContextProvider,
} from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { Receipts } from '../../modules/receipts'

const MODULE_NAME = 'uploads-module'

class UploadsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface UploadsModuleProps {
  onNavigate: EmberNavigate
  organizationId: string
}

const UploadsModule = ({ onNavigate, organizationId }: UploadsModuleProps): ReactElement => {
  return (
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <UserOrganizationSettingsContextProvider organizationId={organizationId}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <EmberRouterContextProvider onNavigate={onNavigate}>
              <CurrentUserContextProvider>
                <ModalContextProvider>
                  <Receipts />
                </ModalContextProvider>
              </CurrentUserContextProvider>
            </EmberRouterContextProvider>
          </UserOrganizationContextProvider>
        </UserOrganizationSettingsContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

const UploadsCustomElement = createCustomElement(UploadsModule, UploadsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, UploadsCustomElement)

export default UploadsCustomElement
