import { AttachmentFile } from '@design-system'

import _ from 'lodash'
import { RefObject, useCallback, useLayoutEffect } from 'react'

import { useFormContext } from '../../../../../hooks/useForm'
import { useMeasureDirty } from '../../../../../hooks/useMeasureDirty'
import { getBillFormBaseWrapperHeight } from '../../../utils/getBillFormBaseWrapperHeight'
import { BillFormSchema } from '../../BillEditableForm/utils/formData'

const A4_PAPER_RATIO = 1.41

const findLargestSizeRatio = (files: AttachmentFile[]): number => {
  const largestRatioFile = _.chain(files)
    .filter(({ imageHeight, imageWidth }) => !!imageHeight && !!imageWidth)
    .sortBy(({ imageHeight, imageWidth }) => (imageHeight || 1) / (imageWidth || 1))
    .last()
    .value()

  if (!largestRatioFile) {
    return 1
  }

  const { imageHeight, imageWidth } = largestRatioFile || {}
  return (imageHeight || 1) / (imageWidth || 1)
}

export const useImageWrapperHeight = (
  wrapperRef: RefObject<HTMLDivElement>,
  files?: AttachmentFile[],
  isEnabled?: boolean,
) => {
  const { getValues } = useFormContext<BillFormSchema>()
  const { width: wrapperWidth } = useMeasureDirty(wrapperRef)

  const reset = useCallback(() => {
    const wrapperElement = wrapperRef.current
    const height = getBillFormBaseWrapperHeight()

    if (!wrapperElement) {
      return
    }

    wrapperElement.style.height = `${height}px`
  }, [wrapperRef])

  useLayoutEffect(() => {
    const wrapperElement = wrapperRef.current

    if (!isEnabled || !wrapperElement || !wrapperWidth || !files?.length) {
      return
    }

    const largestSizeRadio = findLargestSizeRatio(files)
    const sizeRatio = Math.max(A4_PAPER_RATIO, largestSizeRadio)
    const height = wrapperWidth * sizeRatio

    wrapperElement.style.height = `${height}px`
  }, [wrapperWidth, wrapperRef, isEnabled, getValues, files])

  return {
    reset,
  }
}
