import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { useUserOrganization } from '../../app/organization'
import { getBillTimeline } from '../query-api'
import { BillTimeline } from '../types/billTimeline'
import { getBillTimelineQueryKey } from '../utils/getBillTimelineQueryKey'

const filterTimeline = (timeline: BillTimeline[]): BillTimeline[] => {
  return timeline.filter((timeline) => timeline.type === 'UserCommentedOnBill')
}

export const useBillTimeline = (billId?: string) => {
  const { organization } = useUserOrganization()

  const billTimelineQueryKey = useMemo(
    () => getBillTimelineQueryKey(billId || '', organization?.id),
    [billId, organization?.id],
  )

  const { data: timelineData, ...rest } = useQuery(
    billTimelineQueryKey,
    () =>
      getBillTimeline({
        billId: billId as string,
        organizationId: organization?.id as string,
      }),
    {
      enabled: !!billId && !!organization?.id,
    },
  )

  const timelineFiltered = timelineData?.data ? filterTimeline(timelineData?.data) : []

  return {
    timeline: timelineFiltered,
    count: timelineFiltered.length,
    ...rest,
  }
}
