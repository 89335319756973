import { ButtonProtected } from '@components'
import { Button, ButtonsGroup } from '@design-system'

import React, { ReactElement, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Scope } from '../../../../../../../../enums/scope'
import { TrackingContext } from '../../../../../../../../enums/trackingContext'
import { useOrganizationSubscription } from '../../../../../../../../hooks'
import { useUmbrella } from '../../../../../../../app/umbrellas'
import { UmbrellaRoute } from '../../../../../../enums/UmbrellaRoute'
import { usePaymentMethod } from '../../../../hooks/usePaymentMethod'
import { CouponCodeForm } from '../CouponCodeForm'
import * as Styled from './styles'

interface FooterProps {
  onEditPaymentInformationClick?: () => void
  onEnterCouponCodeSubmit?: (couponCode: string) => void
}

export const Footer = ({
  onEditPaymentInformationClick,
  onEnterCouponCodeSubmit,
}: FooterProps): ReactElement | null => {
  const [isCouponCodeFormVisible, setIsCouponCodeFormVisible] = useState(false)
  const { umbrella } = useUmbrella()
  const { cardAdded } = useOrganizationSubscription()
  const { data: umbrellaPaymentMethod } = usePaymentMethod()
  const { t } = useTranslation()

  const hasPaymentMethod = cardAdded || !!umbrellaPaymentMethod
  const isFooterVisible = !!onEditPaymentInformationClick || !!onEnterCouponCodeSubmit
  const couponCodeCopy = isCouponCodeFormVisible
    ? t('billing_summary.footer.coupon_code.close')
    : t('billing_summary.footer.coupon_code.open')

  const handleCouponCodeSectionLinkClick = useCallback(() => {
    setIsCouponCodeFormVisible((prevIsCouponCodeFormVisible) => !prevIsCouponCodeFormVisible)
  }, [])

  const handleSubmit = useCallback(
    (couponCode: string) => {
      setIsCouponCodeFormVisible(false)
      onEnterCouponCodeSubmit?.(couponCode)
    },
    [onEnterCouponCodeSubmit],
  )

  if (!isFooterVisible) {
    return null
  }

  return (
    <Styled.FooterWrapper>
      <ButtonsGroup direction="column" fitToWidth>
        {!!onEditPaymentInformationClick && hasPaymentMethod && (
          <ButtonProtected
            scopes={Scope.OrganizationPaymentWrite}
            scopesCheckDisabled={!!umbrella?.id}
            trackingContext={TrackingContext.PaymentInformationEdit}
            onClick={onEditPaymentInformationClick}
            icon="paperWithPencil"
            variant="secondary"
            fullWidth
          >
            {t('billing_summary.footer.edit_payment')}
          </ButtonProtected>
        )}
        {!!onEnterCouponCodeSubmit && (
          <ButtonProtected
            scopes={Scope.OrganizationPaymentWrite}
            scopesCheckDisabled={!!umbrella?.id}
            trackingContext={TrackingContext.PaymentInformationEdit}
            onClick={handleCouponCodeSectionLinkClick}
            variant="secondary"
            fullWidth
          >
            {couponCodeCopy}
          </ButtonProtected>
        )}
        {isCouponCodeFormVisible && <CouponCodeForm onSubmit={handleSubmit} />}
        <Button to={UmbrellaRoute.Invoices} variant="secondary" fullWidth>
          {t('billing_summary.footer.view_previous_payments')}
        </Button>
      </ButtonsGroup>
    </Styled.FooterWrapper>
  )
}
