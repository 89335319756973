import React, { ReactElement } from 'react'

import { CurrencyDefaultPaymentMethodsSection } from './elements/CurrencyDefaultPaymentMethodsSection'
import { InvoiceAndProductSettingsSection } from './elements/InvoiceAndProductSettingsSection'
import { InvoiceTemplatesSection } from './elements/InvoiceTemplatesSection'
import { PaymentMethodsSection } from './elements/PaymentMethodsSection'

export const InvoiceSettings = (): ReactElement => (
  <>
    <InvoiceAndProductSettingsSection />
    <InvoiceTemplatesSection />
    <PaymentMethodsSection />
    <CurrencyDefaultPaymentMethodsSection />
  </>
)
