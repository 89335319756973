import { Flex } from '@design-system'

import { ReactElement, ReactNode } from 'react'

interface ScoutCardFooterProps {
  children: ReactNode
}

export const ScoutCardFooter = ({ children }: ScoutCardFooterProps): ReactElement => (
  <Flex alignItems="center" justifyContent="space-between">
    {children}
  </Flex>
)
