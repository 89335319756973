import { Color, NavListItemText } from '@design-system'

import React, { ReactElement, ReactNode } from 'react'

import * as Styled from './styles'

interface MenuItemTextProps {
  children: string
  badge?: ReactNode
  textColor?: Color
}

export const MenuItemText = ({ children, textColor, badge }: MenuItemTextProps): ReactElement => (
  <Styled.MenuItemTextWrapper>
    <NavListItemText color={textColor}>{children}</NavListItemText>
    {badge && <Styled.BadgeWrapper>{badge}</Styled.BadgeWrapper>}
  </Styled.MenuItemTextWrapper>
)
