import { LanguageSelect, ProcessingOverlay, SettingsSection } from '@components'
import { Badge, ButtonDropdown, Color, FormItem as FormItemDS, FormItemsGroup, NavItem } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { CustomEvent } from '../../../../../../enums/customEvent'
import { ThemeLayoutMode } from '../../../../../../types/themeLayoutMode'
import { dispatchCustomEvent } from '../../../../../../utils/customEvent'
import { useUserOrganization } from '../../../../../app/organization'
import { useCurrentUser } from '../../../../../app/user'
import { useLanguageChange } from '../../hooks/useLanguageChange'
import { useThemeLayoutModeChange } from '../../hooks/useThemeLayoutModeChange'
import * as Styled from './styles'

export const UserProfileOtherSettings = (): ReactElement => {
  const { isLoading: isLoadingOrganization } = useUserOrganization()
  const { changeLanguage } = useLanguageChange()
  const { changeThemeLayoutMode } = useThemeLayoutModeChange()
  const { user, isLoading: isLoadingCurrentUser } = useCurrentUser()
  const { t } = useTranslation()

  const isLoading = isLoadingOrganization || isLoadingCurrentUser

  const themeItems: NavItem<ThemeLayoutMode>[] = useMemo(
    () => [
      {
        id: 'light',
        value: 'light',
        children: t('settings.user.theme.light'),
      },
      {
        id: 'dark',
        value: 'dark',
        children: t('settings.user.theme.dark'),
        iconRight: <Badge>{t('beta')}</Badge>,
      },
      {
        id: 'auto',
        value: 'auto',
        children: t('settings.user.theme.auto'),
      },
    ],
    [t],
  )

  const handleThemeChange = useCallback(
    (id: string, value: ThemeLayoutMode) => {
      changeThemeLayoutMode(value)
      dispatchCustomEvent<ThemeLayoutMode>(CustomEvent.ThemeLayoutModeChanged, value)
    },
    [changeThemeLayoutMode],
  )

  return (
    <SettingsSection>
      <SettingsSection.Content title={t('settings.user.other_settings')}>
        <Styled.FormFieldsWrapper>
          {isLoading && <ProcessingOverlay color={Color.White} />}
          <FormItemsGroup>
            <FormItemDS label={t('locale.your_language')}>
              <LanguageSelect selectedId={user?.locale} onSelect={changeLanguage} withSearch={false} />
            </FormItemDS>
            <FormItemDS label={t('settings.user.theme.label')}>
              <ButtonDropdown<ThemeLayoutMode>
                variant="secondary"
                items={themeItems}
                placement="bottom-start"
                onSelect={handleThemeChange}
                selectedId={user?.themeMode || ''}
                fullWidth
              />
            </FormItemDS>
          </FormItemsGroup>
        </Styled.FormFieldsWrapper>
      </SettingsSection.Content>
    </SettingsSection>
  )
}
