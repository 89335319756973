import { ExportType } from '@components'
import { getCurrentLocale, Locale } from '@design-system'

import { apiUrl, getAccessToken } from '../../../utils'
import { FilterOptions } from '../elements/ExportReport'
import { ReportsApiUrl } from '../enums/reportsApiUrl'
import { getRegnskabBasisRequestQueryParams } from './getRegnskabBasisRequestQueryParams'
import { getReportRequestQueryParams } from './getReportRequestQueryParams'

interface CommonParams {
  accessToken: string | undefined
  acceptLanguage: Locale
}

export const getExportStatementDownloadUrl = (
  reportUrl: ReportsApiUrl,
  organizationId: string,
  type: ExportType,
  options: FilterOptions | undefined,
): string => {
  const commonParams: CommonParams = {
    accessToken: getAccessToken(),
    acceptLanguage: getCurrentLocale(),
  }

  const queryParams = getReportRequestQueryParams(reportUrl, options, commonParams)
  let downloadUrl: string

  if (type === ExportType.RegnskabBasis) {
    const regnskabBasisParams = getRegnskabBasisRequestQueryParams(options, commonParams)
    downloadUrl = `${apiUrl}/v2/organizations/${organizationId}/danishBasicAccountingReport.csv?${regnskabBasisParams}`
  } else {
    downloadUrl = `${apiUrl}/v2/organizations/${organizationId}/${reportUrl}.${type}?${queryParams}`
  }

  return downloadUrl
}
