import { Button, ButtonsGroup } from '@design-system'

import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

import { SpecificState } from '../../types'
import { selectSelectedBankLines } from '../selectors'
import * as Styled from './styles'
import { SelectedBankIds } from './types'

interface SelectionPanelActionsProps {
  isDeleteMode: boolean
  onDelete?: () => void
  onEditFinish: () => void
  onSelectionChange: (selectedIds: string[]) => void
  selectedIds: SelectedBankIds
}

export const SelectionPanelActions = ({
  isDeleteMode,
  selectedIds,
  onSelectionChange,
  onDelete,
  onEditFinish,
}: SelectionPanelActionsProps) => {
  const { t } = useTranslation()
  const { bankLineMatchIds, validSelectedIds, allSelected } =
    useSelector((state: SpecificState) => {
      return selectSelectedBankLines(state, selectedIds, isDeleteMode)
    }) || []
  const canDelete = !!onDelete
  const isDeleteButtonDisabled = bankLineMatchIds.length === 0 || selectedIds.length === 0

  const handleCheckboxChange = useCallback(() => {
    onSelectionChange(allSelected ? [] : bankLineMatchIds)
  }, [allSelected, bankLineMatchIds, onSelectionChange])

  return (
    <Styled.SelectionPanelWrapper>
      <Styled.SelectionPanelCheckbox checked={allSelected} onChange={handleCheckboxChange} />
      <Styled.SelectionPanelText>
        {t('bankreconciliation.matchgroups.selection.totalchosen', { count: validSelectedIds.length })}
      </Styled.SelectionPanelText>
      <Styled.SelectionPanelButtonWrapper>
        <ButtonsGroup>
          {canDelete && (
            <Button danger disabled={isDeleteButtonDisabled} onClick={onDelete} size="m" type="button">
              {t('bankreconciliation.matchgroups.selection.delete')}
            </Button>
          )}
          <Button onClick={onEditFinish} size="m" type="button">
            {t('bankreconciliation.matchgroups.selection.done')}
          </Button>
        </ButtonsGroup>
      </Styled.SelectionPanelButtonWrapper>
    </Styled.SelectionPanelWrapper>
  )
}
