import { getCurrentLocale } from '@design-system'

import { useCallback } from 'react'

import { BillFormFieldsChangeSource } from '../../../../../enums/billFormFieldsChangeSource'
import { useFormContext } from '../../../../../hooks/useForm'
import { useAccounts } from '../../../../app/accounts'
import { useUserOrganization } from '../../../../app/organization'
import { useTaxRates } from '../../../../app/taxrates'
import { Contact } from '../../../../contacts/types/contact'
import { useBillForm } from '../contexts/billFormContext'
import { autoCompleteBillForm } from '../utils/autoCompleteBillForm'
import { BillForm, BillFormSchema, getDefaultValues } from '../utils/formData'
import { getAutoCompletedFormWithVendor } from '../utils/getAutoCompletedFormWithVendor'
import { getShouldOverridenParamChange } from '../utils/getShouldOverridenParamChange'
import { useTrackAutocompletedFields } from '../utils/trackAutocompletedFields'

export const useVendorAutoComplete = () => {
  const { organization } = useUserOrganization()
  const locale = getCurrentLocale()
  const { getValues, reset: resetForm, setValue } = useFormContext<BillFormSchema>()
  const { accounts = [] } = useAccounts()
  const { taxRates = [] } = useTaxRates()
  const { trackAutocompletedFields } = useTrackAutocompletedFields()
  const { formFieldsChangeSource, setFormFieldsChangeSource } = useBillForm()

  const autoCompleteWithVendorData = useCallback(
    async (vendor: Partial<Contact> | undefined, source: BillFormFieldsChangeSource) => {
      const billForm = getValues()
      let billFormUpdated: BillForm

      if (!vendor) {
        const defaultValuesForm = getDefaultValues()
        billFormUpdated = autoCompleteBillForm({
          billFormDefault: billForm,
          billFormOverrides: { ...defaultValuesForm, autoCompletedFields: billForm.autoCompletedFields },
          baseCurrencyId: organization?.baseCurrencyId,
          shouldOverridenParamChangeCallback: getShouldOverridenParamChange({ shouldChangeExpenseParams: true }),
        })
      } else {
        billFormUpdated = await getAutoCompletedFormWithVendor({
          accounts,
          baseCurrencyId: organization?.baseCurrencyId,
          billForm,
          countryId: organization?.countryId,
          chartOfAccountId: organization?.chartOfAccountId,
          isVatExempted: organization?.isVatExempted,
          locale,
          taxRates,
          vendor,
        })
      }

      if (billFormUpdated?.billLines) {
        for (const [i, line] of billFormUpdated?.billLines.entries()) {
          trackAutocompletedFields({
            billLines: billForm?.billLines?.[i],
            updatedBillLines: line,
            context: source,
            formFieldsChangeSource,
            setFormFieldsChangeSource,
          })
        }
      }

      resetForm(billFormUpdated, { keepDefaultValues: true })
      // Wait one frame before setting autoCompletedFields
      window.requestAnimationFrame(() => {
        setValue('autoCompletedFields', billFormUpdated.autoCompletedFields)
      })
    },
    [
      accounts,
      formFieldsChangeSource,
      getValues,
      locale,
      organization?.baseCurrencyId,
      organization?.chartOfAccountId,
      organization?.countryId,
      organization?.isVatExempted,
      resetForm,
      setValue,
      setFormFieldsChangeSource,
      taxRates,
      trackAutocompletedFields,
    ],
  )

  return { autoCompleteWithVendorData }
}
