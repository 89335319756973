import {
  NavListItemWithBlockDescription,
  NavListItemWithBlockDescriptionValue,
  Select,
  SelectProps,
} from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../../../../../app/organization'
import { fetchVatRateTemplates } from '../../../../../query-api'
import { getVatRateTemplatesItems } from './utils/getVatRateTemplatesItems'

interface VatRatesTemplatesSelectProps
  extends Omit<SelectProps<NavListItemWithBlockDescriptionValue>, 'items' | 'onSelect'> {
  onSelect?: (id?: string) => void
}

export const VatRatesTemplatesSelect = ({ onSelect, ...selectProps }: VatRatesTemplatesSelectProps): ReactElement => {
  const { organization } = useUserOrganization()
  const { data: templates } = useQuery(
    [QueryKeys.VatRatesTemplates, organization?.id],
    () => fetchVatRateTemplates({ organizationId: organization?.id as string }),
    {
      enabled: !!organization?.id,
    },
  )

  const items = useMemo(
    () => (templates?.taxRateTemplates ? getVatRateTemplatesItems(templates.taxRateTemplates) : []),
    [templates?.taxRateTemplates],
  )

  const handleSelect = useCallback(
    (id?: string) => {
      onSelect?.(id)
    },
    [onSelect],
  )

  return (
    <Select
      {...selectProps}
      items={items}
      dropdownItemRender={(props) => <NavListItemWithBlockDescription {...props} />}
      dropdownSize="fitTrigger"
      onSelect={handleSelect}
    />
  )
}
