import { NavItem, Select } from '@design-system'

import React, { ReactElement, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormContext } from '../../../../../../../../hooks'
import { AccountingBasis } from '../../../../../../../app/organization/types/accountingBasis'
import { OrganizationAccountingFormSchema } from '../../../../utils/organizationAccountingSettingsFormData'

export const AccountingBasisFormItem = (): ReactElement => {
  const { FormItem } = useFormContext<OrganizationAccountingFormSchema>()
  const { t } = useTranslation()

  const items: NavItem<AccountingBasis>[] = useMemo(
    () => [
      {
        id: 'accrual',
        value: AccountingBasis.Accrual,
        children: t('settings.organization.accounting.accounting_basis.accrual'),
      },
      {
        id: 'cash',
        value: AccountingBasis.Cash,
        children: t('settings.organization.accounting.accounting_basis.cash'),
      },
    ],
    [t],
  )

  return (
    <FormItem
      name="accountingBasis"
      label={t('settings.organization.accounting.accounting_basis')}
      render={({ field: { onChange, value, ...rest } }) => (
        <Select<AccountingBasis>
          dropdownSize="fitTrigger"
          items={items}
          onSelect={onChange}
          selectedId={value}
          {...rest}
        />
      )}
    />
  )
}
