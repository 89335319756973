import { notify } from '@design-system'

import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { SubscriptionPeriod } from 'src/modules/app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from 'src/modules/app/organization/enums/subscriptionPlan'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { QueryKeys } from '../../../enums/queryKeys'
import { checkCouponEligibility } from '../routes/OrganizationSubscriptionSettings/services/query-api'
import { getPriceAccountedForCoupons } from '../routes/OrganizationSubscriptionSettings/utils'

interface UseCouponEligibilityProps {
  organizationId: string
  selectedPlan: SubscriptionPlan
  selectedPeriod: SubscriptionPeriod
  confirmPrice: number
  recurringPrice: number
  signupCouponPlan?: SubscriptionPlan
}

export const useCouponEligibility = ({
  organizationId,
  selectedPlan,
  selectedPeriod,
  confirmPrice,
  recurringPrice,
  signupCouponPlan,
}: UseCouponEligibilityProps) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [discountPercent, setDiscountPercent] = useState<number>()

  const onCouponEligible = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.OrganizationSubscription)

    notify({
      id: NotificationKeys.OrganizationSubscriptionCouponRedeem,
      message: t('organization_subscription.coupon.success'),
      variant: 'success',
    })
  }, [queryClient, t])

  const onCouponIneligible = useCallback(() => {
    notify({
      id: NotificationKeys.OrganizationSubscriptionCouponRedeem,
      message: t('organization_subscription.coupon.error'),
      variant: 'error',
    })
    setDiscountPercent(0)
  }, [t])

  const checkCouponEligibilityMutation = useMutation(
    (coupon: string) =>
      checkCouponEligibility(organizationId, coupon, {
        period: selectedPeriod,
        plan: selectedPlan,
      }),
    {
      onSuccess: (data) => {
        setDiscountPercent(data.Amount * 100)
        onCouponEligible()
      },
      onError: onCouponIneligible,
    },
  )

  const handleCouponEligibilityCheck = useCallback(
    (coupon: string) => {
      checkCouponEligibilityMutation.mutate(coupon)
    },
    [checkCouponEligibilityMutation],
  )

  const shouldApplyCoupon = useCallback(() => {
    if (signupCouponPlan) {
      return !!discountPercent && signupCouponPlan === selectedPlan
    }

    return !!discountPercent
  }, [signupCouponPlan, discountPercent, selectedPlan])

  const calculatePriceWithCoupon = useCallback(
    (initialPrice: number) => {
      if (!discountPercent) {
        return initialPrice
      }

      return getPriceAccountedForCoupons(initialPrice, shouldApplyCoupon(), discountPercent)
    },
    [discountPercent, shouldApplyCoupon],
  )

  const totalPrice = useMemo(() => {
    return calculatePriceWithCoupon(confirmPrice)
  }, [calculatePriceWithCoupon, confirmPrice])

  const totalRecurringPrice = useMemo(() => {
    return calculatePriceWithCoupon(recurringPrice)
  }, [calculatePriceWithCoupon, recurringPrice])

  return {
    handleCouponEligibilityCheck,
    calculatePriceWithCoupon,
    setDiscountPercent,
    totalPrice,
    totalRecurringPrice,
  }
}
