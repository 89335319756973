import { notify } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { NotificationKeys } from '../../../../enums/notificationKeys'
import { SubmitLoginResponseData } from '../../../../query-api/login-query'
import { SubmitSignupResponseData } from '../../../../query-api/signup-query'
import { setAccessToken } from '../../../../utils'
import { useOrganizationInvitation } from '../../context/organizationInvitationContext'
import { acceptOrganizationInvitation } from '../../query-api'
import { Layout } from '../Layout'
import { LoginForm } from '../LoginForm'
import { SignupForm } from '../SignupForm'
import * as Styled from './styles'

interface AcceptOrganizationInvitationProps {
  existingUser: boolean
  invitationEmail: string
  invitationToken: string
  organizationName: string
  organizationUrl: string
}

export const AcceptOrganizationInvitation = ({
  existingUser,
  invitationEmail,
  invitationToken,
  organizationName,
  organizationUrl,
}: AcceptOrganizationInvitationProps): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { authorizeEmberUser, registerEmberOrganization } = useOrganizationInvitation()

  const handleError = useCallback(() => {
    notify({ id: NotificationKeys.LoginError, message: t('error_saving'), variant: 'error' })
  }, [t])

  const acceptInvitationOnLogin = useCallback(
    async ({ meta: { accessToken } }: SubmitLoginResponseData) => {
      // Authorize the current user by access token
      setAccessToken(accessToken)
      authorizeEmberUser(accessToken)

      // Accept the invitation by invitation's token
      await acceptOrganizationInvitation(invitationToken)

      // Register the current user organization in the Ember
      registerEmberOrganization({
        organizationUrl,
        onSuccess: () => {
          navigate(EmberRoute.Dashboard, [organizationUrl])
        },
        onError: handleError,
      })
    },
    [organizationUrl, invitationToken, authorizeEmberUser, navigate, registerEmberOrganization, handleError],
  )

  const acceptInvitationOnSignup = useCallback(
    async ({ meta: { accessToken } }: SubmitSignupResponseData) => {
      // Authorize the current user by access token
      setAccessToken(accessToken)
      authorizeEmberUser(accessToken)

      // Register the current user organization in the Ember
      registerEmberOrganization({
        organizationUrl,
        onSuccess: () => {
          navigate(EmberRoute.Dashboard, [organizationUrl])
        },
        onError: handleError,
      })
    },
    [organizationUrl, authorizeEmberUser, navigate, registerEmberOrganization, handleError],
  )

  return (
    <Layout withFooter>
      <Styled.AcceptInvitationFormWrapper>
        {existingUser ? (
          <LoginForm
            invitationEmail={invitationEmail}
            onSuccess={acceptInvitationOnLogin}
            onError={handleError}
            title={t('organization_invitation.form.title', { organizationName })}
          />
        ) : (
          <SignupForm
            invitationEmail={invitationEmail}
            invitationToken={invitationToken}
            onSuccess={acceptInvitationOnSignup}
            title={t('organization_invitation.form.title', { organizationName })}
          />
        )}
      </Styled.AcceptInvitationFormWrapper>
    </Layout>
  )
}
