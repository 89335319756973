import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUmbrella } from '../../../../app/umbrellas'
import { fetchUmbrellaRoles } from '../../../../app/umbrellas/query-api'
import { UmbrellaRole } from '../../../../app/umbrellas/types/umbrellaRole'

interface UseUmbrellaRolesResponse {
  data: UmbrellaRole[]
  isLoading: boolean
}

export const useUmbrellaRoles = (): UseUmbrellaRolesResponse => {
  const { umbrella } = useUmbrella()
  const umbrellaId = umbrella?.id || ''

  const {
    data: rolesData,
    isIdle,
    isLoading,
  } = useQuery([QueryKeys.UmbrellaRoles], () => fetchUmbrellaRoles(umbrellaId), {
    enabled: !!umbrellaId,
  })

  return {
    data: rolesData?.data || [],
    isLoading: isIdle || isLoading,
  }
}
