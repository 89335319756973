import { getCurrentLocale } from '@design-system'

import { Metable } from '../../../../types/metable'
import { getRequest } from '../../../../utils'

export interface DaybookConsequencesReportOptions {
  organizationId: string
  daybookId: string
  showAllAccounts?: boolean
  period?: string
}

export interface DaybookConsequencesReportResponse extends Metable {
  draftConsequences: string
}

export const fetchDaybookConsequencesReport = ({
  organizationId,
  daybookId,
  showAllAccounts,
  period,
}: DaybookConsequencesReportOptions): Promise<DaybookConsequencesReportResponse> => {
  const locale = getCurrentLocale()
  return getRequest(
    `/v2/organizations/${organizationId}/daybooks/${daybookId}/draft-consequences?period=${period}&hideNotModified=${showAllAccounts}`,
    {
      'accept-language': locale,
    },
  )
}
