import { getMenuItem, MenuItemExtended } from './menuItems'

export const umbrellaMenuItems: MenuItemExtended[] = [
  getMenuItem('customers'),
  getMenuItem('users'),
  getMenuItem('roles'),
  getMenuItem('inbox'),
  getMenuItem('umbrellaProfile'),
  getMenuItem('umbrellaSubscriptions'),
]
