import { TFunction } from 'i18next'
import { array, bool, object, SchemaOf, string } from 'yup'

import { ContactPerson } from '../../../../contacts/types/contactPerson'
import { doesStringContainHtml } from './doesStringContainHtml'

export interface EmailInvoiceForm {
  recipients: ContactPerson[] | undefined
  subject: string | undefined
  message: string | undefined
  requestCopy: boolean
}

export type SendEmailInvoiceFormSchema = SchemaOf<EmailInvoiceForm>

export type GetEmailInvoiceFormDefaultValuesProps = {
  contactId?: string
  contactPersons?: ContactPerson[]
  message?: string
  requestCopy: boolean
  selectedContactPersonId?: string
  subject?: string
}

export const getEmailInvoiceFormDefaultValues = ({
  contactId,
  contactPersons,
  message,
  requestCopy,
  selectedContactPersonId,
  subject,
}: GetEmailInvoiceFormDefaultValuesProps) => {
  const isSingleContact = contactPersons?.length === 1 && contactPersons[0].contactId === contactId
  const selectedContact = contactPersons?.find((contactPerson) => contactPerson.id === selectedContactPersonId)
  const multipleContactsDefault = selectedContact ? [selectedContact] : []

  return {
    recipients: isSingleContact ? contactPersons : multipleContactsDefault,
    subject,
    message,
    requestCopy,
  }
}

export const getEmailInvoiceValidationSchema = (t: TFunction): SendEmailInvoiceFormSchema => {
  return object({
    recipients: array()
      .of(
        object({
          id: string(),
          contactId: string(),
          isPrimary: bool(),
          name: string(),
          email: string(),
        }),
      )
      .min(1, t('modal.send_invoice.form.recipient.min_error'))
      .optional(),
    subject: string().min(1, t('modal.send_invoice.missing.subject')),
    message: string()
      .min(1, t('modal.send_invoice.missing.body'))
      .test(
        'email-doesnt-contain-html',
        t('modal.send_invoice.email_contains_html_error'),
        (value) => !doesStringContainHtml(value),
      ),
    requestCopy: bool().default(false),
  })
}
