import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { getNextVoucherNumber } from '../../../query-api'

export const useNextVoucherNumber = () => {
  const { organization } = useUserOrganization()

  const { data, ...rest } = useQuery(
    [QueryKeys.NextVoucherNo, organization?.id],
    () => getNextVoucherNumber(organization?.id as string),
    {
      enabled: !!organization?.id,
      cacheTime: 0,
    },
  )

  return {
    nextVoucherNumber: data?.nextVoucherNo,
    ...rest,
  }
}
