import { PurchaseCardsGroup } from '@components'

import React, { ReactElement } from 'react'

import { SubscriptionPeriod } from '../../../../../app/organization/enums/subscriptionPeriod'
import { SubscriptionPlan } from '../../../../../app/organization/enums/subscriptionPlan'
import { PlanSelectionContextProvider } from './contexts/planSelectionContext'
import { BasicPlanCard } from './elements/BasicPlanCard'
import { CompletePlanCard } from './elements/CompletePlanCard'
import { FreePlanCard } from './elements/FreePlanCard'
import { PlusPlanCard } from './elements/PlusPlanCard'
import { useShouldDisplayPlan } from './hooks/useShouldDisplayPlan'
import * as Styled from './styles'

interface SubscriptionPlansProps {
  onPlanSelect: (plan: SubscriptionPlan, period?: SubscriptionPeriod) => void
}

export const SubscriptionPlans = ({ onPlanSelect }: SubscriptionPlansProps): ReactElement => {
  const { shouldDisplayFreePlan, shouldDisplayBasicPlan, shouldDisplayPlusPlan, shouldDisplayCompletePlan } =
    useShouldDisplayPlan()

  return (
    <PlanSelectionContextProvider onPlanSelect={onPlanSelect}>
      <Styled.SubscriptionPlansWrapper>
        <PurchaseCardsGroup>
          {shouldDisplayBasicPlan && <BasicPlanCard plan={SubscriptionPlan.BasicPaid} />}
          {shouldDisplayPlusPlan && <PlusPlanCard plan={SubscriptionPlan.Plus} />}
          {shouldDisplayCompletePlan && <CompletePlanCard plan={SubscriptionPlan.Complete} />}
        </PurchaseCardsGroup>
        {shouldDisplayFreePlan && <FreePlanCard plan={SubscriptionPlan.Free} />}
      </Styled.SubscriptionPlansWrapper>
    </PlanSelectionContextProvider>
  )
}
