import { AppBuildTheme } from '../types/appBuildTheme'

const AGERAS_APP_URL = window.ENV?.AGERAS_APP_URL || process.env.AGERAS_APP_URL
const BILLY_APP_URL = window.ENV?.BILLY_APP_URL || process.env.BILLY_APP_URL

export const useRedirectToAppBuildTheme = () => {
  const redirectToAppBuildTheme = (theme: AppBuildTheme, organizationUrl: string) => {
    const redirectUrl =
      theme === 'billy' ? `${BILLY_APP_URL}/${organizationUrl}` : `${AGERAS_APP_URL}/${organizationUrl}`

    window.location.replace(redirectUrl)
  }

  return {
    redirectToAppBuildTheme,
  }
}
