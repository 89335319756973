import { useMutation } from 'react-query'

import { APIError } from '../../../../../utils'
import { deleteOrganizationUmbrellaMember, useUserOrganization } from '../../../../app/organization'

interface UseDeleteBookkeeperArgs {
  onError?: (error?: APIError) => void
  onSuccess?: () => void
}

export const useDeleteBookkeeper = ({ onError, onSuccess }: UseDeleteBookkeeperArgs = {}) => {
  const { organization } = useUserOrganization()
  const { isLoading: isSaving, mutate: deleteBookkeeper } = useMutation(
    (umbrellaId: string) => deleteOrganizationUmbrellaMember(organization?.id || '', umbrellaId),
    {
      onError,
      onSuccess,
    },
  )

  return {
    deleteBookkeeper,
    isSaving,
  }
}
