import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { Provider as ReduxProvider } from 'react-redux'

import { store } from '../../modules'
import { PrismicDocument } from '../../modules/prismic'

class PrismicWebComponent extends DOMModel {
  @byAttrVal type: string
  @byAttrVal uid: string
  @byAttrVal tags: string
  @registerEvent('getMaxSteps') getMaxSteps: Function
}

type PrismicProps = {
  type: string
  uid: string
  tags: string
  onGetMaxSteps: Function
}

function PrismicModule({ type, uid, tags, onGetMaxSteps }: PrismicProps): ReactElement {
  const t = tags.split(',')
  return (
    <ReduxProvider store={store}>
      <PrismicDocument
        type={type}
        tags={t}
        uid={uid}
        getMaxStep={(steps: number) => onGetMaxSteps({ detail: steps })}
      />
    </ReduxProvider>
  )
}

const PrismicCustomElement = createCustomElement(PrismicModule, PrismicWebComponent, 'element')

customElements.get('prismic-module') || customElements.define('prismic-module', PrismicCustomElement)

export default PrismicCustomElement
