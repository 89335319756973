import { isBookkeepingHeroAllowed } from '@components'
import { AttachmentFile } from '@design-system'

import { BohrScanResult } from '../../../../../types/bohrScanResult'
import { getLocalDateFromDateString } from '../../../../../utils/getLocalDateFromDateString'
import { findContactsWithBohr } from '../../../../app/contacts'
import { ChartOfAccountId } from '../../../../app/organization/enums/chartOfAccountId'
import { Contact } from '../../../../contacts/types/contact'
import { BillFormPartial } from './formData'
import { getBookkeepingTagsFromTagScores } from './getBookkeepingTagsFromTagScores'

const TAG_SUGGESTIONS_AMOUNT = 3

interface GetBillFormFromBohrDataProps {
  attachmentFile?: AttachmentFile
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  locale: string
  organizationId: string
  withBookkeepingSuggestions?: boolean
}

export const getBillFormFromBohrData = async ({
  attachmentFile,
  chartOfAccountId,
  countryId,
  locale,
  organizationId,
  withBookkeepingSuggestions = true,
}: GetBillFormFromBohrDataProps): Promise<BillFormPartial> => {
  const {
    document_currency: currencyIdLegacy,
    document_due_date: dueDateLegacy,
    document_issue_date: issueDateLegacy,
    document_total_incl_vat: inclVatAmountLegacy,
    entities,
    supplier: supplierLegacy,
    tag_scores: tagScores,
  }: BohrScanResult = JSON.parse(attachmentFile?.dataJson || '{}')
  const {
    currency: currencyData,
    due_date: dueDateData,
    issue_date: issueDateData,
    total_incl_vat: totalInclVatData,
    vendor: vendorData,
  } = entities || {}
  const currencyId = currencyData?.content || currencyIdLegacy
  const dueDate = dueDateData?.content || dueDateLegacy
  const billDate = issueDateData?.content || issueDateLegacy
  const inclVatAmount = totalInclVatData?.content || inclVatAmountLegacy
  const supplier = vendorData?.content || supplierLegacy

  const getVendorData = async (): Promise<Partial<Contact> | undefined> => {
    const registrationNo = supplier?.vat_no
    const name = supplier?.name

    if (registrationNo && name) {
      try {
        const vendors = await findContactsWithBohr(organizationId, name, registrationNo)

        if (vendors.length) {
          return vendors[0]
        }
      } catch (error) {
        console.error(error)
        return undefined
      }
    }

    return {
      ...(supplier?.registration_no ? { registrationNo: supplier.registration_no } : {}),
      ...(supplier?.vat_no ? { vatNo: supplier.vat_no } : {}),
      ...(supplier?.country ? { countryId: supplier.country } : {}),
      ...(supplier?.name ? { name: supplier.name } : {}),
      ...(supplier?.address_city ? { cityText: supplier.address_city } : {}),
      ...(supplier?.address_street ? { street: supplier.address_street } : {}),
      ...(supplier?.phone ? { phone: supplier.phone } : {}),
      ...(supplier?.address_zip ? { zipcodeText: supplier.address_zip } : {}),
    }
  }

  const vendor = await getVendorData()

  const tagScoresLimited = tagScores ? tagScores.slice(0, TAG_SUGGESTIONS_AMOUNT) : undefined
  const isBookkeepingTagSuggestionsEnabled =
    withBookkeepingSuggestions && !!chartOfAccountId && isBookkeepingHeroAllowed(chartOfAccountId)
  const bookkeepingTagSuggestions =
    isBookkeepingTagSuggestionsEnabled && tagScoresLimited && tagScoresLimited.length
      ? await getBookkeepingTagsFromTagScores(tagScoresLimited, locale, chartOfAccountId, countryId)
      : undefined

  return {
    ...(billDate ? { billDate: getLocalDateFromDateString(billDate) } : {}),
    ...(bookkeepingTagSuggestions ? { bookkeepingTagSuggestions } : {}),
    ...(currencyId ? { currencyId } : {}),
    ...(dueDate ? { dueDate: getLocalDateFromDateString(dueDate) } : {}),
    ...(vendor ? { vendor } : {}),
    billLines: inclVatAmount ? [{ inclVatAmount }] : [],
  }
}
