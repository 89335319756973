import { DateFormatter } from '@components'
import {
  getFileThumbnail,
  ModuleLayout,
  SidePanelContextProvider,
  Text,
  withModalConditionalRender,
} from '@design-system'

import { ChangeEvent, Fragment, ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateEffect } from 'react-use'

import { TrackingContext } from '../../../../enums/trackingContext'
import { getLocalDateFromDateString } from '../../../../utils/getLocalDateFromDateString'
import { AccountsContextProvider } from '../../../app/accounts'
import { useUserOrganization } from '../../../app/organization'
import { Receipt } from '../../elements/Receipt'
import { ReceiptButton } from '../../elements/ReceiptButton'
import { ReceiptDropzoneFullScreen } from '../../elements/ReceiptDropzoneFullScreen'
import { ReceiptModal as ReceiptModalComponent } from '../../elements/ReceiptModal'
import { ReceiptsActions } from '../../elements/ReceiptsActions'
import { ReceiptsEmptyState } from '../../elements/ReceiptsEmptyState/ReceiptsEmptyState'
import { ReceiptsListSideActions } from '../../elements/ReceiptsListSideActions'
import { ReceiptsPagination } from '../../elements/ReceiptsPagination'
import { ReceiptsSidePanel } from '../../elements/ReceiptsSidePanel'
import { ScoutConnectedModal } from '../../elements/ScoutConnectedModal'
import { SkeletonReceipt } from '../../elements/SkeletonReceipt'
import { useReceipts } from '../../hooks/useReceipts'
import { useReceiptsFilters } from '../../hooks/useReceiptsFilters'
import { getReceiptModalId } from '../../utils/getReceiptModalId'
import { getReceiptRightInfo } from '../../utils/getReceiptRightInfo'
import * as Styled from './styles'
import { getSkeletonData } from './utils/getSkeletonData'

const ReceiptModal = withModalConditionalRender(ReceiptModalComponent)

export const ReceiptsList = (): ReactElement => {
  const receiptsWrapperRef = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()
  const { organization } = useUserOrganization()

  const [areAllSelectedReceipts, setAreAllSelectedReceipts] = useState(false)
  const [selectedReceiptsIds, setSelectedReceiptsIds] = useState<string[]>([])
  const { attachments: receiptsList, isLoading } = useReceipts()
  const [filters] = useReceiptsFilters()

  // Remove selection when filters or settings are changed
  useUpdateEffect(() => {
    setSelectedReceiptsIds([])
    setAreAllSelectedReceipts(false)
  }, [filters])

  const skeletonItems = useMemo(() => (isLoading ? getSkeletonData() : []), [isLoading])
  const isEmpty = !isLoading && !receiptsList.length
  const isSearching = !!filters.searchQuery

  const handleSelectAllAction = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setAreAllSelectedReceipts(true)

      if (event.target.checked) {
        setSelectedReceiptsIds(receiptsList.map(({ id }) => id))
      } else {
        setSelectedReceiptsIds([])
      }
    },
    [receiptsList],
  )

  const handleReceiptSelect = useCallback((id: string, isSelected: boolean) => {
    if (isSelected) {
      setAreAllSelectedReceipts(false)
      setSelectedReceiptsIds((prevSelectedReceiptsIds) => [...prevSelectedReceiptsIds, id])
    } else {
      setSelectedReceiptsIds((prevSelectedReceiptsIds) =>
        prevSelectedReceiptsIds.filter((selectedId) => selectedId !== id),
      )
    }
  }, [])

  return (
    <SidePanelContextProvider>
      <ModuleLayout
        sideActions={
          <ReceiptsListSideActions
            selectedReceiptsIds={selectedReceiptsIds}
            setSelectedReceiptsIds={setSelectedReceiptsIds}
          />
        }
        title={t('receipts.title')}
      >
        <Styled.ReceiptsWrapper ref={receiptsWrapperRef}>
          {isEmpty && !isSearching && <ReceiptsEmptyState type={filters?.type} />}
          {(!isEmpty || isSearching) && (
            <ReceiptsActions
              areAllReceiptsSelected={areAllSelectedReceipts}
              onSelectAllActionChange={handleSelectAllAction}
              selectedReceiptsCount={selectedReceiptsIds.length}
            />
          )}
          <Styled.ReceiptsList>
            {isLoading
              ? skeletonItems.map((_, index) => (
                  <Styled.SkeletonReceiptWrapper key={index}>
                    <SkeletonReceipt />
                  </Styled.SkeletonReceiptWrapper>
                ))
              : !isEmpty &&
                receiptsList.map((receipt) => {
                  const { amount, createdTime, documentDate, file, id, supplier, type } = receipt
                  const fileName = file.fileName || undefined
                  const leftSideDate = documentDate || createdTime
                  const [dateParsed] = leftSideDate ? leftSideDate.split('T') : []
                  const date = getLocalDateFromDateString(dateParsed)

                  return (
                    <Fragment key={id}>
                      <Receipt
                        footer={<ReceiptButton attachment={receipt} />}
                        id={id}
                        isSelected={selectedReceiptsIds.includes(id)}
                        leftSide={date ? <DateFormatter value={date} /> : '-'}
                        onSelect={handleReceiptSelect}
                        fileName={fileName}
                        fileSrc={getFileThumbnail(file, 'medium')}
                        rightSide={getReceiptRightInfo({ amount })}
                        title={supplier || file.fileName}
                        type={type}
                      />
                      <ReceiptModal id={getReceiptModalId(id)} receipt={receipt} title={supplier} />
                    </Fragment>
                  )
                })}
          </Styled.ReceiptsList>
          {!isEmpty && <ReceiptsPagination listRef={receiptsWrapperRef} isLoading={isLoading} />}
          {isSearching && isEmpty && <Text alignment="center">{t('search.no_results')}</Text>}
        </Styled.ReceiptsWrapper>
        {!isEmpty && <ReceiptDropzoneFullScreen trackingContext={TrackingContext.Uploads} />}
        <AccountsContextProvider organizationId={organization?.id}>
          <ReceiptsSidePanel />
        </AccountsContextProvider>
      </ModuleLayout>
      <ScoutConnectedModal />
    </SidePanelContextProvider>
  )
}
