import { Translate } from '@components'
import { Button, Text } from '@design-system'

import React, { ReactElement } from 'react'
import { Trans } from 'react-i18next'

import * as Styled from './styles'

interface UpsellWidgetBigProps {
  onButtonClick?: () => void
}

export const UpsellWidgetSmall = ({ onButtonClick }: UpsellWidgetBigProps): ReactElement => (
  <Styled.UpsellWidgetSmallWrapper>
    <Styled.ContentWrapper>
      <Text alignment="center" weight="bold">
        <Trans i18nKey="menu.upsell_widget.on_free_plan">
          You are
          <br />
          on a free plan
        </Trans>
      </Text>
    </Styled.ContentWrapper>
    <Button onClick={onButtonClick}>
      <Translate value="menu.upsell_widget.button_cta" />
    </Button>
  </Styled.UpsellWidgetSmallWrapper>
)
