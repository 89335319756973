import cc from 'classcat'
import React, {
  ButtonHTMLAttributes,
  ElementType,
  forwardRef,
  HTMLAttributeAnchorTarget,
  MouseEvent,
  ReactElement,
  ReactNode,
  Ref,
  useCallback,
  useMemo,
} from 'react'
import { Link } from 'react-router-dom'

import { Color } from '../../enums/color'
import { Asable } from '../../types/asable'
import { LinkProps } from '../../types/linkProps'
import { IconName } from '../Icon'
import { ButtonIcon, ButtonIconProps } from './ButtonIcon'
import * as Styled from './styles'
import { Size, Variant } from './types'

export type ButtonSize = Size
export type ButtonVariant = Variant

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement>, Asable {
  active?: boolean
  centered?: boolean
  children?: ReactNode
  danger?: boolean
  disabled?: boolean
  focused?: boolean
  forceSquareRatio?: boolean
  fullRound?: boolean
  fullWidth?: boolean
  href?: string
  icon?: IconName | ReactElement
  iconColor?: Color
  iconRight?: IconName | ReactElement
  iconRightColor?: Color
  loading?: boolean
  size?: Size
  skipLeftRounding?: boolean
  skipRightRounding?: boolean
  success?: boolean
  target?: HTMLAttributeAnchorTarget
  to?: string
  variant?: Variant
}

export const Button = forwardRef(
  (
    {
      as,
      active,
      centered = true,
      children,
      danger,
      disabled,
      focused,
      forceSquareRatio,
      href,
      icon,
      iconColor,
      iconRight,
      iconRightColor,
      loading,
      onClick,
      size = 'l',
      skipLeftRounding,
      skipRightRounding,
      success,
      target,
      to,
      type = 'button',
      variant = 'primary',
      ...rest
    }: ButtonProps,
    forwardedRef: Ref<HTMLButtonElement>,
  ): ReactElement => {
    const buttonIconProps = useMemo(
      (): Pick<ButtonIconProps, 'size' | 'withIconMargin'> => ({
        size,
        withIconMargin: !!children,
      }),
      [children, size],
    )

    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        if (loading || disabled) {
          event.preventDefault()
          return
        }

        onClick?.(event)
      },
      [disabled, loading, onClick],
    )

    const linkProps: LinkProps | undefined = useMemo(() => {
      if (href) {
        return { as: 'a' as ElementType, href, target }
      }

      if (to) {
        return { as: Link as ElementType, to }
      }

      return as ? { as } : undefined
    }, [href, to, as, target])

    return (
      <Styled.Button
        {...rest}
        {...linkProps}
        active={active}
        className={cc(['ds-button', rest.className, variant, { active, disabled, focused, danger, success }])}
        centered={centered}
        danger={danger}
        disabled={disabled}
        focused={focused}
        forceSquareRatio={forceSquareRatio || !children}
        loading={loading}
        onClick={handleClick}
        ref={forwardedRef}
        size={size}
        skipLeftRounding={skipLeftRounding}
        skipRightRounding={skipRightRounding}
        success={success}
        tabIndex={0}
        type={type}
        variant={variant}
        withIconMargin={!!children}
      >
        {/*
          ButtonIcon can't be conditionally rendered due to the chromium bug:
          https://github.com/facebook/react/issues/11538
        */}
        <ButtonIcon
          {...buttonIconProps}
          icon={icon}
          color={iconColor}
          loading={loading}
          position="left"
          isVisible={loading || !!icon}
        />
        {children}
        <ButtonIcon
          {...buttonIconProps}
          icon={iconRight}
          color={iconRightColor}
          isVisible={!!iconRight}
          position="right"
        />
      </Styled.Button>
    )
  },
)
