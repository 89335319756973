import * as React from 'react'
import { useTranslation } from 'react-i18next'

import { ReactComponent as BankReconciliationError } from '../../../../../../../../assets/images/bank-reconciliation-error.svg'
import { ReactComponent as BankReconciliationSuccess } from '../../../../../../../../assets/images/bank-reconciliation-success.svg'
import { useMissingReceiptsBankLines } from '../../../../../../../thingsToDo/hooks/useMissingReceiptsBankLines'
import * as Styled from './styles'

export interface ConnectionInboxEnabledProps {
  isConnectionError: boolean
}

export const ConnectionInboxEnabled = ({ isConnectionError }: ConnectionInboxEnabledProps) => {
  const { t } = useTranslation()
  const { bankLines: missingReceiptsBankLines, isError: isMissingReceiptsError } = useMissingReceiptsBankLines()

  const hasMissingReceipts = missingReceiptsBankLines.length > 0
  const isError = hasMissingReceipts || isConnectionError || isMissingReceiptsError

  const getErrorText = () => {
    if (isError) {
      if (isConnectionError) {
        return t('dashboard.widget.bank-integration.connection_error')
      } else {
        return t('dashboard.widget.bank-integration.missing_receipts.error_message', {
          amount: missingReceiptsBankLines.length,
        })
      }
    }
    return t('dashboard.widget.bank-integration.no_missing_receipts')
  }

  return (
    <>
      <Styled.ConnectionErrorContainer>
        {isError ? <BankReconciliationError /> : <BankReconciliationSuccess />}

        <Styled.Text colorVariant={isError ? 'danger' : 'success'}>{getErrorText()}</Styled.Text>

        {isConnectionError && (
          <Styled.MenetoErrorText colorVariant="secondary">
            {t('dashboard.widget.bank-integration.connection_error_meneto')}
          </Styled.MenetoErrorText>
        )}
      </Styled.ConnectionErrorContainer>
    </>
  )
}
