import produce from 'immer'

import { AppState } from '../types'
import { PRODUCTS_RECEIVED, PRODUCTS_REQUESTED } from './actions'
import { ProductsState } from './types'

const productsReducer = (
  state: AppState,
  { type, payload }: any, // TODO: Why doesn't Saga like union types??
): AppState => {
  switch (type) {
    case PRODUCTS_REQUESTED:
      return state

    case PRODUCTS_RECEIVED:
      return produce(state, (draftState: ProductsState) => {
        draftState.products = payload
      })

    default:
      return state
  }
}

export default productsReducer
