import { notify, useModal } from '@design-system'

import { createContext, ReactElement, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../../hooks'
import { APIError } from '../../../../../../../../utils'
import { getErrorMessage } from '../../../../../../../../utils/getErrorMessage'
import { useUserOrganization } from '../../../../../../../app/organization'
import { useTaxRates } from '../../../../../../../app/taxrates'
import { VatRateFormData } from '../../../../types/vatRateFormData'
import { fractionToPercent } from '../../../../utils/fractionToPercent'
import { removeIdAndTaxRateId, shareFromFractionToPercent, shareFromPercentToFraction, VatRateType } from '../../shared'
import { useCreateVatRate } from '../hooks/useCreateVatRate'
import { useUpdateVatRate } from '../hooks/useUpdateVatRate'
import {
  CreateOrEditVatRateForm,
  getCreateOrEditVatRateFormDefaultValues,
  getValidationSchema,
} from '../utils/formData'

interface ContextState {
  close: (() => void) | undefined
  Form: FormComponent
  isDisabled: boolean
  isEditMode: boolean
  isLoading: boolean
  modalBodyElement: HTMLDivElement | undefined
  submitForm: () => void
}

const CreateOrEditVatRateModalContext = createContext<ContextState | undefined>(undefined)

interface CreateOrEditVatRateModalContextProps {
  children: ReactNode
  vatRateId?: string
  scrollContainerElement: HTMLDivElement | undefined
  modalId: string
  disabled?: boolean
  appliesTo?: VatRateType
}

export const CreateOrEditVatRateModalContextProvider = ({
  children,
  vatRateId,
  scrollContainerElement: modalBodyElement,
  modalId,
  disabled,
  appliesTo,
}: CreateOrEditVatRateModalContextProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { taxRatesIncludingDeactivated = [] } = useTaxRates()

  const defaultValues = useMemo(() => getCreateOrEditVatRateFormDefaultValues(appliesTo), [appliesTo])

  const { Form, handleSubmit, reset } = useForm({
    defaultValues,
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const { close } = useModal(modalId, {
    onOpen: () => {
      reset()
    },
  })

  useEffect(() => {
    if (vatRateId) {
      const vatRate = taxRatesIncludingDeactivated.find(({ id }) => id === vatRateId)

      if (vatRate) {
        const {
          abbreviation,
          deductionComponents,
          netAmountMetaFieldId,
          rate,
          description,
          name,
          isActive,
          appliesToSales,
        } = vatRate

        const filteredDeductionComponents = deductionComponents.map((item) => removeIdAndTaxRateId(item))

        const data: CreateOrEditVatRateForm = {
          abbreviation,
          deductionComponents: shareFromFractionToPercent(filteredDeductionComponents),
          description,
          name,
          isActive,
          netAmountMetaFieldId: netAmountMetaFieldId || undefined,
          rate: fractionToPercent(rate),
          appliesTo: appliesToSales ? VatRateType.Sales : VatRateType.Purchase,
        }

        reset(data)
      }
    }
  }, [defaultValues.abbreviation, defaultValues.isActive, reset, taxRatesIncludingDeactivated, vatRateId])

  const handleCreateOrEditError = useCallback(
    (error?: APIError) => {
      const message = getErrorMessage(error, 'salesTaxAccount') || t('error_saving')
      notify({ id: NotificationKeys.CreateOrEditVatRates, message, variant: 'error' })
    },
    [t],
  )

  const handleCreateOrEditSuccess = useCallback(() => {
    queryClient.invalidateQueries(QueryKeys.TaxRates)
    close()
  }, [close])

  const { create, isLoading: isCreateProcessing } = useCreateVatRate({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const { update, isLoading: isEditProcessing } = useUpdateVatRate({
    onError: handleCreateOrEditError,
    onSuccess: handleCreateOrEditSuccess,
  })

  const isEditMode = !!vatRateId
  const isLoading = isEditMode ? isEditProcessing : isCreateProcessing

  const handleSubmitForm = useCallback(
    (formData: CreateOrEditVatRateForm) => {
      const organizationId = organization?.id

      if (!organizationId) {
        throw new Error('No organization id')
      }

      if (isEditMode) {
        const {
          name,
          description,
          abbreviation,
          netAmountMetaFieldId,
          appliesTo,
          isActive,
          rate,
          deductionComponents,
        } = formData

        const isAppliesToSales = appliesTo === VatRateType.Sales

        const data: Partial<VatRateFormData> = {
          abbreviation,
          deductionComponents: shareFromPercentToFraction(deductionComponents || []),
          description,
          name,
          isActive,
          netAmountMetaFieldId: netAmountMetaFieldId || null,
          rate: (rate || 0) / 100,
          organizationId,
          appliesToSales: isAppliesToSales,
          appliesToPurchases: !isAppliesToSales,
        }

        return update({ id: vatRateId, payload: data })
      }

      const { name, description, abbreviation, netAmountMetaFieldId, appliesTo, isActive, rate, deductionComponents } =
        formData

      const isAppliesToSales = appliesTo === VatRateType.Sales

      const data: VatRateFormData = {
        abbreviation: abbreviation || '',
        deductionComponents: shareFromPercentToFraction(deductionComponents || []),
        description: description || '',
        name,
        isActive,
        netAmountMetaFieldId: netAmountMetaFieldId || null,
        rate: (rate || 0) / 100,
        organizationId,
        appliesToSales: isAppliesToSales,
        appliesToPurchases: !isAppliesToSales,
      }

      return create(data)
    },
    [organization?.id, isEditMode, create, update, vatRateId],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <CreateOrEditVatRateModalContext.Provider
      value={{
        close,
        Form,
        isDisabled: !!disabled,
        isEditMode,
        isLoading,
        modalBodyElement,
        submitForm,
      }}
    >
      {children}
    </CreateOrEditVatRateModalContext.Provider>
  )
}

export const useCreateOrEditVatRateModal = () => {
  const context = useContext(CreateOrEditVatRateModalContext)

  if (!context) {
    throw new Error('CreateOrEditVatRateModalContextProvider is missing in the module!')
  }

  return context
}
