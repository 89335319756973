import { convertBookkeepingTagDbValueToNavItemValue } from '@components'

import { TFunction } from 'i18next'
import sortBy from 'lodash/sortBy'

import { TaxMode } from '../../../../../enums/taxMode'
import { getLocalDateFromDateString } from '../../../../../utils/getLocalDateFromDateString'
import { Account } from '../../../../app/accounts/types'
import { ChartOfAccountId } from '../../../../app/organization/enums/chartOfAccountId'
import { TaxRate } from '../../../../app/taxrates/types'
import { GetBillResponseData } from '../../../query-api'
import { getAttachmentsFilesFromBill } from '../../../utils/getAttachmentsFilesFromBill'
import { getContactFromBill } from '../../../utils/getContactFromBill'
import { BillForm, getDefaultBillLine, getDefaultValues } from './formData'

interface ConvertBillDataToBillFormProps {
  accounts?: Account[]
  baseCurrencyId?: string
  billData?: GetBillResponseData
  chartOfAccountId?: ChartOfAccountId
  countryId?: string
  isCreditNoteFromBill?: boolean
  isDuplicate?: boolean
  locale: string
  t: TFunction
  taxRates?: TaxRate[]
}

const convertTaxModeToIncludingVat = (billForm: BillForm): BillForm => {
  billForm.taxMode = TaxMode.Including

  if (billForm.billLines?.length) {
    for (const billLine of billForm.billLines) {
      if (billLine.inclVatAmount !== undefined) {
        billLine.inclVatAmount += billLine.vatAmount || 0
      }
    }
  }

  return billForm
}

export const convertBillDataToBillForm = async ({
  accounts,
  baseCurrencyId,
  billData,
  chartOfAccountId,
  countryId,
  isCreditNoteFromBill,
  isDuplicate,
  locale,
  t,
  taxRates,
}: ConvertBillDataToBillFormProps): Promise<BillForm | undefined> => {
  const { bill, billLines, contacts } = billData || {}

  if (!bill) {
    return
  }

  const attachmentsFiles = getAttachmentsFilesFromBill(bill)
  const vendor = getContactFromBill(bill, contacts)
  const billLinesSorted = billLines ? sortBy(billLines, 'priority') : []
  const paymentAccount = bill.paymentAccountId
    ? accounts?.find((account) => account.id === bill.paymentAccountId)
    : undefined
  const creditedBillId = bill.creditedBillId || (bill.id && isCreditNoteFromBill ? bill.id : undefined)

  let billForm: BillForm = {
    ...getDefaultValues(baseCurrencyId),
    ...(attachmentsFiles && !isDuplicate && !isCreditNoteFromBill ? { attachmentsFiles } : {}),
    ...(bill.currencyId ? { currencyId: bill.currencyId } : {}),
    ...(bill.dueDate && !isCreditNoteFromBill ? { dueDate: getLocalDateFromDateString(bill.dueDate) } : {}),
    ...(bill.entryDate && !isCreditNoteFromBill && !isDuplicate
      ? { billDate: getLocalDateFromDateString(bill.entryDate) }
      : {}),
    ...(bill.paymentDate && !isCreditNoteFromBill ? { paymentDate: getLocalDateFromDateString(bill.paymentDate) } : {}),
    ...(bill.state && !isDuplicate && !isCreditNoteFromBill ? { state: bill.state } : {}),
    ...(bill.suppliersInvoiceNo ? { referenceNumber: bill.suppliersInvoiceNo } : {}),
    ...(bill.taxMode ? { taxMode: bill.taxMode } : {}),
    ...(bill.voucherNo && !isDuplicate && !isCreditNoteFromBill ? { voucherNo: bill.voucherNo } : {}),
    ...(creditedBillId ? { creditedBillId } : {}),
    ...(paymentAccount && !isCreditNoteFromBill ? { paymentAccount } : {}),
    ...(vendor ? { vendor } : {}),
    billLines: [],
  }

  for (const billLine of billLinesSorted) {
    const bookkeepingTag = billLine.bookkeepingTag
      ? await convertBookkeepingTagDbValueToNavItemValue(
          JSON.parse(billLine.bookkeepingTag),
          locale,
          chartOfAccountId,
          countryId,
        )
      : undefined
    const expenseCategory = billLine.accountId
      ? accounts?.find((account) => account.id === billLine.accountId)
      : undefined
    const taxRate = billLine.taxRateId ? taxRates?.find((taxRate) => taxRate.id === billLine.taxRateId) : undefined
    const description = isCreditNoteFromBill
      ? t('bill.credit.refund_description', { description: billLine.description })
      : billLine.description

    billForm.billLines?.push({
      ...getDefaultBillLine(),
      ...(expenseCategory ? { expenseCategory } : {}),
      ...(billLine.amount ? { inclVatAmount: billLine.amount } : {}),
      ...(billLine.bookkeepingTag ? { bookkeepingTag } : {}),
      ...(description ? { description } : {}),
      ...(billLine.tax ? { vatAmount: billLine.tax } : {}),
      ...(taxRate ? { taxRate } : {}),
    })
  }

  if (billForm.taxMode === TaxMode.Excluding) {
    billForm = convertTaxModeToIncludingVat(billForm)
  }

  return billForm
}
