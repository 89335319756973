import { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useForm } from '../../../hooks'
import { useUserOrganization } from '../../../modules/app/organization'
import { useRejectReceipt } from '../../../modules/receipts/hooks/useRejectReceipt'
import { getDefaultValues, getValidationSchema, RejectReceiptForm } from '../utils/formData'

export interface UseRejectReceiptFormProps {
  attachmentId?: string
  onSuccess: () => void
}

export const useRejectReceiptForm = ({ attachmentId, onSuccess }: UseRejectReceiptFormProps) => {
  const { t } = useTranslation()
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()
  const { reject, isProcessing } = useRejectReceipt({
    onSuccess,
  })
  const { Form, FormItem, handleSubmit, reset } = useForm({
    defaultValues: useMemo(() => getDefaultValues(), []),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  useEffect(() => {
    if (!isOrganizationLoading) {
      reset(getDefaultValues())
    }
  }, [isOrganizationLoading])

  const handleSubmitForm = useCallback((values: RejectReceiptForm) => {
    const { reason } = values
    reject({
      organizationId: organization?.id,
      attachmentId,
      reason,
    })
  }, [])

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return {
    Form,
    FormItem,
    submitForm,
    isProcessing,
    isOrganizationLoading,
  }
}
