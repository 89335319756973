import React, { ReactElement } from 'react'
import { Route, Switch } from 'react-router-dom'

import { RecurringInvoicesRoute } from './enums/recurringInvoicesRoute'
import { RecurringInvoicesList } from './routes/RecurringInvoicesList'
import { RecurringInvoicesListEmpty } from './routes/RecurringInvoicesListEmpty'

export const ReccuringInvoices = (): ReactElement => (
  <Switch>
    <Route exact path={RecurringInvoicesRoute.List} component={RecurringInvoicesList} />
    <Route exact path={RecurringInvoicesRoute.ListEmpty} component={RecurringInvoicesListEmpty} />
  </Switch>
)
