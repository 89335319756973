import { ModalConfirmation, ModalConfirmationProps, notify, useModal } from '@design-system'

import React, { ReactElement, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../../../../enums/queryKeys'
import { useDeletePaymentMethod } from '../../../../../../../../../../hooks/useDeletePaymentMethod'
import { useUserOrganization } from '../../../../../../../../../app/organization'
import { useOrganizationInvoiceSettings } from '../../../../../../contexts/organizationInvoiceSettingsContext'

interface DeletePaymentMethodModalProps extends ModalConfirmationProps {
  paymentMethodId: string
}

export const DeletePaymentMethodModal = ({
  paymentMethodId,
  ...modalProps
}: DeletePaymentMethodModalProps): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close: closeDeletePaymentMethodModal } = useModal(modalProps.id)
  const { reloadPaymentMethods } = useOrganizationInvoiceSettings()
  const { delete: deletePaymentMethod, isProcessing: isDeleting } = useDeletePaymentMethod({
    onSuccess: () => {
      closeDeletePaymentMethodModal()
      notify({
        id: NotificationKeys.PaymentMethodDelete,
        message: t('settings.organization.invoice.payment_methods_settings.delete_modal.notification'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.PaymentMethods)
      reloadPaymentMethods?.()
    },
    onError: (error) => {
      closeDeletePaymentMethodModal()
      notify({
        id: NotificationKeys.PaymentMethodDelete,
        message: error?.body?.errors[0]?.detail,
        variant: 'warning',
      })
    },
  })

  const handleDeletePaymentMethodButtonClick = useCallback(() => {
    if (!organization?.id || !paymentMethodId) {
      return
    }

    deletePaymentMethod({ organizationId: organization.id, paymentMethodId })
  }, [deletePaymentMethod, organization?.id, paymentMethodId])

  return (
    <ModalConfirmation
      {...modalProps}
      cancelLabel={t('settings.organization.invoice.payment_methods_settings.delete_modal.cancel_button')}
      danger
      message={t('settings.organization.invoice.payment_methods_settings.delete_modal.body')}
      okLabel={t('settings.organization.invoice.payment_methods_settings.delete_modal.delete_button')}
      okLoading={isDeleting}
      onOk={handleDeletePaymentMethodButtonClick}
      title={t('settings.organization.invoice.payment_methods_settings.delete_modal.title')}
    />
  )
}
