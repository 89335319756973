import { notify } from '@design-system'

import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { NotificationKeys } from '../../../enums/notificationKeys'
import { updateVoucher } from '../../inbox/services/query-api'
import { VoucherUpdatePayloadData } from '../../inbox/types'
import { useOptimisticVoucherListUpdate } from './useOptimisticVoucherListUpdate'

interface UpdateVoucherMutationProps {
  organizationId: string
  voucherId: string
  onSuccess?: (data: void, variables: VoucherUpdatePayloadData, context: unknown) => void
  onError?: (error: unknown, variables: VoucherUpdatePayloadData, context: unknown) => void
}

export const useUpdateVoucherMutation = ({
  organizationId,
  voucherId,
  onSuccess,
  onError,
}: UpdateVoucherMutationProps) => {
  const { t } = useTranslation()
  const { updateVouchersList, invalidateVouchersList } = useOptimisticVoucherListUpdate(organizationId, voucherId)

  return useMutation(
    (payload: VoucherUpdatePayloadData) => {
      return updateVoucher({ organizationId, voucherId, payload })
    },
    {
      onSuccess: (...props) => {
        onSuccess && onSuccess(...props)
        notify({
          id: NotificationKeys.VoucherInboxUpdate,
          message: t('voucher.inbox.toast.voucher_update_success'),
          variant: 'success',
        })
        updateVouchersList()
      },
      onError: (...props) => {
        onError && onError(...props)
        notify({
          id: NotificationKeys.VoucherInboxUpdate,
          message: t('voucher.inbox.toast.voucher_update_failed'),
          variant: 'error',
        })
      },
      onSettled: invalidateVouchersList,
    },
  )
}
