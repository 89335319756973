import { emptyViewVariants } from '@components'
import { ModuleLayout } from '@design-system'

import React, { ReactElement, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Row } from 'react-table'

import { useEmberRouter } from '../../../../contexts/emberRouterContext'
import { EmberRoute } from '../../../../enums/emberRoute'
import { PeriodMode } from '../../../../enums/periodMode'
import { SortDirection } from '../../../../enums/sortDirection'
import { BillStatus } from '../../../bills/enums/billStatus'
import { BillsSortProperty } from '../../../bills/query-api'
import { BillsListFilters } from '../../../bills/routes/BillsList'
import {
  ContactBalanceEmpty,
  ContactBalanceTable,
  ContactBalanceTableActions,
  ContactBalanceTableRow,
  ContactBalanceType,
  useContactBalanceListFilters,
} from '../../../contactBalance'
import { useCreditors } from './hooks/useCreditors'

export const CreditorsList = (): ReactElement => {
  const { t } = useTranslation()
  const { navigate } = useEmberRouter()
  const { creditors, isLoading, pagination } = useCreditors()
  const [{ searchQuery }] = useContactBalanceListFilters()

  const showEmptyView = useMemo(
    () => !isLoading && !searchQuery && creditors.length === 0,
    [isLoading, searchQuery, creditors],
  )

  const onTableRowClick = useCallback(
    (row: Row<ContactBalanceTableRow>) => {
      const { id: contactId, contactName } = row.original

      const invoicesQueryParams: BillsListFilters = {
        contactId,
        contactName,
        period: PeriodMode.All,
        sortProperty: BillsSortProperty.DueDate,
        sortDirection: SortDirection.Asc,
        status: BillStatus.Unpaid,
      }

      navigate(EmberRoute.Bills, {
        queryParams: invoicesQueryParams,
      })
    },
    [navigate],
  )

  return (
    <ModuleLayout title={t('creditors')}>
      <ContactBalanceTableActions isLoading={isLoading} title={t('creditors')} total={pagination?.total} />
      {showEmptyView ? (
        <ContactBalanceEmpty {...emptyViewVariants.creditors} route={EmberRoute.BillsNew} />
      ) : (
        <ContactBalanceTable
          data={creditors}
          isLoading={isLoading}
          contactBalanceType={ContactBalanceType.Creditor}
          onRowClick={onTableRowClick}
        />
      )}
    </ModuleLayout>
  )
}
