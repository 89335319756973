import { byAttrVal, createCustomElement, DOMModel, registerEvent } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { EmberNavigate, EmberRouterContextProvider } from '../../contexts/emberRouterContext'
import { ThemeProvider } from '../../contexts/themeProvider'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { Debtors } from '../../modules/debtors'

const MODULE_NAME = 'debtors-module'

class DebtorsWebComponent extends DOMModel {
  @byAttrVal organizationId: string
  @registerEvent('navigate') navigate: Function
}

interface DebtorsModuleProps {
  organizationId: string
  onNavigate: EmberNavigate
}

const DebtorsModule = ({ onNavigate, organizationId }: DebtorsModuleProps): ReactElement => (
  <QueryClientProvider client={queryClient}>
    <ThemeProvider>
      <HashRouter>
        <EmberRouterContextProvider onNavigate={onNavigate}>
          <UserOrganizationContextProvider organizationId={organizationId}>
            <Debtors />
          </UserOrganizationContextProvider>
        </EmberRouterContextProvider>
      </HashRouter>
    </ThemeProvider>
  </QueryClientProvider>
)

const DebtorsCustomElement = createCustomElement(DebtorsModule, DebtorsWebComponent, 'element')

customElements.get(MODULE_NAME) || customElements.define(MODULE_NAME, DebtorsCustomElement)

export default DebtorsCustomElement
