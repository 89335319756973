import cc from 'classcat'
import React, { AnchorHTMLAttributes, forwardRef, MouseEvent, ReactElement, Ref, useCallback } from 'react'

import { Asable } from '../../types/asable'
import * as Styled from './styles'

export interface LinkProps extends AnchorHTMLAttributes<HTMLAnchorElement>, Asable {
  disabled?: boolean
  underlined?: boolean
  withAnimation?: boolean
}

export const Link = forwardRef((props: LinkProps, ref: Ref<HTMLAnchorElement>): ReactElement => {
  const { children, disabled, onClick, underlined = true, withAnimation, ...rest } = props

  const handleClick = useCallback(
    (event: MouseEvent<HTMLAnchorElement>) => {
      if (disabled) {
        event.preventDefault()
        return
      }

      onClick?.(event)
    },
    [disabled, onClick],
  )

  return (
    <Styled.Link
      ref={ref}
      disabled={disabled}
      onClick={handleClick}
      underlined={underlined}
      withAnimation={withAnimation}
      {...rest}
      className={cc(['ds-link', { withAnimation, underlined }, rest.className])}
    >
      {children}
    </Styled.Link>
  )
})
