import { notify } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUpdateOrganization, useUserOrganization } from '../../../../app/organization'

interface UseDeleteOrganizationIconProps {
  onSuccess?: () => void
  onError?: () => void
}

export const useDeleteOrganizationIcon = ({ onSuccess, onError }: UseDeleteOrganizationIconProps) => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const {
    update: updateOrganization,
    isProcessing,
    ...rest
  } = useUpdateOrganization({
    onSuccess: () => {
      onSuccess?.()
      notify({
        id: NotificationKeys.OrganizationIconDelete,
        message: t('settings.organization.organization_info.notification.update_success'),
        variant: 'success',
      })
      queryClient.invalidateQueries(QueryKeys.UserOrganization)
    },
    onError: () => {
      onError?.()
      notify({ id: NotificationKeys.OrganizationIconDelete, message: t('error_saving'), variant: 'error' })
    },
  })

  const deleteOrganizationIcon = useCallback(() => {
    if (!organization?.id) {
      return
    }

    updateOrganization({
      organizationId: organization.id,
      payload: {
        iconFileId: null,
      },
    })
  }, [organization?.id, updateOrganization])

  return { deleteOrganizationIcon, isProcessing, ...rest }
}
