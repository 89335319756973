import { ItemsPerPage } from '@design-system'

import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { defaultStaleTime } from '../../../../../config/queryClient'
import { useUserOrganization } from '../../../../app/organization'
import { InvoiceStatus } from '../../../enums/invoiceStatus'
import { fetchInvoices } from '../../../query-api'
import { InvoicesQueryProps } from '../../../types/invoicesQueryProps'
import { getInvoicesQueryKey } from '../../../utils/getInvoicesQueryKey'

export const useIsInvoicesListEmpty = (): boolean => {
  const { organization } = useUserOrganization()

  const queryProps: InvoicesQueryProps = useMemo(
    () => ({
      page: 1,
      pageSize: 1 as ItemsPerPage,
      status: InvoiceStatus.All,
      organizationId: organization?.id as string,
    }),
    [organization],
  )

  const queryKey = useMemo(() => getInvoicesQueryKey(queryProps), [queryProps])

  const { data: invoicesCountData, isFetched: invoicesCountFetched } = useQuery(
    queryKey,
    () => fetchInvoices(queryProps),
    {
      enabled: !!organization,
      staleTime: defaultStaleTime,
    },
  )

  return invoicesCountFetched && invoicesCountData?.invoices.length === 0
}
