import { useGlobalFiscalYear } from '../../../contexts/globalFiscalYearContext'
import { StatsDataQueryParams } from '../services/query-api'

interface UseFiscalYearQueryParams {
  queryParams: StatsDataQueryParams | undefined
}

export const useFiscalYearQueryParams = (organizationId: string): UseFiscalYearQueryParams => {
  const { fiscalYear } = useGlobalFiscalYear()

  if (fiscalYear === undefined) {
    return { queryParams: undefined }
  }

  return {
    queryParams: {
      period: `fiscalyear:${organizationId},${fiscalYear}`,
    },
  }
}
