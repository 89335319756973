import { useUserOrganization } from '../modules/app/organization'
import { SubscriptionPlan } from '../modules/app/organization/enums/subscriptionPlan'

export const useIsBasicPlan = () => {
  const { organization, isLoading } = useUserOrganization()

  return {
    isBasicPlan: isLoading || !organization ? undefined : organization.subscriptionPlan === SubscriptionPlan.Basic,
    isLoading,
  }
}
