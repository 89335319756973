import produce from 'immer'

import { AppState } from '../types'
import { UMBRELLA_ORGANIZATIONS_RECEIVED, USER_ORGANIZATIONS_RECEIVED, USER_ORGANIZATIONS_REQUESTED } from './actions'
import { OrganizationsState } from './types'

const organizationsReducer = (
  state: AppState,
  { type, payload }: any, // TODO: Why doesn't Saga like union types??
): AppState => {
  switch (type) {
    case USER_ORGANIZATIONS_REQUESTED:
      return state

    case USER_ORGANIZATIONS_RECEIVED:
      return produce(state, (draftState: OrganizationsState) => {
        draftState.organizations = payload
      })

    case UMBRELLA_ORGANIZATIONS_RECEIVED:
      return produce(state, (draftState: OrganizationsState) => {
        draftState.umbrellaOrganizations = payload
      })

    default:
      return state
  }
}

export default organizationsReducer
