import { LabsMenuFlags } from '../../../../../hooks/useLabsMenuFlags'
import { isAuthorized } from '../../../../../utils/isAuthorized'
import { MenuItemExtended, MenuItemId } from './menuItems'
import { shouldSkipRenderItemByLabsFlag } from './shouldSkipRenderItemByLabsFlag'

interface MenuFilterOptions {
  isInboxEnabled?: boolean
  labsFlags?: LabsMenuFlags
  // Should be used only for custom conditions:
  shouldSkipRenderItem?: (menuId: MenuItemId) => boolean
}

const inboxEnabled: MenuItemId[] = ['thingsToDo', 'vouchers', 'inbox']
const inboxDisabled: MenuItemId[] = ['expenses', 'uploads']

export const filterMenuItems = (items: MenuItemExtended[], options: MenuFilterOptions): MenuItemExtended[] =>
  items.reduce((result: MenuItemExtended[], item) => {
    const { id, scopes, oldScopes } = item
    const { isInboxEnabled, labsFlags, shouldSkipRenderItem } = options || {}
    const hasAllSubmenuItemsFiltered = item.submenu?.length
      ? filterMenuItems(item.submenu, options).length === 0
      : false

    if (hasAllSubmenuItemsFiltered) {
      return result
    }

    if (scopes && !isAuthorized(scopes)) {
      return result
    }

    if (oldScopes && !isAuthorized(oldScopes, true)) {
      return result
    }

    if (shouldSkipRenderItem && shouldSkipRenderItem(id)) {
      return result
    }

    if (labsFlags && shouldSkipRenderItemByLabsFlag(id, labsFlags)) {
      return result
    }

    if (isInboxEnabled && inboxDisabled.includes(id)) {
      return result
    }

    if (!isInboxEnabled && inboxEnabled.includes(id)) {
      return result
    }

    return [
      ...result,
      {
        ...item,
        ...(item.submenu?.length ? { submenu: filterMenuItems(item.submenu, options) } : {}),
      },
    ]
  }, [])
