import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../enums/queryKeys'
import { getOrganizationMembers } from '../../../settings/routes/OrganizationSettingsUsers/query-api'
import { User } from '../../../settings/routes/OrganizationSettingsUsers/types'
import { useCurrentUser } from '../../user'

interface UseOrganizationMembersProps {
  organizationId: string
  ownerUserId: string
  ownerUmbrellaId: string
}

interface UseOrganizationMembersResponse {
  isLoading: boolean
  organizationMembers: User[]
}

export const useOrganizationMembers = ({
  organizationId,
  ownerUserId,
  ownerUmbrellaId,
}: UseOrganizationMembersProps): UseOrganizationMembersResponse => {
  const { user } = useCurrentUser()

  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.OrganizationSettingsUsersMembers, organizationId],
    () => getOrganizationMembers({ organizationId, ownerUserId, ownerUmbrellaId, user }),
    { enabled: !!organizationId && !!user },
  )

  return {
    organizationMembers: data || [],
    isLoading: isIdle || isLoading,
  }
}
