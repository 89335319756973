import { getRequest, request } from '../../utils'
import { UserOrganization } from '../app/organizations/types'
import { User } from '../app/user'
import { OrganizationInvitation } from './types/organizationInvitation'

export interface OrganizationInvitationData {
  email: string
  existingUser: boolean
  organization: Pick<UserOrganization, 'name' | 'iconUrl' | 'url'>
  invitedByUser: Pick<User, 'name'>
  invitation: OrganizationInvitation
}

interface FetchOrganizationInvitationResponseData {
  data: OrganizationInvitationData
}

export const fetchOrganizationInvitation = (token: string): Promise<FetchOrganizationInvitationResponseData> => {
  return getRequest(`/organizationInvitations/${token}`, {}, false)
}

export const acceptOrganizationInvitation = async (token: string): Promise<void> => {
  await request(
    `/organizationInvitations/${token}/accept`,
    {
      method: 'POST',
      headers: {
        contentType: 'application/vnd.billy.v2+json',
      },
      body: null,
    },
    {
      withAuth: true,
      returnRawResponse: true,
    },
  )
}
