import { InternalAccessor } from '@design-system'

import { useMemo } from 'react'

import { UmbrellaRole } from '../../../../../../app/umbrellas/types/umbrellaRole'
import { TableRow } from '../types/tableRow'

export const useTableData = (roles: UmbrellaRole[]): TableRow[] => {
  return useMemo(
    () =>
      roles.map((role: UmbrellaRole) => ({
        id: role.id,
        usersCount: role.users.length,
        organizationsCount: role.organizations.length,
        roleName: role.name,
        roleDescription: role.description,
        isAdmin: role.grantAdmin,
        hasAccessToAllOrganizations: role.grantAll,
        [InternalAccessor.StaticActions]: true,
      })),
    [roles],
  )
}
