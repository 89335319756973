import { Contact } from '../../../contacts/types/contact'
import { fetchContactById } from '../query-api'

export const getContact = async (contactId: string): Promise<Contact | undefined> => {
  try {
    const data = await fetchContactById(contactId)
    return data.contact
  } catch (error) {
    console.error(error)
    return undefined
  }
}
