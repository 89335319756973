import noop from 'lodash/noop'
import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'

import { EmberActionPayload } from '../../../../signupGuide/context/signupGuideContext'

interface ContextState {
  authorizeEmberUser: (token: string) => void
}

const defaultValue: ContextState = {
  authorizeEmberUser: noop,
}

const UserAuthorizationContext = createContext(defaultValue)

interface UserAuthorizationContextProps {
  children?: ReactNode
  onAuthorizeUser: (payload: EmberActionPayload) => void
}

export const UserAuthorizationContextProvider = ({
  children,
  onAuthorizeUser,
}: UserAuthorizationContextProps): ReactElement => {
  const authorizeEmberUser = useCallback(
    (token: string) => {
      onAuthorizeUser({ detail: { token } })
    },
    [onAuthorizeUser],
  )

  const value = useMemo(
    () => ({
      authorizeEmberUser,
    }),
    [authorizeEmberUser],
  )

  return <UserAuthorizationContext.Provider value={value}>{children}</UserAuthorizationContext.Provider>
}

export const useUserAuthorization = () => useContext(UserAuthorizationContext)
