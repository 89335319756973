import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { SortDirection } from '../../../../../../../enums/sortDirection'
import { useUserOrganization } from '../../../../../../app/organization'
import { VatAccountsSortProperty } from '../../../enums/vatAccountsSortProperty'
import { fetchTaxAccounts } from '../../../query-api'
import { TaxAccountWithDetails } from '../../../types/taxAccountWithDetails'

interface UseFetchVatAccountsResponse {
  data: TaxAccountWithDetails[]
  isLoading?: boolean
}

interface UseFetchVatAccountsProps {
  sortDirection?: SortDirection
  sortProperty?: VatAccountsSortProperty
}

export const useFetchVatAccounts = ({
  sortDirection = SortDirection.Asc,
  sortProperty = VatAccountsSortProperty.Priority,
}: UseFetchVatAccountsProps): UseFetchVatAccountsResponse => {
  const { organization, isLoading: isOrganizationLoading } = useUserOrganization()

  const query = useQuery(
    [QueryKeys.VatAccounts, organization?.id, sortDirection, sortProperty],
    () => fetchTaxAccounts({ organizationId: organization?.id as string, sortDirection, sortProperty }),
    {
      enabled: !!organization?.id,
    },
  )

  return {
    ...query,
    data: query?.data?.salesTaxAccounts || [],
    isLoading: query.isLoading || isOrganizationLoading,
  }
}
