import { ButtonVariant } from '@design-system'

import { InvoiceLogErrorKey } from '../../invoices'

export type ActionType =
  | 'voidInvoice'
  | 'resendEInvoice'
  | 'openSendModal'
  | 'openErrorDetailsModal'
  | 'cancelSendingEInvoice'

interface Action {
  action: ActionType
  tLabel: string
  buttonVariant?: ButtonVariant
}

export const errorKeyToAction: Partial<Record<InvoiceLogErrorKey, Action[]>> = {
  /* eslint-disable @typescript-eslint/naming-convention */
  attachments_unprocessable: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
  ],
  request_error: [
    {
      action: 'openErrorDetailsModal',
      tLabel: 'einvoce.status.action.error_details',
      buttonVariant: 'secondary',
    },
  ],
  request_too_large: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
  ],
  schema_invalid: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
    {
      action: 'openErrorDetailsModal',
      tLabel: 'einvoce.status.action.error_details',
      buttonVariant: 'secondary',
    },
  ],
  schema_invalid_bank_digits: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
    {
      action: 'openErrorDetailsModal',
      tLabel: 'einvoce.status.action.error_details',
      buttonVariant: 'secondary',
    },
  ],
  schema_invalid_zero_quantity: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
    {
      action: 'openErrorDetailsModal',
      tLabel: 'einvoce.status.action.error_details',
      buttonVariant: 'secondary',
    },
  ],
  timeout: [
    {
      action: 'resendEInvoice',
      tLabel: 'einvoce.status.action.resend',
    },
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
      buttonVariant: 'secondary',
    },
  ],
  recipient_not_found: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
  ],
  rejected: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
  ],
  sender_cvr_mismatch: [
    {
      action: 'voidInvoice',
      tLabel: 'einvoce.status.action.void',
    },
  ],
  /* eslint-enable @typescript-eslint/naming-convention */
}
