import numeral from 'numeral'

import { Difference, DifferenceType } from '../../bankReconciliation/types'

interface GetBillReconciliationDifferenceOptions {
  billsAmount?: number
  reconciliationAmount?: number
}

export const getBillReconciliationDifference = ({
  billsAmount,
  reconciliationAmount,
}: GetBillReconciliationDifferenceOptions): Difference => {
  const differenceTypes: DifferenceType[] = []

  if (!reconciliationAmount || !billsAmount) {
    return {
      amount: 0,
      differenceAmount: 0,
      types: [],
    }
  }

  const isPositive = billsAmount > 0 || reconciliationAmount > 0
  if (isPositive) {
    if (reconciliationAmount > billsAmount) {
      differenceTypes.push('overpayment')
      differenceTypes.push('bankFee')
    } else {
      differenceTypes.push('underpayment')
    }
  } else {
    if (reconciliationAmount > billsAmount) {
      differenceTypes.push('underpayment')
    } else {
      differenceTypes.push('overpayment')
      differenceTypes.push('bankFee')
    }
  }

  return {
    amount: billsAmount,
    differenceAmount: numeral(billsAmount).subtract(reconciliationAmount).value() || 0,
    types: differenceTypes,
  }
}
