import produce from 'immer'

import { AppState } from '../types'
import {
  IS_TRUSTED_PARTNER_RECEIVED,
  ORGANIZATION_RECEIVED,
  ORGANIZATION_REQUESTED,
  ORGANIZATION_SETTINGS_RECEIVED,
  ORGANIZATION_UMBRELLAS_RECEIVED,
  ORGANIZATION_UMBRELLAS_REQUESTED,
} from './actions'
import { OrganizationState } from './types/organizationState'

const organizationReducer = (
  state: AppState,
  { type, payload }: any, // TODO: Why doesn't Saga like union types??
): AppState => {
  switch (type) {
    case ORGANIZATION_REQUESTED:
    case ORGANIZATION_UMBRELLAS_REQUESTED:
      return state

    case ORGANIZATION_RECEIVED:
      return produce(state, (draftState: OrganizationState) => {
        draftState.organization = payload
      })

    case ORGANIZATION_SETTINGS_RECEIVED:
      return produce(state, (draftState: OrganizationState) => {
        draftState.organizationSettings = payload
      })

    case ORGANIZATION_UMBRELLAS_RECEIVED:
      return produce(state, (draftState: OrganizationState) => {
        draftState.organizationUmbrellas = payload
      })

    case IS_TRUSTED_PARTNER_RECEIVED:
      return produce(state, (draftState: OrganizationState) => {
        draftState.isTrustedPartner = payload
      })

    default:
      return state
  }
}

export default organizationReducer
