import { NavItem } from '@design-system'

import { UmbrellaOrganization } from '../../../../modules/app/organizations/types'

export const getItems = (
  organizations: UmbrellaOrganization[],
  organizationsIdsToSkip?: string[],
): NavItem<string>[] => {
  const filteredOrganizations = organizations.filter(
    (organization) => !organizationsIdsToSkip?.includes(organization.organizationId),
  )
  return filteredOrganizations.map((organization) => {
    return {
      id: organization.organizationId,
      children: organization.name,
      title: organization.name,
      value: organization.organizationId,
      searchKeywords: [organization.name, organization.registrationNo],
    }
  })
}
