import { decodePeriodValue, encodePeriodValue } from '@components'
import { NavItem } from '@design-system'

import React, { ReactElement, useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { useGlobalFiscalYear } from '../../../../../../contexts/globalFiscalYearContext'
import { PeriodMode } from '../../../../../../enums/periodMode'
import { PeriodValue } from '../../../../../../types/periodValue'
import { useUserOrganization } from '../../../../../app/organization'
import { ReportFilter, ReportFilterValues } from '../../../../elements/ReportsFilters'
import { ReportsTableActions } from '../../../../elements/ReportsTableActions'
import { ComparisonValue } from '../../../../types/comparisonValue'
import { filterToPayloadOption } from '../../../../utils/reportFilterToPayloadOption'
import { defaultPeriodValue, useBalanceFilters } from '../../hooks/useBalanceFilters'
import { getCompareToMonthItems } from '../../utils/getCompareToMonthItems'
import { getInternalChangeMockItems } from '../../utils/getInternalChangeMockItems'

export const BalanceTableActions = (): ReactElement => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const [queryParams, setQueryParams] = useBalanceFilters()
  const { fiscalYear: globalFiscalYear } = useGlobalFiscalYear()
  const hasPeriodBeenSetRef = useRef(false)

  const filterValues: Omit<ReportFilterValues, 'showYtd'> = useMemo(
    () => ({
      hideZero: Boolean(queryParams.hideZeroes),
      invertAmounts: Boolean(queryParams.inverted),
    }),
    [queryParams.hideZeroes, queryParams.inverted],
  )

  // Check the initial period
  useEffect(() => {
    if (!!organization?.id && !hasPeriodBeenSetRef.current) {
      setQueryParams((prevQuery) => {
        hasPeriodBeenSetRef.current = true

        if (!prevQuery.period) {
          return { period: encodePeriodValue(defaultPeriodValue, organization?.id) }
        }

        return prevQuery
      })
    }
  }, [globalFiscalYear, organization?.id, setQueryParams])

  const handlePeriodChange = useCallback(
    (periodValue?: PeriodValue) => {
      if (!organization?.id) {
        return
      }

      setQueryParams({ period: periodValue ? encodePeriodValue(periodValue, organization.id) : undefined })
    },
    [organization?.id, setQueryParams],
  )

  const handleComparisonPeriodSelect = useCallback(
    (_id: string, value: number) => {
      setQueryParams({ compareCount: value })
    },
    [setQueryParams],
  )

  const handleComparisonSelect = useCallback(
    (_id: string, value: ComparisonValue) => {
      setQueryParams({ compareWith: value })
    },
    [setQueryParams],
  )

  const handleFilterChange = useCallback(
    (filter: ReportFilter, value: boolean) => {
      const payloadOption = filterToPayloadOption[filter]
      setQueryParams({ [payloadOption]: value })
    },
    [setQueryParams],
  )

  const periodMode = useMemo(
    () => (queryParams.period ? decodePeriodValue(queryParams.period)?.mode : undefined),
    [queryParams.period],
  )

  const reportsComparisonSelectItems: NavItem<ComparisonValue>[] | undefined = useMemo(() => {
    if (periodMode === PeriodMode.Month) {
      return getCompareToMonthItems(t)
    }

    return getInternalChangeMockItems(t) // show only so it's similar UI, but disable the select in that case
  }, [periodMode, t])

  return (
    <ReportsTableActions
      comparisonId={queryParams.compareWith}
      comparisonPeriodId={queryParams.compareCount?.toString()}
      encodedPeriod={queryParams.period}
      filterValues={filterValues}
      hiddenFilters={['showYtd']}
      isComparisonSelectDisabled={periodMode !== PeriodMode.Month}
      onComparisonPeriodSelect={handleComparisonPeriodSelect}
      onComparisonSelect={handleComparisonSelect}
      onFilterChange={handleFilterChange}
      onPeriodChange={handlePeriodChange}
      reportsComparisonSelectItems={reportsComparisonSelectItems}
      withPeriodComparison={periodMode === PeriodMode.Month && queryParams.compareWith !== 'internal_changes'}
    />
  )
}
