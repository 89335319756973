import { Text } from '@design-system'

import React from 'react'
import { useTranslation } from 'react-i18next'

import { useCurrentUser } from '../../../../../../../app/user'
import * as Styled from './styles'

export const UserEmailInfo = () => {
  const { t } = useTranslation()
  const { user } = useCurrentUser()

  return (
    <Styled.UserInfoWrapper>
      <Text variant="small" colorVariant="secondary" alignment="center">
        {t('menu.user_dropdown.signed_in_as')}
      </Text>
      <Text variant="small" truncate title={user?.email} alignment="center">
        {user?.email}
      </Text>
    </Styled.UserInfoWrapper>
  )
}
