import { isBookkeepingTagValid } from '@components'

import { TFunction } from 'i18next'

import { Account } from '../../../../app/accounts/types'
import { Organization } from '../../../../app/organization/types/organization'
import { TaxRate } from '../../../../app/taxrates/types'
import { BillState } from '../../../enums/billState'
import { GetBillResponseData } from '../../../query-api'
import { convertBillDataToBillForm } from './convertBillDataToBillForm'
import { BillForm, getDefaultBillLine } from './formData'
import { getBillFormAutoCompleted } from './getBillFormAutoCompleted'

interface GetInitialBillFormProps {
  accounts?: Account[]
  billData?: GetBillResponseData
  isCreditNoteFromBill?: boolean
  isDuplicate?: boolean
  locale: string
  organization: Organization
  billFormCurrent?: Partial<BillForm>
  t: TFunction
  taxRates?: TaxRate[]
}

export const getInitialBillForm = async ({
  accounts,
  billData,
  isCreditNoteFromBill,
  isDuplicate,
  locale,
  organization,
  billFormCurrent,
  t,
  taxRates,
}: GetInitialBillFormProps): Promise<BillForm | undefined> => {
  const { baseCurrencyId, chartOfAccountId, isVatExempted, id: organizationId, countryId } = organization

  let billForm = await convertBillDataToBillForm({
    accounts,
    baseCurrencyId,
    billData,
    chartOfAccountId,
    countryId,
    isCreditNoteFromBill,
    isDuplicate,
    locale,
    t,
    taxRates,
  })

  // keep some of the currect form data if set
  if (billForm && billFormCurrent) {
    billForm = { ...billForm, ...billFormCurrent }
  }

  // add default line if any has been saved in DB
  if (billForm && !billForm.billLines?.length) {
    billForm.billLines = [getDefaultBillLine()]
  }

  // we don't want to autoComplete data again after some submitAction is present (it's the use case when we save bill as draft for example)
  const withAutoComplete = !billForm?.submitAction && billForm?.state !== BillState.Approved
  const withBookkeepingSuggestions =
    !billForm?.billLines?.[0].description &&
    (!billForm?.billLines?.[0].bookkeepingTag || !isBookkeepingTagValid(billForm?.billLines?.[0].bookkeepingTag))

  if (billForm && !withBookkeepingSuggestions) {
    billForm.bookkeepingTagSuggestions = undefined
  }

  if (!withAutoComplete || !billForm) {
    return billForm
  }

  billForm = await getBillFormAutoCompleted({
    accounts,
    baseCurrencyId,
    billForm,
    chartOfAccountId,
    countryId,
    isVatExempted,
    locale,
    organizationId,
    taxRates,
    withBookkeepingSuggestions,
  })

  return billForm
}
