import { useMemo } from 'react'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUserOrganization } from '../../../../app/organization'
import { fetchOrganizationOwnersData, FetchOrganizationOwnersDataOptions } from '../query-api'

export const useFetchOrganizationOwnersData = () => {
  const { organization } = useUserOrganization()

  const queryProps: FetchOrganizationOwnersDataOptions = useMemo(
    () => ({
      organizationId: organization?.id as string,
      include: 'organizationOwner.city,organizationOwner.zipcode',
    }),
    [organization?.id],
  )

  const {
    data: organizationOwnersData,
    isIdle,
    isLoading,
  } = useQuery([QueryKeys.OrganizationOwners, queryProps], () => fetchOrganizationOwnersData(queryProps), {
    enabled: !!organization?.id,
  })

  return {
    isLoading: isLoading || isIdle,
    organizationOwnersData,
  }
}
