import { ShortcutKey, Translate } from '@components'
import { Text } from '@design-system'

import React from 'react'

import * as Styled from './styles'

export const ResultsFooter = () => (
  <Styled.ResultsFooterWrapper>
    <Styled.ShortcursList>
      <Styled.ShortcutItem>
        <ShortcutKey icon="caretUp" />
        <ShortcutKey icon="caretDown" />
        <Styled.ShortcutLabelWrapper>
          <Text colorVariant="secondary">
            <Translate value="global_search.results_footer.to_navigate" />
          </Text>
        </Styled.ShortcutLabelWrapper>
      </Styled.ShortcutItem>

      <Styled.ShortcutItem>
        <ShortcutKey icon="arrowReturn" />
        <Styled.ShortcutLabelWrapper>
          <Text colorVariant="secondary">
            <Translate value="global_search.results_footer.to_select" />
          </Text>
        </Styled.ShortcutLabelWrapper>
      </Styled.ShortcutItem>

      <Styled.ShortcutItem>
        <ShortcutKey label="esc" />
        <Styled.ShortcutLabelWrapper>
          <Text colorVariant="secondary">
            <Translate value="global_search.results_footer.to_dismiss" />
          </Text>
        </Styled.ShortcutLabelWrapper>
      </Styled.ShortcutItem>
    </Styled.ShortcursList>
  </Styled.ResultsFooterWrapper>
)
