import {
  Content,
  DateInput,
  Form,
  FormSubmittedData,
  Input,
  SmartSelect,
  SmartSelectItem,
  StackLayout,
  StackLayoutBox,
  Submit,
} from '@components-deprecated'
import { rwd } from '@design-system'

import { css } from '@emotion/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Box } from 'rebass'

import { DateCheckbox } from '../../../components-deprecated/Form'
import { VATSummary } from '../../../components-deprecated/VATSummary/VATSummary'
import {
  AccountSelector,
  ContactSelector,
  CurrencyInput,
  CurrencyInputResult,
  TaxRateSelector,
} from '../../../modules/app'
import { TaxRate } from '../../app/taxrates/types'
import { createAsset, getAssetsTypes } from '../action-creators'
import { CreateAssetsSchema } from '../formschemas'
import { AssetLifetime, AssetType, CreateAssetDto } from '../types'
import { AssetsModuleHeader } from './AssetsModuleHeader'

const LARGE_SCREEN_BREAKPOINT = rwd.getBreakpointValue('large')

const styles = {
  formGrid: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: min-content;
    gap: 10px;
  `,
  bottomMarginOnBig: css`
    ${rwd.equalOrLessThan('large')} {
      margin-bottom: 30px;
    }
  `,
}

type AssetFormProps = {
  organizationId: string
}

export const AssetForm = ({ organizationId }: AssetFormProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [selectedAssetType, setSelectedAssetType] = useState<AssetType | null | any>(null)
  const [selectedLifetime, setSelectedLifetime] = useState<AssetLifetime | null>(null)
  const [selectedTaxRate, setSelectedTaxRate] = useState<TaxRate | null>(null)
  const [amount, setAmount] = useState<CurrencyInputResult | null>({ amount: 0 })
  const [paid, setPaid] = useState(true)
  const assetTypes: AssetType[] = useSelector(({ assets }: any) => assets.assetTypes, shallowEqual)

  // FETCH ASSET TYPES

  useEffect(() => {
    dispatch(getAssetsTypes())
  }, [dispatch])

  // SELECT DEFAULT TYPE & LIFETIMES

  useEffect(() => {
    if (assetTypes && assetTypes[0]) {
      const machinery = assetTypes && assetTypes[1]
      updateSelectedAssetData(machinery)
    }
  }, [assetTypes])

  // HANDLERS

  const handleAmountChange = (selectedAmount: CurrencyInputResult) => {
    setAmount(selectedAmount.amount as unknown as CurrencyInputResult)
  }

  const updateSelectedAssetData = (asset: SmartSelectItem) => {
    setSelectedAssetType(asset)
    setSelectedLifetime(asset.typeLifetimes?.[0])
  }

  const handleOnSubmit = (data: FormSubmittedData) => {
    const asset: CreateAssetDto = {
      typeId: selectedAssetType.id,
      name: data.description,
      lifetimeMonths: selectedLifetime?.estimatedLifeTimeMonths || 24,
      startDate: data.billDate_date,
      startValue: {
        amount: data.amount_input,
        currencyCode: data.amount_selector,
      },
      scrapValue: {
        amount: data.scrapValue_input,
        currencyCode: data.scrapValue_selector,
      },
      vendor: data._vendor.id,
      paymentDate: data.paymentDate,
      paymentAccountId: data._paidFrom?.id,
    }

    dispatch(createAsset(asset, organizationId))
  }

  // COMPUTED VARIABLES

  const getLifetimeText = (lifetime: any) => t('years_count', { count: lifetime?.estimatedLifeTimeMonths / 12 })
  const assetTypesSelector = assetTypes.map((asset: AssetType) => ({ ...asset, text: asset.name }))
  const lifetimeSelector =
    selectedAssetType?.typeLifetimes.map((lifetime: any) => ({
      ...lifetime,
      text: getLifetimeText(lifetime),
    })) || []

  return (
    <Form validationSchema={CreateAssetsSchema(t)} onSubmit={handleOnSubmit} height="100%">
      <AssetsModuleHeader title={t('fixed_assets.create_asset')}>
        <Submit variant="primary">{t('fixed_assets.save_asset')}</Submit>
      </AssetsModuleHeader>
      <Content>
        <StackLayout direction="horizontal" breakWidth={LARGE_SCREEN_BREAKPOINT} height="100%">
          <StackLayoutBox p={30}>
            <StackLayout direction="vertical" breakWidth={LARGE_SCREEN_BREAKPOINT}>
              <StackLayoutBox css={[styles.formGrid, styles.bottomMarginOnBig]} mr={10}>
                <Input
                  type="text"
                  name="description"
                  label={t('fixed_assets.form.description')}
                  placeholder={t('fixed_assets.form.description_placeholder')}
                  required
                />
                <SmartSelect
                  type="text"
                  name="assetType"
                  label={t('fixed_assets.form.type')}
                  items={assetTypesSelector}
                  preselectedId={assetTypesSelector[1] && assetTypesSelector[1].id}
                  onItemSelect={(asset) => updateSelectedAssetData(asset)}
                  required
                />
                <SmartSelect
                  type="text"
                  name="lifetime"
                  label={t('fixed_assets.form.lifetime')}
                  items={lifetimeSelector}
                  preselectedId={lifetimeSelector[0] && lifetimeSelector[0].id}
                  required
                />
                <CurrencyInput
                  name="scrapValue"
                  label={t('fixed_assets.form.scrap_value')}
                  placeholder={t('fixed_assets.form.scrap_value_placeholder')}
                  currency="DKK"
                  required
                />
              </StackLayoutBox>
              <StackLayoutBox css={styles.formGrid}>
                <ContactSelector
                  organizationId={organizationId}
                  name="vendor"
                  label={t('fixed_assets.form.vendor')}
                  placeholder={t('fixed_assets.form.vendor_placeholder')}
                  required
                />
                <DateCheckbox
                  name="billDate"
                  checked={paid}
                  dateInputLabel={t('fixed_assets.form.bill_date')}
                  checkboxLabel={t('fixed_assets.form.paid')}
                  onChange={({ checked }) => setPaid(checked)}
                  required
                />
                {paid && (
                  <>
                    <DateInput
                      name="paymentDate"
                      label={t('fixed_assets.form.payment_date')}
                      css={styles.bottomMarginOnBig}
                    />
                    <AccountSelector
                      name="paidFrom"
                      label={t('fixed_assets.form.paid_from')}
                      css={styles.bottomMarginOnBig}
                      isBankAccount
                      required
                    />
                  </>
                )}
                <CurrencyInput
                  name="amount"
                  placeholder="0,00"
                  currency="DKK"
                  label={t('fixed_assets.form.amount_with_vat')}
                  onChange={handleAmountChange}
                  required
                />
                <TaxRateSelector
                  organizationId={organizationId}
                  name="vatRate"
                  label={t('fixed_assets.form.vat_rate')}
                  placeholder={t('fixed_assets.form.vat_rate_placeholder')}
                  onItemSelect={(item: SmartSelectItem) => setSelectedTaxRate(item as TaxRate)}
                  required
                />
                <Box
                  css={css`
                    grid-column: 2;
                  `}
                >
                  <VATSummary
                    amount={amount as unknown as number}
                    rate={(selectedTaxRate && selectedTaxRate.rate) || 0}
                    placement="right"
                  />
                </Box>
              </StackLayoutBox>
            </StackLayout>
          </StackLayoutBox>
          <StackLayoutBox
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              background-color: #efefef;
              height: auto;
            `}
          >
            Drop here
          </StackLayoutBox>
        </StackLayout>
      </Content>
    </Form>
  )
}
