import qs from 'qs'

import { TrialBalanceReportOptions } from '../types/reportOptions'

export const getRegnskabBasisRequestQueryParams = (
  options?: TrialBalanceReportOptions,
  queryParams?: Record<string, any>,
): string | undefined => {
  const { period } = options || {}

  return qs.stringify({
    ...(period ? { period } : {}),
    ...(queryParams || {}),
  })
}
