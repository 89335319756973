import { amountToDisplayValue } from '@design-system'

import { useMemo } from 'react'

import { Contact } from '../../../../contacts/types/contact'
import { getContactName } from '../../../../contacts/utils/getContactName'
import { InvoiceState } from '../../../enums/invoiceState'
import { InvoiceType } from '../../../types/invoiceType'
import { getInvoiceStatus } from '../utils/getInvoiceStatus'
import { Invoice } from './../../../types/invoice'
import { InvoicesTableAccessor } from './../enums/invoicesTableAccessor'
import { InvoicesTableRow } from './../types/invoicesTableRow'

export const useInvoicesTableData = (invoices: Invoice[], contacts: Contact[]): InvoicesTableRow[] => {
  return useMemo(
    () =>
      invoices.map(
        ({
          amount,
          balance,
          contactId,
          currencyId,
          dueDate,
          entryDate,
          grossAmount,
          id,
          invoiceNo,
          isPaid,
          lineDescription,
          orderNo,
          sentState,
          state,
          tax,
          type,
        }) => {
          const isCreditNote = type === InvoiceType.CreditNote

          return {
            [InvoicesTableAccessor.Date]: entryDate,
            [InvoicesTableAccessor.Status]: getInvoiceStatus({
              amount,
              balance,
              dueDate: dueDate ? new Date(dueDate) : undefined,
              isPaid,
              state,
              tax,
            }),
            [InvoicesTableAccessor.DueDate]: state === InvoiceState.Approved && !isPaid ? dueDate : '',
            [InvoicesTableAccessor.EmailStatus]: sentState,
            [InvoicesTableAccessor.Customer]: getContactName(contacts, contactId),
            [InvoicesTableAccessor.OrderNumber]: orderNo || '',
            [InvoicesTableAccessor.Description]: lineDescription
              ? {
                  isCreditNote,
                  value: lineDescription,
                }
              : undefined,
            [InvoicesTableAccessor.IncludedVat]:
              grossAmount && currencyId ? `${amountToDisplayValue(grossAmount)} ${currencyId}` : undefined,
            [InvoicesTableAccessor.ExcludedVat]:
              amount && currencyId ? `${amountToDisplayValue(amount)} ${currencyId}` : undefined,
            [InvoicesTableAccessor.Balance]: currencyId
              ? `${amountToDisplayValue(balance || 0)} ${currencyId}`
              : undefined,
            [InvoicesTableAccessor.InvoiceNo]: invoiceNo,
            id,
          }
        },
      ),
    [invoices, contacts],
  )
}
