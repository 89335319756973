import { sortBy } from 'lodash'
import { createContext, ReactElement, ReactNode, useContext, useMemo } from 'react'

import { Difference, ReconciliationTransaction } from '../../../../bankReconciliation/types'
import { prefixAmount } from '../../../../bankReconciliation/utils'
import { useBillReconciliationSuggestions } from '../../../hooks/useBillReconciliationSuggestions'
import { getBillReconciliationDifference } from '../../../utils/getBillReconciliationDifference'
import { useBillReconciliation } from './billReconciliationContext'
import { useMultipleBills } from './multipleBillsContext'

interface ContextState {
  hasMatchDifference: boolean
  matchDifference: Difference
  matchSuggestion?: ReconciliationTransaction
}

const MatchContext = createContext<ContextState | undefined>(undefined)

interface MatchContextContextProps {
  children?: ReactNode
}

export const MatchContextProvider = ({ children }: MatchContextContextProps): ReactElement => {
  const { initialBill } = useBillReconciliation()
  const { suggestions } = useBillReconciliationSuggestions(initialBill?.id)
  const { selectedBillsAmountsSum } = useMultipleBills()

  const matchSuggestion = useMemo(() => {
    const sortedByConfidence = sortBy(suggestions, 'confidence')
    const bestSuggestion = sortedByConfidence?.[0]

    if (!bestSuggestion) {
      return undefined
    }

    return bestSuggestion.transactions?.[0]
  }, [suggestions])

  const matchDifference = useMemo(() => {
    const reconciliationAmount = prefixAmount(matchSuggestion)

    return getBillReconciliationDifference({
      billsAmount: selectedBillsAmountsSum,
      reconciliationAmount,
    })
  }, [matchSuggestion, selectedBillsAmountsSum])

  const hasMatchDifference = useMemo(() => {
    return !!matchDifference?.differenceAmount
  }, [matchDifference?.differenceAmount])

  return (
    <MatchContext.Provider
      value={{
        hasMatchDifference,
        matchDifference,
        matchSuggestion,
      }}
    >
      {children}
    </MatchContext.Provider>
  )
}

export const useMatch = () => {
  const context = useContext(MatchContext)

  if (!context) {
    throw new Error('MatchContextProvider is missing in the module!')
  }

  return context
}
