import { useQueryClient } from 'react-query'

import { QueryKeys } from '../enums/queryKeys'
import { useSubscriptionMetrics } from '../modules/findAccountant/hooks/useSubscriptionMetrics'
import { useOrganizationSubscription } from './useOrganizationSubscription'

export const useSubscriptionLimits = () => {
  const queryClient = useQueryClient()
  const { data } = useSubscriptionMetrics()
  const { subscriptionPlan } = useOrganizationSubscription()

  const expenses = data?.expenses
  const expensesLimit = data?.plans[subscriptionPlan]?.expensesLimit
  const invoices = data?.invoices
  const invoicesLimit = data?.plans[subscriptionPlan]?.invoicesLimit

  const hasExceededInvoicesLimit = invoicesLimit && invoices && invoices >= invoicesLimit
  const hasExceededExpensesLimit = expensesLimit && expenses && expenses >= expensesLimit

  const invalidateLimits = () => {
    queryClient.invalidateQueries(QueryKeys.SubscriptionMetrics)
  }

  return {
    expenses,
    expensesLimit,
    invoices,
    invoicesLimit,
    hasExceededInvoicesLimit,
    hasExceededExpensesLimit,
    invalidateLimits,
  }
}
