import { TaxRate } from '../../../../app/taxrates/types'
import { Contact } from '../../../../contacts/types/contact'

export const getTaxRateFromContact = (taxRates: TaxRate[], contact?: Partial<Contact>): TaxRate | undefined => {
  if (!contact || !contact.defaultTaxRateId) {
    return
  }

  return taxRates.find(({ id }) => id === contact.defaultTaxRateId)
}
