import { ResponsePage } from '../../types'

export enum CompanyType {
  ENK = 'ENK',
  PMV = 'PMV',
}

export type Company = {
  city: string
  companyType: CompanyType
  email: string | null
  fax: string | null
  firstFiscalYearEnd: string | null
  firstFiscalYearStart: string | null
  fiscalYearEnd: string | null
  fiscalYearStart: string | null
  id: string
  name: string
  number?: string
  numberTo: string | null
  phone: string | null
  state: string
  street: string
  text: string
  type: string | null
  vatNo: string | null
  www: string | null
  zipcode: string
}

export type CompaniesState = {
  companies: {
    list?: Company[] | null
    page?: ResponsePage | null
  }
}
