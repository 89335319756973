import QueryString from 'qs'
import { useMemo } from 'react'
import { useLocation } from 'react-use'

import { useUserOrganization } from '../../../../app/organization'
import { Organization } from '../../../../app/organization/types/organization'
import { useCurrentUser, User } from '../../../../app/user'
import { STRIPE_AUTHORIZATION_BASE_URL } from '../constants/stripeAuthorizationBaseUrl'
import { NameParts } from '../types/nameParts'
import { getUserNameParts } from '../utils/getUserNameParts'

/* eslint-disable @typescript-eslint/naming-convention */
interface StripeUrlParamsStripeUser extends Pick<Partial<User>, 'email'>, Pick<Partial<Organization>, 'city'> {
  country?: Organization['countryId']
  business_name?: Organization['name']
  street_address?: Organization['street']
  state_code?: Organization['stateId']
  zip?: Organization['zipcode']
  currency?: Organization['baseCurrencyId']
  state?: Organization['stateId']
  phone_number?: Organization['phone'] | User['phone']
  first_name: NameParts['firstName']
  last_name: NameParts['lastName']
}
/* eslint-enable @typescript-eslint/naming-convention */

/* eslint-disable @typescript-eslint/naming-convention */
interface StripeUrlParams {
  client_id: string
  response_type: string
  redirect_uri: string
  scope: string
  always_prompt: boolean
  stripe_user: StripeUrlParamsStripeUser
}
/* eslint-enable @typescript-eslint/naming-convention */

interface UseGetStripeConnectUrlReturn {
  isLoading: boolean
  stripeConnectUrl: string
}

export const useGetStripeConnectUrl = (): UseGetStripeConnectUrlReturn => {
  const location = useLocation()
  const { isLoading: isLoadingOrganization, organization } = useUserOrganization()
  const { isLoading: isLoadingUser, user } = useCurrentUser()

  const { protocol, host } = location
  const redirectHost = host ? host?.replace('support.', 'mit.') : ''

  const stripeConnectUrl = useMemo(() => {
    if (isLoadingOrganization || isLoadingUser) {
      return ''
    }

    const nameParts = getUserNameParts(user)

    /* eslint-disable @typescript-eslint/naming-convention */
    const queryParams: StripeUrlParams = {
      client_id: window?.ENV?.STRIPE_CLIENT_ID,
      response_type: 'code',
      scope: 'read_write',
      always_prompt: true,
      redirect_uri: `${protocol}//${redirectHost}/stripe_oauth_callback`,
      stripe_user: {
        email: user?.email,
        country: organization?.countryId,
        phone_number: organization?.phone || user?.phone,
        business_name: organization?.name,
        street_address: organization?.street,
        city: organization?.city,
        state: organization?.stateId,
        zip: organization?.zipcode,
        currency: organization?.baseCurrencyId,
        first_name: nameParts.firstName,
        last_name: nameParts.lastName,
      },
    }
    /* eslint-enable @typescript-eslint/naming-convention */

    const queryString = QueryString.stringify(queryParams)

    return `${STRIPE_AUTHORIZATION_BASE_URL}?${queryString}`
  }, [organization, user, protocol, redirectHost, isLoadingOrganization, isLoadingUser])

  return {
    isLoading: isLoadingOrganization && isLoadingUser,
    stripeConnectUrl,
  }
}
