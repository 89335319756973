import { notify, useModal } from '@design-system'

import React, { createContext, ReactElement, ReactNode, useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { queryClient } from '../../../../../../../config/queryClient'
import { NotificationKeys } from '../../../../../../../enums/notificationKeys'
import { QueryKeys } from '../../../../../../../enums/queryKeys'
import { FormComponent, useForm } from '../../../../../../../hooks'
import { useUmbrellaMembers } from '../../../../../../../hooks/useUmbrellaMembers'
import { APIError } from '../../../../../../../utils'
import {
  fetchUmbrellaRoles,
  UmbrellaUserInvitationPayload,
  UmbrellaUserRolesPayload,
} from '../../../../../../app/umbrellas/query-api'
import { UmbrellaRole } from '../../../../../../app/umbrellas/types/umbrellaRole'
import { useInviteUmbrellaUser } from '../../../hooks/useInviteUmbrellaUser'
import { useUpdateUserRoles } from '../../../hooks/useUpdateUserRoles'
import { getDefaultValues, getValidationSchema, InviteUmbrellaUserForm } from '../utils/formData'

interface ContextState {
  Form: FormComponent
  isLoadingRoles: boolean
  isProcessingInvite: boolean
  isProcessingUpdate: boolean
  isUpdateMode: boolean
  roles?: UmbrellaRole[]
  submitForm: () => void
}

const InviteOrUpdateUmbrellaUserFormContext = createContext<ContextState | undefined>(undefined)

interface InviteOrUpdateUmbrellaUserFormContextProps {
  modalId: string
  userId?: string
  umbrellaId?: string
  children?: ReactNode
}

export const InviteOrUpdateUmbrellaUserFormContextProvider = ({
  modalId,
  userId,
  umbrellaId,
  children,
}: InviteOrUpdateUmbrellaUserFormContextProps): ReactElement => {
  const { t } = useTranslation()
  const { umbrellaMembers } = useUmbrellaMembers()

  const isUpdateMode = !!userId

  const { close: closeUmbrellaInviteUserModal } = useModal(modalId)
  const { inviteUmbrellaUser, isProcessing: isProcessingInvite } = useInviteUmbrellaUser({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaInvitedUsers)
      closeUmbrellaInviteUserModal()
      notify({
        id: NotificationKeys.UmbrellaUserInvitation,
        message: t('umbrella_users.create_edit_user_modal.create.success'),
        variant: 'success',
      })
      reset(getDefaultValues(selectedUser))
    },
    onError: (error?: APIError) => {
      notify({
        id: NotificationKeys.UmbrellaUserInvitation,
        message: error?.message || '',
        variant: 'error',
      })
    },
  })

  const { updateUserRoles, isProcessing: isProcessingUpdate } = useUpdateUserRoles({
    onSuccess: () => {
      queryClient.invalidateQueries(QueryKeys.UmbrellaMembers)
      closeUmbrellaInviteUserModal()
      notify({
        id: NotificationKeys.UmbrellaUserUpdate,
        message: t('umbrella_users.create_edit_user_modal.edit.success'),
        variant: 'success',
      })
    },
    onError: (error?: APIError) => {
      notify({
        id: NotificationKeys.UmbrellaUserUpdate,
        message: error?.body.errors[0].detail || '',
        variant: 'error',
      })
    },
  })

  const { data: rolesData, isLoading: isLoadingRoles } = useQuery(
    [QueryKeys.UmbrellaRoles, umbrellaId],
    () => fetchUmbrellaRoles(umbrellaId || ''),
    {
      enabled: !!umbrellaId,
    },
  )

  const selectedUser = useMemo(() => {
    return umbrellaMembers.find((umbrellaMember) => umbrellaMember.userId === userId)
  }, [umbrellaMembers, userId])

  const { Form, handleSubmit, reset, setError } = useForm({
    defaultValues: useMemo(() => getDefaultValues(selectedUser), [selectedUser]),
    validationSchema: useMemo(() => getValidationSchema(t), [t]),
  })

  const inviteUser = useCallback(
    (formValues: InviteUmbrellaUserForm) => {
      if (!umbrellaId) {
        return
      }

      // Checking the error here because of a yup bug that won't allow setting the value required: https://github.com/jquense/yup/issues/1389
      if (!formValues.roles?.length) {
        setError('roles', { message: t('umbrella_users.create_edit_user_modal.error.empty_role') })
        return
      }

      const payload: UmbrellaUserInvitationPayload = {
        email: formValues.email,
        roles: formValues.roles,
      }

      inviteUmbrellaUser({ umbrellaId, payload })
    },
    [inviteUmbrellaUser, setError, t, umbrellaId],
  )

  const updateUser = useCallback(
    (formValues: InviteUmbrellaUserForm) => {
      if (!umbrellaId || !userId) {
        return
      }

      const payload: UmbrellaUserRolesPayload = {
        roleIds: formValues.roles || [],
      }

      updateUserRoles({ umbrellaId, userId, payload })
    },
    [umbrellaId, userId, updateUserRoles],
  )

  const handleSubmitForm = useCallback(
    (formValues: InviteUmbrellaUserForm) => {
      if (isUpdateMode) {
        updateUser(formValues)
      } else {
        inviteUser(formValues)
      }
    },
    [isUpdateMode, inviteUser, updateUser],
  )

  const submitForm = useCallback(() => {
    handleSubmit(handleSubmitForm)()
  }, [handleSubmit, handleSubmitForm])

  return (
    <InviteOrUpdateUmbrellaUserFormContext.Provider
      value={{
        Form,
        isUpdateMode,
        isLoadingRoles,
        isProcessingInvite,
        isProcessingUpdate,
        roles: rolesData?.data,
        submitForm,
      }}
    >
      {children}
    </InviteOrUpdateUmbrellaUserFormContext.Provider>
  )
}

export const useInviteOrUpdateUmbrellaUserForm = () => {
  const context = useContext(InviteOrUpdateUmbrellaUserFormContext)

  if (!context) {
    throw new Error('InviteOrUpdateUmbrellaUserFormContextProvider is missing in the module!')
  }

  return context
}
