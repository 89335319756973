import { byAttrVal, createCustomElement, DOMModel } from '@adobe/react-webcomponent'
import React, { ReactElement } from 'react'
import { QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'

import { queryClient } from '../../config/queryClient'
import { ThemeProvider } from '../../contexts/themeProvider'
import { AccountsContextProvider } from '../../modules/app/accounts'
import { UserOrganizationContextProvider } from '../../modules/app/organization'
import { CurrentUserContextProvider } from '../../modules/app/user'
import { ThingsToDo } from '../../modules/thingsToDo'

class ThingsToDoWebComponent extends DOMModel {
  @byAttrVal organizationId: string
}

type ThingsToDoProps = {
  organizationId: string
}

function ThingsToDoModule({ organizationId }: ThingsToDoProps): ReactElement {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <Router>
          <CurrentUserContextProvider>
            <UserOrganizationContextProvider organizationId={organizationId}>
              <AccountsContextProvider organizationId={organizationId}>
                <ThingsToDo />
              </AccountsContextProvider>
            </UserOrganizationContextProvider>
          </CurrentUserContextProvider>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  )
}

const ThingsToDoCustomElement = createCustomElement(ThingsToDoModule, ThingsToDoWebComponent, 'element')

customElements.get('things-to-do-module') || customElements.define('things-to-do-module', ThingsToDoCustomElement)

export default ThingsToDoCustomElement
