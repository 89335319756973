import { Button, transitionToMiliseconds } from '@design-system'

import { useTheme } from 'emotion-theming'
import React, { forwardRef, ReactElement, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { FormProvider, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Flex } from 'rebass'

import { Account } from '../../modules/app/accounts/types'
import { ValidationModal, ValidationModalType } from '../../modules/inbox/elements'
import { Theme } from '../../types/theme'
import { Timeout } from '../../types/timeout'
import { Input } from '../Form'
import { Text as TextComponent } from '../Typography/Text'
import { AccountFormFragment, AllAccountSelector, FileError, FileThumbnail, SavingOverlay } from './elements'
import * as Styled from './styles'
import { ImageFile, UploadItem, UploadItemFile } from './types'

const AUTO_CLOSE_TIMEOUT = 4000
const ITEMS_PER_BATCH = 10

type FormInputs = {
  items: UploadItem[]
}

const defaultItem = { comment: '', account: { id: '' } } as UploadItem

const getDefaultItems = (files: ImageFile[]) =>
  files.map(({ name, lastModified, preview, errors }) => ({
    ...defaultItem,
    ...{ file: { preview, name, lastModified, errors } },
  }))

const getItemsBatches = (items: UploadItem[]) => {
  const itemsBatches = []

  for (let i = 0; i < items.length; i += ITEMS_PER_BATCH) {
    itemsBatches.push(items.slice(i, i + ITEMS_PER_BATCH))
  }

  return itemsBatches
}

const filterBrokenFilesItems = (items: UploadItem[]) => items.filter((item) => !item.file.errors?.length)

const isSomeCommentMissed = (items: UploadItem[]) => items.some((item) => !item.comment?.trim())

type FilesUploadListProps = {
  className?: string
  disabled?: boolean
  files: ImageFile[]
  onItemRemove?: (itemsLeft: UploadItem[]) => void
  onUploadFinish?: (isAnyFileUploaded: boolean) => void
  onItemUpload: (item: UploadItem, itemFile: ImageFile | undefined) => void
  withAccountSelection?: boolean
  sameHeightFileList?: boolean
  customSubmit?: boolean
}

export type FileUploadRefProps = {
  submitFileUpload: () => void
  isSomeFileDropped: () => boolean | undefined
}

export const FilesUploadList = forwardRef<FileUploadRefProps, FilesUploadListProps>(
  (
    {
      className,
      disabled,
      files,
      onItemRemove = () => null,
      onUploadFinish = () => null,
      onItemUpload,
      withAccountSelection,
      sameHeightFileList = false,
      customSubmit = false,
    },
    ref,
  ): ReactElement => {
    const defaultItems = useMemo(() => getDefaultItems(files), [files])
    const [savedCount, setSavedCount] = useState(0)
    const [itemsToSaveCount, setItemsToSaveCount] = useState(0)
    const [bulkAccount, setBulkAccount] = useState<Account>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const theme = useTheme<Theme>()
    const form = useForm<FormInputs>({
      mode: 'onChange',
      defaultValues: { items: defaultItems },
    })

    const {
      formState: { isSubmitting: isSaving, isSubmitted: isSavingFinished },
    } = form

    const { fields, remove: removeField } = useFieldArray({
      control: form.control,
      name: 'items',
    })

    const { t } = useTranslation()
    const areAllFilesSaved = isSavingFinished && itemsToSaveCount === savedCount

    // Ref

    useImperativeHandle(ref, () => ({
      submitFileUpload() {
        handleAddClick(false)
      },
      isSomeFileDropped() {
        return !!getCurrentItems().length
      },
    }))

    // Helpers

    const handleCloseOverlay = useCallback(() => {
      onItemRemove([])
    }, [onItemRemove])

    const isItemsFile = (item: UploadItem, file: File | UploadItemFile) =>
      file.name === item.file.name && file.lastModified === item.file.lastModified

    const getCurrentItems = () => {
      const { items } = form.getValues() as FormInputs
      return items || []
    }

    const resetBulkAccount = () => {
      setBulkAccount(undefined)
    }

    const removeItemFromList = (itemToRemove: UploadItem) => {
      const items = getCurrentItems()
      const itemIndex = items.findIndex((item) => isItemsFile(item, itemToRemove.file))

      if (itemIndex !== -1) {
        removeField(itemIndex)
      }
    }

    const decorateItemWithError = (itemToDecorate: UploadItem, errorMessage?: string) => {
      const items = getCurrentItems()
      const itemIndex = items.findIndex((item) => isItemsFile(item, itemToDecorate.file))

      if (itemIndex !== -1) {
        form.setValue(`items.${itemIndex}.errorMessage`, errorMessage || t('attachments.upload_error_generic'))
      }
    }

    // State changes

    useEffect(() => {
      let autoCloseTimeout: Timeout

      if (isSavingFinished && areAllFilesSaved) {
        autoCloseTimeout = setTimeout(() => {
          handleCloseOverlay()
        }, AUTO_CLOSE_TIMEOUT)
      }

      return () => {
        if (autoCloseTimeout) {
          clearTimeout(autoCloseTimeout)
        }
      }
    }, [handleCloseOverlay, isSavingFinished, areAllFilesSaved])

    const handleItemRemove = (index: number) => {
      const itemsLeft = [...fields] as UploadItem[]
      itemsLeft.splice(index, 1)

      removeField(index)
      onItemRemove(itemsLeft)
    }

    const setItemsErrors = () => {
      const items = getCurrentItems()

      for (let i = 0; i < items.length; i++) {
        const item = items[i]

        if (!item.file.errors?.length && !item.comment?.trim()) {
          form.setError(`items.${i}.comment`, { type: 'custom' })
        }
      }
    }

    const handleModalConfirm = () => {
      setIsModalOpen(false)

      window.setTimeout(() => {
        handleAddClick(true)
      }, transitionToMiliseconds(theme.transitions.fast))
    }

    const handleModalCancel = () => {
      setItemsErrors()
      setIsModalOpen(false)
    }

    const clearItemsErrors = () => {
      form.clearErrors('items')
    }

    const handleSaveItems = async (data: FormInputs) => {
      const { items: rawItems } = data

      const items = filterBrokenFilesItems(rawItems)

      setIsModalOpen(false)

      let localSavedCount = 0

      const saveItem = async (item: UploadItem) => {
        try {
          const itemFile = files.find((file) => isItemsFile(item, file))

          await onItemUpload(item, itemFile)
          setSavedCount((prevSavedCount) => prevSavedCount + 1)
          localSavedCount += 1
          removeItemFromList(item)
        } catch (error: any) {
          decorateItemWithError(item, error?.message)
        }
      }

      setSavedCount(0)
      setItemsToSaveCount(items.length)

      const itemsBatches = getItemsBatches(items)

      for (const itemsBatch of itemsBatches) {
        await Promise.all(itemsBatch.map(saveItem))
      }

      const isAnyFileUploaded = localSavedCount > 0
      onUploadFinish(isAnyFileUploaded)
    }

    const handleAddClick = (skipValidation?: boolean) => {
      clearItemsErrors()

      const rawItems = getCurrentItems()

      const items = filterBrokenFilesItems(rawItems)

      if (!skipValidation && withAccountSelection && isSomeCommentMissed(items)) {
        setIsModalOpen(true)
        return
      }

      form.handleSubmit((data) => handleSaveItems(data))()
    }

    return (
      <FormProvider {...form}>
        <div className={className}>
          <Flex alignItems="center" mb={withAccountSelection || !customSubmit ? '20px' : 0}>
            {withAccountSelection && (
              <AllAccountSelector
                bulkAccount={bulkAccount}
                setBulkAccount={setBulkAccount}
                areAllFilesSaved={areAllFilesSaved}
              />
            )}
            {!customSubmit && (
              <Styled.ButtonWrapper>
                <Button disabled={areAllFilesSaved || isSaving} onClick={() => handleAddClick(false)}>
                  {t('voucher.inbox.form.add_attachments')}
                </Button>
              </Styled.ButtonWrapper>
            )}

            {isSavingFinished && !areAllFilesSaved && (
              <TextComponent ml="20px" color={theme.colors.warning}>
                {t('attachments.could_not_upload_some_file')}
              </TextComponent>
            )}
          </Flex>
          <Styled.FileListContainer sameHeightFileList={sameHeightFileList}>
            <SavingOverlay
              disabled={disabled}
              shouldDisplay={isSaving || areAllFilesSaved}
              isSavingFinished={isSavingFinished}
              itemsToSaveCount={itemsToSaveCount}
              onClose={handleCloseOverlay}
              savedCount={savedCount}
            />
            <Styled.FileListScrollableArea as="ul">
              {fields.map((field, index) => {
                const fieldHasErrors = field.file?.errors?.length

                return (
                  <Styled.FileItem key={field.id} as="li">
                    <FileThumbnail field={field as UploadItem} />
                    {!withAccountSelection && !fieldHasErrors && <TextComponent> {field.file?.name} </TextComponent>}

                    {/* Has to be like this instead of using the variable here, as typescript does not understand */}
                    {field.file?.errors?.length && (
                      <FileError fileName={field.file?.name} errorMessage={field.file?.errors[0].message} />
                    )}

                    {!fieldHasErrors && withAccountSelection && (
                      <AccountFormFragment
                        getCurrentItems={getCurrentItems}
                        field={field}
                        index={index}
                        bulkAccount={bulkAccount}
                        resetBulkAccount={resetBulkAccount}
                        filterBrokenFilesItems={filterBrokenFilesItems}
                      />
                    )}

                    {/* Hidden input is needed to be correctly handled by form library */}
                    <Input formControl={form.control} name={`items[${index}].file`} hidden />

                    <Styled.FileActionsContainer>
                      <Styled.CloseButton
                        color={theme.colors.greyscale2}
                        onClick={() => handleItemRemove(index)}
                        title={t('delete')}
                      />
                    </Styled.FileActionsContainer>
                  </Styled.FileItem>
                )
              })}
            </Styled.FileListScrollableArea>
          </Styled.FileListContainer>
          <ValidationModal
            type={ValidationModalType.MissingComments}
            isOpen={isModalOpen}
            onConfirm={handleModalConfirm}
            onCancel={handleModalCancel}
            onClose={handleModalCancel}
          />
        </div>
      </FormProvider>
    )
  },
)
