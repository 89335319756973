import { useQuery } from 'react-query'

import { QueryKeys } from '../../../enums/queryKeys'
import { useUserOrganization } from '../../app/organization'
import { fetchSubscriptionMetrics } from '../../settings/routes/OrganizationSubscriptionSettings/services/query-api'

export const useSubscriptionMetrics = () => {
  const { organization } = useUserOrganization()

  const organizationId = organization?.id || ''

  const { data, isLoading } = useQuery(
    [QueryKeys.SubscriptionMetrics, organizationId],
    () => fetchSubscriptionMetrics(organizationId),
    {
      enabled: !!organizationId,
    },
  )

  return {
    data,
    isLoading,
  }
}
