import { useModal } from '@design-system'

import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import { queryClient } from '../../../../../config/queryClient'
import { ModalId } from '../../../../../enums/modalId'
import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUpdateOrganizationSettings, useUserOrganization } from '../../../../app/organization'
import { useActorMutations } from '../../../../settings/routes/OrganizationAccessTokensSettings/hooks/useActorMutations'
import { CreateActorResponseData } from '../../../../settings/routes/OrganizationAccessTokensSettings/query-api'
import { FEATURE_AIS } from '../constants/FEATURE_AIS'
import { getDebtCollectionActorPayload } from '../utils/getDebtCollectionActorPayload'
import { notifyDebtCollectionError } from '../utils/notifyDebtCollectionError'
import { notifyDebtCollectionSuccess } from '../utils/notifyDebtCollectionSuccess'
import { useCreateDebtCollectionAccount } from './useCreateDebtCollectionAccount'
import { useFetchDebtCollectionToken } from './useFetchDebtCollectionToken'

export const useEnableDebtCollection = () => {
  const { t } = useTranslation()
  const { organization } = useUserOrganization()
  const { close: closeEnableDebtCollectionModal } = useModal(ModalId.EnableDebtCollectionModal)
  const { open: openDebtCollectionNoteModal } = useModal(ModalId.DebtCollectionNoteModal)
  const { token: debtCollectionToken, isLoading: isLoadingToken } = useFetchDebtCollectionToken()
  const { update: updateSettings, isLoading: isUpdatingSettings } = useUpdateOrganizationSettings({
    onSuccess: () => {
      notifyDebtCollectionSuccess(t)
      queryClient.invalidateQueries(QueryKeys.UserOrganizationSettings)
      closeEnableDebtCollectionModal()
      openDebtCollectionNoteModal()
    },
    onError: () => {
      notifyDebtCollectionError(t)
    },
  })
  const { create: createDebtCollectionAccount, isProcessing: isCreatingExternalAccount } =
    useCreateDebtCollectionAccount({
      onSuccess: () => {
        if (organization?.id) {
          updateSettings({
            key: FEATURE_AIS,
            organizationId: organization.id,
            value: '1',
          })
        } else {
          notifyDebtCollectionError(t)
        }
      },
      onError: () => {
        notifyDebtCollectionError(t)
      },
    })
  const { createActor, isCreatingActor } = useActorMutations({
    onSuccessCreate: (data: CreateActorResponseData) => {
      const token = data?.oAuthAccessTokens?.[0]?.material

      if (!!token && !!organization?.id) {
        createDebtCollectionAccount(token)
      } else {
        notifyDebtCollectionError(t)
      }
    },
  })

  const isProcessing = isCreatingActor || isCreatingExternalAccount || isUpdatingSettings

  const enableDebtCollection = useCallback(() => {
    if (debtCollectionToken && !!organization?.id) {
      createDebtCollectionAccount(debtCollectionToken)
    } else if (!debtCollectionToken && !isLoadingToken && !!organization?.id) {
      const createDebtCollectionActorPayload = getDebtCollectionActorPayload(organization.id)

      createActor(createDebtCollectionActorPayload)
    } else {
      notifyDebtCollectionError(t)
    }
  }, [debtCollectionToken, organization?.id, isLoadingToken, createDebtCollectionAccount, createActor, t])

  return { enableDebtCollection, isLoading: isLoadingToken, isProcessing }
}
