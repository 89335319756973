import isArray from 'lodash/isArray'
import { useQuery } from 'react-query'

import { QueryKeys } from '../../../../../enums/queryKeys'
import { useUmbrella } from '../../../../app/umbrellas'
import { getUmbrellaPaymentMethod } from '../query-api'

export const usePaymentMethod = () => {
  const { umbrella } = useUmbrella()
  const { data, isIdle, isLoading } = useQuery(
    [QueryKeys.UmbrellaPaymentMethod, umbrella?.id],
    () => getUmbrellaPaymentMethod(umbrella?.id as string),
    {
      enabled: !!umbrella?.id,
    },
  )

  return {
    data: isArray(data?.data) ? undefined : data?.data,
    isLoading: isIdle || isLoading,
  }
}
