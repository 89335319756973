import { TFunction } from 'i18next'
import { number, object, SchemaOf, string } from 'yup'

import { Account } from '../../../../../../../../app/accounts/types'

export const createOrEditVatAccountFormDefaultValues: CreateOrEditVatAccountForm = {
  account: undefined,
  type: '',
  priority: 1,
}
export interface CreateOrEditVatAccountForm {
  account: Partial<Account> | undefined
  type: string
  priority: number
}

export type CreateOrEditVatAccountFormSchema = SchemaOf<CreateOrEditVatAccountForm>

export const getValidationSchema = (t: TFunction): CreateOrEditVatAccountFormSchema => {
  return object({
    account: object({ id: string().required(t('required_field')) }),
    type: string().required(t('required_field')),
    priority: number().required(t('required_field')),
  })
}
